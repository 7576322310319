import React, { useEffect, useRef, useState } from 'react';
import CustomTabs from '../../../ReUsableComponent/CustomTabs';
import { userDetailsTab } from '../ViewUserDetails/ViewUserDetailsTabList';
import '../../StoreManagement/ViewStoreDetails/ViewStore.css';
import Loader from '../../../ReUsableComponent/Loader';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import TickIconWithGreenBg from '../../../assets/svg/noun_tick_684585.svg';
import PdfFileicon from '../../../assets/svg/pdfIcon.svg';
import JpgFileIcon from '../../../assets/svg/jpgIcon.svg';
import { userManagementActions } from '../../../store/reducers/UserManagementReducer';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import EditIcon from '../../../assets/svg/edit-icon.svg';
import StatusTag from '../../../ReUsableComponent/StatusTag';
import BrandImage from '../../../assets/png/hrxLogo.png';
import RightArrowIcon from '../../../assets/svg/rightArrow.svg';
import InputTextField from '../../../ReUsableComponent/InputTextField';
import CustomAutoComplete from '../../../ReUsableComponent/CustomAutocomplete';
import CustomDatePicker from '../../../ReUsableComponent/DatePickerComponent';
import { teamManagementActions } from '../../../store/reducers/TeamManagementReducer';
import { CreateAndUpdateUser, GetDesignationList, GetReportingManagerList, GetUserRoleList, UpdateUser, UploadUserDocs, GetBrandStateList } from '../TeamManagement.service';
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import { checkValidation } from '../../../utils/ValidationFunction';
import { getListStateCityByPincode } from '../../BrandManagement/BrandManagement.service';
import { GetBrandNameList, GetStoreCustomerNameList } from '../../RuleManagement/RuleManagement.service';
import { calculate_age } from '../../../utils/AgeCalculator';
import moment from 'moment';
import dayjs from 'dayjs';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import { dateIntoDayJs } from '../../../utils/DateConversions';
import MultiSelectCheckBox from '../../../ReUsableComponent/MultiSelectCheckBox';
import PNG from '../../../assets/svg/png.svg';
import CustomDropzone from '../../../ReUsableComponent/CustomDropzone';
import ImportIcon from '../../../assets/svg/uploadIconForDoc.svg';
import ProgressBarForFileUpload from '../../../ReUsableComponent/ProgressBarForFileUpload';
import SuccessPopup from '../../../ReUsableComponent/SuccessPopupMsg';
import FileRenderer from '../../../ReUsableComponent/FileRenderer';
import CustomModal from '../../../ReUsableComponent/CustomModal';
import CloseIcon from '../../../assets/svg/closeIconofmodal.svg';
import { getFileType } from './../../../ReUsableComponent/CustomFormValidation';

const GENDER = [{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }, { label: 'Other', value: 'Other' }];
const STATUS = [{ label: 'Active', value: 'Active' }, { label: 'Inactive', value: 'Inactive' }];
const REGION_LIST = [{ label: 'North', value: 'North', name: 'region' }, { label: 'East', value: 'East', name: 'region' }, { label: 'West', value: 'West', name: 'region' }, { label: 'South', value: 'South', name: 'region' }];

const uploadedFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 835,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};
const viewFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const ViewUserDetails = () => {
    const timeoutRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [selectedTab, setSelectedTab] = useState(useSelector((state) => state.userManagement.selectedTab));
    const [loading, setLoading] = useState(false);
    const USER_ID = useSelector(state => state.userAuthentication.userId);
    const userBreadCrumbLabel = useSelector((state) => state.userManagement.viewUserDetailsTabLabel);
    const userDetailsData = useSelector((state) => state.userManagement.viewUserDetailData);
    const [inputValue, setInputValue] = useState({
        'name': userDetailsData?.name, 'emp_id': userDetailsData?.emp_id, 'gender': userDetailsData?.gender, 'mobile_number': userDetailsData?.mobile_number,
        'email_id': userDetailsData?.email_id, 'doj': userDetailsData?.doj, 'dob': userDetailsData?.dob, 'age': userDetailsData?.age,
        'company': userDetailsData?.company, 'adhar_no': userDetailsData?.adhar_no, 'pan_no': userDetailsData?.pan_no, 'address': userDetailsData?.address,
        'pincode': userDetailsData?.pincode, 'city': userDetailsData?.city, 'state': userDetailsData?.state, 'region': userDetailsData?.region,
        'year_exp': userDetailsData?.year_exp, 'current_gross_ctc': userDetailsData?.current_gross_ctc, 'last_gross_ctc': userDetailsData?.last_gross_ctc, 'last_city': userDetailsData?.last_city,
        'door_count': userDetailsData?.door_count, 'gross_sales': userDetailsData?.gross_sales, 'bank_name': userDetailsData?.bank_name, 'accountNumber': userDetailsData?.accountNumber,
        'bank_ifsc': userDetailsData?.bank_ifsc, 'branch_name': userDetailsData?.branch_name, 'branch_address': userDetailsData?.branch_address, 'esi_no': userDetailsData?.esi_no,
        'pf_no': userDetailsData?.pf_no, 'remarks': userDetailsData?.remarks, 'brand_name': userDetailsData?.brand_name, 'store_detail': userDetailsData?.store_detail,
        'designation_id': userDetailsData?.designation_id, 'role': userDetailsData?.role, 'reporting_manager': userDetailsData?.reporting_manager, 'reporting_manager_mob': userDetailsData?.reporting_manager_mob,
        'reporting_manager_email': userDetailsData?.reporting_manager_email, 'exit_date': userDetailsData?.exit_date, 'login_type': userDetailsData?.login_type,
        'channel': userDetailsData?.channel, 'user_status': userDetailsData?.user_status, 'education': userDetailsData?.education, 'past_experience': userDetailsData?.past_experience,
        'previous_companies': userDetailsData?.previous_companies, 'pan_doc': userDetailsData?.pan_doc, 'adhar_doc': userDetailsData?.adhar_doc, 'brand_region': userDetailsData?.brand_region,
        'brand_state': userDetailsData?.brand_state,
    });

    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const [validation, setValidation] = useState({});
    const [openEditForm, setOpenEditForm] = useState(false);
    const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
    const STORE_LIST = useSelector(state => state.userManagement.storeList);
    const BRNAD_LIST = useSelector(state => state.userManagement.brandList);
    const ROLE_LIST = useSelector(state => state.userManagement.userRoles);
    const DESIGNATION_LIST = useSelector(state => state.userManagement.designations);
    const USER_LIST = useSelector(state => state.userManagement.reportingMngr);
    const CHANNEL_LIST = useSelector(state => state.userManagement.channels);
    const SELECTED_STORES = useSelector(state => state.userManagement.selectedStores);
    const [showViewMoreDataPopup, setShowViewMoreDataPopup] = useState(false);
    const [showViewMoreData, setShowViewMoreData] = useState('');
    const LOGIN_TYPE = [{ label: 'Web', value: 'web' }, { label: 'Mobile', value: 'mobile' }, { label: 'Both', value: 'both' }];

    const [openFileViewerModal, setOpenFileViewerModal] = useState(false);
    const [viewFile, setViewFile] = useState([]);

    const STATES_LIST = useSelector(state => state.userManagement.statesList);
    const [selectedRegion, setSelectedRegion] = useState(userDetailsData && userDetailsData?.brand_region);
    const [statesNameList, setStatesNameList] = useState([]);
    const [brandNameQueryParm, setBrandNameQueryParm] = useState(userDetailsData && userDetailsData?.brand_name?.map(item => item?.brand_id));
    const [selectedBrandState, setSelectedBrandState] = useState(userDetailsData && userDetailsData?.brand_state);

    const openFile = (item) => {
        setViewFile(item);
        setOpenFileViewerModal(true);
    }

    const handleTabChange = (event, newValue) => {
        if (!openEditForm) {
            setSelectedTab(newValue);
            userDetailsTab.forEach((tab, id) => {
                if (id === newValue) {
                    dispatch(userManagementActions.setViewUserDetailsTabLabel({ type: tab.label }));
                }
            })
            dispatch(userManagementActions.setSelectedTab({ type: newValue }));
        }
    };

    useEffect(() => {
        if (userDetailsData?.store_detail?.length !== 0) {
            dispatch(userManagementActions.setSelectedStores({ type: userDetailsData?.store_code }));
        }

    }, [userDetailsData])

    useEffect(() => {
        if (selectedTab === 0) {
            //fieldcheck
            if (!inputValue?.name || !inputValue?.emp_id || !inputValue?.gender || !inputValue?.mobile_number || !inputValue?.designation_id || !inputValue?.role
                || !inputValue?.email_id || !inputValue?.doj || !inputValue?.dob || !inputValue?.age || !inputValue?.company || !inputValue?.adhar_no
                || !inputValue?.pan_no || !inputValue?.address || !inputValue?.pincode || !inputValue?.city || !inputValue?.state || !inputValue?.region
                || !inputValue?.year_exp || !inputValue?.current_gross_ctc || !inputValue?.last_gross_ctc || (validation?.name && validation?.name !== '')
                || (validation?.emp_id && validation?.emp_id !== '') || (validation?.last_city && validation?.last_city !== '') || (validation?.mobile_number && validation?.mobile_number !== '')
                || (validation?.email_id && validation?.email_id !== '') || (validation?.doj && validation?.doj !== '') || (validation?.dob && validation?.dob !== '')
                || (validation?.age && validation?.age !== '') || (validation?.company && validation?.company !== '') || (validation?.adhar_no && validation?.adhar_no !== '')
                || (validation?.pan_no && validation?.pan_no !== '') || (validation?.address && validation?.address !== '') || (validation?.pincode && validation?.pincode !== '')
                || (validation?.city && validation?.city !== '') || (validation?.state && validation?.state !== '') || (validation?.region && validation?.region !== '')
                || (validation?.year_exp && validation?.year_exp !== '') || (validation?.current_gross_ctc && validation?.current_gross_ctc !== '')
                || (validation?.last_gross_ctc && validation?.last_gross_ctc !== '')) {
                setDisableSubmitBtn(true);
            } else {
                setDisableSubmitBtn(false);
            }
        } else if (selectedTab === 1) {
            if (!inputValue?.bank_name || !inputValue?.accountNumber || !inputValue?.bank_ifsc || !inputValue?.branch_name
                || !inputValue?.branch_address || !inputValue?.esi_no || !inputValue?.pf_no || !inputValue?.remarks || (validation?.branch_address && validation?.branch_address !== '')
                || (validation?.bank_name && validation?.bank_name !== '') || (validation?.accountNumber && validation?.accountNumber !== '')
                || (validation?.bank_ifsc && validation?.bank_ifsc !== '') || (validation?.branch_name && validation?.branch_name !== '')
                || (validation?.branch_address && validation?.branch_address !== '') || (validation?.esi_no && validation?.esi_no !== '')
                || (validation?.pf_no && validation?.pf_no !== '') || (validation?.remarks && validation?.remarks !== '')) {
                setDisableSubmitBtn(true);
            } else {
                setDisableSubmitBtn(false);
            }
        } else if (selectedTab === 2) {
            if (!inputValue?.exit_date || !inputValue?.login_type || !inputValue?.channel
                || ((inputValue?.designation_id === 'SM' || inputValue?.designation_id === 'FL') && inputValue?.store_id?.length < 1 || inputValue?.brand_name?.length < 1)
                || ((inputValue?.designation_id === 'Manager' || inputValue?.designation_id === 'ASM') && inputValue?.store_id?.length < 1 || inputValue?.brand_name?.length < 1)
                || ((inputValue?.designation_id === 'RSM') && inputValue?.store_id?.length < 1 || inputValue?.brand_name?.length < 1)
            ) {
                setDisableSubmitBtn(true);
            } else {
                setDisableSubmitBtn(false);
            }
        } else if (selectedTab === 3) {
            if (!inputValue?.education || (validation?.education && validation?.education !== '')) {
                setDisableSubmitBtn(true);
            } else {
                setDisableSubmitBtn(false);
            }
        } else if (selectedTab === 4) {
            if ((inputValue?.pan_doc?.length === 0) || (inputValue?.pan_doc === undefined) || (inputValue?.adhar_doc?.length === 0) || (inputValue?.adhar_doc === undefined)) {
                setDisableSubmitBtn(true);
            } else {
                setDisableSubmitBtn(false);
            }
        }
    }, [validation, inputValue, selectedTab])

    const closeSuccessPopup = () => {
        setOpenSuccessPopup(false);
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        history.push('/app/teamManagement');
    };
    const handleViewMore = (Name) => {
        setShowViewMoreDataPopup(true);
        setShowViewMoreData(Name);
    };
    useEffect(() => {
        let brandIds = []
        userDetailsData?.brand_name?.map(brand => {
            brandIds.push(brand?.brand_id)
        })
        setBrandNameQueryParm(brandIds);
        setSelectedRegion(userDetailsData?.brand_region);
        setSelectedBrandState(userDetailsData?.brand_state)
    }, [])
    useEffect(() => {
        getBrandList();
        // getStoreList();
        getReportingmngrList('');
        getDesignations();
        getUserRoleList();
        getChannelList();
    }, []);

    useEffect(() => {
        getBrandStateList(brandNameQueryParm.toString());
    }, [selectedRegion]);

    useEffect(() => {
        getStoreList();
        getReportingmngrList('')
    }, [brandNameQueryParm, selectedRegion, selectedBrandState]);

    const getBrandList = () => {
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item?.brand_name,
                    value: item?.brand_name,
                    brand_id: item?._id
                }
                dropdownData.push(row)
            })
            dispatch(userManagementActions.setBrandList({ type: dropdownData }));

        });
    }

    const getBrandStateList = (brand) => {
        let param = `?region=${selectedRegion}&brand=${brand}`
        GetBrandStateList(param, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item,
                    value: item,
                }
                dropdownData.push(row)
            });
            setStatesNameList(dropdownData);
            dispatch(userManagementActions.setStatesList({ type: dropdownData }));
        });
    }

    const getStoreList = () => {
        let queryParams = `?brand_id=${brandNameQueryParm?.join(',')}&region=${selectedRegion ? selectedRegion : ''}&state=${selectedBrandState ? selectedBrandState : ''}`;
        GetStoreCustomerNameList(queryParams, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item?.customer_name,
                    value: item?.customer_name,
                    store_code: item?.store_code,
                    store_id: item?._id
                }
                dropdownData.push(row);
            })
            dispatch(userManagementActions.setStoreList({ type: dropdownData }));
        });
    }
    const getUserRoleList = () => {
        let param = `?module=${'user_roles'}&dropdown_key=user_roles`
        GetUserRoleList(param, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data[0]?.dropdown_values?.map(item => {
                let row = {
                    label: item,
                    value: item,
                }
                dropdownData.push(row);
            })
            dispatch(userManagementActions.setUserRoles({ type: dropdownData }));
        });
    }
    const getDesignations = () => {
        let param = `?module=${'user_designation'}&dropdown_key=user_designation`
        GetDesignationList(param, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data[0]?.dropdown_values?.map(item => {
                let row = {
                    label: item,
                    value: item,
                }
                dropdownData.push(row);
            })
            dispatch(userManagementActions.setDesignations({ type: dropdownData }));
        });
    }

    const getChannelList = () => {
        let param = `?module=${'brand'}&dropdown_key=ChannelOffline`
        GetDesignationList(param, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data[0]?.dropdown_values?.map(item => {
                let row = {
                    label: item,
                    value: item,
                }
                dropdownData.push(row);
            })
            dispatch(userManagementActions.setChannels({ type: dropdownData }));
        });
    }

    const handleUpdateUser = () => {
        setLoading(true);
        let param = `?user_id=${userDetailsData?._id}`
        UpdateUser(inputValue, param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                dispatch(userManagementActions.setViewUserDetailData({ type: response?.data?.data }));
                setOpenEditForm(false);
                if (selectedTab !== 4) {
                    ToastSuccessService(response.data.message);
                } else {
                    setOpenSuccessPopup(true);
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    // Set a new timeout to automatically close the popup
                    timeoutRef.current = setTimeout(() => {
                        closeSuccessPopup();
                    }, 5000);
                }
            } else {
                ToastErrorService(response.data.message)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const convertDate = (date) => {
        let newDate = dateIntoDayJs(date)
        return moment(newDate).format("DD MMM YYYY");
    }

    const convertDateTo = (openingDate) => {
        let date = new Date(openingDate);
        // Extract the date part
        let year = date.getFullYear();
        let month = date.getMonth() + 1; // Month is zero-based, so we add 1
        let day = date.getDate();

        // Create a new date string in the format YYYY-MM-DD
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        return moment(dateString).format("DD MMM YYYY");
    };

    const getReportingmngrList = (name, keyName) => {
        let param = `?name=${name}&brand_id=${brandNameQueryParm?.join(',')}`
        GetReportingManagerList(param, USER_TOKEN).then(response => {
            if (name) {
                setInputValue(values => ({ ...values, ['reporting_manager_email']: response?.data?.data[0].email, ['reporting_manager_mob']: response?.data?.data[0].mobileNumber }));
            } else {
                let dropdownData = [];
                response?.data?.data?.map((item, index) => {
                    let row = {
                        label: item?.name,
                        value: item?.name,
                        id: index,
                        email_id: item?.email
                    }
                    dropdownData.push(row);
                })
                dropdownData = dropdownData.filter(function (element) {
                    return element?.email_id !== inputValue?.email_id;
                })
                dispatch(userManagementActions.setReportingMngr({ type: dropdownData }));
            }
        });
    }

    const onChangeValue = (event, name, value) => {
        if (name === 'reporting_manager') {
            getReportingmngrList(value.value);
        } else if (name === 'brand_region') {
            setSelectedRegion(value?.value);
        } else if (name === 'brand_state') {
            setSelectedBrandState(value?.value);
            // dispatch(userManagementActions.setSelectedStates({ type: value?.value }));
        }
        setInputValue(values => ({ ...values, [name]: value.value }));

    };

    const arrayCheck = (item) => {
        if (typeof item === 'object' && item[0]?.brand_name) {
            return item?.slice(0, 2).map(brand => brand?.brand_name)?.toString();
        } else if (typeof item[0] === 'string' && item[0] !== '') {
            return item.toString();
        } else {
            return '-';
        }
    }

    const handleFileDrop = (files, name) => {
        setLoading(true);
        const fileUploaded = files[0];
        const formData = new FormData();
        if (name === 'pan_doc') {
            formData.append('service_type', 'Pan Document');
        } else if (name === 'adhar_doc') {
            formData.append('service_type', 'Adhar Document');
        }
        formData.append('service', 'User_Documents');
        formData.append('file', fileUploaded);
        let userDocs = [];
        UploadUserDocs(formData, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                userDocs = { ...userDocs, [name]: response.data.data }
                setInputValue(values => ({
                    ...values, [name]: [{ 'fileUrl': response?.data?.data?.file_url, 'size': response?.data?.data?.size, 'fileName': response?.data?.data?.fileName, 'fileType': response?.data?.data?.file_type, 'created_at': response?.data?.data?.created_at }]
                }));
            }
        }).catch(error => {
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    };

    const checkMinLength = (name, minlength, value) => {
        if (value?.length < minlength) {
            setValidation(prev => ({ ...prev, [name]: `Please enter minimum ${minlength} character` }))
        }
    }

    const onInputValue = (event, type) => {
        const name = event.target.name;
        if (name === 'name' || name === 'emp_id' || name === 'last_city' || name === 'bank_name' || name === 'address'
            || name === 'previous_companies' || name === 'branch_address' || name === 'education' || name === 'company'
            || name === 'branch_name' || name === 'remarks' || name === 'past_experience' || name === 'previous_companies') {
            checkMinLength(name, event.target.minLength, event.target.value);
        }
        if (name === 'mobile_number' || name === 'adhar_no' || name === 'pincode' || name === 'door_count' || name === 'accountNumber' || name === 'esi_no') {
            event.target.value = event.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
        } else if (name === 'name' || name === 'past_experience') {
            event.target.value = event.target.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1');
        } else if (name === 'pan_no' || name === 'bank_ifsc' || name === 'pf_no') {
            event.target.value = event.target.value.toUpperCase();
        } else if (name === 'gross_sales') {
            event.target.value = event.target.value.replace(/[^0-9,.]/g, '').replace(/(\..*)\./g, '$1');
        } else if (name === 'last_gross_ctc' || name === 'current_gross_ctc') {
            event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        }
        if (name === 'store_code') {
            setInputValue(values => ({
                ...values, 'store_detail': {
                    ...values.store_detail, [name]: event.target.value
                }
            }));
        } else {
            let valuecheck = { [name]: event.target.value }
            checkValidation(valuecheck, validation, name)
            setInputValue(values => ({ ...values, [name]: event.target.value }));
        }
        if (name === 'pincode' && event.target.value?.length === 6) {
            getCityStateByPin(event.target.value);
        }

    }

    const getCityStateByPin = (pincode) => {
        const param = `?pincode=${pincode}`
        getListStateCityByPincode(param, USER_TOKEN).then(response => {
            if (response?.data?.data?.length > 0) {
                setInputValue(values => ({
                    ...values, ['state']: response?.data?.data[0]?.state,
                    ['city']: response?.data?.data[0]?.city, ['region']: response?.data?.data[0]?.region
                }))
            } else {
                setInputValue(values => ({
                    ...values, ['state']: '',
                    ['city']: '', ['region']: ''
                }))
                ToastErrorService('No data available for given pincode');
            }

            let stateData = response?.data?.data?.map((item) => ({
                label: item?.state,
                value: item?.state,
                name: 'state'
            }));
            const uniqueStateData = Array.from(new Set(stateData.map(item => item.value))).map(value => {
                return stateData.find(item => item.value === value);
            });

            let cityData = response?.data?.data?.map((item) => ({
                label: item?.city,
                value: item?.city,
                name: 'city'
            }));
            const uniqueCityData = Array.from(new Set(cityData.map(item => item.value))).map(value => {
                return cityData.find(item => item.value === value);
            });

            let regionData = response?.data?.data?.map((item) => ({
                label: item?.region,
                value: item?.region,
                name: 'region'
            }));
            const uniqueRegionData = Array.from(new Set(regionData.map(item => item.value))).map(value => {
                return regionData.find(item => item.value === value);
            });

            let areaData = response?.data?.data?.map((item) => ({
                label: item?.area,
                value: item?.area,
                name: 'area'
            }));
            const uniqueAreaData = Array.from(new Set(areaData.map(item => item.value))).map(value => {
                return areaData.find(item => item.value === value);
            });
            let master = [{ stateMaster: uniqueStateData, cityMaster: uniqueCityData, regionMaster: uniqueRegionData, areaMaster: uniqueAreaData }]
            dispatch(teamManagementActions.setMasterData({ type: master }))
            // setAddressMaster(prev => ({ ...prev, stateMaster: uniqueStateData, cityMaster: uniqueCityData, regionMaster: uniqueRegionData, areaMaster: uniqueAreaData }))
        }).catch(error => {

        }).finally(() => {

        })
    }

    const onSelectValue = (event, name) => {
        const {
            target: { value },
        } = event;
        if (name === 'store_id') {
            let storeObject = [];
            value.map(storeName => {
                const selectedStore = STORE_LIST?.find(store => store?.value === storeName);
                let storeSelected = {
                    store_id: selectedStore?.store_id,
                    store_name: selectedStore?.label,
                    store_code: selectedStore?.store_code,
                }
                storeObject.push(storeSelected);
                dispatch(userManagementActions.setSelectedBrands({ type: value }));
            }).filter(store => store);
            setInputValue(values => ({
                ...values, 'store_detail': storeObject,
            }));
            dispatch(userManagementActions.setSelectedStores({ type: value }));

        } else if (name === 'brand_name') {
            let brandObject = [];
            let brandIdsObject = [];
            value.map(brandName => {
                const selectedBrand = BRNAD_LIST?.find(brand => brand?.value === brandName);
                let brandSelected = {
                    brand_name: selectedBrand?.label,
                    brand_id: selectedBrand?.brand_id
                }
                brandObject.push(brandSelected);
                // brandIdsObject.push(brandSelected?.brand_id);
                dispatch(userManagementActions.setSelectedBrands({ type: value }));
            }).filter(store => store);
            brandObject = brandObject.filter(function (element) {
                return element?.brand_id !== undefined;
            })
            brandObject.forEach(item => { brandIdsObject.push(item?.brand_id) });
            setInputValue(values => ({
                ...values, 'brand_name': brandObject, brand_state: '', store_detail: [], reporting_manager: '', reporting_manager_mob: '', reporting_manager_email: ''
            }));
            setBrandNameQueryParm(brandIdsObject);
            getBrandStateList(brandIdsObject.toString());
        }
    }
    const onDateSelect = (value, name) => {
        let date = new Date(value)
        if (name === 'dob') {
            setInputValue(values => ({ ...values, ['age']: calculate_age(date) }));
        }
        setInputValue(values => ({ ...values, [name]: moment(date).format("DD-MM-YYYY") }));
    }

    const onClickEditDetails = () => {
        setOpenEditForm(true);
    }

    const valueCheck = (value) => {
        if (value) {
            return value
        } else {
            return '-'
        }
    }


    const onClickCancel = () => {
        setOpenEditForm(false);
    }


    const fileTypeIcon = (fileType) => {
        if (fileType === 'pdf') {
            return PdfFileicon
        } else if (fileType === 'jpeg' || fileType === 'jpg') {
            return JpgFileIcon
        } else {
            return PNG
        }
    }

    const removeFile = (file) => {
        if (file === 'adhar_doc') {
            setInputValue(values => ({ ...values, ['adhar_doc']: undefined }));
        } else {
            setInputValue(values => ({ ...values, ['pan_doc']: undefined }));
        }
    }

    const renderTabData = (currentTab, brandDetails) => {
        if (currentTab === 0) {
            return (<div className=''>
                <div className='montSerratFont fontSize18 tabheader'>User Personal Information</div>
                <div className='position-relative'>
                    {!openEditForm && <div className='editStoreBtn'>
                        <SecondaryButton onClick={onClickEditDetails} title={<div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <img src={EditIcon} alt="" />
                                <div className='textColorBlue fontMedium'>Edit Details</div>
                            </div>
                        </div>} />
                    </div>}
                </div>
                <div className='paddingTop24 notoSansFont'>
                    {(userDetailsData && !openEditForm) ? <div className='storeOwnerDetails'>
                        <div>
                            <div className='storeLabel'>Name</div>
                            <div className='storeValue text-capitalize'>{valueCheck(userDetailsData?.name)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Gender</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.gender)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Email Id</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.email_id)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Mobile Number</div>
                            <div className='storeValue'>+91 {userDetailsData?.mobile_number}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Emp Id</div>
                            <div className='storeValue'>{userDetailsData?.emp_id}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Birth Date</div>
                            <div className='storeValue'>{convertDate(userDetailsData?.dob)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Age</div>
                            <div className='storeValue'>{userDetailsData?.age ? userDetailsData?.age + ' Years' : '-'}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>User Designation</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.designation_id)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>User Role</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.role)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Aadhaar Number</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.adhar_no)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>PAN Number</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.pan_no)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Address</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.address)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Region</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.region)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Current Gross CTC</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.current_gross_ctc)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Last Gross CTC</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.last_gross_ctc)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Company</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.company)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Years Of Experience</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.year_exp)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Date Of Joining</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.doj) !== '-' ? convertDate(userDetailsData?.doj) : valueCheck(userDetailsData?.doj)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Pincode</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.pincode)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>City</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.city)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>State</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.state)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Last Working City/District</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.last_city)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Door Count</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.door_count)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Annual Business Value (Gross Sales)</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.gross_sales)}</div>
                        </div>
                    </div> :
                        <form >
                            <div className={`pt-3 formContainer ${openEditForm && 'teamMngmnt'} customScroll`}>
                                <div>
                                    <InputTextField errored={inputValue?.name ? ((validation?.name && validation?.name !== '') ? true : false) : false} errorMsg={validation.name} minLength={3} maxLength={80} name={'name'} type='text' disabled={false} value={inputValue?.name} onChange={(e) => onInputValue(e, 'Func_Alpha')} required label='Name' />
                                </div>
                                <div>
                                    <InputTextField errored={inputValue?.emp_id ? ((validation?.emp_id && validation?.emp_id !== '') ? true : false) : false} errorMsg={validation.emp_id} minLength={3} maxLength={80} name={'emp_id'} type='text' disabled={false} value={inputValue?.emp_id} onChange={onInputValue} required label='Employee Id' />
                                </div>
                                <div>
                                    <CustomAutoComplete name={'gender'} value={inputValue?.gender} onChange={(e, value) => onChangeValue(e, 'gender', value)} required options={GENDER} label='Gender*' />
                                </div>
                                <div>
                                    <InputTextField errored={inputValue?.mobile_number ? ((validation?.mobile_number && validation?.mobile_number !== '') ? true : false) : false} errorMsg={validation.mobile_number} minLength={10} maxLength={10} name={'mobile_number'} type='text' disabled={false} value={inputValue?.mobile_number} onChange={onInputValue} required label='Mobile Number' />
                                </div>
                                <div>
                                    <InputTextField errored={inputValue?.email_id ? ((validation?.email_id && validation?.email_id !== '') ? true : false) : false} errorMsg={validation.email_id} minLength={6} maxLength={80} name={'email_id'} type='text' disabled={false} value={inputValue?.email_id} onChange={onInputValue} required label='E- Mail Id' />
                                </div>
                                <div>
                                    <CustomAutoComplete value={inputValue?.designation_id} onChange={(e, value) => onChangeValue(e, 'designation_id', value)} required options={DESIGNATION_LIST} label='User Designation*' />
                                </div>
                                <div>
                                    <CustomAutoComplete value={inputValue?.role} onChange={(e, value) => onChangeValue(e, 'role', value)} required options={ROLE_LIST} label='User Role*' />
                                </div>
                                <div>
                                    <CustomDatePicker maxDateRange={dayjs().subtract(18, 'year')} disabled={false} value={inputValue?.dob} defaultValue={dayjs(dateIntoDayJs(inputValue?.dob))} onDateChange={(newValue) => onDateSelect(newValue, 'dob')} required label='Birth Date*' />
                                </div>
                                <div>
                                    <InputTextField InputLabelProps={{ shrink: inputValue?.age ? true : false }} name={'age'} type='text' disabled={true} value={inputValue?.age} onChange={onInputValue} required label='Age' />
                                </div>
                                <div>
                                    <CustomDatePicker minDateRange={dayjs(dateIntoDayJs(inputValue?.dob)).add(18, 'year')} disabled={false} value={inputValue?.doj} defaultValue={dayjs(dateIntoDayJs(inputValue?.doj))} onDateChange={(newValue) => onDateSelect(newValue, 'doj')} required label='Date Of Joining*' />
                                </div>
                                <div>
                                    <InputTextField errored={inputValue?.company ? ((validation?.company && validation?.company !== '') ? true : false) : false} errorMsg={validation.company} minLength={3} maxLength={80} name={'company'} type='text' disabled={false} value={inputValue?.company} onChange={onInputValue} required label='Company' />
                                </div>
                                <div>
                                    <InputTextField errored={inputValue?.last_city ? ((validation?.last_city && validation?.last_city !== '') ? true : false) : false} errorMsg={validation.last_city} minLength={3} maxLength={40} name={'last_city'} type='text' disabled={false} value={inputValue?.last_city} onChange={onInputValue} required={false} label='Last Working City/District' />
                                </div>
                                <div>
                                    <InputTextField errored={inputValue?.adhar_no ? ((validation?.adhar_no && validation?.adhar_no !== '') ? true : false) : false} errorMsg={validation.adhar_no} minLength={12} maxLength={12} name={'adhar_no'} type='text' disabled={false} value={inputValue?.adhar_no} onChange={onInputValue} required label='Aadhaar Number' />
                                </div>
                                <div>
                                    <InputTextField errored={inputValue?.pan_no ? ((validation?.pan_no && validation?.pan_no !== '') ? true : false) : false} errorMsg={validation.pan_no} minLength={10} maxLength={10} name={'pan_no'} type='text' disabled={false} value={inputValue?.pan_no} onChange={onInputValue} required label='PAN Number' />
                                </div>
                                <div>
                                    <InputTextField errored={inputValue?.address ? ((validation?.address && validation?.address !== '') ? true : false) : false} errorMsg={validation.address} minLength={3} maxLength={100} name={'address'} type='text' disabled={false} value={inputValue?.address} onChange={onInputValue} required label='Address' />
                                </div>
                                <div>
                                    <InputTextField errored={inputValue?.pincode ? ((validation?.pincode && validation?.pincode !== '') ? true : false) : false} errorMsg={validation.pincode} minLength={6} maxLength={6} name={'pincode'} type='text' disabled={false} value={inputValue?.pincode} onChange={onInputValue} required label='Pincode' />
                                </div>
                                <div>
                                    <InputTextField disabled={true} InputLabelProps={{ shrink: inputValue?.city ? true : false }} minLength={3} maxLength={40} value={inputValue?.city} required label='City' />
                                </div>
                                <div>
                                    <InputTextField disabled={true} InputLabelProps={{ shrink: inputValue?.region ? true : false }} minLength={3} maxLength={30} value={inputValue?.region} required label='Region' />
                                </div>
                                <div>
                                    <InputTextField disabled={true} InputLabelProps={{ shrink: inputValue?.state ? true : false }} minLength={3} maxLength={20} value={inputValue?.state} required label='State' />
                                </div>
                                <div>
                                    <InputTextField minLength={1} maxLength={4} name='door_count' type='text' disabled={false} value={inputValue?.door_count} onChange={onInputValue} required={false} label='Door Count' />
                                </div>
                                <div>
                                    <InputTextField errored={inputValue?.year_exp ? ((validation?.year_exp && validation?.year_exp !== '') ? true : false) : false} errorMsg={validation.year_exp} minLength={1} maxLength={4} name='year_exp' type='text' disabled={false} value={inputValue?.year_exp} onChange={onInputValue} required label='Years of Experience' />
                                </div>
                                <div>
                                    <InputTextField errored={inputValue?.gross_sales ? ((validation?.gross_sales && validation?.gross_sales !== '') ? true : false) : false} errorMsg={validation.gross_sales} name='gross_sales' type='text' disabled={false} value={inputValue?.gross_sales} onChange={onInputValue} required={false} label='Annual Business Value (Gross Sales)' />
                                </div>
                                <div>
                                    <InputTextField errored={inputValue?.last_gross_ctc ? ((validation?.last_gross_ctc && validation?.last_gross_ctc !== '') ? true : false) : false} errorMsg={validation.last_gross_ctc} name='last_gross_ctc' type='text' disabled={false} value={inputValue?.last_gross_ctc} onChange={onInputValue} required label='Last Gross CTC (LPA)' />
                                </div>
                                <div>
                                    <InputTextField errored={inputValue?.current_gross_ctc ? ((validation?.current_gross_ctc && validation?.current_gross_ctc !== '') ? true : false) : false} errorMsg={validation.current_gross_ctc} name='current_gross_ctc' type='text' disabled={false} value={inputValue?.current_gross_ctc} onChange={onInputValue} required label='Current Gross CTC (LPA)' />
                                </div>
                            </div>
                        </form>}
                </div>
            </div>)
        } else if (currentTab === 1) {
            return (<div className=''>
                <div className='montSerratFont fontSize18 tabheader'>User Bank Details</div>
                {!openEditForm && <div className='position-relative'>
                    <div className='editStoreBtn'>
                        <SecondaryButton onClick={onClickEditDetails} title={<div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <img src={EditIcon} alt="" />
                                <div className='textColorBlue fontMedium'>Edit Details</div>
                            </div>
                        </div>} />
                    </div>
                </div>}
                <div className='position-relative'>
                </div>
                <div className='paddingTop24 notoSansFont'>
                    {(userDetailsData && !openEditForm) ? <div className='storeOwnerDetails'>
                        <div>
                            <div className='storeLabel'>Bank Name</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.bank_name)}</div>
                        </div>

                        <div>
                            <div className='storeLabel'>Bank Account Number</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.accountNumber)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Branch</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.branch_name)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Branch Address</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.branch_address)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>IFSC Code</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.bank_ifsc)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>ESI Number</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.esi_no)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>PF Number</div>
                            <div className='storeValue text-capitalize'>{valueCheck(userDetailsData?.pf_no)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Remarks</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.remarks)}</div>
                        </div>
                    </div> :
                        <div className='pt-3 formContainer teamMngmnt customScroll'>
                            <div>
                                <InputTextField errored={inputValue?.bank_name ? ((validation?.bank_name && validation?.bank_name !== '') ? true : false) : false} errorMsg={validation?.bank_name} name={'bank_name'} minLength={2} maxLength={120} type='text' disabled={false} value={inputValue?.bank_name} onChange={onInputValue} required label='Bank Name' />
                            </div>
                            <div>
                                <InputTextField errored={inputValue?.accountNumber ? ((validation?.accountNumber && validation?.accountNumber !== '') ? true : false) : false} errorMsg={validation?.accountNumber} name={'accountNumber'} minLength={8} maxLength={20} type='text' disabled={false} value={inputValue?.accountNumber} onChange={onInputValue} required label='Account Number' />
                            </div>
                            <div>
                                <InputTextField errored={inputValue?.bank_ifsc ? ((validation?.bank_ifsc && validation?.bank_ifsc !== '') ? true : false) : false} errorMsg={validation?.bank_ifsc} name={'bank_ifsc'} minLength={11} maxLength={11} type='text' disabled={false} value={inputValue?.bank_ifsc} onChange={onInputValue} required label='IFSC Code' />
                            </div>
                            <div>
                                <InputTextField errored={inputValue?.branch_name ? ((validation?.branch_name && validation?.branch_name !== '') ? true : false) : false} errorMsg={validation?.branch_name} name={'branch_name'} minLength={2} maxLength={80} type='text' disabled={false} value={inputValue?.branch_name} onChange={onInputValue} required label='Branch' />
                            </div>
                            <div>
                                <InputTextField errored={inputValue?.branch_address ? ((validation?.branch_address && validation?.branch_address !== '') ? true : false) : false} errorMsg={validation?.branch_address} name={'branch_address'} minLength={3} maxLength={100} type='text' disabled={false} value={inputValue?.branch_address} onChange={onInputValue} required label='Branch Address' />
                            </div>
                            <div>
                                <InputTextField errored={inputValue?.esi_no ? ((validation?.esi_no && validation?.esi_no !== '') ? true : false) : false} errorMsg={validation?.esi_no} name={'esi_no'} minLength={17} maxLength={17} type='text' disabled={false} value={inputValue?.esi_no} onChange={onInputValue} required label='ESI Number' />
                            </div>
                            <div>
                                <InputTextField errored={inputValue?.pf_no ? ((validation?.pf_no && validation?.pf_no !== '') ? true : false) : false} errorMsg={validation?.pf_no} name={'pf_no'} minLength={22} maxLength={26} type='text' disabled={false} value={inputValue?.pf_no} onChange={onInputValue} required label='PF Number' />
                            </div>
                            <div>
                                <InputTextField errored={inputValue?.remarks ? ((validation?.remarks && validation?.remarks !== '') ? true : false) : false} errorMsg={validation?.remarks} name={'remarks'} minLength={2} maxLength={100} type='text' disabled={false} value={inputValue?.remarks} onChange={onInputValue} required label='Remarks' />
                            </div>
                        </div>}
                </div>
            </div>)
        } else if (currentTab === 2) {
            return (<div className=''>
                <div className='montSerratFont fontSize18 tabheader'>User Onboarding Details</div>
                {!openEditForm && <div className='position-relative'>
                    <div className='editStoreBtn'>
                        <SecondaryButton onClick={onClickEditDetails} title={<div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <img src={EditIcon} alt="" />
                                <div className='textColorBlue fontMedium'>Edit Details</div>
                            </div>
                        </div>} />
                    </div>
                </div>}
                <div className='position-relative'>
                </div>
                <div className='paddingTop24 notoSansFont'>
                    {(userDetailsData && !openEditForm) ? <div className='storeOwnerDetails'>
                        <div>
                            <div className='storeLabel'>Brand Name</div>
                            <div className='storeValue'>{
                                userDetailsData?.brand_name?.length > 0
                                    ? arrayCheck(userDetailsData?.brand_name)
                                    : '-'}
                                {userDetailsData?.brand_name?.length > 2 && (
                                    <button className='viewMoreBtn' onClick={() => handleViewMore('brand')}>View More</button>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className='storeLabel'>Brand Region</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.brand_region)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Brand State</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.brand_state)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Store Name</div>
                            <div className='storeValue'>{
                                userDetailsData?.store_detail?.length > 0
                                    ? userDetailsData?.store_detail?.slice(0, 2).map(store => store?.store_name)?.toString()
                                    : '-'}
                                {userDetailsData?.store_detail?.length > 2 && (
                                    <button className='viewMoreBtn' onClick={() => handleViewMore('store')}>View More</button>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className='storeLabel'>Reporting Manager</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.reporting_manager)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Reporting Manager Email Id</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.reporting_manager_email)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Reporting Manager Mobile Number</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.reporting_manager_mob)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Current Company Date Of Exit</div>
                            <div className='storeValue'>{valueCheck(userDetailsData?.exit_date)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Login Type</div>
                            <div className='storeValue text-capitalize'>{valueCheck(userDetailsData?.login_type)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>Channel</div>
                            <div className='storeValue text-uppercase'>{valueCheck(userDetailsData?.channel)}</div>
                        </div>
                        <div>
                            <div className='storeLabel'>User Status</div>
                            <div className='storeValue text-capitalize'>
                                <StatusTag status={userDetailsData?.user_status.toUpperCase() === 'active'.toUpperCase() ? 'Active' : 'Inactive'} />
                            </div>
                        </div>
                    </div> :
                        <div className='pt-3 formContainer teamMngmnt customScroll'>
                            <div>
                                <MultiSelectCheckBox showSelectAll={false} selectedValue={inputValue?.brand_name?.map(brand => brand?.brand_name)} handleChange={(e) => onSelectValue(e, 'brand_name')} required options={BRNAD_LIST} label='Brand Name*' />
                            </div>
                            <div>
                                <CustomAutoComplete value={inputValue?.brand_region} onChange={(e, value) => onChangeValue(e, 'brand_region', value)} required options={REGION_LIST} label='Brand Region' />
                            </div>
                            <div>
                                <CustomAutoComplete value={inputValue?.brand_state} onChange={(e, value) => onChangeValue(e, 'brand_state', value)} required options={STATES_LIST} label='Brand State' />
                            </div>
                            <div>
                                <MultiSelectCheckBox showSelectAll={false} selectedValue={inputValue?.store_detail?.map(store => store?.store_name)} handleChange={(e) => onSelectValue(e, 'store_id')} required options={STORE_LIST} label='Store Name*' />
                            </div>
                            <div>
                                <CustomAutoComplete value={inputValue?.reporting_manager} onChange={(e, value) => onChangeValue(e, 'reporting_manager', value)} options={USER_LIST} label='Reporting Manager' />
                            </div>
                            <div>
                                <InputTextField key={'reporting_manager_mob'} InputLabelProps={{ shrink: inputValue.reporting_manager_mob ? true : false }} name={'reporting_manager_mob'} minLength={3} maxLength={80} type='text' value={inputValue?.reporting_manager_mob} onChange={onInputValue} label='Reporting Manager Mobile Number' />
                            </div>
                            <div>
                                <InputTextField key={'reporting_manager_email'} InputLabelProps={{ shrink: inputValue.reporting_manager_email ? true : false }} name={'reporting_manager_email'} minLength={3} maxLength={80} type='text' value={inputValue?.reporting_manager_email} onChange={onInputValue} label='Reporting Manager Email Id' />
                            </div>
                            <div>
                                <CustomDatePicker disabled={false} defaultValue={inputValue?.exit_date ? dayjs(dateIntoDayJs(inputValue?.exit_date)) : null} value={inputValue?.exit_date} onDateChange={(newValue) => onDateSelect(newValue, 'exit_date')} required label='Current Company Date Of Exit*' />
                            </div>
                            <div>
                                <CustomAutoComplete value={inputValue?.login_type} onChange={(e, value) => onChangeValue(e, 'login_type', value)} required options={LOGIN_TYPE} label='Login Type*' />
                            </div>
                            <div>
                                <CustomAutoComplete value={inputValue?.channel} onChange={(e, value) => onChangeValue(e, 'channel', value)} required options={CHANNEL_LIST} label='Channel*' />
                            </div>
                            <div>
                                <CustomAutoComplete className='capitalize' disabled={false} value={inputValue?.user_status} onChange={(e, value) => onChangeValue(e, 'user_status', value)} required options={STATUS} label='User Status*' />
                            </div>
                        </div>}
                </div>
            </div>)
        } else if (currentTab === 3) {
            return (<div className=''>
                <div className='montSerratFont fontSize18 tabheader'>User Work Experience</div>
                {!openEditForm && <div className='position-relative'>
                    <div className='editStoreBtn'>
                        <SecondaryButton onClick={onClickEditDetails} title={<div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <img src={EditIcon} alt="" />
                                <div className='textColorBlue fontMedium'>Edit Details</div>
                            </div>
                        </div>} />
                    </div>
                </div>}
                <div className='position-relative'>
                </div>
                <div className='notoSansFont'>
                    <div className='paddingTop24 notoSansFont'>
                        {(userDetailsData && !openEditForm) ? <div className='storeOwnerDetails'>
                            <div>
                                <div className='storeLabel'>User’s Education</div>
                                <div className='storeValue'>{userDetailsData?.education}</div>
                            </div>
                            <div>
                                <div className='storeLabel'>Past Experience</div>
                                <div className='storeValue'>{userDetailsData?.past_experience ? userDetailsData?.past_experience : '-'}</div>
                            </div>
                            <div>
                                <div className='storeLabel'>Previous Companies</div>
                                <div className='storeValue'>{userDetailsData?.previous_companies}</div>
                            </div>
                        </div> :
                            <div className='pt-3 formContainer teamMngmnt customScroll'>
                                <div>
                                    <InputTextField errored={inputValue.education ? ((validation?.education && validation?.education !== '') ? true : false) : false} errorMsg={validation.education} key={'education'} name={'education'} minLength={2} maxLength={40} type='text' disabled={false} value={inputValue.education} onChange={onInputValue} required label='User’s Education' />
                                </div>
                                <div>
                                    <InputTextField key={'past_experience'} errored={inputValue.past_experience ? ((validation?.past_experience && validation?.past_experience !== '') ? true : false) : false} errorMsg={validation.past_experience} name={'past_experience'} minLength={5} maxLength={40} type='text' disabled={false} value={inputValue.past_experience} onChange={onInputValue} required={false} label='Past Experience' />
                                </div>
                                <div>
                                    <InputTextField key={'previous_companies'} errored={inputValue.previous_companies ? ((validation?.previous_companies && validation?.previous_companies !== '') ? true : false) : false} errorMsg={validation.previous_companies} name={'previous_companies'} minLength={3} maxLength={200} type='text' disabled={false} value={inputValue.previous_companies} onChange={onInputValue} required={false} label='Previous Companies' />
                                </div>
                            </div>}
                    </div>
                </div>
            </div>)
        } else if (currentTab === 4) {
            return (<div className=''>
                <div className='montSerratFont fontSize18 tabheader'>Uploaded Documents</div>
                {!openEditForm && <div className='position-relative'>
                    <div className='editStoreBtn'>
                        <SecondaryButton onClick={onClickEditDetails} title={<div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <img src={EditIcon} alt="" />
                                <div className='textColorBlue fontMedium'>Edit Documents</div>
                            </div>
                        </div>} />
                    </div>
                </div>}
                <div className='position-relative'>
                </div>
                {!openEditForm ?
                    <div className='uploadedDocumentContainer notoSansFont pt-4 '>
                        {inputValue?.pan_doc && inputValue?.pan_doc[0]?.fileName ?
                            <div className='uploadedDocumentDetail position-relative' onClick={() => openFile(inputValue?.pan_doc[0])}>
                                <div className='pe-3'>
                                    <img src={fileTypeIcon(userDetailsData?.pan_doc[0]?.fileType)} width={23} height={'100%'} alt="" />
                                </div>
                                <div className='fontSize12'>
                                    <div>{userDetailsData?.pan_doc[0]?.fileName}</div>
                                    <div className='textColorGray'>Last updated on {convertDateTo(userDetailsData?.pan_doc[0]?.created_at)}</div>
                                </div>
                                <div className='position-absolute' style={{ right: '15px' }}>
                                    <img src={TickIconWithGreenBg} alt="" />
                                </div>
                            </div>
                            :
                            <div className='no-doc-uploaded'>PAN document not uploaded.</div>
                        }
                        {inputValue?.adhar_doc && inputValue?.adhar_doc[0]?.fileName ?
                            <div className='uploadedDocumentDetail position-relative' onClick={() => openFile(inputValue?.adhar_doc[0])}>
                                <div className='pe-3'>
                                    <img src={fileTypeIcon(userDetailsData?.adhar_doc[0]?.fileType)} width={23} height={'100%'} alt="" />
                                </div>
                                <div className='fontSize12'>
                                    <div>{userDetailsData?.adhar_doc[0]?.fileName}</div>
                                    <div className='textColorGray'>Last updated on {convertDateTo(userDetailsData?.adhar_doc[0]?.created_at)}</div>
                                </div>
                                <div className='position-absolute' style={{ right: '15px' }}>
                                    <img src={TickIconWithGreenBg} alt="" />
                                </div>
                            </div>
                            :
                            <div className='no-doc-uploaded'>Aadhaar document not uploaded.</div>
                        }
                    </div>
                    :
                    <div className='documentUpload teamMngmnt customScroll notoSansFont'>
                        <div className='uploadCard'>
                            {inputValue?.pan_doc === undefined || inputValue?.pan_doc?.length === 0 ?
                                <CustomDropzone filesAccepted={['.pdf', '.jpg', '.jpeg']} onFileDrop={(files) => handleFileDrop(files, 'pan_doc')} displayContent={<div>
                                    <div className='d-flex align-items-center'>
                                        <button className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                            <div className="cursorPointer">
                                                <img src={ImportIcon} alt="" />
                                            </div>
                                        </button>
                                        <div>
                                            <div className='fontMedium textColorBlack fontSize16'>Upload PAN</div>
                                            <div className='fontSize14 textColorGray'>
                                                <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                <div className='pt-2'>PDF,JPEG or JPG</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>} />
                                :
                                <div style={{ width: '100%' }}>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3 importExportFile cursorPointer' title='Import' >
                                            <div className="cursorPointer">
                                                <img src={fileTypeIcon(inputValue?.pan_doc[0]?.fileType)} width={29} height={29} alt="" />
                                            </div>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <div className='fontMedium textColorBlack fontSize16'>Pan</div>
                                            <div className='fontSize14 textColorGray'>
                                                <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('pan_doc')} progress={100} File={inputValue?.pan_doc[0]} uploadSpeed={''} notShowBrand={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='uploadCard notoSansFont'>
                            {inputValue?.adhar_doc === undefined || inputValue?.adhar_doc?.length === 0 ?
                                <CustomDropzone filesAccepted={['.pdf', '.jpg', '.jpeg']} onFileDrop={(files) => handleFileDrop(files, 'adhar_doc')} displayContent={<div>
                                    <div className='d-flex align-items-center'>
                                        <button className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                            <div className="cursorPointer">
                                                <img src={ImportIcon} alt="" />
                                            </div>
                                        </button>
                                        <div>
                                            <div className='fontMedium textColorBlack fontSize16'>Upload Aadhaar</div>
                                            <div className='fontSize14 textColorGray'>
                                                <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                <div className='pt-2'>PDF,JPEG or JPG</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>} />
                                :
                                <div style={{ width: '100%' }}>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3 importExportFile cursorPointer' title='Import'>
                                            <div className="cursorPointer">
                                                <img src={fileTypeIcon(inputValue?.adhar_doc[0]?.fileType)} width={29} height={29} alt="" />
                                            </div>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <div className='fontMedium textColorBlack fontSize16'>Aadhaar</div>
                                            <div className='fontSize14 textColorGray'>
                                                <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('adhar_doc')} progress={100} File={inputValue?.adhar_doc[0]} uploadSpeed={''} notShowBrand={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>)
        }
    }

    return (
        <div>
            <div className='d-flex justify-content-between align-items-baseline'>
                <div className='breadCrumbs'>
                    <span className='fontLight'>
                        <Link className="breadCrumbs" to={'/app/teamManagement'}>Home</Link>
                    </span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>View User Details</span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>{userBreadCrumbLabel}</span>
                </div>
            </div>
            <div className='paddingTop12'>
                <div>
                    <CustomTabs tabList={userDetailsTab} value={selectedTab} handleChange={handleTabChange} children={<div className={`bg-white storeDetailsContainer ${!openEditForm ? '' : 'edit-user-details'} scrollChange`}>{renderTabData(selectedTab, '')}</div>} />
                </div>
                {openEditForm && <div className='pt-3'>
                    <div className='bg-white continueBtn d-flex'>
                        <div className='pe-3'>
                            <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                        </div>
                        <div>
                            <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleUpdateUser} disabled={disableSubmitBtn} title={`${selectedTab !== 4 ? 'Save Changes' : 'Upload Document'}`} />
                        </div>
                    </div>
                </div>}
                <CustomModal open={showViewMoreDataPopup} isCloseIcon={true} style={uploadedFileModalStyle} handleClose={() => { setShowViewMoreDataPopup(false) }} content={
                    <div>
                        <div className='text-center'>
                            <div className='fontSize16 textColor333 customScroll' style={{ textAlign: 'left', maxHeight: '400px', overflow: 'auto' }}>
                                {showViewMoreData &&
                                    showViewMoreData === 'store' &&
                                    userDetailsData?.store_detail?.map(item => (
                                        <li>{item?.store_name}</li>
                                    ))
                                }
                                {showViewMoreData &&
                                    showViewMoreData === 'brand' &&
                                    userDetailsData?.brand_name?.map(item => (
                                        <li>{item?.brand_name}</li>
                                    ))
                                }
                            </div>
                        </div>
                    </div>}
                />
            </div>
            {/* Success Popup content */}
            <SuccessPopup open={openSuccessPopup} closable={false} onClose={closeSuccessPopup} content={<div>
                <div className='paddingTop32 text-center'>
                    <div className='textColorBlack fontSize24 montserratFont fontMedium'>Updated Successfully!</div>
                    <div className='fontSize16 textColor333'>User details updated successfully.</div>
                </div>
            </div>} />

            <CustomModal classes="customeProgressModal" open={openFileViewerModal} style={viewFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>{viewFile?.fileName}</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenFileViewerModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24' style={{ height: '80vh' }}>
                        {getFileType(viewFile?.fileName) === 'pdf' ?
                            <object data={viewFile?.fileUrl} type="application/pdf" width="100%" height="100%">
                                <p>Alternative text - include a link <a href={viewFile?.fileUrl}>to the PDF!</a></p>
                            </object>
                            :
                            <FileRenderer file={viewFile?.fileUrl} fileType={getFileType(viewFile?.fileName)} />
                        }
                    </div>
                </div>} />
            {loading && <Loader />}
        </div>
    )
}

export default ViewUserDetails