import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import '../../global.css';
import CalendarIcon from '../../assets/svg/calendar_2_fill.svg';

const CustomDateRangePicker = (props) => {

    const { startDate, endDate, calendarOpen, selectedRange, handleSelect, closeOnOutSide, disabled, minDate, restrictFutureDates } = props

    const isWithinRange = (date) => {
        return startDate && endDate && date >= startDate && date <= endDate;
    };

    return (
        <div className='customDateRangePicker'>
            <DatePicker
                disabled={disabled}
                open={calendarOpen}
                selected={startDate}
                onSelect={(date) => handleSelect(date)}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                highlightDates={[
                    { "highlighted": true, "date": selectedRange }
                ]}
                dayClassName={(date) =>
                    isWithinRange(date) ? 'highlighted' : null
                }
                shouldCloseOnSelect={false}
                dateFormat={'dd MMM yyyy'}
                onClickOutside={closeOnOutSide}
                minDate={minDate ? minDate : ''}
                maxDate={restrictFutureDates ? new Date() : ''}
            />
        </div>
    );
};

export default CustomDateRangePicker