import { environment } from "./Request";

const API_CONFIG = {
    // User Authentication
    ADMIN_LOGIN: environment.API_URL + 'kc/keycloak/login',
    CREATE_PASSWORD: environment.API_URL + 'kc/keycloak/change-password',
    SEND_OTP: environment.API_URL + 'kc/keycloak/send-otp',
    VERIFY_OTP: environment.API_URL + 'kc/keycloak/validate-otp',
    LOG_OUT_USER: environment.API_URL + 'kc/keycloak/logout',
    // Store API
    GET_STORE_LIST: environment.API_URL + 'store/filter-search',
    GET_STORE_DASHBOARD_DATA: environment.API_URL + 'store/get_dash',
    DOWNLOAD_STORE_LIST: environment.API_URL + 'store/download',
    UPLOAD_STORE_LIST: environment.API_URL + 'store/store-bulk-upload',
    GET_STORE_DETAILS_BY_ID: environment.API_URL + 'Store/by-id',
    UPDATE_STORE_STATUS: environment.API_URL + 'store/update-status',
    CREATE_STORE: environment.API_URL + 'store',
    GET_ALL_BRANDS_LIST: environment.API_URL + 'brand/names',
    //Brand API
    GET_BRAND_LIST: environment.API_URL + 'brand/get-brand',
    CREATE_BRAND_LIST: environment.API_URL + 'brand/create-brand',
    UPDATE_BRAND_DETAILS: environment.API_URL + 'brand/update-brand',
    UPDATE_BRAND_STATUS: environment.API_URL + 'brand/update-status',
    GET_ALL_BRANDS: environment.API_URL + 'brand/storeCount',
    GET_BRAND_DASHBOARD_DATA: environment.API_URL + 'brand/brandDashboard',
    CREAT_BRAND: environment.API_URL + 'brand',
    DOWNLOAD_BRAND_LIST: environment.API_URL + 'brand/download',
    UPLOAD_BRAND_MASTER_DATA: environment.API_URL + 'background/itemMasterUpload',
    DOWNLOAD_BRAND_MASTER_DATA: environment.API_URL + 'brand/item-master-download',
    GET_BRAND_MASTER_DATA: environment.API_URL + 'brand/item-master',
    //User Management
    GET_USER_LIST: environment.API_URL + 'user/getUserRecord',
    BULK_CREATE_USERS: environment.API_URL + 'user/bulk_users',
    CREATE_USER: environment.API_URL + 'user/',
    UPDATE_USER: environment.API_URL + 'user/updateUserDetails',
    GET_REPORTING_MANAGER: environment.API_URL + 'user/getUserDropDown',
    GET_TEME_HIERARCHY_MANAGER_LIST: environment.API_URL + 'user/hierarchy-view',
    DOWNLOAD_USER_MANGMENT_REPORTS: environment.API_URL + 'user/download',
    //Reports
    GET_UNPROCESSED_REPORTS: environment.API_URL + 'report/unprocessedFilesList',
    GET_CONSOLIDATED_REPORTS: environment.API_URL + 'report/brandsReportMonthWise',
    DOWNLOAD_COSOLIDATED_REPORTS: environment.API_URL + 'report/getConsolidatedReport',
    DOWNLOAD_UNPROCESSED_REPORT: environment.API_URL + 'report/preSignedUrl',
    //Core
    GET_FINANCIAL_YEAR_LIST: environment.API_URL + 'core/fyYears',
    GET_TEMPLATE_OF_TABLE: environment.API_URL + 'core/getTemplate',
    GET_CREATE_BRAND_DROPDOWN_LIST: environment.API_URL + 'core/get_dropdown',
    GET_LIST_STATE_CITY_BY_PINCODE: environment.API_URL + 'core/addres',
    FILE_UPLOAD: environment.API_URL + 'core/fileUpload',
    GET_DROPDOWN_MASTER: environment.API_URL + 'core/get_dropdown',
    UPLOAD_SALES_STOCK_REPORT: environment.API_URL + 'background/upload-reports',
    GET_USER_BRAND_STATE: environment.API_URL + 'core/states',
    GET_STYLE_CODE: environment.API_URL + 'core/style-code',
    GET_REGIONS: environment.API_URL + 'core/region',
    GET_BARCODES: environment.API_URL + 'core/barcode',

    // RULE MANGAMNET
    GET_REPORT_HEADER_VALUES: environment.API_URL + 'core/reportHeader',
    GET_BRAND_NAME: environment.API_URL + 'brand/names',
    GET_STORE_CUSTOMER_NAME: environment.API_URL + 'store/customer-names',
    GET_HEADER_VALUE_ONLY: environment.API_URL + 'core/headerValues',
    CREATE_RULE_MANGMENT: environment.API_URL + 'core/reportHeader',
    UPDATE_RULE_MANGMENT: environment.API_URL + 'core/updateReportHeader',
    //Feedback
    GET_FEEDBACK_LIST: environment.API_URL + 'user/getFeedback',
    UPDATE_FEEDBACK_DEAILS: environment.API_URL + 'user/updateFeedback',
    CREATE_FEEDBACK: environment.API_URL + 'user/addFeedback',
    // Sales
    GET_SALES_YEAR: environment.API_URL + 'sales/salesViewRange',
    GET_SALES_DASHBOARD_DATA: environment.API_URL + 'sales/salesDashboard',
    DOWNLOAD_SALES_DASHBOARD_DATA: environment.API_URL + 'sales/detailsDownload',
    DOWNLOAD_SALES_RECON_DATA: environment.API_URL + 'sales/downloadReconcilationFile',
    //Stock
    GET_STOCK_DASHBOARD_DATA: environment.API_URL + 'stock/stockDashboard',
    DOWNLOAD_DASHBOARD_REPORT: environment.API_URL + 'stock/detailsDownload',
    //Target Vs Achievement
    FETCH_TARGET_VS_ACHIEVEMENT_LIST: environment.API_URL + 'target-achievement',
    DOWNLOAD_TARGET_VS_ACHIEVEMENT_LIST: environment.API_URL + 'target-achievement/download',
    DOWNLOAD_TEMPLETE_TARGET_VS_ACHIEVEMENT_LIST: environment.API_URL + 'target-achievement/download_templete',
    BULK_INSERT: environment.API_URL + 'target-achievement/bulk-insert',
    TARGET_VS_ACHIEVEMENT_DASHBOARD_DATA: environment.API_URL + 'target-achievement/dashboard',
    // incentive/
    CREATE_INCENTIVE: environment.API_URL + 'incentive/create-incentive',
    INCENTIVE_HISTORY: environment.API_URL + 'incentive/history-incentive',
    GET_USER_INCENTIVE: environment.API_URL + 'incentive/user-incentive',
    DOWNLOAD_USER_INCENTIVE: environment.API_URL + 'incentive/download-incentive',
    // Promotion
    GET_PROMOTIONS_LIST: environment.API_URL + 'promotions/get-promotions',
    UPDATE_PROMOTIONS_LIST: environment.API_URL + 'promotions/update-promotion',
    DOWNLOAD_PROMOTION_LIST: environment.API_URL + 'promotions/download',
    CREATE_NEW_PROMOTION: environment.API_URL + 'promotions/create-promotion',
    GET_SEASONS: environment.API_URL + 'Stock/all-seasons',
    GET_CATEGORY_BY_BRAND: environment.API_URL + 'Stock/brand-category',
    // Employee Status
    GET_ALL_EMPLOYEE_STATUS: environment.API_URL + 'user/employee-status',
    GET_ALL_SALES_DETAILS: environment.API_URL + 'user/employee-sales',
    GET_ALL_ATTENDANCE_DETAILS: environment.API_URL + 'user/employee-attendance',
    //Notifications
    SUBMIT_NOTIFICATION_DATA: environment.API_URL + 'notifications/send-notification',
    GET_NOTIFICATION_LIST: environment.API_URL + 'notifications'
};

export default API_CONFIG;
