import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    defaultYear: '',
    defaultBrand: '',
    defaultBrandForRecon: {},
    defaultMonthForRecon: {},
};

const salesSlice = createSlice({
    name: 'sales',
    initialState,
    reducers: {
        setDefaultYear: (state, action) => {
            state.defaultYear = action.payload.type
        },
        setDefaultBrand: (state, action) => {
            state.defaultBrand = action.payload.type
        },
        setDefaultBrandForRecon: (state, action) => {
            state.defaultBrandForRecon = action.payload.type
        },
        setDefaultMonthForRecon: (state, action) => {
            state.defaultMonthForRecon = action.payload.type
        },
    }
});

export const salesActions = salesSlice.actions;
export default salesSlice;