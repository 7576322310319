


import React, { useEffect, useMemo, useRef, useState } from 'react';
import '../../../global.css';
import './ViewEmployeeStatusDetails.css';
import useIcon from './../../../assets/svg/user_1077114.svg';
import CustomPagination from '../../../ReUsableComponent/Pagination';
import Loader from '../../../ReUsableComponent/Loader';
import CustomTableWithFilter from '../../../ReUsableComponent/CustomTableWithFilter'
import { EmployeeStatusSalesDetailsHeader } from '../../TableHeaders';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { employeeStatusActions } from '../../../store/reducers/EmployeeStatusReducer';
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import { GetEmployeeAttendanceList, GetEmployeeSalesList } from './../EmployeeStatus.service';
import CustomMonthYearPicker from '../../../ReUsableComponent/CustomMonthYearPicker';
import dayjs from 'dayjs';
import RightArrowIcon from './../../../assets/svg/rightArrow.svg';
import { RefreshOutlined } from '@mui/icons-material';

const ViewEmployeeStatusDetails = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const gridRef = useRef(null);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const selectedPage = useSelector(state => state.employeeStatus.selectedViewEmplStatusPage);
    const frontlinerId = useSelector(state => state.employeeStatus.employeeStatuseRowId);
    const newSelectedBrandId = useSelector((state) => state.employeeStatus.selectedBrandId);
    const [loading, setLoading] = React.useState(false);
    const [employeeStatusActiveTabBtn, setEmployeeStatusActiveTabBtn] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState('');
    const [selectedMonthYear, setSelectedMonthYear] = useState('');
    const [allAttendenceDetails, setAllAttendenceDetails] = useState([]);
    const [employeeStatusSailsData, setEmployeeStatusSailsData] = useState([]);

    // start
    useEffect(() => {
        getAllSalesData();
        getAllAttendanceData();
    }, [selectedMonthYear]);

    const onClickIncentiveBtn = (value) => {
        setEmployeeStatusActiveTabBtn(value);
    }

    const getCurrentYearAndMonth = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        return `${year}-${month}`;
    };

    const onDateSelect = (value) => {
        let date = new Date(value);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        let slectedDate = `${year}-${month}`;
        setSelectedMonthYear(slectedDate);
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
    }

    function formatTime(dateString) {
        const date = new Date(dateString);
        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        });
      
        return formattedTime;
    }
      
    const getAllAttendanceData = () => {
        setLoading(true);
        let param = `?user_id=${frontlinerId?.id}&month=${selectedMonthYear !== '' ? selectedMonthYear : getCurrentYearAndMonth()}`;
        // let param = `?user_id=665d708fe1f5bbd0ff56e132&month=2024-10'`
        GetEmployeeAttendanceList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                setLoading(false);
                setAllAttendenceDetails(response?.data?.data?.attendanceRecords);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const getAllSalesData = () => {
        setLoading(true);
        let param = `?frontlinerId=${frontlinerId?.id}&month=${selectedMonthYear !== '' ? selectedMonthYear : getCurrentYearAndMonth()}&page=${selectedPage}&limit=20`;
        // let param = `?frontlinerId=65facea45852ca154ab17afb&month=2024-06&page=1&limit=20`
        GetEmployeeSalesList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                setLoading(false);
                let salesData = []
                response.data.data.salesRecords.forEach(element => {
                    let salesDataObject = {
                        // employeeSalesStatus: element?.is_verified ? "Completed" : "Incomplete",
                        nameBrand: element?.brand_name ? element?.brand_name : '-',
                        category: element?.group ? element?.group : '-',
                        subCategory: element?.presence ? element?.presence : '-' ,
                        nameStore: element?.store_details?.customer_name ? element?.store_details?.customer_name : '-' ,
                        // productType: element?.group ? element?.group : '-' ,
                        color: element?.color ? element?.color : '-' ,
                        size: element?.size ? element?.size : '-' ,
                        packOf: element?.quantity ? element?.quantity : '-' ,
                        barcode: element?.barcode ? element?.barcode : '-' ,
                        MRP: element.mrp ? element.mrp : '-' ,
                    }
                    salesData.push(salesDataObject)
                });
                setEmployeeStatusSailsData(salesData);
                setTotalPagesCount(response?.data?.data?.pagination?.totalRecords)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    // table
    const onClickBrandView = () => {

    }

    const handlePageChange = (pageValue) => {
        dispatch(employeeStatusActions.setSelectedViewEmplStatusPage({ type: pageValue }));
    }

    const backHomePage = () => {
        dispatch(employeeStatusActions.setSelectedBrandId({ type: newSelectedBrandId }));
    }

    // const capitalize = (str) => {
    //     return str.split(' ')
    //         .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    //         .join(' ');
    // }

    const onGridReady = (params) => {
        params.api.setFilterModel(employeeStatusSailsData);
    };

    const onClickReset = () => {
        const gridApi = gridRef.current.api;
        gridApi.setFilterModel(null); // Clears all the filters
        // gridApi.onFilterChanged(); 
    }

    return (
        <div className='mainContainer'>
            <div className='d-flex justify-content-between align-items-baseline' style={{padding: '15px 0px'}}>
                <div className='breadCrumbs'>
                    <span className='fontLight'>
                        <Link className="breadCrumbs" to={'/app/employeeStatus'} onClick={backHomePage}>Home</Link>
                    </span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>{`${employeeStatusActiveTabBtn === 1 ? 'Attendance' : 'Sales'} List View`}</span>

                </div>
            </div>
            <div className='notoSansFont containerDiv'>
                <div className='filterMainDiv'>
                    <div className='cat-season-btn-cont incentiveToggalCont fontSize12'>
                        <button onClick={() => onClickIncentiveBtn(1)} className={`${employeeStatusActiveTabBtn === 1 ? 'active-tab-btn-bg' : 'tab-btn-bg'}`}>Attendance</button>
                        <button onClick={() => onClickIncentiveBtn(2)} className={`${employeeStatusActiveTabBtn === 2 ? 'active-tab-btn-bg' : 'tab-btn-bg'}`}>Sales</button>
                    </div>
                </div>
                
                <div className='d-flex justify-content-end align-items-center'>
                    {employeeStatusActiveTabBtn !== 1 && 
                    <div className='position-relative'>
                        <button onClick={onClickReset} className='me-2 reset_filters_button'>
                            <span className='me-1 reset_filters_text'>Reset Filters</span>
                            <RefreshOutlined className='RefreshOutlined' />
                        </button>
                    </div>
                    }
                    <div className='position-relative'>
                        <CustomMonthYearPicker disabled={false} value={selectedMonthYear} defaultValue={dayjs()} onDateChange={(newValue) => onDateSelect(newValue)} maxDateRange={dayjs()} required customClass={'customeClass'} />
                    </div>
                </div>
            </div>

            <div className='paddingTop24'>
                <span>User Name : </span>
                <span><b>{frontlinerId?.nameUser}</b></span>
                <span>{" "} {" "}</span>
                <span>Employee ID : </span>
                <span><b>{frontlinerId?.emplId}</b></span>
                <span>{" "} {" "}</span>
                <span>Store Name : </span>
                <span><b>{frontlinerId?.store_name}</b></span>
            </div>
            
            <div className='paddingTop24'>
                {employeeStatusActiveTabBtn === 1 ? 
                    <>
                        <div className='attendenceDetailsContainer'>
                            {/* <div className='montserratFont fontSize18 paddingBottom10'>Attendence Details</div> */}
                            <div className='attendenceDetailsCardContainer'>
                                {allAttendenceDetails?.length !== 0 ?
                                allAttendenceDetails?.map(item => 
                                    <>
                                        <div className={`attendenceDetailsCard textColorBlack text-capitalize ${item?.is_attended ? 'bgColor' : ''}`}>
                                            <div className='userProfileCircleRow'>
                                                <div className='userProfileCircle'>
                                                    <img src={useIcon} alt="" />
                                                </div>
                                                <div className='userProfileName'>{formatDate(item?.date)}</div>
                                                <div className={`userProfileStatus ${item?.is_attended ? 'active' : ''}`}></div>
                                            </div>
                                            <div className='punchInOutContainer'>
                                                {item?.is_attended ? 
                                                <div className='punchInOutRowContainer'>
                                                    <div className='punchInRowContainer'>
                                                        {item?.attendanceDetail?.map(newItem => 
                                                            <>
                                                                {newItem?.time_type === '0' &&
                                                                    <div className='punchInOut'>
                                                                        <p>Punch In Time</p>
                                                                        <h6>{formatTime(newItem?.time)}</h6>
                                                                    </div>
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className='punchOutRowContainer'>
                                                        {item?.attendanceDetail?.map(newItem => 
                                                            <>
                                                                {newItem?.time_type === '1' &&
                                                                    <div className='punchInOut'>
                                                                        <p>Punch Out Time</p>
                                                                        <h6>{formatTime(newItem?.time)}</h6>
                                                                    </div>
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                :
                                                <div className='punchInOutRowContainer'>
                                                    <div className='punchInRowContainer'>
                                                        <div className='punchInOut'>
                                                            <p>Punch In Time</p>
                                                            <h6>00:00</h6>
                                                        </div>
                                                    </div>
                                                    <div className='punchOutRowContainer'>
                                                        <div className='punchInOut'>
                                                            <p>Punch Out Time</p>
                                                            <h6>00:00</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                                :
                                <div className='noDataFound'>No Data Found</div>
                                }
                            </div>
                        </div>
                    </>
                    :
                    <>

                        {/*  */}
                        <div className=''>
                            <div className='tableContainer emplStatusSales'>
                                {/* <div className='montserratFont fontSize18 paddingBottom10'>Sales Details</div> */}
                                <CustomTableWithFilter 
                                    refGrid={gridRef} 
                                    onGridReady={onGridReady} 
                                    column={EmployeeStatusSalesDetailsHeader} 
                                    tableRowData={employeeStatusSailsData} 
                                    onClickView={onClickBrandView} 
                                    customeHeightClass={'tableCustomeHeightClass'} />
                            </div>

                        </div>
                        <div className='paddingTop16 d-flex justify-content-end customPagination'>
                            <CustomPagination onClick={handlePageChange} activePage={selectedPage} pageSize={20} currentPage={selectedPage} totalItems={totalPagesCount} />
                        </div>
                    </>
                }
                
            </div>

            {loading && <Loader />}
        </div>
    )
}

export default ViewEmployeeStatusDetails;