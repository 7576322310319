import React, { useEffect, useState, useRef } from 'react';
import CustomHorizontalStepper from '../../../ReUsableComponent/CustomHorizontalStepper';
import RightArrowIcon from '../../../assets/svg/rightArrow.svg';
import { Link, useHistory } from 'react-router-dom';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import '../TeamManagement.css';
import ProgressBarWithLabel from '../../../ReUsableComponent/ProgressBarWithLabel';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import CreateUserForm from './CreateUserForm';
import moment from 'moment/moment';
import { getListStateCityByPincode } from '../../BrandManagement/BrandManagement.service';
import { useDispatch, useSelector } from 'react-redux';
import { teamManagementActions } from '../../../store/reducers/TeamManagementReducer';
import { CreateAndUpdateUser, GetDesignationList, GetReportingManagerList, GetUserRoleList, UploadUserDocs, GetBrandStateList } from '../TeamManagement.service';
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import { GetBrandNameList, GetStoreCustomerNameList } from '../../RuleManagement/RuleManagement.service';
import { userManagementActions } from '../../../store/reducers/UserManagementReducer';
import Loader from '../../../ReUsableComponent/Loader';
import { calculate_age } from '../../../utils/AgeCalculator';
import SuccessPopup from '../../../ReUsableComponent/SuccessPopupMsg';
import { Validate, ValidationFunction, checkValidation } from '../../../utils/ValidationFunction';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const OfflineChannels = [
    { label: 'MBO', value: 'MBO' },
    { label: 'EBO', value: 'EBO' },
    { label: 'Regional Player', value: 'Regional Player' },
    { label: 'Departmental Store', value: 'Departmental Store' },
    { label: 'E-commerce', value: 'E-commerce' },
    { label: 'Others', value: 'Others' },
];
const OnlineChannels = [
    { label: 'Amazon', value: 'Amazon' },
    { label: 'Flipkart', value: 'Flipkart' },
    { label: 'Myntra', value: 'Myntra' }
];
const ChannelServed = [
    { label: 'Online', value: 'Online' },
    { label: 'Offline', value: 'Offline' }
];


const CreateUser = () => {
    const timeoutRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const steps = ['Personal Information', 'Bank Details', 'Onboarding Details', 'Work Experience', 'Upload Documents'];
    const STATUS = [{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }];
    const USER_ID = useSelector(state => state.userAuthentication.userId);
    const [activeStep, setActiveStep] = React.useState(0);
    const [progress, setProgress] = React.useState(0);
    const [fileUploadprogress, setFileUploadProgress] = React.useState(0);
    const [startTime, setStartTime] = useState(null);
    const [inputValue, setInputValue] = useState({ 'user_status': 'Inactive' });
    const [storeNameList, setStoreNameList] = useState([]);
    const [brandNameList, setBrandNameList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const [validation, setValidation] = useState({});
    const [disableSubmitBtn, setdisableSubmitBtn] = useState(true);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [statesNameList, setStatesNameList] = useState([]);
    const [brandNameQueryParm, setBrandNameQueryParm] = useState([]);
    const [selectedBrandState, setSelectedBrandState] = useState('');
    const createUserRole = useSelector(state => state.userManagement.createUserRole);
    useEffect(() => {
        getBrandList();
        getReportingmngrList('');
        getDesignations();
        getUserRoleList();
        getChannelList();
        // dispatch(userManagementActions.setSelectedBrands({ type: [] }));
        // dispatch(userManagementActions.setSelectedStores({ type: [] }));
    }, []);

    useEffect(() => {
        getBrandStateList(brandNameQueryParm.toString());
    }, [selectedRegion]);

    useEffect(() => {
        getStoreList();
        getReportingmngrList('')
    }, [brandNameQueryParm, selectedRegion, selectedBrandState]);

    useEffect(() => {
        if (activeStep === 0) {
            //fieldcheck
            if (!inputValue?.name || !inputValue?.emp_id || !inputValue?.gender || !inputValue?.mobile_number || !inputValue?.designation_id || !inputValue?.role
                || !inputValue?.email_id || !inputValue?.doj || !inputValue?.dob || !inputValue?.age || !inputValue?.company || !inputValue?.adhar_no
                || !inputValue?.pan_no || !inputValue?.address || !inputValue?.pincode || !inputValue?.city || !inputValue?.state || !inputValue?.region
                || !inputValue?.year_exp || !inputValue?.current_gross_ctc || !inputValue?.last_gross_ctc || (validation?.name && validation?.name !== '')
                || (validation?.emp_id && validation?.emp_id !== '') || (validation?.last_city && validation?.last_city !== '') || (validation?.mobile_number && validation?.mobile_number !== '')
                || (validation?.email_id && validation?.email_id !== '') || (validation?.doj && validation?.doj !== '') || (validation?.dob && validation?.dob !== '')
                || (validation?.age && validation?.age !== '') || (validation?.company && validation?.company !== '') || (validation?.adhar_no && validation?.adhar_no !== '')
                || (validation?.pan_no && validation?.pan_no !== '') || (validation?.address && validation?.address !== '') || (validation?.pincode && validation?.pincode !== '')
                || (validation?.city && validation?.city !== '') || (validation?.state && validation?.state !== '') || (validation?.region && validation?.region !== '')
                || (validation?.year_exp && validation?.year_exp !== '') || (validation?.current_gross_ctc && validation?.current_gross_ctc !== '')
                || (validation?.last_gross_ctc && validation?.last_gross_ctc !== '') || (validation?.company && validation?.company !== '')
                || (validation?.address && validation?.address !== '')) {
                setdisableSubmitBtn(true);
            } else {
                setdisableSubmitBtn(false);
            }
        } else if (activeStep === 1) {
            if (!inputValue?.bank_name || !inputValue?.accountNumber || !inputValue?.bank_ifsc || !inputValue?.branch_name
                || !inputValue?.branch_address || !inputValue?.esi_no || !inputValue?.pf_no || !inputValue?.remarks
                || (validation?.bank_name && validation?.bank_name !== '') || (validation?.accountNumber && validation?.accountNumber !== '')
                || (validation?.bank_ifsc && validation?.bank_ifsc !== '') || (validation?.branch_name && validation?.branch_name !== '')
                || (validation?.branch_address && validation?.branch_address !== '') || (validation?.esi_no && validation?.esi_no !== '')
                || (validation?.pf_no && validation?.pf_no !== '') || (validation?.remarks && validation?.remarks !== '')) {
                setdisableSubmitBtn(true);
            } else {
                setdisableSubmitBtn(false);
            }
        } else if (activeStep === 2) {
            if (!inputValue?.exit_date || !inputValue?.login_type || !inputValue?.channel
                || ((createUserRole === 'SM' || createUserRole === 'FL') && inputValue?.store_id?.length < 1 || inputValue?.brand_name?.length < 1)
                || ((createUserRole === 'Manager' || createUserRole === 'ASM') && inputValue?.store_id?.length < 1 || inputValue?.brand_name?.length < 1)
                || ((createUserRole === 'RSM') && inputValue?.store_id?.length < 1 || inputValue?.brand_name?.length < 1)
            ) {
                setdisableSubmitBtn(true);

            } else {
                setdisableSubmitBtn(false);
            }
        } else if (activeStep === 3) {
            if (!inputValue?.education) {
                setdisableSubmitBtn(true);
            } else {
                setdisableSubmitBtn(false);
            }
        } else if (activeStep === 4) {
            if ((!inputValue?.pan_doc) || (!inputValue?.adhar_doc)) {
                setdisableSubmitBtn(true);
            } else {
                setdisableSubmitBtn(false);
            }
        }
    }, [validation, inputValue, activeStep])


    const handleNext = () => {
        if ((activeStep + 1) < steps.length) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            dispatch(userManagementActions.setSelectedStores({ type: [] }));
            dispatch(userManagementActions.setSelectedBrands({ type: [] }));
            dispatch(userManagementActions.setPan({ type: {} }));
            dispatch(userManagementActions.setAadhaar({ type: {} }));
        }
        if (progress < 100) {
            let formCompleted = 100 / steps.length
            setProgress(prev =>
                prev += formCompleted
            )
        }
    };

    const getBrandList = () => {
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item?.brand_name,
                    value: item?.brand_name,
                    brand_id: item?._id
                }
                dropdownData.push(row)
            })
            setBrandNameList(dropdownData);
            dispatch(userManagementActions.setBrandList({ type: dropdownData }));

        });
    }

    const getBrandStateList = (brand) => {
        let param = `?region=${selectedRegion}&brand=${brand}`
        GetBrandStateList(param, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item,
                    value: item,
                }
                dropdownData.push(row)
            });
            setStatesNameList(dropdownData);
            dispatch(userManagementActions.setStatesList({ type: dropdownData }));
        });
    }

    const getStoreList = () => {
        let queryParams = `?brand_id=${brandNameQueryParm?.join(',')}&region=${selectedRegion ? selectedRegion : ''}&state=${selectedBrandState ? selectedBrandState : ''}`;
        GetStoreCustomerNameList(queryParams, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item?.customer_name,
                    value: item?.customer_name,
                    store_code: item?.store_code,
                    store_id: item?._id
                }
                dropdownData.push(row);
            })
            setStoreNameList(dropdownData);
            dispatch(userManagementActions.setStoreList({ type: dropdownData }));
        });
    }
    const getUserRoleList = () => {
        let param = `?module=${'user_roles'}&dropdown_key=user_roles`
        GetUserRoleList(param, USER_TOKEN).then(response => {
            if (response?.data?.statusCode === 400) {
                dispatch(userManagementActions.setUserRoles({ type: [] }));
            } else {
                let dropdownData = [];
                response?.data?.data[0]?.dropdown_values?.map(item => {
                    let row = {
                        label: item,
                        value: item,
                    }
                    dropdownData.push(row);
                })
                dispatch(userManagementActions.setUserRoles({ type: dropdownData }));
            }
        });
    }
    const getDesignations = () => {
        let param = `?module=${'user_designation'}&dropdown_key=user_designation`
        GetDesignationList(param, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data[0]?.dropdown_values?.map(item => {
                let row = {
                    label: item,
                    value: item,
                }
                dropdownData.push(row);
            })
            dispatch(userManagementActions.setDesignations({ type: dropdownData }));
        });
    }
    const getReportingmngrList = (name, keyName) => {
        let param = `?name=${name}&brand_id=${brandNameQueryParm?.join(',')}`
        GetReportingManagerList(param, USER_TOKEN).then(response => {
            if (name) {
                setInputValue(values => ({ ...values, ['reporting_manager_email']: response?.data?.data[0].email, ['reporting_manager_mob']: response?.data?.data[0].mobileNumber }));
            } else {
                let dropdownData = [];
                response?.data?.data?.map((item, index) => {
                    let row = {
                        label: item?.name,
                        value: item?.name,
                        id: index,
                        email_id: item?.email
                    }
                    dropdownData.push(row);
                })
                dropdownData = dropdownData.filter(function (element) {
                    return element?.email_id !== inputValue?.email_id;
                })
                dispatch(userManagementActions.setReportingMngr({ type: dropdownData }));
            }
        });
    }
    const getChannelList = () => {
        let param = `?module=${'brand'}&dropdown_key=ChannelOffline`
        GetDesignationList(param, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data[0]?.dropdown_values?.map(item => {
                let row = {
                    label: item,
                    value: item,
                }
                dropdownData.push(row);
            })
            dispatch(userManagementActions.setChannels({ type: dropdownData }));
        });
    }

    const handleCreateUser = () => {
        setLoading(true);
        let param = `?id=${USER_ID}`
        CreateAndUpdateUser(inputValue, param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 201) {
                setInputValue({ 'id': response?.data?.data?._id, 'user_status': 'Inactive', 'doj': response?.data?.data?.doj });
                ToastSuccessService(response.data.message);
                getReportingmngrList('');
                handleNext();
                if (activeStep === 4) {
                    setOpenSuccessPopup(true);
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    // Set a new timeout to automatically close the popup
                    timeoutRef.current = setTimeout(() => {
                        closeSuccessPopup();
                    }, 5000);
                }
            } else {
                ToastErrorService(response.data.message)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const checkMinLength = (name, minlength, value) => {
        if (value?.length < minlength) {
            setValidation(prev => ({ ...prev, [name]: `Please enter minimum ${minlength} character` }))
        }
    }

    const onInputValue = (event) => {
        const name = event.target.name;
        if (name === 'name' || name === 'emp_id' || name === 'last_city' || name === 'bank_name' || name === 'address'
            || name === 'previous_companies' || name === 'branch_address' || name === 'education' || name === 'company'
            || name === 'branch_name' || name === 'remarks' || name === 'past_experience' || name === 'previous_companies') {
            checkMinLength(name, event.target.minLength, event.target.value);
        }
        if (name === 'name') {
            event.target.value = event.target.value.replace(/\b\w/g, char => char.toUpperCase());
        }
        if (name === 'mobile_number' || name === 'adhar_no' || name === 'pincode' || name === 'door_count' || name === 'accountNumber' || name === 'esi_no') {
            event.target.value = event.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
        } else if (name === 'name' || name === 'past_experience') {
            event.target.value = event.target.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1');
        } else if (name === 'pan_no' || name === 'bank_ifsc' || name === 'pf_no') {
            event.target.value = event.target.value.toUpperCase();
        } else if (name === 'gross_sales') {
            event.target.value = event.target.value.replace(/[^0-9,.]/g, '').replace(/(\..*)\./g, '$1');
        } else if (name === 'last_gross_ctc' || name === 'current_gross_ctc') {
            event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        }
        if (name === 'store_code') {
            setInputValue(values => ({
                ...values, 'store_detail': {
                    ...values.store_detail, [name]: event.target.value
                }
            }));
        } else {
            let valuecheck = { [name]: event.target.value }
            checkValidation(valuecheck, validation, name)
            setInputValue(values => ({ ...values, [name]: event.target.value }));
        }
        if (name === 'pincode' && event.target.value?.length === 6) {
            getCityStateByPin(event.target.value);
        }

    }

    const onChangeValue = (event, name, value) => {
        if (name === 'reporting_manager') {
            getReportingmngrList(value.value);
        } else if (name === 'brand_region') {
            setSelectedRegion(value?.value);
        } else if (name === 'brand_state') {
            setSelectedBrandState(value?.value);
            // dispatch(userManagementActions.setSelectedStates({ type: value?.value }));
        }
        setInputValue(values => ({ ...values, [name]: value.value }));
        if (name === 'designation_id') {
            dispatch(userManagementActions.setCreateUserRole({ type: value.value }));
        }

    };

    const onSelectValue = (event, name) => {
        const {
            target: { value },
        } = event;
        if (name === 'store_id') {
            let storeObject = [];
            value.map(storeName => {
                const selectedStore = storeNameList?.find(store => store?.value === storeName);
                let storeSelected = {
                    store_id: selectedStore?.store_id,
                    store_name: selectedStore?.label,
                    store_code: selectedStore?.store_code,
                }
                storeObject.push(storeSelected);
                dispatch(userManagementActions.setSelectedBrands({ type: value }));
            }).filter(store => store);
            setInputValue(values => ({
                ...values, 'store_detail': storeObject,
            }));
            dispatch(userManagementActions.setSelectedStores({ type: value }));

        } else if (name === 'brand_name') {
            let brandObject = [];
            let brandIdsObject = [];
            value.map(brandName => {
                const selectedBrand = brandNameList?.find(brand => brand?.value === brandName);
                let brandSelected = {
                    brand_name: selectedBrand?.label,
                    brand_id: selectedBrand?.brand_id
                }
                brandObject.push(brandSelected);
                brandIdsObject.push(brandSelected?.brand_id);
                dispatch(userManagementActions.setSelectedBrands({ type: value }));
            }).filter(store => store);
            setInputValue(values => ({
                ...values, 'brand_name': brandObject, brand_state: '', store_detail: [], reporting_manager: '', reporting_manager_mob: '', reporting_manager_email: ''
            }));
            setBrandNameQueryParm(brandIdsObject);
            getBrandStateList(brandIdsObject.toString());
        }
    }

    const onDateSelect = (value, name) => {
        let date = new Date(value)
        if (name === 'dob') {
            setInputValue(values => ({ ...values, ['age']: calculate_age(date) }));
        }
        setInputValue(values => ({ ...values, [name]: moment(date).format("DD-MM-YYYY") }));
    }

    const handleFileDrop = (files, name) => {
        setLoading(true);
        const fileUploaded = files[0];
        const formData = new FormData();
        if (name === 'pan_doc') {
            formData.append('service_type', 'Pan Document');
        } else if (name === 'adhar_doc') {
            formData.append('service_type', 'Adhar Document');
        }
        formData.append('service', 'User_Documents');
        formData.append('file', fileUploaded);
        let userDocs = [];
        UploadUserDocs(formData, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                setInputValue(values => ({ ...values, [name]: { 'fileUrl': response?.data?.data?.file_url, 'size': response?.data?.data?.size, 'fileName': response?.data?.data?.fileName, 'fileType': response?.data?.data?.file_type, 'created_at': response?.data?.data?.created_at } }));
                userDocs = { ...userDocs, [name]: { 'fileUrl': response?.data?.data?.file_url, 'size': response?.data?.data?.size, 'fileName': response?.data?.data?.fileName, 'fileType': response?.data?.data?.file_type, 'created_at': response?.data?.data?.created_at } }
                if (name === 'pan_doc') {
                    dispatch(userManagementActions.setPan({ type: userDocs }));
                } else {
                    dispatch(userManagementActions.setAadhaar({ type: userDocs }));
                }
            }
        }).catch(error => {
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    };

    const getCityStateByPin = (pincode) => {
        const param = `?pincode=${pincode}`
        getListStateCityByPincode(param, USER_TOKEN).then(response => {
            if (response?.data?.data?.length > 0) {
                setInputValue(values => ({
                    ...values, ['state']: response?.data?.data[0]?.state,
                    ['city']: response?.data?.data[0]?.city, ['region']: response?.data?.data[0]?.region
                }))
            } else {
                setInputValue(values => ({
                    ...values, ['state']: '',
                    ['city']: '', ['region']: ''
                }))
                ToastErrorService('No data available for given pincode');
            }

            let stateData = response?.data?.data?.map((item) => ({
                label: item?.state,
                value: item?.state,
                name: 'state'
            }));
            const uniqueStateData = Array.from(new Set(stateData.map(item => item.value))).map(value => {
                return stateData.find(item => item.value === value);
            });

            let cityData = response?.data?.data?.map((item) => ({
                label: item?.city,
                value: item?.city,
                name: 'city'
            }));
            const uniqueCityData = Array.from(new Set(cityData.map(item => item.value))).map(value => {
                return cityData.find(item => item.value === value);
            });

            let regionData = response?.data?.data?.map((item) => ({
                label: item?.region,
                value: item?.region,
                name: 'region'
            }));
            const uniqueRegionData = Array.from(new Set(regionData.map(item => item.value))).map(value => {
                return regionData.find(item => item.value === value);
            });

            let areaData = response?.data?.data?.map((item) => ({
                label: item?.area,
                value: item?.area,
                name: 'area'
            }));
            const uniqueAreaData = Array.from(new Set(areaData.map(item => item.value))).map(value => {
                return areaData.find(item => item.value === value);
            });
            let master = [{ stateMaster: uniqueStateData, cityMaster: uniqueCityData, regionMaster: uniqueRegionData, areaMaster: uniqueAreaData }]
            dispatch(teamManagementActions.setMasterData({ type: master }))
            // setAddressMaster(prev => ({ ...prev, stateMaster: uniqueStateData, cityMaster: uniqueCityData, regionMaster: uniqueRegionData, areaMaster: uniqueAreaData }))
        }).catch(error => {

        }).finally(() => {

        })
    }

    const onClickCancel = () => {
        history.push('/app/teamManagement');
    }

    const closeSuccessPopup = () => {
        if (timeoutRef.current) { clearTimeout(timeoutRef.current); }
        setOpenSuccessPopup(false);
        history.push('/app/teamManagement');
    };

    return (
        <div className='notoSansFont'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='breadCrumbs'>
                    <span className='fontLight'>
                        <Link className="breadCrumbs" to={'/app/teamManagement'}>Home</Link>
                    </span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>Create User</span>
                </div>
            </div>
            <div className='pt-3'>
                <div className='textColor333 pb-1'>You Have Completed <span className='textColorGreen fontBold'>{progress < 10 ? '0' : ''}{Math.round(progress)}%</span> User Profile!</div>
                <ProgressBarWithLabel progress={progress} />
            </div>
            <div className='pt-3'>
                <CustomHorizontalStepper steps={steps} isStepSkipped={null} isStepOptional={null} activeStep={activeStep} />
            </div>
            <div className='pt-3'>
                <div className='bg-white createStoreFormCard'>
                    <CreateUserForm validations={validation} onSelectValue={onSelectValue} values={inputValue} activeStep={activeStep} onInputValue={onInputValue} onChangeValue={onChangeValue} handleFileDrop={handleFileDrop} onDateSelect={onDateSelect} selectedRegion={selectedRegion} selectedBrandState={selectedBrandState} />
                </div>
            </div>

            <div className='pt-3'>
                <div className='bg-white continueBtn d-flex'>
                    {activeStep === 0 && <div className='pe-3'>
                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                    </div>}
                    <div>
                        <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleCreateUser} disabled={disableSubmitBtn} title={`${activeStep !== 4 ? 'Confirm & Next' : 'Upload Document'}`} />
                    </div>
                </div>
            </div>

            {/* Success Popup content */}
            <SuccessPopup open={openSuccessPopup} closable={false} onClose={closeSuccessPopup} content={<div>
                <div className='paddingTop32 text-center'>
                    <div className='textColorBlack fontSize24 montserratFont fontMedium'>Created Successfully!</div>
                    <div className='fontSize16 textColor333'>User Created successfully.</div>
                </div>
            </div>} />
            {loading && <Loader />}
        </div>
    )
}

export default CreateUser