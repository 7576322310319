import React, { useEffect, useState, useRef } from 'react';
import CustomTabs from '../../../ReUsableComponent/CustomTabs';
import { ViewStoreTabs } from './ViewStoreTabList';
import './ViewStore.css';
import Loader from '../../../ReUsableComponent/Loader';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import TickIconWithGreenBg from '../../../assets/svg/noun_tick_684585.svg';
import PdfFileicon from '../../../assets/svg/pdfIcon.svg';
import JpgFileIcon from '../../../assets/svg/jpgIcon.svg';
import { storeManagementActions } from '../../../store/reducers/StoreManagementReducer';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import EditIcon from '../../../assets/svg/edit-icon.svg';
import StatusTag from '../../../ReUsableComponent/StatusTag';
import RightArrowIcon from '../../../assets/svg/rightArrow.svg';
import { Link } from 'react-router-dom';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import CustomDatePicker from '../../../ReUsableComponent/DatePickerComponent';
import dayjs from 'dayjs';

import InputTextField from '../../../ReUsableComponent/InputTextField';
import CustomAutoComplete from '../../../ReUsableComponent/CustomAutocomplete';
import MultiSelectCheckBox from '../../../ReUsableComponent/MultiSelectCheckBox';
import CustomDropzone from '../../../ReUsableComponent/CustomDropzone';

import ExcellIcon from '../../../assets/svg/pdf.svg';
import PngIcon from '../../../assets/svg/png.svg';
import JpegIcon from '../../../assets/svg/jpeg.svg';

import CloseIcon from '../../../assets/svg/closeIconofmodal.svg';

import { CustomErrorMsg } from './../../../ReUsableComponent/CustomErrorMsg';
import PlusIcon from '../../../assets/svg/plus_icon.svg';
import ImportIcon from '../../../assets/svg/uploadIconForDoc.svg';
import ProgressBarForFileUpload from './../../../ReUsableComponent/ProgressBarForFileUpload';
import SuccessPopup from './../../../ReUsableComponent/SuccessPopupMsg';

import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import FileRenderer from '../../../ReUsableComponent/FileRenderer';
import CustomModal from '../../../ReUsableComponent/CustomModal';
// import PdfViewer from './../../../ReUsableComponent/PdfViewer';


import { getListStateCityByPincode, getDropdownList, fileUpload, getAllBrandList, updateStoreDetailsBasedOnStoreId } from './../StoreManagent.service';
import { validateTextInputAny, isNumberOrFloat, validateTextInputAnyMin, validateTextInput, validateEmailInput, validateNumberInput, validatePinCodeInput, validatePANInput, validateGSTINInput, validateTextAddressInput, validateText2To200Input, validateNumber2To6Input, validateDateFormat, validateNumber1To2Input, validateNumber1To3Input, validateNumberAtLeast1Input, validateFreeNumberInput, handleNumericInput, validateLatitude, validateLongitude, validateTextDotCommaSpace, isFileDetailsEmpty, formatDateToTextMonthDDYYYY, allMandatoryFieldsFilledStore, getFileType, validateTextUnderscoreSlash } from './../../../ReUsableComponent/CustomFormValidation';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const uploadedFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const uploadFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const ViewStoreDetails = () => {
    const timeoutRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);

    const getStoreId = useSelector((state) => state.storeManagement.storeDetailData._id);
    const storeBreadCrumbLabel = useSelector((state) => state.storeManagement.viewStoreSelectedTabLabel);

    const prevus_STATUS = [{ label: 'Active', value: true }, { label: 'Inactive', value: false }];
    const STATUS = prevus_STATUS.map(obj => {
        return { ...obj, name: "a" };
    });

    const storeDetailData = useSelector((state) => state.storeManagement.createStoreOwnerDetails);
    const createStoreOwnerDetails = useSelector((state) => state.storeManagement.createStoreOwnerDetails);

    const getActiveStep = useSelector((state) => state.storeManagement.stepActiveState);
    const [activeStep, setActiveStep] = useState(getActiveStep);

    const getProgress = useSelector((state) => state.storeManagement.stepProgress);

    const capitalize = (str) => str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
    // const capitalize = (str) => {
    //     if (typeof str !== 'string') {
    //       return ;
    //     }
    //     return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
    // };

    const [progress, setProgress] = useState(getProgress);

    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);

    const [selectedTab, setSelectedTab] = useState(useSelector((state) => state.storeManagement.selectedTab));
    const [loading, setLoading] = useState(false);
    const [isEditFormVisible, setIsEditFormVisible] = useState(true);

    // const CONSTITUTIONOFFIRM = [
    //     { label: 'Partnership', value: 'Partnership', name: 'constitution_of_firm' }, 
    //     { label: 'Proprietorship', value: 'Proprietorship', name: 'constitution_of_firm' },
    //     { label: 'Limited Liability Partnership', value: 'Limited Liability Partnership', name: 'constitution_of_firm' }, 
    //     { label: 'Private Limited Company', value: 'Private Limited Company', name: 'constitution_of_firm' },
    //     { label: 'Pubic Limited Company', value: 'Pubic Limited Company', name: 'constitution_of_firm' }, 
    //     { label: 'Company Limited by Guarantee', value: 'Company Limited by Guarantee', name: 'constitution_of_firm' },
    //     { label: 'Unlimited Company', value: 'Unlimited Company', name: 'constitution_of_firm' }, 
    //     { label: 'Others', value: 'Others', name: 'constitution_of_firm' },
    // ];
    // const STORE_TYPE = [
    //     { label: 'Discounted Store', value: 'Discounted Store', name: 'constitution_of_firm' }, 
    //     { label: 'Text 1', value: 'Text 1', name: 'constitution_of_firm' },
    //     { label: 'Text 2', value: 'Text 2', name: 'constitution_of_firm' }, 
    // ];
    // const STORE_OPENING_STATUS = [{ label: 'New Running', value: 'New Running', name: 'store_opening_status' }, { label: 'Old Running', value: 'Old Running', name: 'store_opening_status' }];
    const STORE_STATUS = [{ label: 'Active', value: true, name: 'store_status' }, { label: 'Inactive', value: false, name: 'store_status' }];
    const IS_BRAND_STAFF = [{ label: 'Yes', value: true, name: 'is_brand_staff' }, { label: 'No', value: false, name: 'is_brand_staff' }];
    const DISCOUNT = [{ label: 'Yes', value: 'Yes', name: 'discount' }, { label: 'No', value: 'No', name: 'discount' }];
    const TARGET_LINKED_INSENTIVES = [{ label: 'Yes', value: 'Yes', name: 'target_linked_insentives' }, { label: 'No', value: 'No', name: 'target_linked_insentives' }];
    const CASH_DISCOUNT = [{ label: 'Yes', value: 'Yes', name: 'cash_discount' }, { label: 'No', value: 'No', name: 'cash_discount' }];
    const getStoreStatus = STORE_STATUS.find(option => option.value === createStoreOwnerDetails?.store_status);
    const getIsBrandStaffStatus = createStoreOwnerDetails?.brands?.map(detail => {
        const matchedOption = IS_BRAND_STAFF?.find(option => option?.value === detail?.is_brand_staff);
        return matchedOption?.label;
    });


    const [constitutionOfFirm, setConstitutionOfFirm] = useState([]);
    const [storeTypeDropData, setStoreTypeDropData] = useState([]);
    const [storeOpeningStatusDropData, setStoreOpeningStatusDropData] = useState([]);
    const [storeGstSlab, setStoreGstSlab] = useState([]);
    const [storeClassification, setStoreClassification] = useState([]);
    const [storeClass, setStoreClass] = useState([]);

    const [cityTier, setCityTier] = useState([]);
    const [storeRetailBillingSystem, setStoreRetailBillingSystem] = useState([]);
    const [channelDrop, setChannelDrop] = useState([]);
    const [distributionType, setDistributionType] = useState([]);
    const [freshMarginType, setFreshMarginType] = useState([]);
    const [eossMarginType, setEossMarginType] = useState([]);
    const [discountPercentage, setDiscountPercentage] = useState([]);
    const [cashDiscountType, setCashDiscountType] = useState([]);
    const [StockCorrection, setStockCorrection] = useState([]);
    const [promoContribution, setPromoContribution] = useState([]);
    const [securityDrop, setSecurityDrop] = useState([]);
    const [businessModelDrop, setBusinessModelDrop] = useState([]);
    const [floorPlacementDrop, setFloorPlacementDrop] = useState([]);

    const [categoryDivisionDrop, setCategoryDivisionDrop] = useState([]);
    const [categoryGroupDrop, setCategoryGroupDrop] = useState([]);
    const [categoryPresenceDrop, setCategoryPresenceDrop] = useState([]);

    const [categoryDivisionDropKeyName, setCategoryDivisionDropKeyName] = useState(() => storeDetailData?.brands?.map(brand => brand?.category_division || []));
    const [categoryGroupDropKeyName, setCategoryGroupDropKeyName] = useState(() => storeDetailData?.brands?.map(brand => brand?.category_group || []));


    const [allBrandsListData, setAllBrandsListData] = useState([]);
    const [storeFloorPlacement, setStoreFloorPlacement] = useState([]);
    const [storeNames, setStoreNames] = useState([]);
    const [productType, setProductType] = useState([]);
    const [channelServed, setChannelServed] = useState([]);

    const [allBrandStatus, setAllBrandStatus] = useState();

    const [selectedStoreStatus, setSelectedStoreStatus] = useState(getStoreStatus || null);
    const [isBrandStaff, setIsBrandStaff] = useState(getIsBrandStaffStatus || null);
    const USER_ID = useSelector((state) => state.userAuthentication.userId);
    const [inputConstitutionOfFirm, setInputConstitutionOfFirm] = useState('');
    const [storeStatus, setStoreStatus] = useState('');
    const [storeOpeningStatus, setStoreOpeningStatus] = useState('');
    const [dropService, setDropService] = useState('store');
    const [dropdownKey, setDropdownKey] = useState('');
    const [iSBRANDSTAFFStatus, setISBRANDSTAFFStatus] = useState('');
    const [categoryGroupKey, setCategoryGroupKey] = useState('');
    const [categoryDivisionKey, setCategoryDivisionKey] = useState('');

    const [mouAggrementProgress, setMouAggrementProgress] = React.useState(0);
    const [gstDocumentParentStoreProgress, setGstDocumentParentStoreProgress] = React.useState(0);
    const [gstDocumentStoreProgress, setGstDocumentStoreProgress] = React.useState(0);
    const [panDocumentProgress, setPanDocumentProgress] = React.useState(0);
    const [uploadStorePictureProgress, setUploadStorePictureProgress] = React.useState(0);

    const [storeFacadeProgress, setstoreFacadeProgress] = React.useState(0);
    const [brandFloorSpaceProgress, setbrandFloorSpaceProgress] = React.useState(0);
    const [adjacentProgress, setadjacentProgress] = React.useState(0);
    const [otherProgress, setotherProgress] = React.useState(0);


    const [mouAggrementUploadSpeed, setMouAggrementUploadSpeed] = useState(null);
    const [gstDocumentParentStoreUploadSpeed, setGstDocumentParentStoreUploadSpeed] = useState(null);
    const [gstDocumentStoreUploadSpeed, setGstDocumentStoreUploadSpeed] = useState(null);
    const [panDocumentUploadSpeed, setPanDocumentUploadSpeed] = useState(null);
    const [uploadStorePictureUploadSpeed, setUploadStorePictureUploadSpeed] = useState(null);

    const [storeFacadeUploadSpeed, setStoreFacadeUploadSpeed] = useState(null);
    const [brandFloorSpaceUploadSpeed, setBrandFloorSpaceUploadSpeed] = useState(null);
    const [adjacentUploadSpeed, setAdjacentUploadSpeed] = useState(null);
    const [otherUploadSpeed, setOtherUploadSpeed] = useState(null);

    const [startTime, setStartTime] = useState(null);

    const [mouAggrementTypeIcon, setMouAggrementTypeIcon] = useState(storeDetailData?.document_details && ((getFileType(storeDetailData?.document_details[0]?.doc_name) === 'pdf') ? ExcellIcon : ((getFileType(storeDetailData?.document_details[0]?.doc_name) === 'png') ? PngIcon : JpegIcon)));
    const [gstDocumentParentStoreFileTypeIcon, setGstDocumentParentStoreFileTypeIcon] = useState(storeDetailData?.document_details && ((getFileType(storeDetailData?.document_details[1]?.doc_name) === 'pdf') ? ExcellIcon : ((getFileType(storeDetailData?.document_details[1]?.doc_name) === 'png') ? PngIcon : JpegIcon)));
    const [gstDocumentStoreFileTypeIcon, setGstDocumentStoreFileTypeIcon] = useState(storeDetailData?.document_details && ((getFileType(storeDetailData?.document_details[2]?.doc_name) === 'pdf') ? ExcellIcon : ((getFileType(storeDetailData?.document_details[2]?.doc_name) === 'png') ? PngIcon : JpegIcon)));
    const [panDocumentFileTypeIcon, setPanDocumentFileTypeIcon] = useState(storeDetailData?.document_details && ((getFileType(storeDetailData?.document_details[3]?.doc_name) === 'pdf') ? ExcellIcon : ((getFileType(storeDetailData?.document_details[3]?.doc_name) === 'png') ? PngIcon : JpegIcon)));
    const [uploadStorePictureFileTypeIcon, setUploadStorePictureFileTypeIcon] = useState(storeDetailData?.document_details && ((getFileType(storeDetailData?.document_details[4]?.doc_name) === 'pdf') ? ExcellIcon : ((getFileType(storeDetailData?.document_details[4]?.doc_name) === 'png') ? PngIcon : JpegIcon)));
    const [otherFileTypeIcon, setOtherFileTypeIcon] = useState(storeDetailData?.document_details && ((getFileType(storeDetailData?.document_details[5]?.doc_name) === 'pdf') ? ExcellIcon : ((getFileType(storeDetailData?.document_details[5]?.doc_name) === 'png') ? PngIcon : JpegIcon)));

    const [storeFacadeTypeIcon, setStoreFacadeTypeIcon] = useState(storeDetailData?.document_details && ((getFileType(storeDetailData?.document_details[6]?.doc_name) === 'pdf') ? ExcellIcon : ((getFileType(storeDetailData?.document_details[6]?.doc_name) === 'png') ? PngIcon : JpegIcon)));
    const [brandFloorSpaceFileTypeIcon, setBrandFloorSpaceFileTypeIcon] = useState(storeDetailData?.document_details && ((getFileType(storeDetailData?.document_details[7]?.doc_name) === 'pdf') ? ExcellIcon : ((getFileType(storeDetailData?.document_details[7]?.doc_name) === 'png') ? PngIcon : JpegIcon)));
    const [adjacentFileTypeIcon, setAdjacentFileTypeIcon] = useState(storeDetailData?.document_details && ((getFileType(storeDetailData?.document_details[8]?.doc_name) === 'pdf') ? ExcellIcon : ((getFileType(storeDetailData?.document_details[8]?.doc_name) === 'png') ? PngIcon : JpegIcon)));

    const tempObj = {
        store_id: '',
        brand_id: '',
        brand_name: '',
        file_id: '',
        doc_name: '',
        doc_url: '',
        size: '',
        created_at: '',
        doc_type: '',
    }

    const [mouAggrementFile, setMouAggrementFile] = useState(storeDetailData?.document_details ? storeDetailData?.document_details[0] === undefined ? tempObj : storeDetailData?.document_details[0] : tempObj);
    const [gstDocumentParentStoreFile, setGstDocumentParentStoreFile] = useState(storeDetailData?.document_details ? storeDetailData?.document_details[1] === undefined ? tempObj : storeDetailData?.document_details[1] : tempObj);
    const [gstDocumentStoreFile, setGstDocumentStoreFile] = useState(storeDetailData?.document_details ? storeDetailData?.document_details[2] === undefined ? tempObj : storeDetailData?.document_details[2] : tempObj);
    const [panDocumentFile, setPanDocumentFile] = useState(storeDetailData?.document_details ? storeDetailData?.document_details[3] === undefined ? tempObj : storeDetailData?.document_details[3] : tempObj);
    const [uploadStorePictureFile, setUploadStorePictureFile] = useState(storeDetailData?.document_details ? storeDetailData?.document_details[4] === undefined ? tempObj : storeDetailData?.document_details[4] : tempObj);
    const [storeFacadeFile, setStoreFacadeFile] = useState(storeDetailData?.document_details ? storeDetailData?.document_details[5] === undefined ? tempObj : storeDetailData?.document_details[5] : tempObj);
    const [brandFloorSpaceFile, setBrandFloorSpaceFile] = useState(storeDetailData?.document_details ? storeDetailData?.document_details[6] === undefined ? tempObj : storeDetailData?.document_details[6] : tempObj);
    const [adjacentFile, setAdjacentFile] = useState(storeDetailData?.document_details ? storeDetailData?.document_details[7] === undefined ? tempObj : storeDetailData?.document_details[7] : tempObj);
    const [otherFile, setOtherFile] = useState(storeDetailData?.document_details ? storeDetailData?.document_details[8] === undefined ? tempObj : storeDetailData?.document_details[8] : tempObj);



    // error Handling
    const [ownerDetailsFormError, setOwnerDetailsFormError] = useState({
        contact_detail: {},
        address: {},
    });
    const [storeDemographicFormError, setStoreDemographicFormError] = useState({
        contact_detail: {},
        address: {},
    });
    const [parentStoreFormError, setParentStoreFormError] = useState({});
    const [contactDetailsFormError, setContactDetailsFormError] = useState({});
    const [storeContractDetailsFormError, setStoreContractDetailsFormError] = useState({});
    const [storeWallBrandsDetailsFormError, setStoreWallBrandsDetailsFormError] = useState({});
    const [storeAddressesDetailsFormError, setStoreAddressesDetailsFormError] = useState({
        billing_place_address: {},
        shipping_place_address: {},
        warehouse_address: {},
        return_warehouse_address: {},
    });

    // data store
    const [ownerDetails, setOwnerDetails] = useState({
        constitution_of_firm: createStoreOwnerDetails?.owner_detail?.constitution_of_firm ? createStoreOwnerDetails?.owner_detail?.constitution_of_firm : '',
        authorise_signitory_name: createStoreOwnerDetails?.owner_detail?.authorise_signitory_name ? createStoreOwnerDetails?.owner_detail?.authorise_signitory_name : '',
        business_incharge_name: createStoreOwnerDetails?.owner_detail?.business_incharge_name ? createStoreOwnerDetails?.owner_detail?.business_incharge_name : '',
        business_incharge_mobile_number: createStoreOwnerDetails?.owner_detail?.business_incharge_mobile_number ? createStoreOwnerDetails?.owner_detail?.business_incharge_mobile_number : '',
        contact_detail: {
            email: createStoreOwnerDetails?.owner_detail?.contact_detail?.email ? createStoreOwnerDetails?.owner_detail?.contact_detail?.email : '',
            mobile: createStoreOwnerDetails?.owner_detail?.contact_detail?.mobile ? createStoreOwnerDetails?.owner_detail?.contact_detail?.mobile : '',
            person_name: createStoreOwnerDetails?.owner_detail?.contact_detail?.person_name ? createStoreOwnerDetails?.owner_detail?.contact_detail?.person_name : '',
        },
        address: {
            address: createStoreOwnerDetails?.owner_detail?.address?.address ? createStoreOwnerDetails?.owner_detail?.address?.address : '',
            pincode: createStoreOwnerDetails?.owner_detail?.address?.pincode ? createStoreOwnerDetails?.owner_detail?.address?.pincode : '',
            region: createStoreOwnerDetails?.owner_detail?.address?.region ? createStoreOwnerDetails?.owner_detail?.address?.region : '',
            state: createStoreOwnerDetails?.owner_detail?.address?.state ? createStoreOwnerDetails?.owner_detail?.address?.state : '',
            city: createStoreOwnerDetails?.owner_detail?.address?.city ? createStoreOwnerDetails?.owner_detail?.address?.city : '',
            area: createStoreOwnerDetails?.owner_detail?.address?.area ? createStoreOwnerDetails?.owner_detail?.address?.area : '',
        }
    });
    const [storeDemographic, setStoreDemographic] = useState({
        customer_name: createStoreOwnerDetails?.customer_name ? createStoreOwnerDetails?.customer_name : '',
        store_legal_name: createStoreOwnerDetails?.store_legal_name ? createStoreOwnerDetails?.store_legal_name : '',
        store_trade_name: createStoreOwnerDetails?.store_trade_name ? createStoreOwnerDetails?.store_trade_name : '',
        latitude: createStoreOwnerDetails?.latitude ? createStoreOwnerDetails?.latitude : '',
        longitude: createStoreOwnerDetails?.longitude ? createStoreOwnerDetails?.longitude : '',
        store_status: createStoreOwnerDetails?.store_status ? createStoreOwnerDetails?.store_status : false,
        store_opening: createStoreOwnerDetails?.store_opening ? createStoreOwnerDetails?.store_opening : '',
        store_opening_status: createStoreOwnerDetails?.store_opening_status ? createStoreOwnerDetails?.store_opening_status : '',
        store_type: createStoreOwnerDetails?.store_type ? createStoreOwnerDetails?.store_type : '',
        store_classification: createStoreOwnerDetails?.store_classification ? createStoreOwnerDetails?.store_classification : [],
        store_class: createStoreOwnerDetails?.store_class ? createStoreOwnerDetails?.store_class : '',
        store_retail_billing: createStoreOwnerDetails?.store_retail_billing ? createStoreOwnerDetails?.store_retail_billing : '',
        store_details: createStoreOwnerDetails?.store_details ? createStoreOwnerDetails?.store_details : '',
        cust_no: createStoreOwnerDetails?.cust_no ? createStoreOwnerDetails?.cust_no : '',
        gstin: createStoreOwnerDetails?.gstin ? createStoreOwnerDetails?.gstin : '',
        gst_slab: createStoreOwnerDetails?.gst_slab ? createStoreOwnerDetails?.gst_slab : '',
        address: {
            address: createStoreOwnerDetails?.store_address?.address ? createStoreOwnerDetails?.store_address?.address : '',
            pincode: createStoreOwnerDetails?.store_address?.pincode ? createStoreOwnerDetails?.store_address?.pincode : '',
            region: createStoreOwnerDetails?.store_address?.region ? createStoreOwnerDetails?.store_address?.region : '',
            state: createStoreOwnerDetails?.store_address?.state ? createStoreOwnerDetails?.store_address?.state : '',
            city: createStoreOwnerDetails?.store_address?.city ? createStoreOwnerDetails?.store_address?.city : '',
            area: createStoreOwnerDetails?.store_address?.area ? createStoreOwnerDetails?.store_address?.area : '',
            email: createStoreOwnerDetails?.store_address?.email ? createStoreOwnerDetails?.store_address?.email : '',
            mobile: createStoreOwnerDetails?.store_address?.mobile ? createStoreOwnerDetails?.store_address?.mobile : '',
        },
        city_tier: createStoreOwnerDetails?.city_tier ? createStoreOwnerDetails?.city_tier : '',
        regional_manager: createStoreOwnerDetails?.regional_manager ? createStoreOwnerDetails?.regional_manager : '',
        pan_number: createStoreOwnerDetails?.pan_number ? createStoreOwnerDetails?.pan_number : '',

        // need to change in backend
        store_floor_size: 0,
        // store_floor_size: createStoreOwnerDetails?.store_floor_size ? createStoreOwnerDetails?.store_floor_size : '',
    });
    const [parentStore, setParentStore] = useState({
        parent_store_id: createStoreOwnerDetails?.parent_store_id ? createStoreOwnerDetails?.parent_store_id : '',
        parent_store_name: createStoreOwnerDetails?.parent_store_name ? createStoreOwnerDetails?.parent_store_name : '',
        parent_store_gstin: createStoreOwnerDetails?.parent_store_gstin ? createStoreOwnerDetails?.parent_store_gstin : '',
    });
    const [contactDetails, setContactDetails] = useState([]);
    const [storeContractDetails, setStoreContractDetails] = useState({
        distribution_type: createStoreOwnerDetails?.distribution_type ? createStoreOwnerDetails?.distribution_type : '',
        channel: createStoreOwnerDetails?.channel ? createStoreOwnerDetails?.channel : '',
        fresh_margin_type: createStoreOwnerDetails?.fresh_margin_type ? createStoreOwnerDetails?.fresh_margin_type : '',
        fresh_margin: createStoreOwnerDetails?.fresh_margin ? createStoreOwnerDetails?.fresh_margin : '',
        eoss_margin_type: createStoreOwnerDetails?.eoss_margin_type ? createStoreOwnerDetails?.eoss_margin_type : '',
        eoss_margin: createStoreOwnerDetails?.eoss_margin ? createStoreOwnerDetails?.eoss_margin : '',
        eoss_threshold_value: createStoreOwnerDetails?.eoss_threshold_value ? createStoreOwnerDetails?.eoss_threshold_value : 0,
        base_margin: createStoreOwnerDetails?.base_margin ? createStoreOwnerDetails?.base_margin : 0,
        markdown_percentage_type: createStoreOwnerDetails?.markdown_percentage_type ? createStoreOwnerDetails?.markdown_percentage_type : '',
        markdown_percentage: createStoreOwnerDetails?.markdown_percentage ? createStoreOwnerDetails?.markdown_percentage : '',
        // discount: createStoreOwnerDetails?.discount ? ((capitalize(createStoreOwnerDetails?.discount) === 'Yes' || capitalize(createStoreOwnerDetails?.discount) === 'No') ? capitalize(createStoreOwnerDetails?.discount) : createStoreOwnerDetails?.discount === false ? 'No' : 'Yes'  ) : '',
        discount: createStoreOwnerDetails?.discount === true ? 'Yes' : 'No',
        discount_percentage: createStoreOwnerDetails?.discount_percentage ? createStoreOwnerDetails?.discount_percentage : '',
        // target_linked_insentives: createStoreOwnerDetails?.target_linked_insentives ? ((capitalize(createStoreOwnerDetails?.target_linked_insentives) === 'Yes' || capitalize(createStoreOwnerDetails?.target_linked_insentives) === 'No') ? capitalize(createStoreOwnerDetails?.target_linked_insentives) : createStoreOwnerDetails?.target_linked_insentives === false ? 'No' : 'Yes'  ) : '',
        target_linked_insentives: createStoreOwnerDetails?.target_linked_insentives === true ? 'Yes' : 'No',
        target_linked_insentives_percentagre: createStoreOwnerDetails?.target_linked_insentives_percentagre ? createStoreOwnerDetails?.target_linked_insentives_percentagre : '',
        // cash_discount: createStoreOwnerDetails?.cash_discount ? ((capitalize(createStoreOwnerDetails?.cash_discount) === 'Yes' || capitalize(createStoreOwnerDetails?.cash_discount) === 'No') ? capitalize(createStoreOwnerDetails?.cash_discount) : createStoreOwnerDetails?.cash_discount === false ? 'No' : 'Yes'  ) : '',
        cash_discount: createStoreOwnerDetails?.cash_discount === true ? 'Yes' : 'No',
        cash_discount_type: createStoreOwnerDetails?.cash_discount_type ? createStoreOwnerDetails?.cash_discount_type : '',
        cash_discount_percentage: createStoreOwnerDetails?.cash_discount_percentage ? createStoreOwnerDetails?.cash_discount_percentage : '',
        payable_duration: createStoreOwnerDetails?.payable_duration ? createStoreOwnerDetails?.payable_duration : '',
        stock_correction: createStoreOwnerDetails?.stock_correction ? createStoreOwnerDetails?.stock_correction : '',
        promo_contribution: createStoreOwnerDetails?.promo_contribution ? createStoreOwnerDetails?.promo_contribution : '',
        // is_security: createStoreOwnerDetails?.is_security ? createStoreOwnerDetails?.is_security : '',
        // is_security: createStoreOwnerDetails?.is_security ? ((capitalize(createStoreOwnerDetails?.is_security) === 'Yes' || capitalize(createStoreOwnerDetails?.is_security) === 'No') ? capitalize(createStoreOwnerDetails?.is_security) : createStoreOwnerDetails?.is_security === false ? 'No' : 'Yes'  ) : '',
        is_security: createStoreOwnerDetails?.is_security === true ? 'Yes' : 'No',
        // need To ask
        comments: createStoreOwnerDetails?.security_comments ? createStoreOwnerDetails?.security_comments : '',
        credit_limit: createStoreOwnerDetails?.credit_limit ? createStoreOwnerDetails?.credit_limit : '',
        business_model: createStoreOwnerDetails?.business_model ? createStoreOwnerDetails?.business_model : [],
        store_floor_size: createStoreOwnerDetails?.store_floor_size ? createStoreOwnerDetails?.store_floor_size : '',
        primary_billing_discount: 0,
    });
    const [storeWallBrandsDetails, setStoreWallBrandsDetails] = useState([]);
    const [storeAddressesDetails, setStoreAddressesDetails] = useState({
        billing_place_address: {
            address: createStoreOwnerDetails?.billing_place_address?.address ? createStoreOwnerDetails?.billing_place_address?.address : '',
            pincode: createStoreOwnerDetails?.billing_place_address?.pincode ? createStoreOwnerDetails?.billing_place_address?.pincode : '',
            region: createStoreOwnerDetails?.billing_place_address?.region ? createStoreOwnerDetails?.billing_place_address?.region : '',
            state: createStoreOwnerDetails?.billing_place_address?.state ? createStoreOwnerDetails?.billing_place_address?.state : '',
            city: createStoreOwnerDetails?.billing_place_address?.city ? createStoreOwnerDetails?.billing_place_address?.city : '',
            area: createStoreOwnerDetails?.billing_place_address?.area ? createStoreOwnerDetails?.billing_place_address?.area : '',
            email: createStoreOwnerDetails?.billing_place_address?.email ? createStoreOwnerDetails?.billing_place_address?.email : '',
            phone_number: createStoreOwnerDetails?.billing_place_address?.phone_number ? createStoreOwnerDetails?.billing_place_address?.phone_number : '',
            mobile: createStoreOwnerDetails?.billing_place_address?.mobile ? createStoreOwnerDetails?.billing_place_address?.mobile : '',
            person_name: createStoreOwnerDetails?.billing_place_address?.person_name ? createStoreOwnerDetails?.billing_place_address?.person_name : '',
            person_dept: createStoreOwnerDetails?.billing_place_address?.person_dept ? createStoreOwnerDetails?.billing_place_address?.person_dept : '',
            person_other: createStoreOwnerDetails?.billing_place_address?.person_other ? createStoreOwnerDetails?.billing_place_address?.person_other : '',
        },
        shipping_place_address: {
            address: createStoreOwnerDetails?.shipping_place_address?.address ? createStoreOwnerDetails?.shipping_place_address?.address : '',
            pincode: createStoreOwnerDetails?.shipping_place_address?.pincode ? createStoreOwnerDetails?.shipping_place_address?.pincode : '',
            region: createStoreOwnerDetails?.shipping_place_address?.region ? createStoreOwnerDetails?.shipping_place_address?.region : '',
            state: createStoreOwnerDetails?.shipping_place_address?.state ? createStoreOwnerDetails?.shipping_place_address?.state : '',
            city: createStoreOwnerDetails?.shipping_place_address?.city ? createStoreOwnerDetails?.shipping_place_address?.city : '',
            area: createStoreOwnerDetails?.shipping_place_address?.area ? createStoreOwnerDetails?.shipping_place_address?.area : '',
            email: createStoreOwnerDetails?.shipping_place_address?.email ? createStoreOwnerDetails?.shipping_place_address?.email : '',
            phone_number: createStoreOwnerDetails?.shipping_place_address?.phone_number ? createStoreOwnerDetails?.shipping_place_address?.phone_number : '',
            mobile: createStoreOwnerDetails?.shipping_place_address?.mobile ? createStoreOwnerDetails?.shipping_place_address?.mobile : '',
            person_name: createStoreOwnerDetails?.shipping_place_address?.person_name ? createStoreOwnerDetails?.shipping_place_address?.person_name : '',
            person_dept: createStoreOwnerDetails?.shipping_place_address?.person_dept ? createStoreOwnerDetails?.shipping_place_address?.person_dept : '',
            person_other: createStoreOwnerDetails?.shipping_place_address?.person_other ? createStoreOwnerDetails?.shipping_place_address?.person_other : '',
        },
        warehouse_address: {
            address: createStoreOwnerDetails?.warehouse_address?.address ? createStoreOwnerDetails?.warehouse_address?.address : '',
            pincode: createStoreOwnerDetails?.warehouse_address?.pincode ? createStoreOwnerDetails?.warehouse_address?.pincode : '',
            region: createStoreOwnerDetails?.warehouse_address?.region ? createStoreOwnerDetails?.warehouse_address?.region : '',
            state: createStoreOwnerDetails?.warehouse_address?.state ? createStoreOwnerDetails?.warehouse_address?.state : '',
            city: createStoreOwnerDetails?.warehouse_address?.city ? createStoreOwnerDetails?.warehouse_address?.city : '',
            area: createStoreOwnerDetails?.warehouse_address?.area ? createStoreOwnerDetails?.warehouse_address?.area : '',
            email: createStoreOwnerDetails?.warehouse_address?.email ? createStoreOwnerDetails?.warehouse_address?.email : '',
            phone_number: createStoreOwnerDetails?.warehouse_address?.phone_number ? createStoreOwnerDetails?.warehouse_address?.phone_number : '',
            mobile: createStoreOwnerDetails?.warehouse_address?.mobile ? createStoreOwnerDetails?.warehouse_address?.mobile : '',
            person_name: createStoreOwnerDetails?.warehouse_address?.person_name ? createStoreOwnerDetails?.warehouse_address?.person_name : '',
            person_dept: createStoreOwnerDetails?.warehouse_address?.person_dept ? createStoreOwnerDetails?.warehouse_address?.person_dept : '',
            person_other: createStoreOwnerDetails?.warehouse_address?.person_other ? createStoreOwnerDetails?.warehouse_address?.person_other : '',
        },
        return_warehouse_address: {
            address: createStoreOwnerDetails?.return_warehouse_address?.address ? createStoreOwnerDetails?.return_warehouse_address?.address : '',
            pincode: createStoreOwnerDetails?.return_warehouse_address?.pincode ? createStoreOwnerDetails?.return_warehouse_address?.pincode : '',
            region: createStoreOwnerDetails?.return_warehouse_address?.region ? createStoreOwnerDetails?.return_warehouse_address?.region : '',
            state: createStoreOwnerDetails?.return_warehouse_address?.state ? createStoreOwnerDetails?.return_warehouse_address?.state : '',
            city: createStoreOwnerDetails?.return_warehouse_address?.city ? createStoreOwnerDetails?.return_warehouse_address?.city : '',
            area: createStoreOwnerDetails?.return_warehouse_address?.area ? createStoreOwnerDetails?.return_warehouse_address?.area : '',
            email: createStoreOwnerDetails?.return_warehouse_address?.email ? createStoreOwnerDetails?.return_warehouse_address?.email : '',
            phone_number: createStoreOwnerDetails?.return_warehouse_address?.phone_number ? createStoreOwnerDetails?.return_warehouse_address?.phone_number : '',
            mobile: createStoreOwnerDetails?.return_warehouse_address?.mobile ? createStoreOwnerDetails?.return_warehouse_address?.mobile : '',
            person_name: createStoreOwnerDetails?.return_warehouse_address?.person_name ? createStoreOwnerDetails?.return_warehouse_address?.person_name : '',
            person_dept: createStoreOwnerDetails?.return_warehouse_address?.person_dept ? createStoreOwnerDetails?.return_warehouse_address?.person_dept : '',
            person_other: createStoreOwnerDetails?.return_warehouse_address?.person_other ? createStoreOwnerDetails?.return_warehouse_address?.person_other : '',
        },
    });
    const [uploadedFiles, setUploadedFiles] = useState(createStoreOwnerDetails?.document_details ? createStoreOwnerDetails?.document_details : [{
        store_id: '',
        brand_id: '',
        brand_name: '',
        file_id: '',
        doc_name: '',
        doc_url: '',
        size: '',
        created_at: '',
        doc_type: '',
    }]);

    const [isDiscount, setIsDiscount] = useState(!storeDetailData?.discount);
    const [isTargetLinkedInsentives, setIsTargetLinkedInsentives] = useState(!storeDetailData?.target_linked_insentives);
    const [isCashDiscount, setIsCashDiscount] = useState(!storeDetailData?.cash_discount);
    const [openFileViewerModal, setOpenFileViewerModal] = useState(false);
    const [viewFile, setViewFile] = useState([]);

    const [showViewMoreDataPopup, setShowViewMoreDataPopup] = useState(false);
    const [brandViewMoreIndex, setBrandViewMoreIndex] = useState(0);


    // useEffect(() => {
    //     setstoreFacadeProgress(storeDetailData?.document_details[0]?.doc_name !== '' ? 100 : 0);
    //     setbrandFloorSpaceProgress(storeDetailData?.document_details[1]?.doc_name !== '' ? 100 : 0);
    //     setadjacentProgress(storeDetailData?.document_details[2]?.doc_name !== '' ? 100 : 0);
    //     setotherProgress(storeDetailData?.document_details[3]?.doc_name !== '' ? 100 : 0);
    //     // setFormEditedData(storeDetailData);
    // }, []);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        ViewStoreTabs.forEach((tab, id) => {
            if (id === newValue) {
                dispatch(storeManagementActions.setViewStoreSelectedTabLabel({ type: tab.label }));
            }
        })
        dispatch(storeManagementActions.setSelectedTab({ type: newValue }));
        renderTabData(newValue, storeDetailData);
    };

    const convertDate = (openingDate) => {

        // let fullDateTimeString = '2024-03-26T13:30:00';
        let date = new Date(openingDate);
        // Extract the date part
        let year = date.getFullYear();
        let month = date.getMonth() + 1; // Month is zero-based, so we add 1
        let day = date.getDate();

        // Create a new date string in the format YYYY-MM-DD
        let dateString = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
        return dateString
    }

    const onClickViewMoreBrands = () => {
        alert('Coming Soon!!!')
    }

    const onClickEditDetails = () => {
        setIsEditFormVisible(!isEditFormVisible);
    }

    const onClickSaveEditDetails = () => {
        setIsEditFormVisible(!isEditFormVisible);
        if (selectedTab === 7) {
            handleUpdateBrandDetailsBasedOnStoreId(getStoreId, 'uploadButton');
        } else {
            handleUpdateBrandDetailsBasedOnStoreId(getStoreId, '');
        }
    }

    const onClickCancel = () => {
        setIsEditFormVisible(!isEditFormVisible);
    }

    // For File Upload Code Start
    const handleFileDrop = (name, files) => {
        const file = files[0];
        const size = files[0]?.size;
        const allowedTypesForPdf = ['application/pdf'];
        const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
        const allowedTypesForLogo = ['image/png', 'image/jpeg', 'image/jpg'];
        if (name === 'UploadStorePicture') {
            if (allowedTypesForLogo.includes(file.type)) {
                simulateUpload(name);
                if (file.type === 'image/png') {
                    handleFileUpload(name, file, 'Store Logo', PngIcon, size);
                } else if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
                    handleFileUpload(name, file, 'Store Logo', JpegIcon, size);
                }

                if (name === 'UploadStorePicture') {
                    setUploadStorePictureUploadSpeed(0);
                }
            } else {
                alert('Please select a JPEG, JPG or PNG file Only.');
            }
        } else if (name === 'MouAggrement') {
            if (allowedTypesForPdf.includes(file.type)) {
                simulateUpload(name);

                if (file.type === 'application/pdf') {
                    handleFileUpload(name, file, 'Store Document', ExcellIcon, size);
                }

                if (name === 'MouAggrement') {
                    setMouAggrementUploadSpeed(0);
                }
            } else {
                alert('Please select a PDF file Only.');
            }
        } else if (name === 'GstDocumentParentStore') {
            if (allowedTypesForPdf.includes(file.type)) {
                simulateUpload(name);

                if (file.type === 'application/pdf') {
                    handleFileUpload(name, file, 'Store Document', ExcellIcon, size);
                }

                if (name === 'GstDocumentParentStore') {
                    setGstDocumentParentStoreUploadSpeed(0);
                }
            } else {
                alert('Please select a PDF file Only.');
            }
        } else {
            if (allowedTypes.includes(file.type)) {
                simulateUpload(name);

                if (file.type === 'application/pdf') {
                    handleFileUpload(name, file, 'Store Document', ExcellIcon, size);
                } else if (file.type === 'image/png') {
                    handleFileUpload(name, file, 'Store Logo', PngIcon, size);
                } else if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
                    handleFileUpload(name, file, 'Store Logo', JpegIcon, size);
                }
                //if(name === 'MouAggrement' ){
                //     setMouAggrementUploadSpeed(0);
                // }else if(name === 'GstDocumentParentStore' ){
                //     setGstDocumentParentStoreUploadSpeed(0);
                // }
                else if (name === 'GstDocumentStore') {
                    setGstDocumentStoreUploadSpeed(0);
                } else if (name === 'PanDocument') {
                    setPanDocumentUploadSpeed(0);
                }
                // else if(name === 'UploadStorePicture' ){
                //     setUploadStorePictureUploadSpeed(0);
                // }
                else if (name === 'StoreFacade') {
                    setstoreFacadeProgress(0);
                } else if (name === 'BrandFloorSpace') {
                    setbrandFloorSpaceProgress(0);
                } else if (name === 'Adjacent') {
                    setotherProgress(0)
                } else if (name === 'Other') {
                    setotherProgress(0)
                }
            } else {
                alert('Please select a PDF, JPEG, JPG or PNG file Only.');
            }
        }
    };

    const simulateUpload = (name) => {
        if (name === 'MouAggrement') {
            const interval = setInterval(() => {
                setMouAggrementProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setMouAggrementUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setMouAggrementUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'GstDocumentParentStore') {
            const interval = setInterval(() => {
                setGstDocumentParentStoreProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setGstDocumentParentStoreUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setGstDocumentParentStoreUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'GstDocumentStore') {
            const interval = setInterval(() => {
                setGstDocumentStoreProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setGstDocumentStoreUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setGstDocumentStoreUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'PanDocument') {
            const interval = setInterval(() => {
                setPanDocumentProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setPanDocumentUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setPanDocumentUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'UploadStorePicture') {
            const interval = setInterval(() => {
                setUploadStorePictureProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setUploadStorePictureUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setUploadStorePictureUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'StoreFacade') {
            const interval = setInterval(() => {
                setstoreFacadeProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setStoreFacadeUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setStoreFacadeUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'BrandFloorSpace') {
            const interval = setInterval(() => {
                setbrandFloorSpaceProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setBrandFloorSpaceUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setBrandFloorSpaceUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'Adjacent') {
            const interval = setInterval(() => {
                setadjacentProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setAdjacentUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setAdjacentUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500);
        } else if (name === 'Other') {
            const interval = setInterval(() => {
                setotherProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setOtherUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setOtherUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500);
        }
    };

    const handleFileUpload = async (name, file, fileServiceType, fileTypeIcon, size) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('service', 'Brand_Documents');
        formData.append('file_name', 'Data');
        formData.append('service_type', fileServiceType);
        formData.append('size', size);
        formData.append('store_name', createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '');

        fileUpload(formData, USER_TOKEN).then(response => {

            if (response.data.statusCode === 400) {
                ToastErrorService(response.data.message)
            } else {
                if (name === 'MouAggrement') {
                    setMouAggrementFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "MouAggrement",
                        }
                    );
                    setMouAggrementTypeIcon(fileTypeIcon);
                } else if (name === 'GstDocumentParentStore') {
                    setGstDocumentParentStoreFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "GstDocumentParentStore",
                        }
                    );
                    setGstDocumentParentStoreFileTypeIcon(fileTypeIcon);
                } else if (name === 'GstDocumentStore') {
                    setGstDocumentStoreFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "GstDocumentStore",
                        }
                    );
                    setGstDocumentStoreFileTypeIcon(fileTypeIcon);
                } else if (name === 'PanDocument') {
                    setPanDocumentFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "PanDocument",
                        }
                    );
                    setPanDocumentFileTypeIcon(fileTypeIcon);
                } else if (name === 'UploadStorePicture') {
                    setUploadStorePictureFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "UploadStorePicture",
                        }
                    );
                    setUploadStorePictureFileTypeIcon(fileTypeIcon);
                } else if (name === 'StoreFacade') {
                    setStoreFacadeFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "StoreFacade",
                        }
                    );
                    setStoreFacadeTypeIcon(fileTypeIcon);
                } else if (name === 'BrandFloorSpace') {
                    setBrandFloorSpaceFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "BrandFloorSpace",
                        }
                    );
                    setBrandFloorSpaceFileTypeIcon(fileTypeIcon);
                } else if (name === 'Adjacent') {
                    setAdjacentFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "Adjacent",
                        }
                    );
                    setAdjacentFileTypeIcon(fileTypeIcon);
                } else if (name === 'Other') {
                    setOtherFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "Other",
                        }
                    );
                    setOtherFileTypeIcon(fileTypeIcon);
                }
            }
        }).catch(error => {
        }).finally(() => {
            // setLoading(false);
        })
    };

    const removeFile = (name) => {
        // setUploadedFile([]);
        if (name === 'MouAggrement') {
            setMouAggrementFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: ''
                }
            );
        } else if (name === 'GstDocumentParentStore') {
            setGstDocumentParentStoreFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: ''
                }
            );
        } else if (name === 'GstDocumentStore') {
            setGstDocumentStoreFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: ''
                }
            );
        } else if (name === 'PanDocument') {
            setPanDocumentFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: ''
                }
            );
        } else if (name === 'UploadStorePicture') {
            setUploadStorePictureFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: ''
                }
            );
        } else if (name === 'StoreFacade') {
            setStoreFacadeFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            )
        } else if (name === 'BrandFloorSpace') {
            setBrandFloorSpaceFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            )
        } else if (name === 'Adjacent') {
            setAdjacentFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            )
        } else if (name === 'Other') {
            setOtherFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            )
        }
    }

    useEffect(() => {
        const filesToUpload = [
            mouAggrementFile,
            gstDocumentParentStoreFile,
            gstDocumentStoreFile,
            panDocumentFile,
            uploadStorePictureFile,
            storeFacadeFile,
            brandFloorSpaceFile,
            adjacentFile,
            otherFile
        ].filter(file => file);

        setUploadedFiles(filesToUpload);
    }, [mouAggrementFile, gstDocumentParentStoreFile, gstDocumentStoreFile, panDocumentFile, uploadStorePictureFile, storeFacadeFile, brandFloorSpaceFile, adjacentFile, otherFile])



    // const isFileDetailsEmpty = (obj) => {
    //     return (
    //         obj?.file_id === '' &&
    //         obj?.doc_name === '' &&
    //         obj?.doc_url === '' &&
    //         obj?.size === '' &&
    //         obj?.created_at === '' &&
    //         obj?.doc_type === ''
    //     );
    // }

    const handlePinCode = (name, value, field) => {
        const param = `?pincode=${value}`
        getListStateCityByPincode(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                let stateData = response?.data?.data?.map((item) => ({
                    label: item?.state,
                    value: item?.state,
                    name: 'state'
                }));
                const uniqueStateData = Array.from(new Set(stateData.map(item => item.value))).map(value => {
                    return stateData.find(item => item.value === value);
                });

                let cityData = response?.data?.data?.map((item) => ({
                    label: item?.city,
                    value: item?.city,
                    name: 'city'
                }));
                const uniqueCityData = Array.from(new Set(cityData.map(item => item.value))).map(value => {
                    return cityData.find(item => item.value === value);
                });

                let regionData = response?.data?.data?.map((item) => ({
                    label: item?.region,
                    value: item?.region,
                    name: 'region'
                }));
                const uniqueRegionData = Array.from(new Set(regionData.map(item => item.value))).map(value => {
                    return regionData.find(item => item.value === value);
                });

                let areaData = response?.data?.data?.map((item) => ({
                    label: item?.area,
                    value: item?.area,
                    name: 'area'
                }));
                const uniqueAreaData = Array.from(new Set(areaData.map(item => item.value))).map(value => {
                    return areaData.find(item => item.value === value);
                });

                if (name === 'ownerDetails.address.pincode') {
                    setOwnerDetails(prev => ({
                        ...prev, [field.parent]: {
                            ...prev[field.parent],
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : '',
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : '',
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : '',
                            area: uniqueAreaData[0]?.value ? uniqueAreaData[0]?.value : '',
                        }
                    }));
                } else if (name === "storeDemographic.address.pincode") {
                    setStoreDemographic(prev => ({
                        ...prev, [field.parent]: {
                            ...prev[field.parent],
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : '',
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : '',
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : '',
                            area: uniqueAreaData[0]?.value ? uniqueAreaData[0]?.value : '',
                        }
                    }));
                } else if (name === "storeAddressesDetails.billing_place_address.pincode") {
                    setStoreAddressesDetails(prev => ({
                        ...prev, [field.parent]: {
                            ...prev[field.parent],
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : '',
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : '',
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : '',
                            area: uniqueAreaData[0]?.value ? uniqueAreaData[0]?.value : '',
                        }
                    }));
                } else if (name === "storeAddressesDetails.shipping_place_address.pincode") {
                    setStoreAddressesDetails(prev => ({
                        ...prev, [field.parent]: {
                            ...prev[field.parent],
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : '',
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : '',
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : '',
                            area: uniqueAreaData[0]?.value ? uniqueAreaData[0]?.value : '',
                        }
                    }));
                } else if (name === "storeAddressesDetails.warehouse_address.pincode") {
                    setStoreAddressesDetails(prev => ({
                        ...prev, [field.parent]: {
                            ...prev[field.parent],
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : '',
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : '',
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : '',
                            area: uniqueAreaData[0]?.value ? uniqueAreaData[0]?.value : '',
                        }
                    }));
                } else if (name === "storeAddressesDetails.return_warehouse_address.pincode") {
                    setStoreAddressesDetails(prev => ({
                        ...prev, [field.parent]: {
                            ...prev[field.parent],
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : '',
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : '',
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : '',
                            area: uniqueAreaData[0]?.value ? uniqueAreaData[0]?.value : '',
                        }
                    }));
                }
            } else {
                ToastErrorService(response.data.message)
            }
        }).catch(error => {
            ToastErrorService(error);
        }).finally(() => {
            // setLoading(false);
        })
    }

    useEffect(() => {
        handleGetAllDropdownList();
        handleGetAllBrandsList();
    }, []);

    const formatCategories = (categories, name) => {
        return categories.map(category => ({
            label: category,
            value: category,
            name: name,
        }));
    };

    const handleGetAllDropdownList = () => {
        const param = `?service=${dropService}&dropdown_key=${dropdownKey}`
        getDropdownList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                response?.data?.data?.map((item) => {
                    if (item?.dropdown_key === 'constitution_of_firm') {
                        setConstitutionOfFirm(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'store_type') {
                        setStoreTypeDropData(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'store_opening_status') {
                        setStoreOpeningStatusDropData(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'gst_slab') {
                        setStoreGstSlab(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'store_classification') {
                        setStoreClassification(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'store_class') {
                        setStoreClass(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'floor_placement') {
                        setFloorPlacementDrop(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'city_tier') {
                        setCityTier(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'store_retail_billing_system') {
                        setStoreRetailBillingSystem(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'store_names') {
                        setStoreNames(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'ProductType') {
                        setProductType(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "channel") {
                        setChannelDrop(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "ChannelServed") {
                        setChannelServed(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "distribution_type") {
                        setDistributionType(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "fresh_margin_type") {
                        setFreshMarginType(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "eoss_margin_type") {
                        setEossMarginType(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "discount_percentage") {
                        setDiscountPercentage(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "cash_discount_type") {
                        setCashDiscountType(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "stock_correction") {
                        setStockCorrection(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "promo_contribution") {
                        setPromoContribution(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "Security") {
                        setSecurityDrop(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "business_model") {
                        setBusinessModelDrop(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "category_division") {
                        setCategoryDivisionDrop(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }

                });
            } else {
                ToastErrorService(response.data.message)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            // setLoading(false);
        })
    }


    useEffect(() => {
        storeDetailData?.brands?.forEach((brand, index) => {
            handleGetAllDropdownCategoryGroup('category_group', categoryDivisionDropKeyName[index], index);
            handleGetAllDropdownCategoryGroup('category_presence', categoryGroupDropKeyName[index], index);
        });
    }, [])

    const handleGetAllDropdownCategoryGroup = (dropKey, category, index) => {
        let param;
        if (dropKey === 'category_group') {
            param = `?module=${dropService}&dropdown_key=${dropKey}&category_division=${category?.join(",")}`
        } else if (dropKey === 'category_presence') {
            param = `?module=${dropService}&dropdown_key=${dropKey}&category_group=${category?.join(",")}`
        }

        getDropdownList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                const data = response.data.data;
                if (dropKey === "category_group") {
                    const formattedCategories = category?.length === 0 ? [] : formatCategories(extractDropdownValues(data), dropKey);
                    setCategoryGroupDrop(prevState => {
                        const newState = [...prevState];
                        newState[index] = formattedCategories;
                        return newState;
                    });
                } else if (dropKey === "category_presence") {
                    const formattedCategories = category?.length === 0 ? [] : formatCategories(extractDropdownValues(data), dropKey);
                    setCategoryPresenceDrop(prevState => {
                        const newState = [...prevState];
                        newState[index] = formattedCategories;
                        return newState;
                    });
                }
            }
        }).catch(error => {
            ToastErrorService(error);
        }).finally(() => {
            // setLoading(false);
        });
    };

    // Function to extract and combine dropdown values
    const extractDropdownValues = (docs) => {
        let combinedValues = new Set();
        docs.forEach(doc => {
            doc.dropdown_values.forEach(value => {
                combinedValues.add(value);
            });
        });
        return Array.from(combinedValues);
    };

    const formatAllBrandsList = (allBrandsLists) => {
        return allBrandsLists.map(allBrandsList => ({
            label: allBrandsList?.brand_name,
            value: allBrandsList?.brand_name,
            brand_name: allBrandsList?.brand_name,
            email_id: allBrandsList?.email_id,
            id: allBrandsList?._id,
        }));
    };

    const handleGetAllBrandsList = () => {
        let param = `?user_id=${USER_ID}`
        getAllBrandList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                setAllBrandsListData(formatAllBrandsList(response?.data?.data));
            } else {
                ToastErrorService(response.data.message)
            }
        }).catch(error => {
            ToastErrorService(error);
        }).finally(() => {
            // setLoading(false);
        })
    }

    const checkObject = (variable) => {
        if (typeof variable === 'object' && variable !== null) {
            return true
        } else {
            return false
        }
    }

    const handleInputChange = (step, stateName, field, value, validationFunc, errorMsg) => {
        let inputName = checkObject(field) ? `${stateName}.${field?.parent}.${field?.child}` : `${stateName}.${field}`;
        let errorInputName = checkObject(field) ? `${field?.parent}.${field?.child}` : field;
        if (step === 0) {
            handleSetInputDataInStore(field, inputName, setOwnerDetails, value, validationFunc, errorInputName, setOwnerDetailsFormError, errorMsg);
        } else if (step === 1) {
            handleSetInputDataInStore(field, inputName, setStoreDemographic, value, validationFunc, errorInputName, setStoreDemographicFormError, errorMsg);
        } else if (step === 2) {
            handleSetInputDataInStore(field, inputName, setParentStore, value, validationFunc, errorInputName, setParentStoreFormError, errorMsg);
        } else if (step === 4) {
            if (field === 'discount') {
                setIsDiscount(value === 'Yes' ? false : true);
            } else if (field === 'cash_discount') {
                setIsCashDiscount(value === 'Yes' ? false : true);
            } else if (field === 'target_linked_insentives') {
                setIsTargetLinkedInsentives(value === 'Yes' ? false : true);
            }
            handleSetInputDataInStore(field, inputName, setStoreContractDetails, value, validationFunc, errorInputName, setStoreContractDetailsFormError, errorMsg);
        }
        else if (step === 6) {
            handleSetInputDataInStore(field, inputName, setStoreAddressesDetails, value, validationFunc, errorInputName, setStoreAddressesDetailsFormError, errorMsg);
        }
    };

    const handleBrandContactsDetailsChange = (event, index) => {
        const { name, value } = event.target;
        if (name === 'person_name') {
            handleInputArrayChange(name, value, index, contactDetails, setContactDetails, validateTextInputAnyMin, setContactDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'person_dept') {
            handleInputArrayChange(name, value, index, contactDetails, setContactDetails, validateTextInputAnyMin, setContactDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'phone_number') {
            handleInputArrayChange(name, value, index, contactDetails, setContactDetails, validateNumberInput, setContactDetailsFormError, CustomErrorMsg.mobile, '');
        } else if (name === 'email') {
            handleInputArrayChange(name, value, index, contactDetails, setContactDetails, validateEmailInput, setContactDetailsFormError, CustomErrorMsg.email, '');
        } else if (name === 'person_other') {
            handleInputArrayChange(name, value, index, contactDetails, setContactDetails, validateTextInputAnyMin, setContactDetailsFormError, CustomErrorMsg.common, '');
        }
    }

    const handleStoreWallBrandsDetailsDetailsChange = (event, newValue, index, name) => {
        const { value } = event.target;
        const updatedDetails = [...storeWallBrandsDetails];
        if (name === 'brand_name') {
            handleInputArrayChange(name, newValue, index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateTextInputAnyMin, setStoreWallBrandsDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'wall_space') {
            handleInputArrayChange(name, handleNumericInput(value), index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateFreeNumberInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg?.common, '');
        } else if (name === 'enter_cdu') {
            handleInputArrayChange(name, handleNumericInput(value), index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateFreeNumberInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg?.common, '');
        } else if (name === 'default_length') {
            handleInputArrayChange(name, handleNumericInput(value), index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateFreeNumberInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg?.common, '');
        } else if (name === 'total_sq_ft') {
            handleInputArrayChange(name, handleNumericInput(value), index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateFreeNumberInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg?.common, '');
        } else if (name === 'display_options') {
            handleInputArrayChange(name, handleNumericInput(value), index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateFreeNumberInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg?.common, '');
        } else if (name === 'per_running_feet') {
            handleInputArrayChange(name, handleNumericInput(value), index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateFreeNumberInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg?.common, '');
        } else if (name === 'floor_placement') {
            handleInputArrayChange(name, value, index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateTextInputAny, setStoreWallBrandsDetailsFormError, CustomErrorMsg?.common, '');
        } else if (name === 'display_qty') {
            handleInputArrayChange(name, handleNumericInput(value), index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateFreeNumberInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg?.common, '');
        } else if (name === 'depth_per_option') {
            handleInputArrayChange(name, handleNumericInput(value), index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateFreeNumberInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg?.common, '');
        } else if (name === 'category_division') {
            updatedDetails[index]['category_group'] = [];
            updatedDetails[index]['category_presence'] = [];
            setCategoryPresenceDrop(prevState => {
                const newState = [...prevState];
                newState[index] = [];
                return newState;
            });
            setStoreWallBrandsDetails(updatedDetails);
            handleGetAllDropdownCategoryGroup('category_group', value, index);
            handleInputArrayChange(name, value, index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateTextInputAny, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'category_group') {
            updatedDetails[index]['category_presence'] = [];
            setStoreWallBrandsDetails(updatedDetails);
            handleGetAllDropdownCategoryGroup('category_presence', value, index);
            handleInputArrayChange(name, value, index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateTextInputAny, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'category_presence') {
            handleInputArrayChange(name, value, index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateTextInputAny, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'is_brand_staff') {
            const newIsBrandStaff = [...isBrandStaff];
            newIsBrandStaff[index] = newValue?.label;
            setIsBrandStaff(newIsBrandStaff);
            handleInputArrayChange(name, newValue?.value, index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateTextInputAny, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'brand_staff_name') {
            handleInputArrayChange(name, value, index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateTextInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'brand_staff_terms') {
            handleInputArrayChange(name, value, index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateTextInputAny, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        }
    }

    const handleSetInputDataInStore = (field, inputName, setState, value, validationFunc, errorInputName, setErrorState, errorMsg) => {
        const keys = errorInputName.split('.');
        if (typeof field === 'string') {
            if (field === 'store_status') {
                setSelectedStoreStatus(value);
                setState(prev => ({ ...prev, [field]: value?.value }));
            } else {
                setState(prev => ({ ...prev, [field]: value }));
            }

        } else if (typeof field === 'object' && field.parent) {
            setState(prev => ({
                ...prev, [field.parent]: { ...prev[field.parent], [field.child]: value }
            }));
        }

        if (validationFunc(value)) {
            setErrorState(prevState => {
                let updatedState = { ...prevState };
                if (keys.length === 1) {
                    updatedState[keys[0]] = null;
                } else {
                    let nestedObj = updatedState;
                    for (let i = 0; i < keys.length - 1; i++) {
                        nestedObj = nestedObj[keys[i]];
                    }
                    nestedObj[keys[keys.length - 1]] = null;
                }
                return updatedState;
            });

            if (field?.child === 'pincode') {
                let conditionPinCode = (value) => {
                    if (value.length === 6 && /^\d+$/.test(value)) {
                        return true;
                    }
                    return false;
                }
                if (conditionPinCode) {
                    handlePinCode(inputName, value, field);
                }
            }
        } else {
            setErrorState(prevState => {
                let updatedState = { ...prevState };
                if (keys.length === 1) {
                    updatedState[keys[0]] = errorMsg;
                } else {
                    let nestedObj = updatedState;
                    for (let i = 0; i < keys.length - 1; i++) {
                        nestedObj = nestedObj[keys[i]];
                    }
                    nestedObj[keys[keys.length - 1]] = errorMsg;
                }
                return updatedState;
            });
        }
    }

    const handleInputArrayChange = (name, value, index, state, setState, validationFunc, setErrorState, errorMsg, companyContactDetailsBranchPincode) => {
        if (name === 'brand_name') {
            const updatedDetails = [...state];
            updatedDetails[index]['brand_id'] = value?.id;
            updatedDetails[index][name] = value?.label;
            setState(updatedDetails);
        } else {
            const updatedDetails = [...state];
            updatedDetails[index][name] = value;
            setState(updatedDetails);
        }

        if (validationFunc(value)) {
            setErrorState(prevErrors => ({ ...prevErrors, [name]: null }));
        } else {
            setErrorState(prevErrors => ({ ...prevErrors, [name]: errorMsg }));
        }
    }

    const handleUpdateBrandDetailsBasedOnStoreId = (storeId, uploadButton) => {
        const tempStoreContractDetails = {
            ...storeContractDetails,
            store_floor_size: Number(storeContractDetails?.store_floor_size),
            discount_percentage: isDiscount ? "" : storeContractDetails?.discount_percentage,
            target_linked_insentives_percentagre: isTargetLinkedInsentives ? "" : storeContractDetails?.target_linked_insentives_percentagre,
            cash_discount_percentage: isCashDiscount ? "" : storeContractDetails?.cash_discount_percentage,
            // discount: createStoreOwnerDetails?.discount ? ((createStoreOwnerDetails?.discount === true || createStoreOwnerDetails?.discount === false ) ? createStoreOwnerDetails?.discount : capitalize(createStoreOwnerDetails?.discount) === 'Yes' ? true : false  ) : '',
            // target_linked_insentives: createStoreOwnerDetails?.target_linked_insentives ? ((createStoreOwnerDetails?.target_linked_insentives === true || createStoreOwnerDetails?.target_linked_insentives === false) ? createStoreOwnerDetails?.target_linked_insentives : capitalize(createStoreOwnerDetails?.target_linked_insentives) === 'Yes' ? true : false  ) : '',
            // cash_discount: createStoreOwnerDetails?.cash_discount ? ((createStoreOwnerDetails?.cash_discount === true || createStoreOwnerDetails?.cash_discount === false) ? createStoreOwnerDetails?.cash_discount : capitalize(createStoreOwnerDetails?.cash_discount) === 'Yes' ? true : false  ) : '',
            // is_security: createStoreOwnerDetails?.is_security ? ((createStoreOwnerDetails?.is_security === true || createStoreOwnerDetails?.is_security === false) ? createStoreOwnerDetails?.is_security : capitalize(createStoreOwnerDetails?.is_security) === 'Yes' ? true : false  ) : '',
            discount: storeContractDetails?.discount ? (capitalize(storeContractDetails?.discount) === 'Yes' ? true : false) : false,
            target_linked_insentives: storeContractDetails?.target_linked_insentives ? (capitalize(storeContractDetails?.target_linked_insentives) === 'Yes' ? true : false) : false,
            cash_discount: storeContractDetails?.cash_discount ? (capitalize(storeContractDetails?.cash_discount) === 'Yes' ? true : false) : false,
            is_security: storeContractDetails?.is_security ? (capitalize(storeContractDetails?.is_security) === 'Yes' ? true : false) : false,
        }

        const tempStoreWallBrandsDetails = storeWallBrandsDetails?.map(details => ({
            ...details,
            wall_space: Number(details?.wall_space),
            enter_cdu: Number(details?.enter_cdu),
            default_length: Number(details?.default_length),
            total_sq_ft: Number(details?.total_sq_ft),
            display_options: Number(details?.display_options),
            per_running_feet: Number(details?.per_running_feet),
            display_qty: Number(details?.display_qty),
            depth_per_option: Number(details?.depth_per_option),
        }));


        let param = `?store_id=${storeId}`;
        let reqBody = {
            owner_detail: ownerDetails,
            // store_demographic: tempStoreDemographic,
            store_demographic: storeDemographic,
            parent_store: parentStore,
            store_contact_detail: contactDetails,
            // store_contract:storeContractDetails,
            store_contract: tempStoreContractDetails,
            brands: tempStoreWallBrandsDetails,
            billing_place_address: storeAddressesDetails.billing_place_address,
            shipping_place_address: storeAddressesDetails.shipping_place_address,
            warehouse_address: storeAddressesDetails.warehouse_address,
            return_warehouse_address: storeAddressesDetails.return_warehouse_address,
            // brand_staff_info: brandStaffDetails,
            store_doc_details: uploadedFiles,
            updated_by: 'true',
            is_profile_completed: allMandatoryFieldsFilledStore(ownerDetails, storeDemographic, parentStore, contactDetails, storeContractDetails, storeWallBrandsDetails, storeAddressesDetails, mouAggrementFile, gstDocumentParentStoreFile, gstDocumentStoreFile, panDocumentFile) ? true : false,
        };
        updateStoreDetailsBasedOnStoreId(reqBody, param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                // getStoreDetailById(storeId);
                ToastSuccessService(response.data.message);
                let responseData = response?.data?.data;
                dispatch(storeManagementActions.setCreateStoreOwnerDetails({ type: responseData }));
                // dispatch(storeManagementActions.setStoreDetailData({ type: response?.data?.data }));
                if (activeStep !== 0) {
                    if ((activeStep + 1) < ViewStoreTabs.length) {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        dispatch(storeManagementActions.setStepActiveState({ type: activeStep + 1 }));
                    }
                    if (progress < 100) {
                        let formCompleted = 100 / (ViewStoreTabs.length)
                        setProgress(prev =>
                            prev += formCompleted
                        )
                        dispatch(storeManagementActions.setStepProgress({ type: progress + formCompleted }));
                    }
                }
                if (uploadButton === "uploadButton") {
                    setOpenSuccessPopup(true);
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    // Set a new timeout to automatically close the popup
                    timeoutRef.current = setTimeout(() => {
                        dispatch(storeManagementActions.setStepActiveState({ type: 0 }));
                        dispatch(storeManagementActions.setStepProgress({ type: 0 }));
                        dispatch(storeManagementActions.setCreateStoreOwnerDetails({ type: {} }));
                        history.push('/app/storeManagement');
                        setOpenSuccessPopup(false);
                    }, 5000)
                }
            } else if (response.data.statusCode === 404) {
                ToastErrorService(response.data.message);
                dispatch(storeManagementActions.setStepActiveState({ type: 0 }));
                dispatch(storeManagementActions.setStepProgress({ type: 0 }));
                dispatch(storeManagementActions.setCreateStoreOwnerDetails({ type: {} }));
            } else {
                ToastErrorService(response.data.message);
            }
        }).catch(error => {
            ToastErrorService(error);
        }).finally(() => {
            // setLoading(false);
        })
    }


    useEffect(() => {
        if (createStoreOwnerDetails?.store_contact_detail) {
            const processedContacts = createStoreOwnerDetails?.store_contact_detail?.map(person => ({
                person_dept: person?.person_dept || '',
                person_name: person?.person_name || '',
                phone_number: person?.phone_number || '',
                email: person?.email || '',
                person_other: person?.person_other || '',
            }));

            const nonEmptyContacts = processedContacts?.filter(contact =>
                Object.values(contact).some(value => value !== '')
            );

            if (nonEmptyContacts?.length === 0) {
                setContactDetails([{
                    person_dept: '',
                    person_name: '',
                    phone_number: '',
                    email: '',
                    person_other: ''
                }]);
            } else {
                setContactDetails(nonEmptyContacts);
            }
        }
    }, [createStoreOwnerDetails]);

    const addNewContactDetailsForm = () => {
        const newContact = {
            email: '',
            phone_number: '',
            // mobile: '',
            person_name: '',
            person_dept: '',
            person_other: '',
        };

        if (contactDetails?.length <= 4) {
            setContactDetails([...contactDetails, newContact]);
        }
    }

    useEffect(() => {
        if (createStoreOwnerDetails?.brands) {
            const processedContacts = createStoreOwnerDetails?.brands?.map(person => ({
                brand_id: person?.brand_id || '',
                brand_name: person?.brand_name || '',
                wall_space: person?.wall_space || 0,
                enter_cdu: person?.enter_cdu || 0,
                default_length: person?.default_length || 0,
                total_sq_ft: person?.total_sq_ft || 0,
                display_options: person?.display_options || 0,
                per_running_feet: person?.per_running_feet || '',
                floor_placement: person?.floor_placement || [],
                category_group: person?.category_group || [],
                display_qty: person?.display_qty || 0,
                depth_per_option: person?.depth_per_option || 0,
                category_division: person?.category_division || [],
                category_presence: person?.category_presence || [],
                is_brand_staff: person?.is_brand_staff || false,
                brand_staff_name: person?.brand_staff_name || '',
                brand_staff_terms: person?.brand_staff_terms || '',
                staff_details: person?.staff_details || '',
            }));

            const nonEmptyContacts = processedContacts?.filter(contact =>
                Object.values(contact).some(value => value !== '')
            );

            if (nonEmptyContacts?.length === 0) {
                setStoreWallBrandsDetails([{
                    brand_id: "",
                    brand_name: "",
                    wall_space: 0,
                    enter_cdu: 0,
                    default_length: 0,
                    total_sq_ft: 0,
                    display_options: 0,
                    per_running_feet: '',
                    floor_placement: [],
                    category_group: [],
                    display_qty: 0,
                    depth_per_option: 0,
                    category_division: [],
                    category_presence: [],
                    is_brand_staff: false,
                    brand_staff_name: '',
                    brand_staff_terms: '',
                    staff_details: [],
                }]);
            } else {
                setStoreWallBrandsDetails(nonEmptyContacts);
            }
        }
    }, [createStoreOwnerDetails]);

    const addNewStoreWallBrandsDetailsForm = () => {
        const newObject = {
            brand_id: "",
            brand_name: "",
            wall_space: '',
            enter_cdu: '',
            default_length: '',
            total_sq_ft: '',
            display_options: '',
            per_running_feet: '',
            floor_placement: [],
            category_group: [],
            display_qty: '',
            depth_per_option: '',
            category_division: [],
            category_presence: [],
            is_brand_staff: false,
            brand_staff_name: '',
            brand_staff_terms: '',
            staff_details: [],
        }


        if (storeWallBrandsDetails?.length <= 4) {
            setStoreWallBrandsDetails([...storeWallBrandsDetails, newObject]);
        }
    }

    useEffect(() => {
        setOwnerDetailsFormError({
            constitution_of_firm: createStoreOwnerDetails?.owner_detail?.constitution_of_firm && null,
            authorise_signitory_name: createStoreOwnerDetails?.owner_detail?.authorise_signitory_name && null,
            business_incharge_name: createStoreOwnerDetails?.owner_detail?.business_incharge_name && null,
            business_incharge_mobile_number: createStoreOwnerDetails?.owner_detail?.business_incharge_mobile_number && null,
            contact_detail: {
                email: createStoreOwnerDetails?.owner_detail?.contact_detail?.email && null,
                mobile: createStoreOwnerDetails?.owner_detail?.contact_detail?.mobile && null,
                person_name: createStoreOwnerDetails?.owner_detail?.contact_detail?.person_name && null,
            },
            address: {
                address: createStoreOwnerDetails?.owner_detail?.address?.address && null,
                pincode: createStoreOwnerDetails?.owner_detail?.address?.pincode && null,
                region: createStoreOwnerDetails?.owner_detail?.address?.region && null,
                state: createStoreOwnerDetails?.owner_detail?.address?.state && null,
                city: createStoreOwnerDetails?.owner_detail?.address?.city && null,
                area: createStoreOwnerDetails?.owner_detail?.address?.area && null,
            }
        });
        setStoreDemographicFormError({
            customer_name: createStoreOwnerDetails?.customer_name && null,
            store_legal_name: createStoreOwnerDetails?.store_legal_name && null,
            store_trade_name: createStoreOwnerDetails?.store_trade_name && null,
            latitude: createStoreOwnerDetails?.latitude && null,
            longitude: createStoreOwnerDetails?.longitude && null,
            store_opening: createStoreOwnerDetails?.store_opening && null,
            store_opening_status: createStoreOwnerDetails?.store_opening_status && null,
            store_type: createStoreOwnerDetails?.store_type && null,
            store_retail_billing: createStoreOwnerDetails?.store_retail_billing && null,
            cust_no: createStoreOwnerDetails?.cust_no && null,
            gstin: createStoreOwnerDetails?.gstin && null,
            gst_slab: createStoreOwnerDetails?.gst_slab && null,
            address: {
                address: createStoreOwnerDetails?.store_address?.address && null,
                pincode: createStoreOwnerDetails?.store_address?.pincode && null,
                region: createStoreOwnerDetails?.store_address?.region && null,
                state: createStoreOwnerDetails?.store_address?.state && null,
                city: createStoreOwnerDetails?.store_address?.city && null,
                area: createStoreOwnerDetails?.store_address?.area && null,
                email: createStoreOwnerDetails?.store_address?.email && null,
                mobile: createStoreOwnerDetails?.store_address?.mobile && null,
            },
            regional_manager: createStoreOwnerDetails?.regional_manager && null,
            pan_number: createStoreOwnerDetails?.pan_number && null,
        });
        setParentStoreFormError({
            parent_store_name: createStoreOwnerDetails?.parent_store_name && null,
            parent_store_gstin: createStoreOwnerDetails?.parent_store_gstin && null,
        });
        setContactDetailsFormError({
            person_dept: createStoreOwnerDetails?.store_contact_detail[0]?.person_dept && null,
            person_name: createStoreOwnerDetails?.store_contact_detail[0]?.person_name && null,
            phone_number: createStoreOwnerDetails?.store_contact_detail[0]?.phone_number && null,
            email: createStoreOwnerDetails?.store_contact_detail[0]?.email && null,
        });
        setStoreContractDetailsFormError({
            distribution_type: createStoreOwnerDetails?.distribution_type && null,
            channel: createStoreOwnerDetails?.channel && null,
            fresh_margin_type: createStoreOwnerDetails?.fresh_margin_type && null,
            fresh_margin: createStoreOwnerDetails?.fresh_margin && null,
            eoss_margin_type: createStoreOwnerDetails?.eoss_margin_type && null,
            eoss_margin: createStoreOwnerDetails?.eoss_margin && null,
            eoss_threshold_value: (createStoreOwnerDetails?.eoss_threshold_value === 0 ? null : createStoreOwnerDetails?.eoss_threshold_value) && null,
            base_margin: (createStoreOwnerDetails?.base_margin === 0 ? null : createStoreOwnerDetails?.base_margin) && null,
            markdown_percentage_type: createStoreOwnerDetails?.markdown_percentage_type && null,
            markdown_percentage: createStoreOwnerDetails?.markdown_percentage && null,
            discount: (createStoreOwnerDetails?.discount || !createStoreOwnerDetails?.discount) && null,
            discount_percentage: null,
            target_linked_insentives: (createStoreOwnerDetails?.target_linked_insentives || !createStoreOwnerDetails?.target_linked_insentives) && null,
            target_linked_insentives_percentagre: null,
            cash_discount: (createStoreOwnerDetails?.cash_discount || !createStoreOwnerDetails?.cash_discount) && null,
            cash_discount_type: createStoreOwnerDetails?.cash_discount_type && null,
            cash_discount_percentage: null,
            payable_duration: createStoreOwnerDetails?.payable_duration && null,
            stock_correction: createStoreOwnerDetails?.stock_correction && null,
            promo_contribution: createStoreOwnerDetails?.promo_contribution && null,
            is_security: (createStoreOwnerDetails?.is_security || !createStoreOwnerDetails?.is_security) && null,
            // need To ask
            comments: createStoreOwnerDetails?.security_comments && null,
            credit_limit: createStoreOwnerDetails?.credit_limit && null,
            business_model: createStoreOwnerDetails?.business_model && null,
        });
        setStoreWallBrandsDetailsFormError({
            brand_name: createStoreOwnerDetails?.brands[0]?.brand_name && null,
            // wall_space: createStoreOwnerDetails?.brands[0]?.wall_space !== 0 && null,
            wall_space: (createStoreOwnerDetails?.brands[0]?.wall_space === 0 ? null : createStoreOwnerDetails?.brands[0]?.wall_space) && null,
            category_group: createStoreOwnerDetails?.brands[0]?.category_group && null,
            category_division: createStoreOwnerDetails?.brands[0]?.category_division && null,
            category_presence: createStoreOwnerDetails?.brands[0]?.category_presence && null,
        });
        setStoreAddressesDetailsFormError({
            billing_place_address: {
                address: createStoreOwnerDetails?.billing_place_address?.address && null,
                pincode: createStoreOwnerDetails?.billing_place_address?.pincode && null,
                // region: createStoreOwnerDetails?.billing_place_address?.region === '' ? '' : null,
                // state: createStoreOwnerDetails?.billing_place_address?.state === '' ? '' : null,
                // city: createStoreOwnerDetails?.billing_place_address?.city === '' ? '' : null,
                // area: createStoreOwnerDetails?.billing_place_address?.area === '' ? '' : null,
                email: createStoreOwnerDetails?.billing_place_address?.email && null,
                phone_number: createStoreOwnerDetails?.billing_place_address?.phone_number && null,
                mobile: createStoreOwnerDetails?.billing_place_address?.mobile && null,
                person_name: createStoreOwnerDetails?.billing_place_address?.person_name && null,
                person_dept: createStoreOwnerDetails?.billing_place_address?.person_dept && null,
                person_other: createStoreOwnerDetails?.billing_place_address?.person_other && null,
            },
            shipping_place_address: {
                address: createStoreOwnerDetails?.shipping_place_address?.address && null,
                pincode: createStoreOwnerDetails?.shipping_place_address?.pincode && null,
                // region: createStoreOwnerDetails?.shipping_place_address?.region === '' ? '' : null,
                // state: createStoreOwnerDetails?.shipping_place_address?.state === '' ? '' : null,
                // city: createStoreOwnerDetails?.shipping_place_address?.city === '' ? '' : null,
                // area: createStoreOwnerDetails?.shipping_place_address?.area === '' ? '' : null,
                email: createStoreOwnerDetails?.shipping_place_address?.email && null,
                phone_number: createStoreOwnerDetails?.shipping_place_address?.phone_number && null,
                mobile: createStoreOwnerDetails?.shipping_place_address?.mobile && null,
                person_name: createStoreOwnerDetails?.shipping_place_address?.person_name && null,
                person_dept: createStoreOwnerDetails?.shipping_place_address?.person_dept && null,
                person_other: createStoreOwnerDetails?.shipping_place_address?.person_other && null,
            },
            warehouse_address: {
                address: createStoreOwnerDetails?.warehouse_address?.address && null,
                pincode: createStoreOwnerDetails?.warehouse_address?.pincode && null,
                // region: createStoreOwnerDetails?.warehouse_address?.region === '' ? '' : null,
                // state: createStoreOwnerDetails?.warehouse_address?.state === '' ? '' : null,
                // city: createStoreOwnerDetails?.warehouse_address?.city === '' ? '' : null,
                // area: createStoreOwnerDetails?.warehouse_address?.area === '' ? '' : null,
                email: createStoreOwnerDetails?.warehouse_address?.email && null,
                phone_number: createStoreOwnerDetails?.warehouse_address?.phone_number && null,
                mobile: createStoreOwnerDetails?.warehouse_address?.mobile && null,
                person_name: createStoreOwnerDetails?.warehouse_address?.person_name && null,
                person_dept: createStoreOwnerDetails?.warehouse_address?.person_dept && null,
                person_other: createStoreOwnerDetails?.warehouse_address?.person_other && null,
            },
            return_warehouse_address: {
                address: createStoreOwnerDetails?.return_warehouse_address?.address && null,
                pincode: createStoreOwnerDetails?.return_warehouse_address?.pincode && null,
                // region: createStoreOwnerDetails?.return_warehouse_address?.region === '' ? '' : null,
                // state: createStoreOwnerDetails?.return_warehouse_address?.state === '' ? '' : null,
                // city: createStoreOwnerDetails?.return_warehouse_address?.city === '' ? '' : null,
                // area: createStoreOwnerDetails?.return_warehouse_address?.area === '' ? '' : null,
                email: createStoreOwnerDetails?.return_warehouse_address?.email && null,
                phone_number: createStoreOwnerDetails?.return_warehouse_address?.phone_number && null,
                mobile: createStoreOwnerDetails?.return_warehouse_address?.mobile && null,
                person_name: createStoreOwnerDetails?.return_warehouse_address?.person_name && null,
                person_dept: createStoreOwnerDetails?.return_warehouse_address?.person_dept && null,
                person_other: createStoreOwnerDetails?.return_warehouse_address?.person_other && null,
            },
        });
    }, []);

    const openFile = (item) => {
        setViewFile(item);
        setOpenFileViewerModal(true);
    }

    const handleEmptyDocument = (index) => {
        if (index === 0) {
            return <div className='no-doc-uploaded'>Mou/ Aggrement Document Not Uploaded.</div>
        } else if (index === 1) {
            return <div className='no-doc-uploaded'>GST Document (Parent Store) Not Uploaded.</div>
        } else if (index === 2) {
            return <div className='no-doc-uploaded'>GST Document (Store) Not Uploaded.</div>
        } else if (index === 3) {
            return <div className='no-doc-uploaded'>PAN Card Document Not Uploaded.</div>
        }
    }

    const [isSameAsBilling, setIsSameAsBilling] = useState(false);
    const [isSameShippingPlaceAddress, setIsSameShippingPlaceAddress] = useState(false);
    const [isSameWarehouseAddress, setIsSameWarehouseAddress] = useState(false);
    const [inputChecked, setInputChecked] = useState({ billingAddress: false, shippingAddress: false, });

    const initialState = {
        address: '',
        pincode: '',
        area: '',
        city: '',
        region: '',
        state: '',
        email: '',
        phone_number: '',
        mobile: '',
    };

    const initialStateNull = {
        address: null,
        pincode: null,
        area: null,
        city: null,
        region: null,
        state: null,
        email: null,
        phone_number: null,
        mobile: null,
    };

    const handleCopyAddress = (e, sourceKey, targetKey, setIsSame, formErrorKey) => {
        const isChecked = e.target.checked;
        setIsSame(isChecked);

        const fetchedInitialState = {
            address: createStoreOwnerDetails[targetKey]?.address ? createStoreOwnerDetails[targetKey]?.address : '',
            pincode: createStoreOwnerDetails[targetKey]?.pincode ? createStoreOwnerDetails[targetKey]?.pincode : '',
            region: createStoreOwnerDetails[targetKey]?.region ? createStoreOwnerDetails[targetKey]?.region : '',
            state: createStoreOwnerDetails[targetKey]?.state ? createStoreOwnerDetails[targetKey]?.state : '',
            city: createStoreOwnerDetails[targetKey]?.city ? createStoreOwnerDetails[targetKey]?.city : '',
            area: createStoreOwnerDetails[targetKey]?.area ? createStoreOwnerDetails[targetKey]?.area : '',
            email: createStoreOwnerDetails[targetKey]?.email ? createStoreOwnerDetails[targetKey]?.email : '',
            phone_number: createStoreOwnerDetails[targetKey]?.phone_number ? createStoreOwnerDetails[targetKey]?.phone_number : '',
            mobile: createStoreOwnerDetails[targetKey]?.mobile ? createStoreOwnerDetails[targetKey]?.mobile : '',
            person_name: createStoreOwnerDetails[targetKey]?.person_name ? createStoreOwnerDetails[targetKey]?.person_name : '',
            person_dept: createStoreOwnerDetails[targetKey]?.person_dept ? createStoreOwnerDetails[targetKey]?.person_dept : '',
            person_other: createStoreOwnerDetails[targetKey]?.person_other ? createStoreOwnerDetails[targetKey]?.person_other : '',
        }

        const sourceAddress = storeAddressesDetails?.[sourceKey];

        const keys = ['address', 'pincode', 'region', 'state', 'city', 'area', 'email', 'phone_number', 'mobile', 'person_name', 'person_dept', 'person_other',];

        const updatedSourceAddress = keys.reduce((acc, key) => {
            acc[key] = sourceAddress[key] || '';
            return acc;
        }, {});

        const updatedFormErrors = keys.reduce((acc, key) => {
            if (!sourceAddress[key]) {
                acc[key] = '';
            }
            return acc;
        }, {});

        const updatedAddressFormErrors = keys.reduce((acc, key) => {
            if (!storeAddressesDetailsFormError[key]) {
                acc[key] = null;
            }
            return acc;
        }, {});

        if (isChecked) {
            setStoreAddressesDetails((prevDetails) => ({
                ...prevDetails,
                [targetKey]: { ...updatedSourceAddress },
            }));

            // setStoreAddressesDetailsFormError((prevErrors) => ({
            //     ...prevErrors,
            //     [formErrorKey]: { ...prevErrors[formErrorKey], ...updatedFormErrors },
            // }));

            const newFormError = {};
            for (let key in initialState) {
                if (!sourceAddress?.[key]) {
                    newFormError[key] = ''; // Set error as empty string
                } else {
                    newFormError[key] = null; // Clear the error
                }
            }

            setStoreAddressesDetailsFormError((prevErrors) => ({
                ...prevErrors,
                [formErrorKey]: { ...newFormError },
            }));
        } else {
            setStoreAddressesDetails((prevDetails) => ({
                ...prevDetails,
                [targetKey]: fetchedInitialState,
            }));
            setStoreAddressesDetailsFormError((prevErrors) => ({
                ...prevErrors,
                [formErrorKey]: { ...prevErrors[formErrorKey], ...updatedAddressFormErrors },
            }));
        }
    };

    // Example usage for each checkbox:
    const handleCopyBillingAddress = (e) => {
        handleCopyAddress(e, 'billing_place_address', 'shipping_place_address', setIsSameAsBilling, 'shipping_place_address');
    }

    const handleCopyBillingAddressForWarehouse = (e) => {
        setInputChecked({
            billingAddress: !inputChecked.billingAddress,
            shippingAddress: false, // Uncheck the other checkbox
        });
        handleCopyAddress(e, 'billing_place_address', 'warehouse_address', setIsSameShippingPlaceAddress, 'warehouse_address');
    }

    const handleCopyShippingAddressForWarehouse = (e) => {
        setInputChecked({
            billingAddress: false, // Uncheck the other checkbox
            shippingAddress: !inputChecked.shippingAddress,
        });
        handleCopyAddress(e, 'shipping_place_address', 'warehouse_address', setIsSameShippingPlaceAddress, 'warehouse_address');
    }

    const handleCopyWarehouseAddress = (e) => {
        handleCopyAddress(e, 'warehouse_address', 'return_warehouse_address', setIsSameWarehouseAddress, 'return_warehouse_address');
    }

    const handleViewMore = (Name, index) => {
        setShowViewMoreDataPopup(true);
        setBrandViewMoreIndex(index);
    };

    const renderTabData = (currentTab, storeDetails) => {
        if (currentTab === 0) {
            return (
                <>
                    {isEditFormVisible ?
                        <div className='bg-white storeDetailsContainer scrollChange'>
                            <div className='montSerratFont fontSize18 tabheader'>Store Owner Details</div>
                            <div>
                                <div className='position-relative'>
                                    <div className='editStoreBtn'>
                                        <SecondaryButton onClick={onClickEditDetails} title={<div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <img src={EditIcon} alt="" />
                                                <div className='textColorBlue fontMedium'>Edit Details</div>
                                            </div>
                                        </div>} />
                                    </div>
                                </div>
                                <div className='paddingTop24 notoSansFont'>
                                    {storeDetails && <div className='storeOwnerDetails'>
                                        <div>
                                            <div className='storeLabel'>Constitution Of The Firm</div>
                                            <div className='storeValue'>{storeDetails?.owner_detail?.constitution_of_firm !== 'null' ? storeDetails?.owner_detail?.constitution_of_firm : "-"}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Owner’s Name</div>
                                            <div className='storeValue'>{storeDetails?.owner_detail?.contact_detail?.person_name !== 'null' ? storeDetails?.owner_detail?.contact_detail?.person_name : "-"}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Owner’s Mobile Number</div>
                                            <div className='storeValue'>+91 {storeDetails?.owner_detail?.contact_detail?.mobile !== 'null' ? storeDetails?.owner_detail?.contact_detail?.mobile : ""}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Owner E-Mail Id</div>
                                            <div className='storeValue'>{storeDetails?.owner_detail?.contact_detail?.email !== 'null' ? storeDetails?.owner_detail?.contact_detail?.email : "-"}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Authorise Signatory Name</div>
                                            <div className='storeValue'>{storeDetails?.owner_detail?.authorise_signitory_name !== 'null' ? storeDetails?.owner_detail?.authorise_signitory_name : "-"}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Address</div>
                                            <div className='storeValue'>{storeDetails?.owner_detail?.address?.address !== 'null' ? storeDetails?.owner_detail?.address?.address : "-"}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Principle Place Pin Code</div>
                                            <div className='storeValue'>{storeDetails?.owner_detail?.address?.pincode !== 'null' ? storeDetails?.owner_detail?.address?.pincode : "-"}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Principle Place Area</div>
                                            <div className='storeValue'>{storeDetails?.owner_detail?.address?.area !== 'null' ? storeDetails?.owner_detail?.address?.area : "-"}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Principle Place City</div>
                                            <div className='storeValue'>{storeDetails?.owner_detail?.address?.city !== 'null' ? storeDetails?.owner_detail?.address?.city : "-"}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Principle Place State</div>
                                            <div className='storeValue'>{storeDetails?.owner_detail?.address?.state !== 'null' ? storeDetails?.owner_detail?.address?.state : "-"}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Principle Place Region</div>
                                            <div className='storeValue'>{storeDetails?.owner_detail?.address?.region !== 'null' ? storeDetails?.owner_detail?.address?.region : "-"}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Business Incharge Name</div>
                                            <div className='storeValue'>{storeDetails?.owner_detail?.business_incharge_name !== 'null' ? storeDetails?.owner_detail?.business_incharge_name : "-"}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Incharge Mobile Number</div>
                                            <div className='storeValue'>+91 {storeDetails?.owner_detail?.business_incharge_mobile_number !== 'null' ? storeDetails?.owner_detail?.business_incharge_mobile_number : ""}</div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div className='saveChangeCont scrollChange'>
                                <div className='bg-white storeMagDetailsContainer'>
                                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                                        Store Owner Details
                                    </div>
                                    <div className='pt-3 formContainer customScroll'>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(0, 'ownerDetails', 'constitution_of_firm', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setInputConstitutionOfFirm(newInputValue)} inputValue={inputConstitutionOfFirm} value={ownerDetails?.constitution_of_firm} options={constitutionOfFirm} required label='Constitution of the firm*' />
                                            {ownerDetailsFormError?.constitution_of_firm && <div className="error">{ownerDetailsFormError?.constitution_of_firm}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={ownerDetails?.contact_detail?.person_name} onChange={(e) => handleInputChange(0, 'ownerDetails', { parent: 'contact_detail', child: 'person_name' }, e.target.value, validateTextInputAnyMin, CustomErrorMsg?.name)} required minLength={3} maxLength={80} label='Owner Name' />
                                            {ownerDetailsFormError?.contact_detail?.person_name && <div className="error">{ownerDetailsFormError?.contact_detail?.person_name}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={ownerDetails?.contact_detail?.mobile} onChange={(e) => handleInputChange(0, 'ownerDetails', { parent: 'contact_detail', child: 'mobile' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Owner Mobile Number' />
                                            {ownerDetailsFormError?.contact_detail?.mobile && <div className="error">{ownerDetailsFormError?.contact_detail?.mobile}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={ownerDetails?.contact_detail?.email} onChange={(e) => handleInputChange(0, 'ownerDetails', { parent: 'contact_detail', child: 'email' }, e.target.value, validateEmailInput, CustomErrorMsg?.email)} required minLength={''} maxLength={''} label='Owner E-Mail Id' />
                                            {ownerDetailsFormError?.contact_detail?.email && <div className="error">{ownerDetailsFormError?.contact_detail?.email}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={ownerDetails?.authorise_signitory_name} onChange={(e) => handleInputChange(0, 'ownerDetails', 'authorise_signitory_name', e.target.value, validateTextInputAnyMin, CustomErrorMsg?.name)} required minLength={2} maxLength={40} label='Authorise Signatory Name' />
                                            {ownerDetailsFormError?.authorise_signitory_name && <div className="error">{ownerDetailsFormError?.authorise_signitory_name}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={ownerDetails?.address?.address} onChange={(e) => handleInputChange(0, 'ownerDetails', { parent: 'address', child: 'address' }, e.target.value, validateTextAddressInput, CustomErrorMsg?.address)} required minLength={10} maxLength={100} label='Principle Place Of Business Address' />
                                            {ownerDetailsFormError?.address?.address && <div className="error">{ownerDetailsFormError?.address?.address}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={ownerDetails?.address?.pincode} onChange={(e) => handleInputChange(0, 'ownerDetails', { parent: 'address', child: 'pincode' }, e.target.value, validatePinCodeInput, CustomErrorMsg?.pincode)} required minLength={6} maxLength={6} label='Principle Place Pin Code' />
                                            {ownerDetailsFormError?.address?.pincode && <div className="error">{ownerDetailsFormError?.address?.pincode}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={true} value={ownerDetails?.address?.area} required label='Principle Place Area' />
                                            {ownerDetailsFormError?.address?.area && <div className="error">{ownerDetailsFormError?.address?.area}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={true} value={ownerDetails?.address?.city} required label='Principle Place City' />
                                            {ownerDetailsFormError?.address?.city && <div className="error">{ownerDetailsFormError?.address?.city}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={true} value={ownerDetails?.address?.state} required label='Principle Place State' />
                                            {ownerDetailsFormError?.address?.state && <div className="error">{ownerDetailsFormError?.address?.state}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={true} value={ownerDetails?.address?.region} required label='Principle Place Region' />
                                            {ownerDetailsFormError?.address?.region && <div className="error">{ownerDetailsFormError?.address?.region}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={ownerDetails?.business_incharge_name} onChange={(e) => handleInputChange(0, 'ownerDetails', 'business_incharge_name', e.target.value, validateTextInputAnyMin, CustomErrorMsg?.name)} required minLength={3} maxLength={80} label='Business Incharge Name' />
                                            {ownerDetailsFormError?.business_incharge_name && <div className="error">{ownerDetailsFormError?.business_incharge_name}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={ownerDetails?.business_incharge_mobile_number} onChange={(e) => handleInputChange(0, 'ownerDetails', 'business_incharge_mobile_number', e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Business Incharge Number' />
                                            {ownerDetailsFormError?.business_incharge_mobile_number && <div className="error">{ownerDetailsFormError?.business_incharge_mobile_number}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white storeDetailsBtnContainer d-flex mt-4'>
                                    <div className='pe-3'>
                                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                                    </div>
                                    <CustomBlueButton customCreatePropsBtnClass="save_change" title={<div className='d-flex align-items-center'>
                                        <div>Save Changes</div>
                                    </div>} onClick={onClickSaveEditDetails} disabled={
                                        (ownerDetailsFormError === "" ? true : false) ||
                                        (ownerDetailsFormError &&
                                            (ownerDetailsFormError?.constitution_of_firm !== null ||
                                                ownerDetailsFormError?.authorise_signitory_name !== null ||
                                                ownerDetailsFormError?.business_incharge_name !== null ||
                                                ownerDetailsFormError?.business_incharge_mobile_number !== null ||
                                                ownerDetailsFormError?.contact_detail.email !== null ||
                                                ownerDetailsFormError?.contact_detail.mobile !== null ||
                                                ownerDetailsFormError?.contact_detail.person_name !== null ||
                                                ownerDetailsFormError?.address.address !== null ||
                                                ownerDetailsFormError?.address.pincode !== null ||
                                                ownerDetails?.address?.region === '' ||
                                                ownerDetails?.address?.state === '' ||
                                                ownerDetails?.address?.city === '' ||
                                                ownerDetails?.address?.area === ''
                                            ))} />
                                </div>
                            </div>
                        </>
                    }
                </>
            )
        } else if (currentTab === 1) {
            return (
                <>
                    {isEditFormVisible ?
                        <div className='bg-white storeDetailsContainer scrollChange'>
                            <div className='montSerratFont fontSize18 tabheader'>Store Demographic Details</div>
                            <div className='position-relative'>
                                <div className='editStoreBtn'>
                                    <SecondaryButton onClick={onClickEditDetails} title={<div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <img src={EditIcon} alt="" />
                                            <div className='textColorBlue fontMedium'>Edit Details</div>
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                            <div className='paddingTop24 notoSansFont'>
                                {storeDetails && <div className='storeOwnerDetails'>
                                    <div>
                                        <div className='storeLabel'>Store Name</div>
                                        <div className='storeValue'>{storeDetails?.customer_name !== 'null' ? storeDetails?.customer_name : '-'}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Store Legal Name</div>
                                        <div className='storeValue'>{storeDetails?.store_legal_name !== 'null' ? storeDetails?.store_legal_name : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Store Trade Name</div>
                                        <div className='storeValue'>{storeDetails?.store_trade_name !== 'null' ? storeDetails?.store_trade_name : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Store Status</div>
                                        <div className='storeValue text-capitalize'>
                                            <StatusTag status={storeDetails?.store_status ? 'Active' : 'Inactive'} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Latitude</div>
                                        <div className='storeValue'>{storeDetails?.latitude !== 'null' ? storeDetails?.latitude : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Longitude</div>
                                        <div className='storeValue'>{storeDetails?.longitude !== 'null' ? storeDetails?.longitude : "-"}</div>
                                    </div>
                                    {/* <div>
                                        <div className='storeLabel'>Store Floor Size</div>
                                        <div className='storeValue'>{storeDetails?.store_floor_size !== 'null' ? storeDetails?.store_floor_size : "-"}</div>
                                    </div> */}
                                    <div>
                                        <div className='storeLabel'>Store Opening Date</div>
                                        <div className='storeValue'>{storeDetails?.store_opening !== 'null' ? convertDate(storeDetails?.store_opening) : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Store Opening Status</div>
                                        <div className='storeValue'>{storeDetails?.store_opening_status !== 'null' ? storeDetails?.store_opening_status : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Store Type</div>
                                        <div className='storeValue text-capitalize'>{storeDetails?.store_type !== 'null' ? storeDetails?.store_type : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Store Classification</div>
                                        <div className='storeValue'>{storeDetails?.store_classification !== 'null' ? storeDetails?.store_classification?.join(",") : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Store Class</div>
                                        <div className='storeValue'>{storeDetails?.store_class !== 'null' ? storeDetails?.store_class : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Store Retail Billing System</div>
                                        <div className='storeValue'>{storeDetails?.store_retail_billing !== 'null' ? storeDetails?.store_retail_billing : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Store Details</div>
                                        <div className='storeValue'>{storeDetails?.store_details !== 'null' ? storeDetails?.store_details : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Cust_No / Store Code</div>
                                        <div className='storeValue'>{storeDetails?.cust_no !== 'null' ? storeDetails?.cust_no : '-'}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Store E-Mail Id</div>
                                        <div className='storeValue'>{storeDetails?.store_address?.email !== 'null' ? storeDetails?.store_address?.email : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Store Phone Number</div>
                                        <div className='storeValue'>+91 {storeDetails?.store_address?.mobile !== 'null' ? storeDetails?.store_address?.mobile : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>GSTIN</div>
                                        <div className='storeValue'>{storeDetails?.gstin !== 'null' ? storeDetails?.gstin : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>GST Slab</div>
                                        <div className='storeValue'>{storeDetails?.gst_slab !== 'null' ? storeDetails?.gst_slab : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Address</div>
                                        <div className='storeValue'>{storeDetails?.store_address?.address !== 'null' ? storeDetails?.store_address?.address : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Pincode</div>
                                        <div className='storeValue'>{storeDetails?.store_address?.pincode !== 'null' ? storeDetails?.store_address?.pincode : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Area</div>
                                        <div className='storeValue'>{storeDetails?.store_address?.area !== 'null' ? storeDetails?.store_address?.area : ""}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>City</div>
                                        <div className='storeValue'>{storeDetails?.store_address?.city !== 'null' ? storeDetails?.store_address?.city : "-"}</div>
                                    </div>

                                    <div>
                                        <div className='storeLabel'>City Tier</div>
                                        <div className='storeValue'>{storeDetails?.city_tier !== 'null' ? storeDetails?.city_tier : '-'}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>State</div>
                                        <div className='storeValue'>{storeDetails?.store_address?.state !== 'null' ? storeDetails?.store_address?.state : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Region</div>
                                        <div className='storeValue'>{storeDetails?.store_address?.region !== 'null' ? storeDetails?.store_address?.region : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Regional Manager</div>
                                        <div className='storeValue'>{storeDetails?.regional_manager !== 'null' ? storeDetails?.regional_manager : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>PAN Card</div>
                                        <div className='storeValue'>{storeDetails?.pan_number !== 'null' ? storeDetails?.pan_number : "-"}</div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        :
                        <>
                            <div className='saveChangeCont scrollChange'>
                                <div className='bg-white storeMagDetailsContainer'>
                                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                                        Store Demographic Details
                                    </div>
                                    <div className='pt-3 formContainer customScroll'>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeDemographic?.customer_name} onChange={(e) => handleInputChange(1, 'storeDemographic', 'customer_name', e.target.value, validateTextInputAnyMin, CustomErrorMsg?.name)} required minLength={3} maxLength={100} label='Store Name' />
                                            {storeDemographicFormError?.customer_name && <div className="error">{storeDemographicFormError?.customer_name}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeDemographic?.store_legal_name} onChange={(e) => handleInputChange(1, 'storeDemographic', 'store_legal_name', e.target.value, validateTextInputAnyMin, CustomErrorMsg?.name)} required minLength={3} maxLength={100} label='Store Legal Name' />
                                            {storeDemographicFormError?.store_legal_name && <div className="error">{storeDemographicFormError?.store_legal_name}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeDemographic?.store_trade_name} onChange={(e) => handleInputChange(1, 'storeDemographic', 'store_trade_name', e.target.value, validateTextInputAnyMin, CustomErrorMsg?.name)} required minLength={3} maxLength={100} label='Store Trade Name' />
                                            {storeDemographicFormError?.store_trade_name && <div className="error">{storeDemographicFormError?.store_trade_name}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeDemographic?.latitude} onChange={(e) => handleInputChange(1, 'storeDemographic', 'latitude', e.target.value, validateLatitude, CustomErrorMsg?.latitude)} required minLength={''} maxLength={''} label='Latitude' />
                                            {storeDemographicFormError?.latitude && <div className="error">{storeDemographicFormError?.latitude}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeDemographic?.longitude} onChange={(e) => handleInputChange(1, 'storeDemographic', 'longitude', e.target.value, validateLongitude, CustomErrorMsg?.longitude)} required minLength={''} maxLength={''} label='Longitude' />
                                            {storeDemographicFormError?.longitude && <div className="error">{storeDemographicFormError?.longitude}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(1, 'storeDemographic', 'store_status', newValue, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={selectedStoreStatus} options={STORE_STATUS} required label='Store Status*' />
                                            {storeDemographicFormError?.store_status && <div className="error">{storeDemographicFormError?.store_status}</div>}
                                        </div>
                                        {/* <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeDemographic?.store_floor_size} onChange={(e) => handleInputChange(1, 'storeDemographic', 'store_floor_size', handleNumericInput(e.target.value), validateNumber2To6Input, CustomErrorMsg?.)} minLength={2} maxLength={6} label='Store Floor Size' />
                                            {storeDemographicFormError?.store_floor_size && <div className="error">{storeDemographicFormError?.store_floor_size}</div>}
                                        </div> */}
                                        <div className='inputBottomSpace'>
                                            <CustomDatePicker disabled={false}
                                                // value={storeDemographic?.store_opening} 
                                                selectedDate={storeDemographic?.store_opening ? dayjs(storeDemographic?.store_opening) : null}
                                                defaultValue={storeDemographic?.store_opening ? dayjs(storeDemographic?.store_opening) : null}
                                                onDateChange={(date) => {
                                                    const newDate = new Date(date?.$d);
                                                    handleInputChange(1, 'storeDemographic', 'store_opening', newDate.toISOString(), validateDateFormat, CustomErrorMsg?.date);
                                                }} disableFutureDates={true} required minLength={''} maxLength={''} label='Store Opening Date*' />
                                            {storeDemographicFormError?.store_opening && <div className="error">{storeDemographicFormError?.store_opening}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(1, 'storeDemographic', 'store_opening_status', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreOpeningStatus(newInputValue)} inputValue={storeOpeningStatus} value={storeDemographic?.store_opening_status} options={storeOpeningStatusDropData} required label='Store Opening Status*' />
                                            {storeDemographicFormError?.store_opening_status && <div className="error">{storeDemographicFormError?.store_opening_status}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(1, 'storeDemographic', 'store_type', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeDemographic?.store_type} options={storeTypeDropData} required label='Store Type*' />
                                            {storeDemographicFormError?.store_type && <div className="error">{storeDemographicFormError?.store_type}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <MultiSelectCheckBox handleChange={(e) => handleInputChange(1, 'storeDemographic', 'store_classification', e.target.value, validateTextInputAny, 'Please Select Any Value')} selectedValue={storeDemographic?.store_classification} options={storeClassification} MenuProps={MenuProps} label='Store Classification' />
                                            {/* {storeDemographic?.store_classification?.length === 0 && <div className="error">Please Slect Any Value</div>} */}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(1, 'storeDemographic', 'store_class', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeDemographic?.store_class} options={storeClass} label='Store Class' />
                                            {/* {storeDemographicFormError?.store_class && <div className="error">{storeDemographicFormError?.store_class}</div>} */}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(1, 'storeDemographic', 'store_retail_billing', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeDemographic?.store_retail_billing} options={storeRetailBillingSystem} required label='Store Retail Billing System*' />
                                            {storeDemographicFormError?.store_retail_billing && <div className="error">{storeDemographicFormError?.store_retail_billing}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeDemographic?.store_details} onChange={(e) => handleInputChange(1, 'storeDemographic', 'store_details', e.target.value, validateText2To200Input, CustomErrorMsg?.details)} minLength={2} maxLength={100} label='Store Details' />
                                            {storeDemographicFormError?.store_details && <div className="error">{storeDemographicFormError?.store_details}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeDemographic?.cust_no} onChange={(e) => handleInputChange(1, 'storeDemographic', 'cust_no', e.target.value, validateTextUnderscoreSlash, CustomErrorMsg?.custNum)} required minLength={1} maxLength={""} label='Cust_No / Store Code' />
                                            {storeDemographicFormError?.cust_no && <div className="error">{storeDemographicFormError?.cust_no}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeDemographic?.address?.email} onChange={(e) => handleInputChange(1, 'storeDemographic', { parent: 'address', child: 'email' }, e.target.value, validateEmailInput, CustomErrorMsg?.email)} required minLength={''} maxLength={''} label='E-Mail Id' />
                                            {storeDemographicFormError?.address?.email && <div className="error">{storeDemographicFormError?.address?.email}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeDemographic?.address?.mobile} onChange={(e) => handleInputChange(1, 'storeDemographic', { parent: 'address', child: 'mobile' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Mobile Number' />
                                            {storeDemographicFormError?.address?.mobile && <div className="error">{storeDemographicFormError?.address?.mobile}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeDemographic?.gstin} onChange={(e) => handleInputChange(1, 'storeDemographic', 'gstin', e.target.value, validateGSTINInput, CustomErrorMsg?.gstin)} required minLength={15} maxLength={15} label='GSTIN' />
                                            {storeDemographicFormError?.gstin && <div className="error">{storeDemographicFormError?.gstin}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(1, 'storeDemographic', 'gst_slab', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeDemographic?.gst_slab} options={storeGstSlab} required label='GST Slab*' />
                                            {storeDemographicFormError?.gst_slab && <div className="error">{storeDemographicFormError?.gst_slab}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeDemographic?.address?.address} onChange={(e) => handleInputChange(1, 'storeDemographic', { parent: 'address', child: 'address' }, e.target.value, validateTextAddressInput, CustomErrorMsg?.address)} required minLength={10} maxLength={100} label='Address' />
                                            {storeDemographicFormError?.address?.address && <div className="error">{storeDemographicFormError?.address?.address}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeDemographic?.address?.pincode} onChange={(e) => handleInputChange(1, 'storeDemographic', { parent: 'address', child: 'pincode' }, e.target.value, validatePinCodeInput, CustomErrorMsg?.pincode)} required minLength={6} maxLength={6} label='Pin Code' />
                                            {storeDemographicFormError?.address?.pincode && <div className="error">{storeDemographicFormError?.address?.pincode}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={true} value={storeDemographic?.address?.area} required minLength={''} maxLength={''} label='Area' />
                                            {storeDemographicFormError?.address?.area && <div className="error">{storeDemographicFormError?.address?.area}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={true} value={storeDemographic?.address?.city} required minLength={''} maxLength={''} label='City' />
                                            {storeDemographicFormError?.address?.city && <div className="error">{storeDemographicFormError?.address?.city}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(1, 'storeDemographic', 'city_tier', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreOpeningStatus(newInputValue)} inputValue={storeOpeningStatus} value={storeDemographic?.city_tier} options={cityTier} label='City Tier' />
                                            {storeDemographicFormError?.city_tier && <div className="error">{storeDemographicFormError?.city_tier}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={true} value={storeDemographic?.address?.state} required minLength={''} maxLength={''} label='State' />
                                            {storeDemographicFormError?.address?.state && <div className="error">{storeDemographicFormError?.address?.state}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={true} value={storeDemographic?.address?.region} required minLength={''} maxLength={''} label='Region' />
                                            {storeDemographicFormError?.address?.region && <div className="error">{storeDemographicFormError?.address?.region}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabledClass={'disabledClass'} disabled={true} value={storeDemographic?.regional_manager} onChange={(e) => handleInputChange(1, 'storeDemographic', 'regional_manager', e.target.value, validateTextDotCommaSpace, CustomErrorMsg?.regionalManager)} minLength={3} maxLength={80} label='Regional Manager' />
                                            {storeDemographicFormError?.regional_manager && <div className="error">{storeDemographicFormError?.regional_manager}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeDemographic?.pan_number} onChange={(e) => handleInputChange(1, 'storeDemographic', 'pan_number', e.target.value, validatePANInput, CustomErrorMsg?.pan)} required minLength={10} maxLength={10} label='Pan Card' />
                                            {storeDemographicFormError?.pan_number && <div className="error">{storeDemographicFormError?.pan_number}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white storeDetailsBtnContainer d-flex mt-4'>
                                    <div className='pe-3'>
                                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                                    </div>
                                    <CustomBlueButton customCreatePropsBtnClass="save_change" title={<div className='d-flex align-items-center'>
                                        <div>Save Changes</div>
                                    </div>} onClick={onClickSaveEditDetails} disabled={
                                        (storeDemographicFormError === "" ? true : false) ||
                                        (storeDemographicFormError &&
                                            (storeDemographicFormError?.customer_name !== null ||
                                                storeDemographicFormError?.store_legal_name !== null ||
                                                storeDemographicFormError?.store_trade_name !== null ||
                                                storeDemographicFormError?.latitude !== null ||
                                                storeDemographicFormError?.longitude !== null ||
                                                storeDemographic?.store_opening === '' ||
                                                storeDemographicFormError?.store_opening_status !== null ||
                                                storeDemographicFormError?.store_type !== null ||
                                                storeDemographicFormError?.store_retail_billing !== null ||
                                                storeDemographicFormError?.cust_no !== null ||
                                                storeDemographicFormError?.gstin !== null ||
                                                storeDemographicFormError?.gst_slab !== null ||
                                                storeDemographicFormError?.pan_number !== null ||
                                                storeDemographicFormError?.address.email !== null ||
                                                storeDemographicFormError?.address.mobile !== null ||
                                                storeDemographicFormError?.address.address !== null ||
                                                storeDemographicFormError?.address.pincode !== null ||
                                                storeDemographic?.address?.region === '' ||
                                                storeDemographic?.address?.state === '' ||
                                                storeDemographic?.address?.city === '' ||
                                                storeDemographic?.address?.area === ''
                                            ))} />
                                </div>
                            </div>
                        </>
                    }
                </>
            )
        } else if (currentTab === 2) {
            return (
                <>
                    {isEditFormVisible ?
                        <div className='bg-white storeDetailsContainer scrollChange'>
                            <div className='montSerratFont fontSize18 tabheader'>Parent Store Details</div>
                            <div className='position-relative'>
                                <div className='editStoreBtn'>
                                    <SecondaryButton onClick={onClickEditDetails} title={<div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <img src={EditIcon} alt="" />
                                            <div className='textColorBlue fontMedium'>Edit Details</div>
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                            <div className='paddingTop24 notoSansFont'>
                                {storeDetails && <div className='storeOwnerDetails'>
                                    <div>
                                        <div className='storeLabel'>Parent Store Name</div>
                                        <div className='storeValue'>{storeDetails?.parent_store_name !== 'null' ? storeDetails?.parent_store_name : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Parent Store Gstin</div>
                                        <div className='storeValue'>{storeDetails?.parent_store_gstin !== 'null' ? storeDetails?.parent_store_gstin : "-"}</div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        :
                        <>
                            <div className='saveChangeCont scrollChange'>
                                <div className='bg-white storeMagDetailsContainer'>
                                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                                        Parent Store Details
                                    </div>
                                    <div className='pt-3 formContainer customScroll ParentStoreDetails'>
                                        {/* <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={parentStore?.parent_store_id} onChange={(e) => handleInputChange(1, 'parentStore', 'parent_store_id', e.target.value, validateTextInput, CustomErrorMsg?.)} required minLength={''} maxLength={''} label='Parent Store Id' />
                                            {parentStoreFormError?.parent_store_id && <div className="error">{parentStoreFormError?.parent_store_id}</div>}
                                        </div> */}
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={parentStore?.parent_store_name} onChange={(e) => handleInputChange(2, 'parentStore', 'parent_store_name', e.target.value, validateTextInputAnyMin, CustomErrorMsg?.name)} required minLength={3} maxLength={100} label='Parent Store Name' />
                                            {parentStoreFormError?.parent_store_name && <div className="error">{parentStoreFormError?.parent_store_name}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={parentStore?.parent_store_gstin} onChange={(e) => handleInputChange(2, 'parentStore', 'parent_store_gstin', e.target.value, validateGSTINInput, CustomErrorMsg?.gstin)} required minLength={15} maxLength={15} label='Parent Store Gstin' />
                                            {parentStoreFormError?.parent_store_gstin && <div className="error">{parentStoreFormError?.parent_store_gstin}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white storeDetailsBtnContainer d-flex mt-4'>
                                    <div className='pe-3'>
                                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                                    </div>
                                    <CustomBlueButton customCreatePropsBtnClass="save_change" title={<div className='d-flex align-items-center'>
                                        <div>Save Changes</div>
                                    </div>} onClick={onClickSaveEditDetails} disabled={
                                        (parentStoreFormError === "" ? true : false) ||
                                        (parentStoreFormError &&
                                            (parentStoreFormError?.parent_store_name !== null ||
                                                parentStoreFormError?.parent_store_gstin !== null
                                            ))} />
                                </div>
                            </div>
                        </>
                    }
                </>
            )
        } else if (currentTab === 3) {
            return (
                <>
                    {isEditFormVisible ?
                        <div className='bg-white storeDetailsContainer scrollChange'>
                            <div className='montSerratFont fontSize18 tabheader'>Store Contact Details</div>
                            <div className='position-relative'>
                                <div className='editStoreBtn'>
                                    <SecondaryButton onClick={onClickEditDetails} title={<div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <img src={EditIcon} alt="" />
                                            <div className='textColorBlue fontMedium'>Edit Details</div>
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                            <div className='paddingTop24 notoSansFont'>
                                {storeDetails && <>
                                    {storeDetails?.store_contact_detail?.map((item, index) => (
                                        <>
                                            <div className='pt-3 pb-3'>
                                                <strong>Contacts {index + 1}</strong>
                                            </div>

                                            <div className='storeOwnerDetails'>
                                                <div>
                                                    <div className='storeLabel'>Contact Name</div>
                                                    <div className='storeValue'>{item?.person_name !== 'null' ? item?.person_name : "-"}</div>
                                                </div>
                                                <div>
                                                    <div className='storeLabel'>Department</div>
                                                    <div className='storeValue'>{item?.person_dept !== 'null' ? item?.person_dept : "-"}</div>
                                                </div>
                                                <div>
                                                    <div className='storeLabel'>Contact’s Number</div>
                                                    <div className='storeValue'>+91 {item?.phone_number !== 'null' ? item?.phone_number : "-"}</div>
                                                </div>
                                                <div>
                                                    <div className='storeLabel'>Contact’s E- Mail Id</div>
                                                    <div className='storeValue'>{item?.email !== 'null' ? item?.email : "-"}</div>
                                                </div>
                                                <div>
                                                    <div className='storeLabel'>Contact Person Other Details</div>
                                                    <div className='storeValue text-capitalize'>{item?.person_other !== 'null' ? item?.person_other : "-"}</div>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </>
                                }
                            </div>
                        </div>
                        :
                        <>
                            <div className='saveChangeCont scrollChange'>
                                <div className='bg-white storeMagDetailsContainer'>
                                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                                        Store Contact’s Details
                                    </div>
                                    <div className='pt-3 formContainerNew customScroll'>
                                        <div className='brandContactsDetals customScroll' style={{ position: 'relative' }}>
                                            {
                                                contactDetails && contactDetails?.map((item, index) => (
                                                    <>
                                                        <div className='pt-3'>
                                                            <strong>Contacts {index + 1}</strong>
                                                        </div>

                                                        <div className='pt-3 formContainer' key={index}>

                                                            <div className='inputBottomSpace'>
                                                                <InputTextField type='text' disabled={false} name={'person_name'} value={item?.person_name} onChange={(event) => handleBrandContactsDetailsChange(event, index)} required minLength={3} maxLength={80} label='Contact Name' />
                                                                {contactDetailsFormError?.person_name && <div className="error">{contactDetailsFormError?.person_name}</div>}
                                                            </div>
                                                            <div className='inputBottomSpace'>
                                                                <InputTextField type='text' disabled={false} name={'person_dept'} value={item?.person_dept} onChange={(event) => handleBrandContactsDetailsChange(event, index)} required minLength={2} maxLength={40} label='Department' />
                                                                {contactDetailsFormError?.person_dept && <div className="error">{contactDetailsFormError?.person_dept}</div>}
                                                            </div>
                                                            <div className='inputBottomSpace'>
                                                                <InputTextField type='text' disabled={false} name={'phone_number'} value={item?.phone_number} onChange={(event) => handleBrandContactsDetailsChange(event, index)} required minLength={10} maxLength={10} label='Contact’s Number' />
                                                                {contactDetailsFormError?.phone_number && <div className="error">{contactDetailsFormError?.phone_number}</div>}
                                                            </div>
                                                            <div className='inputBottomSpace'>
                                                                <InputTextField type='text' disabled={false} name={'email'} value={item?.email} onChange={(event) => handleBrandContactsDetailsChange(event, index)} required minLength={''} maxLength={40} label='Contact’s Email Id' />
                                                                {contactDetailsFormError?.email && <div className="error">{contactDetailsFormError?.email}</div>}
                                                            </div>
                                                            <div className='inputBottomSpace'>
                                                                <InputTextField type='text' disabled={false} name={'person_other'} value={item?.person_other} onChange={(event) => handleBrandContactsDetailsChange(event, index)} label='Contact Person Other Details' />
                                                                {/* {contactDetailsFormError.person_other && <div className="error">{contactDetailsFormError.person_other}</div>} */}
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                            }

                                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', position: 'absolute', right: '15px', bottom: '-15px' }} >
                                                <div onClick={addNewContactDetailsForm} className='' style={{ width: '30px', height: '30px', display: 'flex', border: '1px solid grey', borderRadius: '50%', cursor: 'pointer' }}>
                                                    <img src={PlusIcon} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white storeDetailsBtnContainer d-flex mt-4'>
                                    <div className='pe-3'>
                                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                                    </div>
                                    <CustomBlueButton customCreatePropsBtnClass="save_change" title={<div className='d-flex align-items-center'>
                                        <div>Save Changes</div>
                                    </div>} onClick={onClickSaveEditDetails} disabled={
                                        (contactDetailsFormError === "" ? true : false) ||
                                        (contactDetailsFormError &&
                                            (contactDetailsFormError?.email !== null ||
                                                contactDetailsFormError?.phone_number !== null ||
                                                contactDetailsFormError?.person_name !== null ||
                                                contactDetailsFormError?.person_dept !== null
                                            ))} />
                                </div>
                            </div>
                        </>
                    }
                </>
            )
        } else if (currentTab === 4) {
            return (
                <>
                    {isEditFormVisible ?
                        <div className='bg-white storeDetailsContainer scrollChange'>
                            <div className='montSerratFont fontSize18 tabheader'>Store Contract Details</div>
                            <div className='position-relative'>
                                <div className='editStoreBtn'>
                                    <SecondaryButton onClick={onClickEditDetails} title={<div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <img src={EditIcon} alt="" />
                                            <div className='textColorBlue fontMedium'>Edit Details</div>
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                            <div className='paddingTop24 notoSansFont'>
                                {storeDetails && <div className='storeOwnerDetails'>
                                    <div>
                                        <div className='storeLabel'>Distributor/Direct</div>
                                        <div className='storeValue'>{storeDetails?.distribution_type !== 'null' ? storeDetails?.distribution_type : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Channel</div>
                                        <div className='storeValue'>{storeDetails?.channel !== 'null' ? storeDetails?.channel : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Fresh Margin Type</div>
                                        <div className='storeValue'>{storeDetails?.fresh_margin_type !== 'null' ? storeDetails?.fresh_margin_type : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Fresh Margin %</div>
                                        <div className='storeValue text-capitalize'>{storeDetails?.fresh_margin !== 'null' ? storeDetails?.fresh_margin : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>EOSS Margin Type</div>
                                        <div className='storeValue'>{storeDetails?.eoss_margin_type !== 'null' ? storeDetails?.eoss_margin_type : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>EOSS Margin %</div>
                                        <div className='storeValue'>{storeDetails?.eoss_margin !== 'null' ? storeDetails?.eoss_margin : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>EOSS Threshold % Value</div>
                                        <div className='storeValue'>{storeDetails?.eoss_threshold_value !== 'null' ? storeDetails?.eoss_threshold_value : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>BASE Margin %</div>
                                        <div className='storeValue'>{storeDetails?.base_margin !== 'null' ? storeDetails?.base_margin : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Markdown %</div>
                                        <div className='storeValue'>{storeDetails?.markdown_percentage !== 'null' ? storeDetails?.markdown_percentage : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Markdown % Type</div>
                                        <div className='storeValue text-capitalize'>{storeDetails?.markdown_percentage_type !== 'null' ? storeDetails?.markdown_percentage_type : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Discount</div>
                                        <div className='storeValue'>{storeDetails?.discount !== 'null' ? (storeDetails?.discount === false ? 'No' : 'Yes') : "-"}
                                        </div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Discount %</div>
                                        <div className='storeValue'>{storeDetails?.discount_percentage !== 'null' ? storeDetails?.discount_percentage : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Target Linked Incentives</div>
                                        <div className='storeValue'>{storeDetails?.target_linked_insentives !== 'null' ? (storeDetails?.target_linked_insentives === false ? 'No' : 'Yes') : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Target Linked Incentives %</div>
                                        <div className='storeValue text-capitalize'>{storeDetails?.target_linked_insentives_percentagre !== 'null' ? storeDetails?.target_linked_insentives_percentagre : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Cash Discount</div>
                                        <div className='storeValue'>{storeDetails?.cash_discount !== 'null' ? (storeDetails?.cash_discount === false ? 'No' : 'Yes') : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Cash Discount %</div>
                                        <div className='storeValue'>{storeDetails?.cash_discount_percentage !== 'null' ? storeDetails?.cash_discount_percentage : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Cash Discount Type</div>
                                        <div className='storeValue'>{storeDetails?.cash_discount_type !== 'null' ? storeDetails?.cash_discount_type : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Payable Duration</div>
                                        <div className='storeValue text-capitalize'>{storeDetails?.payable_duration !== 'null' ? storeDetails?.payable_duration : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Stock Correction %</div>
                                        <div className='storeValue'>{storeDetails?.stock_correction !== 'null' ? storeDetails?.stock_correction : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Promo Contribution %</div>
                                        <div className='storeValue'>{storeDetails?.promo_contribution !== 'null' ? storeDetails?.promo_contribution : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Security</div>
                                        <div className='storeValue'> {storeDetails?.is_security !== 'null' ? (storeDetails?.is_security === false ? 'No' : 'Yes') : "-"} </div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Security Comment</div>
                                        <div className='storeValue text-capitalize'>{storeDetails?.security_comments ? storeDetails?.security_comments : '-'}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Credit Limit</div>
                                        <div className='storeValue'>{storeDetails?.credit_limit !== 'null' ? storeDetails?.credit_limit : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Business Model</div>
                                        <div className='storeValue text-capitalize'>{storeDetails?.business_model !== 'null' ? storeDetails?.business_model?.join(",") : "-"}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Store Floor Size</div>
                                        <div className='storeValue'>{storeDetails?.store_floor_size !== 'null' ? storeDetails?.store_floor_size : "-"}</div>
                                    </div>
                                    {/* <div>
                                        <div className='storeLabel'>Primary Billing Discount</div>
                                        <div className='storeValue'>{storeDetails?.primary_billing_discount !== 'null' ? storeDetails?.primary_billing_discount : 0}</div>
                                    </div> */}

                                </div>}
                            </div>
                        </div>
                        :
                        <>
                            <div className='saveChangeCont scrollChange'>
                                <div className='bg-white storeMagDetailsContainer'>
                                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                                        Store Contract Details
                                    </div>
                                    <div className='pt-3 formContainer customScroll'>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'distribution_type', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.distribution_type} options={distributionType} required label='Distributor/Direct*' />
                                            {storeContractDetailsFormError?.distribution_type && <div className="error">{storeContractDetailsFormError?.distribution_type}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'channel', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.channel} options={channelDrop} required label='Channel*' />
                                            {storeContractDetailsFormError?.channel && <div className="error">{storeContractDetailsFormError?.channel}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'fresh_margin_type', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.fresh_margin_type} options={freshMarginType} required label='Fresh Margin Type*' />
                                            {storeContractDetailsFormError?.fresh_margin_type && <div className="error">{storeContractDetailsFormError?.fresh_margin_type}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeContractDetails.fresh_margin} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'fresh_margin', e.target.value, isNumberOrFloat, CustomErrorMsg?.margin)} required minLength={1} maxLength={6} label='Fresh Margin %' />
                                            {storeContractDetailsFormError?.fresh_margin && <div className="error">{storeContractDetailsFormError?.fresh_margin}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'eoss_margin_type', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.eoss_margin_type} options={eossMarginType} required label='EOSS Margin Type*' />
                                            {storeContractDetailsFormError?.eoss_margin_type && <div className="error">{storeContractDetailsFormError?.eoss_margin_type}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeContractDetails.eoss_margin} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'eoss_margin', e.target.value, isNumberOrFloat, CustomErrorMsg?.margin)} required minLength={1} maxLength={6} label='EOSS Margin %' />
                                            {storeContractDetailsFormError?.eoss_margin && <div className="error">{storeContractDetailsFormError?.eoss_margin}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeContractDetails.eoss_threshold_value} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'eoss_threshold_value', e.target.value, isNumberOrFloat, CustomErrorMsg?.eossthresholdvalue)} required minLength={1} maxLength={6} label='EOSS Threshold % Value' />
                                            {storeContractDetailsFormError?.eoss_threshold_value && <div className="error">{storeContractDetailsFormError?.eoss_threshold_value}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeContractDetails.base_margin} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'base_margin', e.target.value, isNumberOrFloat, CustomErrorMsg?.basemargin)} required minLength={1} maxLength={6} label='BASE Margin %' />
                                            {storeContractDetailsFormError?.base_margin && <div className="error">{storeContractDetailsFormError?.base_margin}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeContractDetails.markdown_percentage} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'markdown_percentage', e.target.value, isNumberOrFloat, CustomErrorMsg?.percent)} required minLength={1} maxLength={6} label='Markdown %' />
                                            {storeContractDetailsFormError?.markdown_percentage && <div className="error">{storeContractDetailsFormError?.markdown_percentage}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeContractDetails.markdown_percentage_type} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'markdown_percentage_type', e.target.value, validateTextInputAny, CustomErrorMsg?.percentType)} required minLength={2} maxLength={20} label='Markdown % Type' />
                                            {storeContractDetailsFormError?.markdown_percentage_type && <div className="error">{storeContractDetailsFormError?.markdown_percentage_type}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'discount', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.discount} options={DISCOUNT} required label='Discount*' />
                                            {storeContractDetailsFormError?.discount && <div className="error">{storeContractDetailsFormError?.discount}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete disabled={isDiscount} onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'discount_percentage', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.discount_percentage} options={discountPercentage} required label='Discount Percentage*' />
                                            {storeContractDetailsFormError?.discount_percentage && <div className="error">{storeContractDetailsFormError?.discount_percentage}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'target_linked_insentives', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.target_linked_insentives} options={TARGET_LINKED_INSENTIVES} required label='Target Linked Insentives*' />
                                            {storeContractDetailsFormError?.target_linked_insentives && <div className="error">{storeContractDetailsFormError?.target_linked_insentives}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField disabledClass={isTargetLinkedInsentives ? 'disabledClass' : ''} type='text' disabled={isTargetLinkedInsentives} value={storeContractDetails.target_linked_insentives_percentagre} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'target_linked_insentives_percentagre', e.target.value, isNumberOrFloat, CustomErrorMsg?.percent)} required minLength={1} maxLength={6} label='Target Linked Incentives %' />
                                            {storeContractDetailsFormError?.target_linked_insentives_percentagre && <div className="error">{storeContractDetailsFormError?.target_linked_insentives_percentagre}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'cash_discount', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.cash_discount} options={CASH_DISCOUNT} required label='Cash Discount*' />
                                            {storeContractDetailsFormError?.cash_discount && <div className="error">{storeContractDetailsFormError?.cash_discount}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField disabledClass={isCashDiscount ? 'disabledClass' : ''} type='text' disabled={isCashDiscount} value={storeContractDetails.cash_discount_percentage} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'cash_discount_percentage', e.target.value, isNumberOrFloat, CustomErrorMsg?.percent)} required minLength={1} maxLength={6} label='Cash Discount %' />
                                            {storeContractDetailsFormError?.cash_discount_percentage && <div className="error">{storeContractDetailsFormError?.cash_discount_percentage}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'cash_discount_type', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.cash_discount_type} options={cashDiscountType} required label='Cash Discount Type*' />
                                            {storeContractDetailsFormError?.cash_discount_type && <div className="error">{storeContractDetailsFormError?.cash_discount_type}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeContractDetails.payable_duration} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'payable_duration', e.target.value, validateNumber1To3Input, CustomErrorMsg?.payableDuration)} required minLength={1} maxLength={3} label='Payable Duration' />
                                            {storeContractDetailsFormError?.payable_duration && <div className="error">{storeContractDetailsFormError?.payable_duration}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'stock_correction', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.stock_correction} options={StockCorrection} required label='Stock Correction %*' />
                                            {storeContractDetailsFormError?.stock_correction && <div className="error">{storeContractDetailsFormError?.stock_correction}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'promo_contribution', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.promo_contribution} options={promoContribution} required label='Promo Contribution %*' />
                                            {storeContractDetailsFormError?.promo_contribution && <div className="error">{storeContractDetailsFormError?.promo_contribution}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'is_security', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.is_security} options={securityDrop} required label='Security*' />
                                            {storeContractDetailsFormError?.is_security && <div className="error">{storeContractDetailsFormError?.is_security}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeContractDetails.comments} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'comments', e.target.value, validateText2To200Input, CustomErrorMsg?.comment)} required minLength={2} maxLength={200} label='Security Comment' />
                                            {storeContractDetailsFormError?.comments && <div className="error">{storeContractDetailsFormError?.comments}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeContractDetails.credit_limit} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'credit_limit', e.target.value, validateNumberAtLeast1Input, CustomErrorMsg?.creditLimit)} required minLength={2} maxLength={''} label='Credit Limit' />
                                            {storeContractDetailsFormError?.credit_limit && <div className="error">{storeContractDetailsFormError?.credit_limit}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <MultiSelectCheckBox handleChange={(e) => handleInputChange(4, 'storeContractDetails', 'business_model', e.target.value, validateTextInputAny, 'Please Select Any Value')} selectedValue={storeContractDetails?.business_model} options={businessModelDrop} MenuProps={MenuProps} label='Business Model*' />
                                            {/* {storeContractDetails?.business_model?.length === 0 && <div className="error">Please Slect Any Value</div>} */}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeContractDetails.store_floor_size} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'store_floor_size', e.target.value, validateNumber2To6Input, CustomErrorMsg?.size)} minLength={2} maxLength={6} label='Store Floor Size' />
                                            {/* {storeContractDetailsFormError?.store_floor_size && <div className="error">{storeContractDetailsFormError?.store_floor_size}</div>} */}
                                        </div>
                                        {/* <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} value={storeContractDetails.primary_billing_discount} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'primary_billing_discount', e.target.value, validateNumberAtLeast1Input, CustomErrorMsg?.size)} minLength={2} maxLength={6} label='Primary Billing Discount' />
                                        </div> */}
                                    </div>
                                </div>
                                <div className='bg-white storeDetailsBtnContainer d-flex mt-4'>
                                    <div className='pe-3'>
                                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                                    </div>
                                    <CustomBlueButton customCreatePropsBtnClass="save_change" title={<div className='d-flex align-items-center'>
                                        <div>Save Changes</div>
                                    </div>} onClick={onClickSaveEditDetails} disabled={
                                        (storeContractDetailsFormError === "" ? true : false) ||
                                        (storeContractDetailsFormError &&
                                            (storeContractDetailsFormError?.distribution_type !== null ||
                                                storeContractDetailsFormError?.channel !== null ||
                                                storeContractDetailsFormError?.fresh_margin_type !== null ||
                                                storeContractDetailsFormError?.fresh_margin !== null ||
                                                storeContractDetailsFormError?.eoss_margin_type !== null ||
                                                storeContractDetailsFormError?.eoss_margin !== null ||
                                                storeContractDetailsFormError?.eoss_threshold_value !== null ||
                                                storeContractDetailsFormError?.base_margin !== null ||
                                                storeContractDetailsFormError?.markdown_percentage_type !== null ||
                                                storeContractDetailsFormError?.markdown_percentage !== null ||
                                                storeContractDetailsFormError?.discount !== null ||
                                                storeContractDetailsFormError?.discount_percentage !== null ||
                                                storeContractDetailsFormError?.target_linked_insentives !== null ||
                                                storeContractDetailsFormError?.target_linked_insentives_percentagre !== null ||
                                                storeContractDetailsFormError?.cash_discount !== null ||
                                                storeContractDetailsFormError?.cash_discount_type !== null ||
                                                storeContractDetailsFormError?.cash_discount_percentage !== null ||
                                                storeContractDetailsFormError?.payable_duration !== null ||
                                                storeContractDetailsFormError?.stock_correction !== null ||
                                                storeContractDetailsFormError?.promo_contribution !== null ||
                                                storeContractDetailsFormError?.is_security !== null ||
                                                storeContractDetailsFormError?.comments !== null ||
                                                storeContractDetailsFormError?.credit_limit !== null ||
                                                storeContractDetailsFormError?.business_model !== null
                                            ))} />
                                </div>
                            </div>
                        </>
                    }
                </>
            )
        } else if (currentTab === 5) {
            return (
                <>
                    {isEditFormVisible ?
                        <div className='bg-white storeDetailsContainer scrollChange'>
                            <div className='montSerratFont fontSize18 tabheader'>Store Wall Details</div>
                            <div className='position-relative'>
                                <div className='editStoreBtn'>
                                    <SecondaryButton onClick={onClickEditDetails} title={<div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <img src={EditIcon} alt="" />
                                            <div className='textColorBlue fontMedium'>Edit Details</div>
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                            {storeDetails?.brands?.map((item, index) => (
                                <div>
                                    <div className='pt-3'>
                                        <strong>{item?.brand_name !== 'null' ? item?.brand_name : "-"}</strong>
                                    </div>
                                    {/* <div className='text-decoration-underline fontSize14 notoSansFont pt-1'>{item?.brand_name}</div> */}
                                    <div className='paddingTop24 notoSansFont pb-3'>
                                        {storeDetails && <div className='storeOwnerDetails'>
                                            <div>
                                                <div className='storeLabel'>Brand Name</div>
                                                <div className='storeValue'>{item?.brand_name !== 'null' ? item?.brand_name : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Wall Space (ft)</div>
                                                <div className='storeValue'>{item?.wall_space !== 'null' ? item?.wall_space : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Enter Cdu (ft)</div>
                                                <div className='storeValue'>{item?.enter_cdu !== 'null' ? item?.enter_cdu : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Default Length (ft)</div>
                                                <div className='storeValue text-capitalize'>{item?.default_length !== 'null' ? item?.default_length : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Total Sq. Ft</div>
                                                <div className='storeValue'>{item?.total_sq_ft !== 'null' ? item?.total_sq_ft : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Floor Placement</div>
                                                <div className='storeValue text-capitalize'>{item?.floor_placement !== 'null' ? item?.floor_placement : "-"}</div>
                                            </div>
                                            {/* <div>
                                            <div className='storeLabel'>Store Floor Size</div>
                                            <div className='storeValue'>{item?.store_floor_size  !== 'null' ? item?.store_floor_size : "-"}</div>
                                        </div> */}
                                            <div>
                                                <div className='storeLabel'>Option Per Running Feet (Qty)</div>
                                                <div className='storeValue'>{item?.per_running_feet !== 'null' ? item?.per_running_feet : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Depth Per Option (Qty)</div>
                                                <div className='storeValue'>{item?.depth_per_option !== 'null' ? item?.depth_per_option : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Display Options (Qty)</div>
                                                <div className='storeValue text-capitalize'>{item?.display_options !== 'null' ? item?.display_options : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Display Quantity (Qty)</div>
                                                <div className='storeValue'>{item?.display_qty !== 'null' ? item?.display_qty : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Category Division</div>
                                                <div className='storeValue'>{item?.category_division?.join(",")}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Category Group</div>
                                                <div className='storeValue'>{item?.category_group?.join(",")}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Category Presence</div>
                                                <div className='storeValue text-capitalize'>{item?.category_presence?.join(",")}</div>
                                            </div>

                                            <div>
                                                <div className='storeLabel'>Brand Staff</div>
                                                <div className='storeValue'>{item?.staff_details && item?.staff_details?.length !== 0 ? 'Yes' : 'No'}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Staff Name</div>
                                                <div className='storeValue'>
                                                    {item?.staff_details?.length !== 0 ? item?.staff_details?.map(staff => staff.brand_staff_name).join(', ') : '-'}
                                                    {item?.staff_details?.length > 2 && (
                                                        <button className='viewMoreBtn' onClick={() => handleViewMore('brandStaffName', index)}>View More</button>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Staff Terms</div>
                                                <div className='storeValue text-capitalize'>{item?.brand_staff_terms}</div>
                                            </div>
                                        </div>}
                                        {/* <div className='paddingTop32 fontSize14' style={{ width: '192px' }}>
                                        <button className='viewMoreButton' onClick={onClickViewMoreBrands}>View More</button>
                                    </div> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        <>
                            <div className='saveChangeCont scrollChange'>
                                <div className='bg-white storeMagDetailsContainer'>
                                    <div className='montSerratFont fontMedium fontSize18 textColorBlack' style={{ position: 'relative' }}>
                                        Store Wall Details
                                    </div>
                                    <div className='pt-3 formContainerNew customScroll'>
                                        <div className='brandContactsDetals customScroll'>
                                            {
                                                storeWallBrandsDetails && storeWallBrandsDetails?.map((item, index) => (
                                                    <div key={index}>
                                                        <div className='pt-3'>
                                                            <strong>Store Wall {index + 1}</strong>
                                                        </div>

                                                        <div className='pt-3 formContainer'>
                                                            <div className='inputBottomSpace'>
                                                                <CustomAutoComplete onChange={(event, newValue) => handleStoreWallBrandsDetailsDetailsChange(event, newValue, index, 'brand_name')} onInputChange={(e, newInputValue) => { setAllBrandsListData(newInputValue) }} inputValue={allBrandStatus} value={item?.brand_name} options={allBrandsListData} required label='Brand Name*' />
                                                                {storeWallBrandsDetailsFormError?.brand_name && <div className="error">{storeWallBrandsDetailsFormError?.brand_name}</div>}
                                                            </div>
                                                            <div className='inputBottomSpace'>
                                                                <InputTextField type='text' disabled={false} name={'wall_space'} value={item?.wall_space} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'wall_space')} required minLength={1} maxLength={3} label='Wall Space (ft)' />
                                                                {storeWallBrandsDetailsFormError?.wall_space && <div className="error">{storeWallBrandsDetailsFormError?.wall_space}</div>}
                                                            </div>
                                                            <div className='inputBottomSpace'>
                                                                <InputTextField type='text' disabled={false} name={'enter_cdu'} value={item?.enter_cdu} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'enter_cdu')} minLength={1} maxLength={3} label='CDU (ft)' />
                                                                {/* {storeWallBrandsDetailsFormError?.enter_cdu && <div className="error">{storeWallBrandsDetailsFormError?.enter_cdu}</div>} */}
                                                            </div>
                                                            <div className='inputBottomSpace'>
                                                                <InputTextField type='text' disabled={false} name={'default_length'} value={item?.default_length} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'default_length')} minLength={1} maxLength={3} label='Default Length (ft)' />
                                                                {/* {storeWallBrandsDetailsFormError?.default_length && <div className="error">{storeWallBrandsDetailsFormError?.default_length}</div>} */}
                                                            </div>
                                                            <div className='inputBottomSpace'>
                                                                <InputTextField type='text' disabled={false} name={'total_sq_ft'} value={item?.total_sq_ft} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'total_sq_ft')} minLength={2} maxLength={6} label='Total Sq Ft' />
                                                                {/* {storeWallBrandsDetailsFormError.total_sq_ft && <div className="error">{storeWallBrandsDetailsFormError.total_sq_ft}</div>} */}
                                                            </div>
                                                            <div className='inputBottomSpace'>
                                                                <InputTextField type='text' disabled={false} name={'display_options'} value={item?.display_options} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'display_options')} minLength={1} maxLength={3} label='Display Options (Qty)' />
                                                                {/* {storeWallBrandsDetailsFormError?.display_options && <div className="error">{storeWallBrandsDetailsFormError?.display_options}</div>} */}
                                                            </div>
                                                            <div className='inputBottomSpace'>
                                                                <InputTextField type='text' disabled={false} name={'per_running_feet'} value={item?.per_running_feet} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'per_running_feet')} minLength={1} maxLength={3} label='Option Per Running Feet (Qty)' />
                                                                {/* {storeWallBrandsDetailsFormError?.per_running_feet && <div className="error">{storeWallBrandsDetailsFormError?.per_running_feet}</div>} */}
                                                            </div>
                                                            <div className='inputBottomSpace'>
                                                                <MultiSelectCheckBox disabled={false} name={'floor_placement'} handleChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'floor_placement')} selectedValue={item?.floor_placement} options={floorPlacementDrop} MenuProps={MenuProps} label='Floor Placement' />
                                                            </div>
                                                            {/* <div className='inputBottomSpace'>
                                                                <MultiSelectCheckBox disabled={false} name={'category_group'} handleChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'category_group')} selectedValue={item?.category_group}  options={categoryGroupDrop} MenuProps={MenuProps} label='Category Group*' />
                                                            </div> */}
                                                            <div className='inputBottomSpace'>
                                                                <InputTextField type='text' disabled={false} name={'display_qty'} value={item?.display_qty} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'display_qty')} minLength={1} maxLength={6} label='Display Quantity (Qty)' />
                                                                {/* {storeWallBrandsDetailsFormError.display_qty && <div className="error">{storeWallBrandsDetailsFormError.display_qty}</div>} */}
                                                            </div>
                                                            <div className='inputBottomSpace'>
                                                                <InputTextField type='text' disabled={false} name={'depth_per_option'} value={item?.depth_per_option} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'depth_per_option')} minLength={1} maxLength={3} label='Depth Per Option (Qty)' />
                                                                {/* {storeWallBrandsDetailsFormError.depth_per_option && <div className="error">{storeWallBrandsDetailsFormError.depth_per_option}</div>} */}
                                                            </div>
                                                            {/* <div className='inputBottomSpace'>
                                                                <MultiSelectCheckBox disabled={false} name={'category_division'} handleChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'category_division')} selectedValue={item?.category_division}  options={categoryDivisionDrop} MenuProps={MenuProps} label='Category Division*' />
                                                            </div> */}
                                                            {/* <div className='inputBottomSpace'>
                                                                <MultiSelectCheckBox disabled={false} name={'category_presence'} handleChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'category_presence')} selectedValue={item?.category_presence}  options={categoryPresenceDrop} MenuProps={MenuProps} label='Category Presence*' />
                                                            </div> */}
                                                            <div className='inputBottomSpace'>
                                                                <MultiSelectCheckBox disabled={false} name={'category_division'} handleChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'category_division')} selectedValue={item?.category_division} options={categoryDivisionDrop} MenuProps={MenuProps} label='Category Division*' />
                                                            </div>
                                                            <div className='inputBottomSpace'>
                                                                <MultiSelectCheckBox disabled={categoryGroupDrop[index]?.length === 0} name={'category_group'} handleChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'category_group')} selectedValue={item?.category_group} options={categoryGroupDrop[index] || []} MenuProps={MenuProps} label='Category Group*' />
                                                            </div>
                                                            <div className='inputBottomSpace'>
                                                                <MultiSelectCheckBox disabled={categoryPresenceDrop[index]?.length === 0} name={'category_presence'} handleChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'category_presence')} selectedValue={item?.category_presence} options={categoryPresenceDrop[index] || []} MenuProps={MenuProps} label='Category Presence*' />
                                                            </div>
                                                            {/* <div className='inputBottomSpace'>
                                                                <CustomAutoComplete disabled={true} name={'is_brand_staff'} onChange={(event, newValue) => { handleStoreWallBrandsDetailsDetailsChange(event, newValue, index, 'is_brand_staff') }} onInputChange={(e, newInputValue) => setISBRANDSTAFFStatus(newInputValue)} inputValue={iSBRANDSTAFFStatus} value={item?.staff_details?.length !== 0 ? 'Yes' : 'No'} options={IS_BRAND_STAFF} label='Brand Staff' />
                                                            </div>
                                                            <div className='inputBottomSpace'>
                                                                <InputTextField type='text' disabledClass={'disabledClass'} disabled={true} name={'brand_staff_name'} value={item?.staff_details?.map(staff => staff.brand_staff_name).join(', ') } onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'brand_staff_name')} minLength={3} maxLength={80} label='Staff Name' />
                                                            </div> */}
                                                            <div className='inputBottomSpace'>
                                                                <InputTextField type='text' disabled={false} name={'brand_staff_terms'} value={item?.brand_staff_terms} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'brand_staff_terms')} minLength={2} maxLength={100} label='Staff Terms' />
                                                                {/* {storeWallBrandsDetailsFormError.brand_staff_terms && <div className="error">{storeWallBrandsDetailsFormError.brand_staff_terms}</div>} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', paddingRight: '10px' }} >
                                                <div onClick={addNewStoreWallBrandsDetailsForm} className='' style={{ width: '30px', height: '30px', display: 'flex', border: '1px solid grey', borderRadius: '50%', cursor: 'pointer' }}>
                                                    <img src={PlusIcon} alt="" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white storeDetailsBtnContainer d-flex mt-4'>
                                    <div className='pe-3'>
                                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                                    </div>
                                    <CustomBlueButton customCreatePropsBtnClass="save_change" title={<div className='d-flex align-items-center'>
                                        <div>Save Changes</div>
                                    </div>} onClick={onClickSaveEditDetails} disabled={
                                        (storeWallBrandsDetailsFormError === "" ? true : false) ||
                                        (storeWallBrandsDetailsFormError &&
                                            (storeWallBrandsDetailsFormError?.brand_name !== null ||
                                                storeWallBrandsDetailsFormError?.wall_space !== null ||
                                                storeWallBrandsDetails[0]?.category_group?.length === 0 ||
                                                storeWallBrandsDetails[0]?.category_division?.length === 0 ||
                                                storeWallBrandsDetails[0]?.category_presence?.length === 0
                                            ))} />
                                </div>
                            </div>
                        </>
                    }
                </>
            )
        } else if (currentTab === 6) {
            return (
                <>
                    {isEditFormVisible ?
                        <div className='bg-white storeDetailsContainer scrollChange'>
                            <div className='montSerratFont fontSize18 tabheader'>Address Details</div>
                            <div className='position-relative'>
                                <div className='editStoreBtn'>
                                    <SecondaryButton onClick={onClickEditDetails} title={<div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <img src={EditIcon} alt="" />
                                            <div className='textColorBlue fontMedium'>Edit Details</div>
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                            <>
                                <div className='notoSansFont'>
                                    <div className='text-decoration-underline paddingTop24 fontSize14'>Billing Address</div>
                                    <div className='paddingTop24 notoSansFont'>
                                        {storeDetails && <div className='storeOwnerDetails'>
                                            <div>
                                                <div className='storeLabel'>Address</div>
                                                <div className='storeValue'>{storeDetails?.billing_place_address?.address !== 'null' ? storeDetails?.billing_place_address?.address : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Pincode</div>
                                                <div className='storeValue'>{storeDetails?.billing_place_address?.pincode !== 'null' ? storeDetails?.billing_place_address?.pincode : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Area</div>
                                                <div className='storeValue'>{storeDetails?.billing_place_address?.area !== 'null' ? storeDetails?.billing_place_address?.area : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>City</div>
                                                <div className='storeValue'>{storeDetails?.billing_place_address?.city !== 'null' ? storeDetails?.billing_place_address?.city : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Region</div>
                                                <div className='storeValue text-capitalize'>{storeDetails?.billing_place_address?.region !== 'null' ? storeDetails?.billing_place_address?.region : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>State</div>
                                                <div className='storeValue'>{storeDetails?.billing_place_address?.state !== 'null' ? storeDetails?.billing_place_address?.state : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>E- Mail Id</div>
                                                <div className='storeValue'>{storeDetails?.billing_place_address?.email !== 'null' ? storeDetails?.billing_place_address?.email : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Place Mobile Number</div>
                                                <div className='storeValue'>+91 {storeDetails?.billing_place_address?.mobile !== 'null' ? storeDetails?.billing_place_address?.mobile : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Person Mobile Number</div>
                                                <div className='storeValue text-capitalize'>+91 {storeDetails?.billing_place_address?.phone_number !== 'null' ? storeDetails?.billing_place_address?.phone_number : "-"}</div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <div className='notoSansFont paddingTop32'>
                                    <div className='text-decoration-underline paddingTop24 fontSize14'>Shipping Address</div>
                                    <div className='paddingTop24 notoSansFont'>
                                        {storeDetails && <div className='storeOwnerDetails'>
                                            <div>
                                                <div className='storeLabel'>Address</div>
                                                <div className='storeValue'>{storeDetails?.shipping_place_address?.address !== 'null' ? storeDetails?.shipping_place_address?.address : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Pincode</div>
                                                <div className='storeValue'>{storeDetails?.shipping_place_address?.pincode !== 'null' ? storeDetails?.shipping_place_address?.pincode : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Area</div>
                                                <div className='storeValue'>{storeDetails?.shipping_place_address?.area !== 'null' ? storeDetails?.shipping_place_address?.area : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>City</div>
                                                <div className='storeValue'>{storeDetails?.shipping_place_address?.city !== 'null' ? storeDetails?.shipping_place_address?.city : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Region</div>
                                                <div className='storeValue text-capitalize'>{storeDetails?.shipping_place_address?.region !== 'null' ? storeDetails?.shipping_place_address?.region : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>State</div>
                                                <div className='storeValue'>{storeDetails?.shipping_place_address?.state !== 'null' ? storeDetails?.shipping_place_address?.state : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>E- Mail Id</div>
                                                <div className='storeValue'>{storeDetails?.shipping_place_address?.email !== 'null' ? storeDetails?.shipping_place_address?.email : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Place Mobile Number</div>
                                                <div className='storeValue'>+91 {storeDetails?.shipping_place_address?.mobile !== 'null' ? storeDetails?.shipping_place_address?.mobile : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Person Mobile Number</div>
                                                <div className='storeValue text-capitalize'>+91 {storeDetails?.shipping_place_address?.phone_number !== 'null' ? storeDetails?.shipping_place_address?.phone_number : "-"}</div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <div className='notoSansFont paddingTop32'>
                                    <div className='text-decoration-underline paddingTop24 fontSize14'>Warehouse Address</div>
                                    <div className='paddingTop24 notoSansFont'>
                                        {storeDetails && <div className='storeOwnerDetails'>
                                            <div>
                                                <div className='storeLabel'>Address</div>
                                                <div className='storeValue'>{storeDetails?.warehouse_address?.address !== 'null' ? storeDetails?.warehouse_address?.address : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Pincode</div>
                                                <div className='storeValue'>{storeDetails?.warehouse_address?.pincode !== 'null' ? storeDetails?.warehouse_address?.pincode : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Area</div>
                                                <div className='storeValue'>{storeDetails?.warehouse_address?.area !== 'null' ? storeDetails?.warehouse_address?.area : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>City</div>
                                                <div className='storeValue'>{storeDetails?.warehouse_address?.city !== 'null' ? storeDetails?.warehouse_address?.city : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Region</div>
                                                <div className='storeValue text-capitalize'>{storeDetails?.warehouse_address?.region !== 'null' ? storeDetails?.warehouse_address?.region : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>State</div>
                                                <div className='storeValue'>{storeDetails?.warehouse_address?.state !== 'null' ? storeDetails?.warehouse_address?.state : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>E- Mail Id</div>
                                                <div className='storeValue'>{storeDetails?.warehouse_address?.email !== 'null' ? storeDetails?.warehouse_address?.email : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Place Mobile Number</div>
                                                <div className='storeValue'>+91 {storeDetails?.warehouse_address?.mobile !== 'null' ? storeDetails?.warehouse_address?.mobile : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Person Mobile Number</div>
                                                <div className='storeValue text-capitalize'>+91 {storeDetails?.warehouse_address?.phone_number !== 'null' ? storeDetails?.warehouse_address?.phone_number : "-"}</div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <div className='notoSansFont paddingTop32'>
                                    <div className='text-decoration-underline paddingTop24 fontSize14'>Return Warehouse Address</div>
                                    <div className='paddingTop24 notoSansFont'>
                                        {storeDetails && <div className='storeOwnerDetails'>
                                            <div>
                                                <div className='storeLabel'>Address</div>
                                                <div className='storeValue'>{storeDetails?.return_warehouse_address?.address !== 'null' ? storeDetails?.return_warehouse_address?.address : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Pincode</div>
                                                <div className='storeValue'>{storeDetails?.return_warehouse_address?.pincode !== 'null' ? storeDetails?.return_warehouse_address?.pincode : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Area</div>
                                                <div className='storeValue'>{storeDetails?.return_warehouse_address?.area !== 'null' ? storeDetails?.return_warehouse_address?.area : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>City</div>
                                                <div className='storeValue'>{storeDetails?.return_warehouse_address?.city !== 'null' ? storeDetails?.return_warehouse_address?.city : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Region</div>
                                                <div className='storeValue text-capitalize'>{storeDetails?.return_warehouse_address?.region !== 'null' ? storeDetails?.return_warehouse_address?.region : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>State</div>
                                                <div className='storeValue'>{storeDetails?.return_warehouse_address?.state !== 'null' ? storeDetails?.return_warehouse_address?.state : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>E- Mail Id</div>
                                                <div className='storeValue'>{storeDetails?.return_warehouse_address?.email !== 'null' ? storeDetails?.return_warehouse_address?.email : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Place Mobile Number</div>
                                                <div className='storeValue'>+91 {storeDetails?.return_warehouse_address?.mobile !== 'null' ? storeDetails?.return_warehouse_address?.mobile : "-"}</div>
                                            </div>
                                            <div>
                                                <div className='storeLabel'>Person Mobile Number</div>
                                                <div className='storeValue text-capitalize'>+91 {storeDetails?.return_warehouse_address?.phone_number !== 'null' ? storeDetails?.return_warehouse_address?.phone_number : "-"}</div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </>
                        </div>
                        :
                        <>
                            <div className='saveChangeCont scrollChange'>
                                <div className='bg-white storeMagDetailsContainer'>
                                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                                        Address Details
                                    </div>
                                    <div className='pt-3 addressDetailsMainCont formContainerNew customScroll'>
                                        <div className='addressDetailsCont'>
                                            <div>
                                                <div className='text-decoration-underline fontSize14 pb-3'>Billing Address</div>
                                                <div className='formContainer addressesDetailsCont'>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.billing_place_address?.address} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'billing_place_address', child: 'address' }, e.target.value, validateTextAddressInput, CustomErrorMsg?.address)} required minLength={10} maxLength={100} label='Address' />
                                                        {storeAddressesDetailsFormError?.billing_place_address?.address && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.address}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.billing_place_address?.pincode} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'billing_place_address', child: 'pincode' }, e.target.value, validatePinCodeInput, CustomErrorMsg?.pincode)} required minLength={6} maxLength={6} label='Pin Code' />
                                                        {storeAddressesDetailsFormError?.billing_place_address?.pincode && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.pincode}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.billing_place_address?.area} required minLength={''} maxLength={''} label='Area' />
                                                        {storeAddressesDetailsFormError?.billing_place_address?.area && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.area}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.billing_place_address?.city} required minLength={''} maxLength={''} label='City' />
                                                        {storeAddressesDetailsFormError?.billing_place_address?.city && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.city}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.billing_place_address?.region} required minLength={''} maxLength={''} label='Region' />
                                                        {storeAddressesDetailsFormError?.billing_place_address?.region && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.region}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.billing_place_address?.state} required minLength={''} maxLength={''} label='State' />
                                                        {storeAddressesDetailsFormError?.billing_place_address?.state && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.state}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.billing_place_address?.email} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'billing_place_address', child: 'email' }, e.target.value, validateEmailInput, CustomErrorMsg?.email)} required minLength={''} maxLength={''} label='E-Mail Id' />
                                                        {storeAddressesDetailsFormError?.billing_place_address?.email && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.email}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.billing_place_address?.phone_number} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'billing_place_address', child: 'phone_number' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Billing Place Mobile Number' />
                                                        {storeAddressesDetailsFormError?.billing_place_address?.phone_number && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.phone_number}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.billing_place_address?.mobile} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'billing_place_address', child: 'mobile' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Person Mobile Number' />
                                                        {storeAddressesDetailsFormError?.billing_place_address?.mobile && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.mobile}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='text-decoration-underline paddingTop24 fontSize14 pb-3'>
                                                    Shipping Address
                                                    <span className='copySameAsAbove'>
                                                        <input type='checkbox' className='form-check-input' id='sameAsBilling' onChange={handleCopyBillingAddress} />
                                                        <label className='form-check-label' htmlFor='sameAsBilling'>
                                                            Same as Billing Address
                                                        </label>
                                                    </span>
                                                </div>
                                                <div className='formContainer addressesDetailsCont'>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.shipping_place_address?.address} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'shipping_place_address', child: 'address' }, e.target.value, validateTextAddressInput, CustomErrorMsg?.address)} required minLength={10} maxLength={100} label='Address' />
                                                        {storeAddressesDetailsFormError?.shipping_place_address?.address && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.address}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.shipping_place_address?.pincode} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'shipping_place_address', child: 'pincode' }, e.target.value, validatePinCodeInput, CustomErrorMsg?.pincode)} required minLength={6} maxLength={6} label='Pin Code' />
                                                        {storeAddressesDetailsFormError?.shipping_place_address?.pincode && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.pincode}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.shipping_place_address?.area} required minLength={''} maxLength={''} label='Area' />
                                                        {storeAddressesDetailsFormError?.shipping_place_address?.area && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.area}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.shipping_place_address?.city} required minLength={''} maxLength={''} label='City' />
                                                        {storeAddressesDetailsFormError?.shipping_place_address?.city && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.city}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.shipping_place_address?.region} required minLength={''} maxLength={''} label='Region' />
                                                        {storeAddressesDetailsFormError?.shipping_place_address?.region && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.region}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.shipping_place_address?.state} required minLength={''} maxLength={''} label='State' />
                                                        {storeAddressesDetailsFormError?.shipping_place_address?.state && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.state}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.shipping_place_address?.email} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'shipping_place_address', child: 'email' }, e.target.value, validateEmailInput, CustomErrorMsg?.email)} required minLength={''} maxLength={''} label='E-Mail Id' />
                                                        {storeAddressesDetailsFormError?.shipping_place_address?.email && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.email}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.shipping_place_address?.phone_number} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'shipping_place_address', child: 'phone_number' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Shipping Place Phone Number' />
                                                        {storeAddressesDetailsFormError?.shipping_place_address?.phone_number && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.phone_number}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.shipping_place_address?.mobile} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'shipping_place_address', child: 'mobile' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Person Mobile Number' />
                                                        {storeAddressesDetailsFormError?.shipping_place_address?.mobile && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.mobile}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='text-decoration-underline paddingTop24 fontSize14 pb-3'>
                                                    Warehouse Address
                                                    <span className='copySameAsAbove'>
                                                        <input type='checkbox' className='form-check-input' id='sameAsBilling' checked={inputChecked.billingAddress} onChange={handleCopyBillingAddressForWarehouse} />
                                                        <label className='form-check-label' htmlFor='sameAsBilling'>
                                                            Same as Billing Address
                                                        </label>
                                                    </span>
                                                    <span className='copySameAsAbove'>
                                                        <input type='checkbox' className='form-check-input' id='sameAsBilling' checked={inputChecked.shippingAddress} onChange={handleCopyShippingAddressForWarehouse} />
                                                        <label className='form-check-label' htmlFor='sameAsBilling'>
                                                            Same as Shipping Address
                                                        </label>
                                                    </span>
                                                </div>
                                                <div className='formContainer addressesDetailsCont'>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.warehouse_address?.address} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'warehouse_address', child: 'address' }, e.target.value, validateTextAddressInput, CustomErrorMsg?.address)} required minLength={10} maxLength={100} label='Address' />
                                                        {storeAddressesDetailsFormError?.warehouse_address?.address && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.address}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.warehouse_address?.pincode} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'warehouse_address', child: 'pincode' }, e.target.value, validatePinCodeInput, CustomErrorMsg?.pincode)} required minLength={6} maxLength={6} label='Pin Code' />
                                                        {storeAddressesDetailsFormError?.warehouse_address?.pincode && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.pincode}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.warehouse_address?.area} required minLength={''} maxLength={''} label='Area' />
                                                        {storeAddressesDetailsFormError?.warehouse_address?.area && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.area}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.warehouse_address?.city} required minLength={''} maxLength={''} label='City' />
                                                        {storeAddressesDetailsFormError?.warehouse_address?.city && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.city}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.warehouse_address?.region} required minLength={''} maxLength={''} label='Region' />
                                                        {storeAddressesDetailsFormError?.warehouse_address?.region && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.region}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.warehouse_address?.state} required minLength={''} maxLength={''} label='State' />
                                                        {storeAddressesDetailsFormError?.warehouse_address?.state && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.state}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.warehouse_address?.email} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'warehouse_address', child: 'email' }, e.target.value, validateEmailInput, CustomErrorMsg?.email)} required minLength={''} maxLength={''} label='E-Mail Id' />
                                                        {storeAddressesDetailsFormError?.warehouse_address?.email && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.email}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.warehouse_address?.phone_number} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'warehouse_address', child: 'phone_number' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Warehouse Phone Number' />
                                                        {storeAddressesDetailsFormError?.warehouse_address?.phone_number && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.phone_number}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.warehouse_address?.mobile} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'warehouse_address', child: 'mobile' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Person Mobile Number' />
                                                        {storeAddressesDetailsFormError?.warehouse_address?.mobile && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.mobile}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='text-decoration-underline paddingTop24 fontSize14 pb-3'>
                                                    Return Warehouse Address
                                                    <span className='copySameAsAbove'>
                                                        <input type='checkbox' className='form-check-input' id='sameAsBilling' onChange={handleCopyWarehouseAddress} />
                                                        <label className='form-check-label' htmlFor='sameAsBilling'>
                                                            Same as Warehouse Address
                                                        </label>
                                                    </span>
                                                </div>
                                                <div className='formContainer addressesDetailsCont'>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.return_warehouse_address?.address} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'return_warehouse_address', child: 'address' }, e.target.value, validateTextAddressInput, CustomErrorMsg?.address)} required minLength={10} maxLength={100} label='Address' />
                                                        {storeAddressesDetailsFormError?.return_warehouse_address?.address && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.address}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.return_warehouse_address?.pincode} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'return_warehouse_address', child: 'pincode' }, e.target.value, validatePinCodeInput, CustomErrorMsg?.pincode)} required minLength={6} maxLength={6} label='Pin Code' />
                                                        {storeAddressesDetailsFormError?.return_warehouse_address?.pincode && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.pincode}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.return_warehouse_address?.area} required minLength={''} maxLength={''} label='Area' />
                                                        {storeAddressesDetailsFormError?.return_warehouse_address?.area && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.area}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.return_warehouse_address?.city} required minLength={''} maxLength={''} label='City' />
                                                        {storeAddressesDetailsFormError?.return_warehouse_address?.city && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.city}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.return_warehouse_address?.region} required minLength={''} maxLength={''} label='Region' />
                                                        {storeAddressesDetailsFormError?.return_warehouse_address?.region && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.region}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.return_warehouse_address?.state} required minLength={''} maxLength={''} label='State' />
                                                        {storeAddressesDetailsFormError?.return_warehouse_address?.state && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.state}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.return_warehouse_address?.email} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'return_warehouse_address', child: 'email' }, e.target.value, validateEmailInput, CustomErrorMsg?.email)} required minLength={''} maxLength={''} label='E-Mail Id' />
                                                        {storeAddressesDetailsFormError?.return_warehouse_address?.email && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.email}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.return_warehouse_address?.phone_number} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'return_warehouse_address', child: 'phone_number' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label="Return Warehouse's Phone Number" />
                                                        {storeAddressesDetailsFormError?.return_warehouse_address?.phone_number && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.phone_number}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.return_warehouse_address?.mobile} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'return_warehouse_address', child: 'mobile' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Person Mobile Number' />
                                                        {storeAddressesDetailsFormError?.return_warehouse_address?.mobile && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.mobile}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white storeDetailsBtnContainer d-flex mt-4'>
                                    <div className='pe-3'>
                                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                                    </div>
                                    <CustomBlueButton customCreatePropsBtnClass="save_change" title={<div className='d-flex align-items-center'>
                                        <div>Save Changes</div>
                                    </div>} onClick={onClickSaveEditDetails} disabled={
                                        (storeAddressesDetailsFormError === "" ? true : false) ||
                                        (storeAddressesDetailsFormError &&
                                            (storeAddressesDetailsFormError?.billing_place_address?.address !== null ||
                                                storeAddressesDetailsFormError?.billing_place_address?.pincode !== null ||
                                                storeAddressesDetails?.billing_place_address?.region === '' ||
                                                storeAddressesDetails?.billing_place_address?.state === '' ||
                                                storeAddressesDetails?.billing_place_address?.city === '' ||
                                                storeAddressesDetails?.billing_place_address?.area === '' ||
                                                storeAddressesDetailsFormError?.billing_place_address?.email !== null ||
                                                storeAddressesDetailsFormError?.billing_place_address?.phone_number !== null ||
                                                storeAddressesDetailsFormError?.billing_place_address?.mobile !== null ||

                                                storeAddressesDetailsFormError?.shipping_place_address?.address !== null ||
                                                storeAddressesDetailsFormError?.shipping_place_address?.pincode !== null ||
                                                storeAddressesDetails?.shipping_place_address?.region === '' ||
                                                storeAddressesDetails?.shipping_place_address?.state === '' ||
                                                storeAddressesDetails?.shipping_place_address?.city === '' ||
                                                storeAddressesDetails?.shipping_place_address?.area === '' ||
                                                storeAddressesDetailsFormError?.shipping_place_address?.email !== null ||
                                                storeAddressesDetailsFormError?.shipping_place_address?.phone_number !== null ||
                                                storeAddressesDetailsFormError?.shipping_place_address?.mobile !== null ||

                                                storeAddressesDetailsFormError?.warehouse_address?.address !== null ||
                                                storeAddressesDetailsFormError?.warehouse_address?.pincode !== null ||
                                                storeAddressesDetails?.warehouse_address?.region === '' ||
                                                storeAddressesDetails?.warehouse_address?.state === '' ||
                                                storeAddressesDetails?.warehouse_address?.city === '' ||
                                                storeAddressesDetails?.warehouse_address?.area === '' ||
                                                storeAddressesDetailsFormError?.warehouse_address?.email !== null ||
                                                storeAddressesDetailsFormError?.warehouse_address?.phone_number !== null ||
                                                storeAddressesDetailsFormError?.warehouse_address?.mobile !== null ||

                                                storeAddressesDetailsFormError?.return_warehouse_address?.address !== null ||
                                                storeAddressesDetailsFormError?.return_warehouse_address?.pincode !== null ||
                                                storeAddressesDetails?.return_warehouse_address?.region === '' ||
                                                storeAddressesDetails?.return_warehouse_address?.state === '' ||
                                                storeAddressesDetails?.return_warehouse_address?.city === '' ||
                                                storeAddressesDetails?.return_warehouse_address?.area === '' ||
                                                storeAddressesDetailsFormError?.return_warehouse_address?.email !== null ||
                                                storeAddressesDetailsFormError?.return_warehouse_address?.phone_number !== null ||
                                                storeAddressesDetailsFormError?.return_warehouse_address?.mobile !== null
                                            ))} />
                                </div>
                            </div>
                        </>
                    }
                </>
            )
        } else if (currentTab === 7) {
            return <>
                {isEditFormVisible ?
                    <div className='bg-white storeDetailsContainer scrollChange'>
                        <div className=''>
                            <div className='montSerratFont fontSize18 tabheader'>Uploaded Documents</div>
                            <div className='position-relative'>
                                <div className='editStoreBtn'>
                                    <SecondaryButton onClick={onClickEditDetails} title={<div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <img src={EditIcon} alt="" />
                                            <div className='textColorBlue fontMedium'>Edit Details</div>
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                            <div className='pt-3 formContainerNew viewDocumentUpload customScroll'>
                                <div className='documentUpload emptyDocumentCont'>
                                    {storeDetailData?.document_details?.map((item, index) => (
                                        !isFileDetailsEmpty(item) ?
                                            <div className='viewUploadCard' key={index} onClick={() => openFile(item)}>
                                                <div>
                                                    <img src={getFileType(item?.doc_name) === 'pdf' ? ExcellIcon : ((getFileType(item?.doc_name) === 'png') ? PngIcon : JpegIcon)} alt="" />
                                                </div>
                                                <div className='viewUploadCardNameDetails'>
                                                    <p>{item?.doc_name}</p>
                                                    <p>Last updated on {formatDateToTextMonthDDYYYY(item?.created_at)}</p>
                                                </div>
                                                <div>
                                                    <img src={TickIconWithGreenBg} alt="" />
                                                </div>
                                            </div>
                                            :
                                            <>
                                                {handleEmptyDocument(index)}
                                            </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='saveChangeCont scrollChange'>
                        <div className='bg-white storeMagDetailsContainer'>
                            <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                                Uploaded Documents
                            </div>
                            <div className='pt-3 formContainerNew customScroll'>
                                <div className='documentUpload'>

                                    <div className='uploadCard'>
                                        {isFileDetailsEmpty(mouAggrementFile) ?
                                            <CustomDropzone name={'MouAggrement'} onFileDrop={(file) => handleFileDrop('MouAggrement', file)} displayContent={<div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={ImportIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <div className='fontMedium textColorBlack fontSize16'>Mou/ Aggrement</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                            <div className='pt-2'>PDF</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>} />
                                            :
                                            <div style={{ width: '100%' }}>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={mouAggrementTypeIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div style={{ width: '100%' }}>
                                                        <div className='fontMedium textColorBlack fontSize16'>Mou/ Aggrement</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('MouAggrement')} progress={mouAggrementProgress === 0 ? 100 : mouAggrementProgress} File={mouAggrementFile} uploadSpeed={mouAggrementUploadSpeed} notShowBrand={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className='uploadCard'>
                                        {isFileDetailsEmpty(gstDocumentParentStoreFile) ?
                                            <CustomDropzone name={'GstDocumentParentStore'} onFileDrop={(file) => handleFileDrop('GstDocumentParentStore', file)} displayContent={<div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={ImportIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <div className='fontMedium textColorBlack fontSize16'>GST document (Parent Store)</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                            <div className='pt-2'>PDF</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>} />
                                            :
                                            <div style={{ width: '100%' }}>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={gstDocumentParentStoreFileTypeIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div style={{ width: '100%' }}>
                                                        <div className='fontMedium textColorBlack fontSize16'>GST document (Parent Store)</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('GstDocumentParentStore')} progress={gstDocumentParentStoreProgress === 0 ? 100 : gstDocumentParentStoreProgress} File={gstDocumentParentStoreFile} uploadSpeed={gstDocumentParentStoreUploadSpeed} notShowBrand={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className='uploadCard'>
                                        {isFileDetailsEmpty(gstDocumentStoreFile) ?
                                            <CustomDropzone name={'GstDocumentStore'} onFileDrop={(file) => handleFileDrop('GstDocumentStore', file)} displayContent={<div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={ImportIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <div className='fontMedium textColorBlack fontSize16'>GST document (Store)</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                            <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>} />
                                            :
                                            <div style={{ width: '100%' }}>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={gstDocumentStoreFileTypeIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div style={{ width: '100%' }}>
                                                        <div className='fontMedium textColorBlack fontSize16'>GST document (Store)</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('GstDocumentStore')} progress={gstDocumentStoreProgress === 0 ? 100 : gstDocumentStoreProgress} File={gstDocumentStoreFile} uploadSpeed={gstDocumentStoreUploadSpeed} notShowBrand={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className='uploadCard'>
                                        {isFileDetailsEmpty(panDocumentFile) ?
                                            <CustomDropzone name={'PanDocument'} onFileDrop={(file) => handleFileDrop('PanDocument', file)} displayContent={<div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={ImportIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <div className='fontMedium textColorBlack fontSize16'>PAN document</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                            <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>} />
                                            :
                                            <div style={{ width: '100%' }}>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={panDocumentFileTypeIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div style={{ width: '100%' }}>
                                                        <div className='fontMedium textColorBlack fontSize16'>PAN document</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('PanDocument')} progress={panDocumentProgress === 0 ? 100 : panDocumentProgress} File={panDocumentFile} uploadSpeed={panDocumentUploadSpeed} notShowBrand={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='uploadCard'>
                                        {isFileDetailsEmpty(uploadStorePictureFile) ?
                                            <CustomDropzone name={'UploadStorePicture'} onFileDrop={(file) => handleFileDrop('UploadStorePicture', file)} displayContent={<div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={ImportIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <div className='fontMedium textColorBlack fontSize16'>Upload Store (Picture)</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                            <div className='pt-2'>JPEG, JPG or PNG</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>} />
                                            :
                                            <div style={{ width: '100%' }}>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={uploadStorePictureFileTypeIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div style={{ width: '100%' }}>
                                                        <div className='fontMedium textColorBlack fontSize16'>Upload Store (Picture)</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('UploadStorePicture')} progress={uploadStorePictureProgress === 0 ? 100 : uploadStorePictureProgress} File={uploadStorePictureFile} uploadSpeed={uploadStorePictureUploadSpeed} notShowBrand={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className='uploadCard'>
                                        {isFileDetailsEmpty(otherFile) ?
                                            <CustomDropzone name={'Other'} onFileDrop={(file) => handleFileDrop('Other', file)} displayContent={<div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={ImportIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <div className='fontMedium textColorBlack fontSize16'>Other</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                            <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>} />
                                            :
                                            <div style={{ width: '100%' }}>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={otherFileTypeIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div style={{ width: '100%' }}>
                                                        <div className='fontMedium textColorBlack fontSize16'>Other</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('Other')} progress={otherProgress === 0 ? 100 : otherProgress} File={otherFile} uploadSpeed={otherUploadSpeed} notShowBrand={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>


                                    {/* <div className='uploadCard'>
                                        {isFileDetailsEmpty(storeFacadeFile) ?
                                        <CustomDropzone name={'StoreFacade'} onFileDrop={(file) => handleFileDrop('StoreFacade', file)} displayContent={<div>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                    <label className="cursorPointer">
                                                        <img src={ImportIcon} alt="" />
                                                    </label>
                                                </div>
                                                <div>
                                                    <div className='fontMedium textColorBlack fontSize16'>Store Facade</div>
                                                    <div className='fontSize14 textColorGray'>
                                                        <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                        <div className='pt-2'>PNG or JPG</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>} />
                                        :
                                        <div style={{width : '100%'}}>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                    <label className="cursorPointer">
                                                        <img src={storeFacadeTypeIcon} alt="" />
                                                    </label>
                                                </div>
                                                <div style={{width : '100%'}}>
                                                    <div className='fontMedium textColorBlack fontSize16'>Store Facade</div>
                                                    <div className='fontSize14 textColorGray'>
                                                        <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('StoreFacade')} progress={storeFacadeProgress === 0 ? 100 : storeFacadeProgress} File={storeFacadeFile} uploadSpeed={storeFacadeUploadSpeed} notShowBrand={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <div className='uploadCard'>
                                        {isFileDetailsEmpty(brandFloorSpaceFile) ?
                                        <CustomDropzone name={'BrandFloorSpace'} onFileDrop={(file) => handleFileDrop('BrandFloorSpace', file)} displayContent={<div>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                    <label className="cursorPointer">
                                                        <img src={ImportIcon} alt="" />
                                                    </label>
                                                </div>
                                                <div>
                                                    <div className='fontMedium textColorBlack fontSize16'>Brand Floor Space</div>
                                                    <div className='fontSize14 textColorGray'>
                                                        <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                        <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>} />
                                        :
                                        <div style={{width : '100%'}}>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                    <label className="cursorPointer">
                                                        <img src={brandFloorSpaceFileTypeIcon} alt="" />
                                                    </label>
                                                </div>
                                                <div style={{width : '100%'}}>
                                                    <div className='fontMedium textColorBlack fontSize16'>Brand Floor Space</div>
                                                    <div className='fontSize14 textColorGray'>
                                                        <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('BrandFloorSpace')} progress={brandFloorSpaceProgress === 0 ? 100 : brandFloorSpaceProgress} File={brandFloorSpaceFile} uploadSpeed={brandFloorSpaceUploadSpeed} notShowBrand={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <div className='uploadCard'>
                                        {isFileDetailsEmpty(adjacentFile) ?
                                        <CustomDropzone name={'Adjacent'} onFileDrop={(file) => handleFileDrop('Adjacent', file)} displayContent={<div>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                    <label className="cursorPointer">
                                                        <img src={ImportIcon} alt="" />
                                                    </label>
                                                </div>
                                                <div>
                                                    <div className='fontMedium textColorBlack fontSize16'>Adjacent</div>
                                                    <div className='fontSize14 textColorGray'>
                                                        <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                        <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>} />
                                        :
                                        <div style={{width : '100%'}}>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                    <label className="cursorPointer">
                                                        <img src={adjacentFileTypeIcon} alt="" />
                                                    </label>
                                                </div>
                                                <div style={{width : '100%'}}>
                                                    <div className='fontMedium textColorBlack fontSize16'>Adjacent</div>
                                                    <div className='fontSize14 textColorGray'>
                                                        <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('Adjacent')} progress={adjacentProgress  === 0 ? 100 : adjacentProgress} File={adjacentFile} uploadSpeed={adjacentUploadSpeed} notShowBrand={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div> */}


                                </div>
                            </div>
                        </div>
                        <div className='bg-white storeDetailsBtnContainer d-flex mt-4'>
                            <div className='pe-3'>
                                <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                            </div>
                            <CustomBlueButton customCreatePropsBtnClass="save_change" title={<div className='d-flex align-items-center'>
                                <div>Upload Document</div>
                            </div>} onClick={onClickSaveEditDetails} disabled={(isFileDetailsEmpty(mouAggrementFile) || isFileDetailsEmpty(gstDocumentParentStoreFile) || isFileDetailsEmpty(panDocumentFile) || isFileDetailsEmpty(gstDocumentStoreFile))} />
                        </div>
                    </div>
                }
            </>
        }
    }

    return (
        <div>
            <div className='d-flex justify-content-between align-items-baseline'>
                <div className='breadCrumbs'>
                    <span className='fontLight'>
                        <Link className="breadCrumbs" to={'/app/storeDashboard'}>Home</Link>
                    </span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>
                        <Link className="breadCrumbs" to={'/app/storeManagement'}>Store List View</Link>
                    </span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>View Store Details</span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>{storeBreadCrumbLabel}</span>
                </div>
            </div>
            <div className='paddingTop12'>
                <div>
                    <CustomTabs tabList={ViewStoreTabs} value={selectedTab} handleChange={isEditFormVisible ? handleTabChange : ""} children={<div className=''>{renderTabData(selectedTab, storeDetailData)}</div>} />
                </div>
            </div>
            <SuccessPopup open={openSuccessPopup} closable={false} onClose={() => { if (timeoutRef.current) { clearTimeout(timeoutRef.current); } setOpenSuccessPopup(false); history.push('/app/storeManagement'); }} content={<div>
                <div className='paddingTop32 text-center'>
                    <div className='textColorBlack fontSize24 montserratFont fontMedium'>Created Successfully!</div>
                    <div className='fontSize16 textColor333'>You have successfully created your Store</div>
                </div>
            </div>} />

            <CustomModal classes="customeProgressModal" open={openFileViewerModal} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>{viewFile?.doc_name}</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenFileViewerModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24' style={{ height: '80vh' }}>
                        {getFileType(viewFile?.doc_name) === 'pdf' ?
                            // <PdfViewer pdfUrl={`${proxyUrl}${viewFile?.doc_url}`} />
                            <object data={viewFile?.doc_url} type="application/pdf" width="100%" height="100%">
                                <p>Alternative text - include a link <a href={viewFile?.doc_url}>to the PDF!</a></p>
                            </object>
                            :
                            <FileRenderer file={viewFile?.doc_url} fileType={getFileType(viewFile?.doc_name)} />
                        }
                    </div>
                </div>} />

            <CustomModal open={showViewMoreDataPopup} isCloseIcon={true} style={uploadFileModalStyle} handleClose={() => { setShowViewMoreDataPopup(false) }} content={
                <div>
                    <div className='text-center'>
                        <div className='fontSize16 textColor333 customScroll' style={{ textAlign: 'left', maxHeight: '400px', overflow: 'auto' }}>
                            {
                                // storeDetailData?.brands?.map((brand, brandIndex) => (
                                storeDetailData?.brands?.length > 2 && storeDetailData?.brands[brandViewMoreIndex]?.staff_details?.map((staff, staffIndex) => (
                                    <li key={`${staffIndex}`}>{staff.brand_staff_name}</li>
                                ))
                                // ))
                            }
                        </div>
                    </div>
                </div>}
            />
            {loading && <Loader />}
        </div>
    )
}

export default ViewStoreDetails