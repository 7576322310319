import axios from "axios";
import API_CONFIG from "../../Configurations/ApiDeclaration";

export function GetBrandList(param, token) {
    const URL = API_CONFIG.GET_BRAND_LIST + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function UploadBrandList(formdata, token) {
    const URL = API_CONFIG.CREATE_BRAND_LIST;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, formdata, headers);
}
export function UploadBrandMasterList(formdata, token) {
    const URL = API_CONFIG.UPLOAD_BRAND_MASTER_DATA;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, formdata, headers);
}
export function UpdateBrandStatus(param, token) {
    const URL = API_CONFIG.UPDATE_BRAND_STATUS + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.put(URL, '', headers);
}
export function UpdateBrandDetails(requestBody, token) {
    const URL = API_CONFIG.UPDATE_BRAND_DETAILS;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.patch(URL, requestBody, headers);
}
export function GetBrandDasboardData(requestBody, token) {
    const URL = API_CONFIG.GET_BRAND_DASHBOARD_DATA;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}
export function DownaloadBrandList(requestBody, token) {
    const URL = API_CONFIG.DOWNLOAD_BRAND_LIST;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}
export function DownaloadBrandItemMaster(param, token) {
    const URL = API_CONFIG.DOWNLOAD_BRAND_MASTER_DATA + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetBrandItemMaster(param, token) {
    const URL = API_CONFIG.GET_BRAND_MASTER_DATA + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function getAllBrands(param, token) {
    const URL = API_CONFIG.GET_ALL_BRANDS + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}

export function creatBrands(requestBody, token) {
    const URL = API_CONFIG.CREAT_BRAND;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}

export function updateBrandDetailsBasedOnBrandId(requestBody, param, token) {
    const URL = API_CONFIG.UPDATE_BRAND_DETAILS + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.patch(URL, requestBody, headers);
}

export function getCreateBrandDropdownList(param, token) {
    const URL = API_CONFIG.GET_CREATE_BRAND_DROPDOWN_LIST + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}

export function getListStateCityByPincode(param, token) {
    const URL = API_CONFIG.GET_LIST_STATE_CITY_BY_PINCODE + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}

export function fileUpload(requestBody, token) {
    const URL = API_CONFIG.FILE_UPLOAD;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}


