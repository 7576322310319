import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    defaultYear: '',
    defaultBrand: '',
};

const stockSlice = createSlice({
    name: 'stock',
    initialState,
    reducers: {
        setDefaultYear: (state, action) => {
            state.defaultYear = action.payload.type
        },
        setDefaultBrand: (state, action) => {
            state.defaultBrand = action.payload.type
        },
    }
});

export const stockActions = stockSlice.actions;
export default stockSlice;