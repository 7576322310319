import React, { useEffect, useState, useRef } from 'react';
import CustomHorizontalStepper from '../../../ReUsableComponent/CustomHorizontalStepper';
import RightArrowIcon from '../../../assets/svg/rightArrow.svg';
import { Link } from 'react-router-dom';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import '../storeManagement.css';
import ProgressBarWithLabel from '../../../ReUsableComponent/ProgressBarWithLabel';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import SuccessPopup from '../../../ReUsableComponent/SuccessPopupMsg';
import Loader from '../../../ReUsableComponent/Loader';
import '../../TeamManagement/TeamManagement.css';
import CreateStoreInputForm from './CreateStoreInputForm';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { storeManagementActions } from '../../../store/reducers/StoreManagementReducer';
import { createStore, updateStoreDetailsBasedOnStoreId, fileUpload } from './../StoreManagent.service';
import { isFileDetailsEmpty, allMandatoryFieldsFilledStore } from './../../../ReUsableComponent/CustomFormValidation';

const CreateStore = () => {
    const timeoutRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const steps = ['Owners Details', 'Store Demographic', 'Parent Store', 'Store Contact', 'Store Contract', 'Store Wall', 'Addresses', 'Upload Documents'];
    const STATUS = [{ label: 'Active', value: true }, { label: 'Inactive', value: false }];

    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(true);

    const createdBy = useSelector((state) => state.storeManagement.storeCreatedBy);
    const createStoreOwnerDetails = useSelector((state) => state.storeManagement.createStoreOwnerDetails);
    const getStoreDetails = useSelector((state) => state.storeManagement.createStoreDetails);
    const getActiveStep = useSelector((state) => state.storeManagement.stepActiveState);
    const getProgress = useSelector((state) => state.storeManagement.stepProgress);

    const [activeStep, setActiveStep] = useState(getActiveStep);
    // const [activeStep, setActiveStep] = useState(6);
    const [progress, setProgress] = useState(getProgress);
    const [skipped, setSkipped] = useState(new Set());

    const [loading, setLoading] = useState(false);

    const [fileUploadprogress, setFileUploadProgress] = useState(0);
    const [cof, setCof] = useState('');
    const [storeOpeningDate, setStoreOpeningDate] = useState(null);
    const [uploadSpeed, setUploadSpeed] = useState(null);
    const [startTime, setStartTime] = useState(null);


    const [ownerDetailsFormError, setOwnerDetailsFormError] = useState({
        contact_detail: {},
        address: {},
    });
    const [storeDemographicFormError, setStoreDemographicFormError] = useState({
        contact_detail: {},
        address: {},
    });
    const [parentStoreFormError, setParentStoreFormError] = useState({});
    const [contactDetailsFormError, setContactDetailsFormError] = useState({});
    const [storeContractDetailsFormError, setStoreContractDetailsFormError] = useState({});
    const [storeWallBrandsDetailsFormError, setStoreWallBrandsDetailsFormError] = useState({});
    const [storeAddressesDetailsFormError, setStoreAddressesDetailsFormError] = useState({
        billing_place_address: {},
        shipping_place_address: {},
        warehouse_address: {},
        return_warehouse_address: {},
    });
    // const [brandStaffDetailsFormError, setBrandStaffDetailsFormError]= useState({});




    const [ownerDetails, setOwnerDetails] = useState({
        constitution_of_firm: createStoreOwnerDetails?.owner_detail?.constitution_of_firm ? createStoreOwnerDetails?.owner_detail?.constitution_of_firm : '',
        authorise_signitory_name: createStoreOwnerDetails?.owner_detail?.authorise_signitory_name ? createStoreOwnerDetails?.owner_detail?.authorise_signitory_name : '',
        business_incharge_name: createStoreOwnerDetails?.owner_detail?.business_incharge_name ? createStoreOwnerDetails?.owner_detail?.business_incharge_name : '',
        business_incharge_mobile_number: createStoreOwnerDetails?.owner_detail?.business_incharge_mobile_number ? createStoreOwnerDetails?.owner_detail?.business_incharge_mobile_number : '',
        contact_detail: {
            email: createStoreOwnerDetails?.owner_detail?.contact_detail?.email ? createStoreOwnerDetails?.owner_detail?.contact_detail?.email : '',
            mobile: createStoreOwnerDetails?.owner_detail?.contact_detail?.mobile ? createStoreOwnerDetails?.owner_detail?.contact_detail?.mobile : '',
            person_name: createStoreOwnerDetails?.owner_detail?.contact_detail?.person_name ? createStoreOwnerDetails?.owner_detail?.contact_detail?.person_name : '',
        },
        address: {
            address: createStoreOwnerDetails?.owner_detail?.address?.address ? createStoreOwnerDetails?.owner_detail?.address?.address : '',
            pincode: createStoreOwnerDetails?.owner_detail?.address?.pincode ? createStoreOwnerDetails?.owner_detail?.address?.pincode : '',
            region: createStoreOwnerDetails?.owner_detail?.address?.region ? createStoreOwnerDetails?.owner_detail?.address?.region : '',
            state: createStoreOwnerDetails?.owner_detail?.address?.state ? createStoreOwnerDetails?.owner_detail?.address?.state : '',
            city: createStoreOwnerDetails?.owner_detail?.address?.city ? createStoreOwnerDetails?.owner_detail?.address?.city : '',
            area: createStoreOwnerDetails?.owner_detail?.address?.area ? createStoreOwnerDetails?.owner_detail?.address?.area : '',
        }
    });
    const [storeDemographic, setStoreDemographic] = useState({
        customer_name: createStoreOwnerDetails?.customer_name ? createStoreOwnerDetails?.customer_name : '',
        store_legal_name: createStoreOwnerDetails?.store_legal_name ? createStoreOwnerDetails?.store_legal_name : '',
        store_trade_name: createStoreOwnerDetails?.store_trade_name ? createStoreOwnerDetails?.store_trade_name : '',
        latitude: createStoreOwnerDetails?.latitude ? createStoreOwnerDetails?.latitude : '',
        longitude: createStoreOwnerDetails?.longitude ? createStoreOwnerDetails?.longitude : '',
        store_status: createStoreOwnerDetails?.store_status ? createStoreOwnerDetails?.store_status : true,
        store_opening: createStoreOwnerDetails?.store_opening ? createStoreOwnerDetails?.store_opening : '',
        store_opening_status: createStoreOwnerDetails?.store_opening_status ? createStoreOwnerDetails?.store_opening_status : '',
        store_type: createStoreOwnerDetails?.store_type ? createStoreOwnerDetails?.store_type : '',
        store_classification: createStoreOwnerDetails?.store_classification ? createStoreOwnerDetails?.store_classification : [],
        store_class: createStoreOwnerDetails?.store_class ? createStoreOwnerDetails?.store_class : '',
        store_retail_billing: createStoreOwnerDetails?.store_retail_billing ? createStoreOwnerDetails?.store_retail_billing : '',
        store_details: createStoreOwnerDetails?.store_details ? createStoreOwnerDetails?.store_details : '',
        cust_no: createStoreOwnerDetails?.cust_no ? createStoreOwnerDetails?.cust_no : '',
        gstin: createStoreOwnerDetails?.gstin ? createStoreOwnerDetails?.gstin : '',
        gst_slab: createStoreOwnerDetails?.gst_slab ? createStoreOwnerDetails?.gst_slab : '',
        address: {
            address: createStoreOwnerDetails?.store_address?.address ? createStoreOwnerDetails?.store_address?.address : '',
            pincode: createStoreOwnerDetails?.store_address?.pincode ? createStoreOwnerDetails?.store_address?.pincode : '',
            region: createStoreOwnerDetails?.store_address?.region ? createStoreOwnerDetails?.store_address?.region : '',
            state: createStoreOwnerDetails?.store_address?.state ? createStoreOwnerDetails?.store_address?.state : '',
            city: createStoreOwnerDetails?.store_address?.city ? createStoreOwnerDetails?.store_address?.city : '',
            area: createStoreOwnerDetails?.store_address?.area ? createStoreOwnerDetails?.store_address?.area : '',
            email: createStoreOwnerDetails?.store_address?.email ? createStoreOwnerDetails?.store_address?.email : '',
            mobile: createStoreOwnerDetails?.store_address?.mobile ? createStoreOwnerDetails?.store_address?.mobile : '',
        },
        city_tier: createStoreOwnerDetails?.city_tier ? createStoreOwnerDetails?.city_tier : '',
        regional_manager: createStoreOwnerDetails?.regional_manager ? createStoreOwnerDetails?.regional_manager : '',
        pan_number: createStoreOwnerDetails?.pan_number ? createStoreOwnerDetails?.pan_number : '',

        // need to change in backend
        store_floor_size: 0,
        // store_floor_size: createStoreOwnerDetails?.store_floor_size ? createStoreOwnerDetails?.store_floor_size : '',
    });
    const [parentStore, setParentStore] = useState({
        parent_store_id: createStoreOwnerDetails?.parent_store_id ? createStoreOwnerDetails?.parent_store_id : '',
        parent_store_name: createStoreOwnerDetails?.parent_store_name ? createStoreOwnerDetails?.parent_store_name : '',
        parent_store_gstin: createStoreOwnerDetails?.parent_store_gstin ? createStoreOwnerDetails?.parent_store_gstin : '',
    });

    const [contactDetails, setContactDetails] = useState(
        [{
            email: createStoreOwnerDetails?.store_contact_detail?.email ? createStoreOwnerDetails?.store_contact_detail?.email : '',
            phone_number: createStoreOwnerDetails?.store_contact_detail?.phone_number ? createStoreOwnerDetails?.store_contact_detail?.phone_number : '',
            mobile: createStoreOwnerDetails?.store_contact_detail?.mobile ? createStoreOwnerDetails?.store_contact_detail?.mobile : '',
            person_name: createStoreOwnerDetails?.store_contact_detail?.person_name ? createStoreOwnerDetails?.store_contact_detail?.person_name : '',
            person_dept: createStoreOwnerDetails?.store_contact_detail?.person_dept ? createStoreOwnerDetails?.store_contact_detail?.person_dept : '',
            person_other: createStoreOwnerDetails?.store_contact_detail?.person_other ? createStoreOwnerDetails?.store_contact_detail?.person_other : '',
        }]
    );
    const [storeContractDetails, setStoreContractDetails] = useState({
        distribution_type: createStoreOwnerDetails?.distribution_type ? createStoreOwnerDetails?.distribution_type : '',
        channel: createStoreOwnerDetails?.channel ? createStoreOwnerDetails?.channel : '',
        fresh_margin_type: createStoreOwnerDetails?.fresh_margin_type ? createStoreOwnerDetails?.fresh_margin_type : '',
        fresh_margin: createStoreOwnerDetails?.fresh_margin ? createStoreOwnerDetails?.fresh_margin : '',
        eoss_margin_type: createStoreOwnerDetails?.eoss_margin_type ? createStoreOwnerDetails?.eoss_margin_type : '',
        eoss_margin: createStoreOwnerDetails?.eoss_margin ? createStoreOwnerDetails?.eoss_margin : '',
        eoss_threshold_value: createStoreOwnerDetails?.eoss_threshold_value ? createStoreOwnerDetails?.eoss_threshold_value : '',
        base_margin: createStoreOwnerDetails?.base_margin ? createStoreOwnerDetails?.base_margin : '',
        markdown_percentage_type: createStoreOwnerDetails?.markdown_percentage_type ? createStoreOwnerDetails?.markdown_percentage_type : '',
        markdown_percentage: createStoreOwnerDetails?.markdown_percentage ? createStoreOwnerDetails?.markdown_percentage : '',
        discount: createStoreOwnerDetails?.discount ? createStoreOwnerDetails?.discount : '',
        discount_percentage: createStoreOwnerDetails?.discount_percentage ? createStoreOwnerDetails?.discount_percentage : '',
        target_linked_insentives: createStoreOwnerDetails?.target_linked_insentives ? createStoreOwnerDetails?.target_linked_insentives : '',
        target_linked_insentives_percentagre: createStoreOwnerDetails?.target_linked_insentives_percentagre ? createStoreOwnerDetails?.target_linked_insentives_percentagre : '',
        cash_discount: createStoreOwnerDetails?.cash_discount ? createStoreOwnerDetails?.cash_discount : '',
        cash_discount_type: createStoreOwnerDetails?.cash_discount_type ? createStoreOwnerDetails?.cash_discount_type : '',
        cash_discount_percentage: createStoreOwnerDetails?.cash_discount_percentage ? createStoreOwnerDetails?.cash_discount_percentage : '',
        payable_duration: createStoreOwnerDetails?.payable_duration ? createStoreOwnerDetails?.payable_duration : '',
        stock_correction: createStoreOwnerDetails?.stock_correction ? createStoreOwnerDetails?.stock_correction : '',
        promo_contribution: createStoreOwnerDetails?.promo_contribution ? createStoreOwnerDetails?.promo_contribution : '',
        is_security: createStoreOwnerDetails?.is_security ? createStoreOwnerDetails?.is_security : '',
        // Need To ask
        comments: createStoreOwnerDetails?.security_comments ? createStoreOwnerDetails?.security_comments : '',
        credit_limit: createStoreOwnerDetails?.credit_limit ? createStoreOwnerDetails?.credit_limit : '',
        business_model: createStoreOwnerDetails?.business_model ? createStoreOwnerDetails?.business_model : [],
        store_floor_size: createStoreOwnerDetails?.store_floor_size ? createStoreOwnerDetails?.store_floor_size : '',
        primary_billing_discount: 0,
    });
    const [storeWallBrandsDetails, setStoreWallBrandsDetails] = useState(
        [{
            brand_id: createStoreOwnerDetails?.brands?.id ? createStoreOwnerDetails?.brands?.id : "",
            brand_name: createStoreOwnerDetails?.brands?.brand_name ? createStoreOwnerDetails?.brands?.brand_name : "",
            wall_space: createStoreOwnerDetails?.brands?.wall_space ? createStoreOwnerDetails?.brands?.wall_space : '',
            enter_cdu: createStoreOwnerDetails?.brands?.enter_cdu ? createStoreOwnerDetails?.brands?.enter_cdu : '',
            default_length: createStoreOwnerDetails?.brands?.default_length ? createStoreOwnerDetails?.brands?.default_length : '',
            total_sq_ft: createStoreOwnerDetails?.brands?.total_sq_ft ? createStoreOwnerDetails?.brands?.total_sq_ft : '',
            display_options: createStoreOwnerDetails?.brands?.display_options ? createStoreOwnerDetails?.brands?.display_options : '',
            per_running_feet: createStoreOwnerDetails?.brands?.per_running_feet ? createStoreOwnerDetails?.brands?.per_running_feet : '',
            floor_placement: createStoreOwnerDetails?.brands?.floor_placement ? createStoreOwnerDetails?.brands?.floor_placement : [],
            category_group: createStoreOwnerDetails?.brands?.category_group ? createStoreOwnerDetails?.brands?.category_group : [],
            display_qty: createStoreOwnerDetails?.brands?.display_qty ? createStoreOwnerDetails?.brands?.display_qty : '',
            depth_per_option: createStoreOwnerDetails?.brands?.depth_per_option ? createStoreOwnerDetails?.brands?.depth_per_option : '',
            category_division: createStoreOwnerDetails?.brands?.category_division ? createStoreOwnerDetails?.brands?.category_division : [],
            category_presence: createStoreOwnerDetails?.brands?.category_presence ? createStoreOwnerDetails?.brands?.category_presence : [],
            is_brand_staff: createStoreOwnerDetails?.brands?.is_brand_staff ? createStoreOwnerDetails?.brands?.is_brand_staff : false,
            brand_staff_name: createStoreOwnerDetails?.brands?.brand_staff_name ? createStoreOwnerDetails?.brands?.brand_staff_name : '',
            brand_staff_terms: createStoreOwnerDetails?.brands?.brand_staff_terms ? createStoreOwnerDetails?.brands?.brand_staff_terms : '',
        }]
    );
    const [storeAddressesDetails, setStoreAddressesDetails] = useState({
        billing_place_address: {
            address: createStoreOwnerDetails?.billing_place_address?.address ? createStoreOwnerDetails?.billing_place_address?.address : '',
            pincode: createStoreOwnerDetails?.billing_place_address?.pincode ? createStoreOwnerDetails?.billing_place_address?.pincode : '',
            region: createStoreOwnerDetails?.billing_place_address?.region ? createStoreOwnerDetails?.billing_place_address?.region : '',
            state: createStoreOwnerDetails?.billing_place_address?.state ? createStoreOwnerDetails?.billing_place_address?.state : '',
            city: createStoreOwnerDetails?.billing_place_address?.city ? createStoreOwnerDetails?.billing_place_address?.city : '',
            area: createStoreOwnerDetails?.billing_place_address?.area ? createStoreOwnerDetails?.billing_place_address?.area : '',
            email: createStoreOwnerDetails?.billing_place_address?.email ? createStoreOwnerDetails?.billing_place_address?.email : '',
            phone_number: createStoreOwnerDetails?.billing_place_address?.phone_number ? createStoreOwnerDetails?.billing_place_address?.phone_number : '',
            mobile: createStoreOwnerDetails?.billing_place_address?.mobile ? createStoreOwnerDetails?.billing_place_address?.mobile : '',
            person_name: createStoreOwnerDetails?.billing_place_address?.person_name ? createStoreOwnerDetails?.billing_place_address?.person_name : '',
            person_dept: createStoreOwnerDetails?.billing_place_address?.person_dept ? createStoreOwnerDetails?.billing_place_address?.person_dept : '',
            person_other: createStoreOwnerDetails?.billing_place_address?.person_other ? createStoreOwnerDetails?.billing_place_address?.person_other : '',
        },
        shipping_place_address: {
            address: createStoreOwnerDetails?.shipping_place_address?.address ? createStoreOwnerDetails?.shipping_place_address?.address : '',
            pincode: createStoreOwnerDetails?.shipping_place_address?.pincode ? createStoreOwnerDetails?.shipping_place_address?.pincode : '',
            region: createStoreOwnerDetails?.shipping_place_address?.region ? createStoreOwnerDetails?.shipping_place_address?.region : '',
            state: createStoreOwnerDetails?.shipping_place_address?.state ? createStoreOwnerDetails?.shipping_place_address?.state : '',
            city: createStoreOwnerDetails?.shipping_place_address?.city ? createStoreOwnerDetails?.shipping_place_address?.city : '',
            area: createStoreOwnerDetails?.shipping_place_address?.area ? createStoreOwnerDetails?.shipping_place_address?.area : '',
            email: createStoreOwnerDetails?.shipping_place_address?.email ? createStoreOwnerDetails?.shipping_place_address?.email : '',
            phone_number: createStoreOwnerDetails?.shipping_place_address?.phone_number ? createStoreOwnerDetails?.shipping_place_address?.phone_number : '',
            mobile: createStoreOwnerDetails?.shipping_place_address?.mobile ? createStoreOwnerDetails?.shipping_place_address?.mobile : '',
            person_name: createStoreOwnerDetails?.shipping_place_address?.person_name ? createStoreOwnerDetails?.shipping_place_address?.person_name : '',
            person_dept: createStoreOwnerDetails?.shipping_place_address?.person_dept ? createStoreOwnerDetails?.shipping_place_address?.person_dept : '',
            person_other: createStoreOwnerDetails?.shipping_place_address?.person_other ? createStoreOwnerDetails?.shipping_place_address?.person_other : '',
        },
        warehouse_address: {
            address: createStoreOwnerDetails?.warehouse_address?.address ? createStoreOwnerDetails?.warehouse_address?.address : '',
            pincode: createStoreOwnerDetails?.warehouse_address?.pincode ? createStoreOwnerDetails?.warehouse_address?.pincode : '',
            region: createStoreOwnerDetails?.warehouse_address?.region ? createStoreOwnerDetails?.warehouse_address?.region : '',
            state: createStoreOwnerDetails?.warehouse_address?.state ? createStoreOwnerDetails?.warehouse_address?.state : '',
            city: createStoreOwnerDetails?.warehouse_address?.city ? createStoreOwnerDetails?.warehouse_address?.city : '',
            area: createStoreOwnerDetails?.warehouse_address?.area ? createStoreOwnerDetails?.warehouse_address?.area : '',
            email: createStoreOwnerDetails?.warehouse_address?.email ? createStoreOwnerDetails?.warehouse_address?.email : '',
            phone_number: createStoreOwnerDetails?.warehouse_address?.phone_number ? createStoreOwnerDetails?.warehouse_address?.phone_number : '',
            mobile: createStoreOwnerDetails?.warehouse_address?.mobile ? createStoreOwnerDetails?.warehouse_address?.mobile : '',
            person_name: createStoreOwnerDetails?.warehouse_address?.person_name ? createStoreOwnerDetails?.warehouse_address?.person_name : '',
            person_dept: createStoreOwnerDetails?.warehouse_address?.person_dept ? createStoreOwnerDetails?.warehouse_address?.person_dept : '',
            person_other: createStoreOwnerDetails?.warehouse_address?.person_other ? createStoreOwnerDetails?.warehouse_address?.person_other : '',
        },
        return_warehouse_address: {
            address: createStoreOwnerDetails?.return_warehouse_address?.address ? createStoreOwnerDetails?.return_warehouse_address?.address : '',
            pincode: createStoreOwnerDetails?.return_warehouse_address?.pincode ? createStoreOwnerDetails?.return_warehouse_address?.pincode : '',
            region: createStoreOwnerDetails?.return_warehouse_address?.region ? createStoreOwnerDetails?.return_warehouse_address?.region : '',
            state: createStoreOwnerDetails?.return_warehouse_address?.state ? createStoreOwnerDetails?.return_warehouse_address?.state : '',
            city: createStoreOwnerDetails?.return_warehouse_address?.city ? createStoreOwnerDetails?.return_warehouse_address?.city : '',
            area: createStoreOwnerDetails?.return_warehouse_address?.area ? createStoreOwnerDetails?.return_warehouse_address?.area : '',
            email: createStoreOwnerDetails?.return_warehouse_address?.email ? createStoreOwnerDetails?.return_warehouse_address?.email : '',
            phone_number: createStoreOwnerDetails?.return_warehouse_address?.phone_number ? createStoreOwnerDetails?.return_warehouse_address?.phone_number : '',
            mobile: createStoreOwnerDetails?.return_warehouse_address?.mobile ? createStoreOwnerDetails?.return_warehouse_address?.mobile : '',
            person_name: createStoreOwnerDetails?.return_warehouse_address?.person_name ? createStoreOwnerDetails?.return_warehouse_address?.person_name : '',
            person_dept: createStoreOwnerDetails?.return_warehouse_address?.person_dept ? createStoreOwnerDetails?.return_warehouse_address?.person_dept : '',
            person_other: createStoreOwnerDetails?.return_warehouse_address?.person_other ? createStoreOwnerDetails?.return_warehouse_address?.person_other : '',
        },
    });
    // const [brandStaffDetails, setBrandStaffDetails] = useState({
    //     is_brand_staff: createStoreOwnerDetails?.brand_staff_info?.is_brand_staff ? createStoreOwnerDetails?.brand_staff_info?.is_brand_staff : true,
    //     brand_staff_name: createStoreOwnerDetails?.brand_staff_info?.brand_staff_name ? createStoreOwnerDetails?.brand_staff_info?.brand_staff_name : '',
    //     brand_staff_terms: createStoreOwnerDetails?.brand_staff_info?.brand_staff_terms ? createStoreOwnerDetails?.brand_staff_info?.brand_staff_terms : '',
    // })
    const [uploadedFiles, setUploadedFiles] = useState(createStoreOwnerDetails?.document_details ? createStoreOwnerDetails?.document_details : [{
        store_id: '',
        brand_id: '',
        brand_name: '',
        file_id: '',
        doc_name: '',
        doc_url: '',
        size: '',
        created_at: '',
        doc_type: '',
    }]);


    const [mouAggrementFile, setMouAggrementFile] = useState(
        {
            store_id: '',
            brand_id: '',
            brand_name: '',
            file_id: '',
            doc_name: '',
            doc_url: '',
            size: '',
            created_at: '',
            doc_type: '',
        }
    );
    const [gstDocumentParentStoreFile, setGstDocumentParentStoreFile] = useState(
        {
            store_id: '',
            brand_id: '',
            brand_name: '',
            file_id: '',
            doc_name: '',
            doc_url: '',
            size: '',
            created_at: '',
            doc_type: '',
        }
    );
    const [gstDocumentStoreFile, setGstDocumentStoreFile] = useState(
        {
            store_id: '',
            brand_id: '',
            brand_name: '',
            file_id: '',
            doc_name: '',
            doc_url: '',
            size: '',
            created_at: '',
            doc_type: '',
        }
    );
    const [panDocumentFile, setPanDocumentFile] = useState(
        {
            store_id: '',
            brand_id: '',
            brand_name: '',
            file_id: '',
            doc_name: '',
            doc_url: '',
            size: '',
            created_at: '',
            doc_type: '',
        }
    );
    const [uploadStorePictureFile, setUploadStorePictureFile] = useState(
        {
            store_id: '',
            brand_id: '',
            brand_name: '',
            file_id: '',
            doc_name: '',
            doc_url: '',
            size: '',
            created_at: '',
            doc_type: '',
        }
    );
    const [storeFacadeFile, setStoreFacadeFile] = useState(
        {
            store_id: '',
            brand_id: '',
            brand_name: '',
            file_id: '',
            doc_name: '',
            doc_url: '',
            size: '',
            created_at: '',
            doc_type: '',
        }
    );
    const [brandFloorSpaceFile, setBrandFloorSpaceFile] = useState(
        {
            store_id: '',
            brand_id: '',
            brand_name: '',
            file_id: '',
            doc_name: '',
            doc_url: '',
            size: '',
            created_at: '',
            doc_type: '',
        }
    );
    const [adjacentFile, setAdjacentFile] = useState(
        {
            store_id: '',
            brand_id: '',
            brand_name: '',
            file_id: '',
            doc_name: '',
            doc_url: '',
            size: '',
            created_at: '',
            doc_type: '',
        }
    );
    const [otherFile, setOtherFile] = useState(
        {
            store_id: '',
            brand_id: '',
            brand_name: '',
            file_id: '',
            doc_name: '',
            doc_url: '',
            size: '',
            created_at: '',
            doc_type: '',
        }
    );

    const [isDiscount, setIsDiscount] = useState(true);
    const [isTargetLinkedInsentives, setIsTargetLinkedInsentives] = useState(true);
    const [isCashDiscount, setIsCashDiscount] = useState(true);

    const [categoryGroupDrop, setCategoryGroupDrop] = useState([[]]);
    const [categoryPresenceDrop, setCategoryPresenceDrop] = useState([[]]);

    const addNewContactDetailsForm = () => {
        const newContact = {
            email: '',
            phone_number: '',
            mobile: '',
            person_name: '',
            person_dept: '',
            person_other: '',
        };

        if (contactDetails?.length <= 4) {
            setContactDetails([...contactDetails, newContact]);
        }
    }

    const addNewStoreWallBrandsDetailsForm = () => {
        const newObject = {
            brand_id: "",
            brand_name: "",
            wall_space: '',
            enter_cdu: '',
            default_length: '',
            total_sq_ft: '',
            display_options: '',
            per_running_feet: '',
            floor_placement: [],
            category_group: [],
            display_qty: '',
            depth_per_option: '',
            category_division: [],
            category_presence: [],
            is_brand_staff: false,
            brand_staff_name: '',
            brand_staff_terms: '',
        }
        if (storeWallBrandsDetails?.length <= 4) {
            setStoreWallBrandsDetails([...storeWallBrandsDetails, newObject]);
            let newCategoryGroupDrop = [];
            let newCategoryPresenceDrop = [];
            setCategoryGroupDrop([...categoryGroupDrop, newCategoryGroupDrop]);
            setCategoryPresenceDrop([...categoryPresenceDrop, newCategoryPresenceDrop]);
        }
    }

    const capitalize = (str) => str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();




    const isStepOptional = (step) => {
        // return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = async () => {
        let isValid = false;
        if (activeStep === 0) {
            isValid = validateOwnerDetails();
            // isValid = true;
            if (isValid) {
                await handleCreateStore();
            }
        } else if (activeStep === 1) {
            isValid = validateStoreDemographic();
            // isValid = true;
            if (isValid) {
                await handleUpdateBrandDetailsBasedOnStoreId(createStoreOwnerDetails?._id, '');
            }
        } else if (activeStep === 2) {
            isValid = validateParentStore();
            // isValid = true;
            if (isValid) {
                await handleUpdateBrandDetailsBasedOnStoreId(createStoreOwnerDetails?._id, '');
            }
        } else if (activeStep === 3) {
            isValid = validateContactDetails();
            // isValid = true;
            if (isValid) {
                await handleUpdateBrandDetailsBasedOnStoreId(createStoreOwnerDetails?._id, '');
            }
        } else if (activeStep === 4) {
            isValid = validateStoreContractDetails();
            // isValid = true;
            if (isValid) {
                await handleUpdateBrandDetailsBasedOnStoreId(createStoreOwnerDetails?._id, '');
            }
        } else if (activeStep === 5) {
            isValid = validateStoreWallBrandsDetails();
            // isValid = true
            if (isValid) {
                await handleUpdateBrandDetailsBasedOnStoreId(createStoreOwnerDetails?._id, '');
            }
        } else if (activeStep === 6) {
            isValid = validateStoreAddressesDetails();
            // isValid = true
            if (isValid) {
                await handleUpdateBrandDetailsBasedOnStoreId(createStoreOwnerDetails?._id, '');
            }
        }
        // else if (activeStep === 7) {
        //     // isValid = validateBrandStaffDetails();
        //     isValid = true
        //     if (isValid) {
        //         await handleUpdateBrandDetailsBasedOnStoreId(createStoreOwnerDetails?._id, '');
        //     }
        // } 
        else if (activeStep === 7) {
            isValid = true;
            if (isValid) {
                await await handleUpdateBrandDetailsBasedOnStoreId(createStoreOwnerDetails?._id, 'uploadButton');
            }
        }

        if (isValid) {
            // if ((activeStep + 1) < steps.length) {
            //     setActiveStep(prev => prev + 1);
            // }
            // if (progress < 100) {
            //     let formCompleted = 100 / steps.length;
            //     setProgress(prev => prev + formCompleted);
            // }
        } else {
            alert('Please fill in all required fields');
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
        setProgress(0);
        setSkipped(new Set());
    };

    const onChangeStoreOpeningDate = (event) => {
        let { date } = event
    }

    const onChangeCOF = (event) => {
        setCof(event.target.value)
    }

    const handleFileDrop = (files) => {
        const fileUploaded = files[0];
        if (fileUploaded) {
            // setUploadedFile(fileUploaded);
            setFileUploadProgress(0);
            simulateUpload();
            // setOpenFileSelectedModal(true);
            // setOpenUploadFileModal(false);
        }
    };

    const simulateUpload = () => {
        // setUploadButtonDisabled(true);
        const interval = setInterval(() => {
            setFileUploadProgress((prevProgress) => {
                const nextProgress = prevProgress + 10;
                if (nextProgress >= 100) {
                    clearInterval(interval);
                    setUploadSpeed(null);
                    // setUploadButtonDisabled(false);
                    return 100;
                }
                if (!startTime) {
                    setStartTime(Date.now());
                }
                const elapsedTime = (Date.now() - startTime) / 1000; // in seconds
                const speed = (nextProgress / elapsedTime).toFixed(2); // in % per second
                setUploadSpeed(speed);
                return nextProgress;
            });
        }, 500);
    };

    const onClickCancel = () => {
        // alert('Cancel the form');
        dispatch(storeManagementActions.setStepActiveState({ type: 0 }));
        dispatch(storeManagementActions.setStepProgress({ type: 0 }));
        dispatch(storeManagementActions.setCreateStoreOwnerDetails({ type: {} }));
        history.push('/app/storeManagement');
    }


    // Validate Function call
    const validateOwnerDetails = () => {
        const { constitution_of_firm, authorise_signitory_name, business_incharge_name, business_incharge_mobile_number, contact_detail, address } = ownerDetails;
        return constitution_of_firm && authorise_signitory_name && business_incharge_name && business_incharge_mobile_number && contact_detail.email && contact_detail.mobile && contact_detail.person_name && address.address && address.pincode;
    };

    const validateStoreDemographic = () => {
        const { customer_name, store_legal_name, store_trade_name, latitude, longitude, store_status, store_opening, store_opening_status, store_type, store_retail_billing, cust_no, gstin, gst_slab, pan_number, contact_detail, address } = storeDemographic;
        return customer_name && store_legal_name && store_trade_name && latitude && longitude && store_opening && store_opening_status && store_type && store_retail_billing && cust_no && gstin && gst_slab && pan_number && address.email && address.mobile && address.address && address.pincode && address.region && address.state && address.city && address.area;
    };

    const validateParentStore = () => {
        const { parent_store_name, parent_store_gstin } = parentStore;
        return parent_store_name && parent_store_gstin;
    };

    const validateContactDetails = () => {
        const { person_name, person_dept, phone_number, email } = contactDetails[0];
        return person_name && person_dept && phone_number && email;
    };

    const validateStoreContractDetails = () => {
        const { distribution_type, channel, fresh_margin_type, fresh_margin, eoss_margin_type, eoss_margin, eoss_threshold_value, base_margin, markdown_percentage_type, markdown_percentage, discount, discount_percentage, target_linked_insentives, target_linked_insentives_percentagre, cash_discount, cash_discount_type, cash_discount_percentage, payable_duration, stock_correction, promo_contribution, is_security, comments, credit_limit, business_model } = storeContractDetails;
        return distribution_type && channel && fresh_margin_type && fresh_margin && eoss_margin_type && eoss_margin && eoss_threshold_value && base_margin && markdown_percentage_type && markdown_percentage && discount && target_linked_insentives && cash_discount && cash_discount_type && payable_duration && stock_correction && promo_contribution && is_security && comments && credit_limit && business_model;
    };

    const validateStoreWallBrandsDetails = () => {
        const { brand_id, brand_name, wall_space, enter_cdu, default_length, total_sq_ft, display_options, per_running_feet, floor_placement, category_group, display_qty, depth_per_option, category_division, category_presence } = storeWallBrandsDetails[0];
        return brand_id && brand_name && wall_space && category_group && category_division && category_presence;
    };

    const validateStoreAddressesDetails = () => {
        const { billing_place_address, shipping_place_address, warehouse_address, return_warehouse_address } = storeAddressesDetails
        return billing_place_address?.address && billing_place_address?.pincode && billing_place_address?.region && billing_place_address?.state && billing_place_address?.city && billing_place_address?.area && billing_place_address?.email && billing_place_address?.phone_number && billing_place_address?.mobile &&
            shipping_place_address?.address && shipping_place_address?.pincode && shipping_place_address?.region && shipping_place_address?.state && shipping_place_address?.city && shipping_place_address?.area && shipping_place_address?.email && shipping_place_address?.phone_number && shipping_place_address?.mobile &&
            warehouse_address?.address && warehouse_address?.pincode && warehouse_address?.region && warehouse_address?.state && warehouse_address?.city && warehouse_address?.area && warehouse_address?.email && warehouse_address?.phone_number && warehouse_address?.mobile &&
            return_warehouse_address?.address && return_warehouse_address?.pincode && return_warehouse_address?.region && return_warehouse_address?.state && return_warehouse_address?.city && return_warehouse_address?.area && return_warehouse_address?.email && return_warehouse_address?.phone_number && return_warehouse_address?.mobile;
    };

    // const validateBrandStaffDetails = () =>{
    //     const {is_brand_staff, brand_staff_name, brand_staff_terms} = brandStaffDetails;
    //     return is_brand_staff && brand_staff_name && brand_staff_terms;
    // };

    // const validateFileUploads = () => {
    //     const { storeFacade, brandFloorSpace, adjacent, other } = uploadedFiles;
    //     return storeFacade && brandFloorSpace && adjacent && other;
    // };




    // api call
    const handleCreateStore = async () => {
        setLoading(true);
        let requestBody = {
            // created_by: createdBy,
            owner_detail: ownerDetails,
            store_status: true,
        }
        createStore(requestBody, USER_TOKEN).then(response => {
            if (response.data.statusCode === 400) {
                ToastErrorService(response.data.message);
            } else if (response.data.statusCode === 200) {
                ToastSuccessService(response.data.message);
                dispatch(storeManagementActions.setCreateStoreOwnerDetails({ type: response?.data?.data }));
                handleUpdateBrandDetailsBasedOnStoreId(response?.data?.data?._id, '');
                if ((activeStep + 1) < steps.length) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    dispatch(storeManagementActions.setStepActiveState({ type: activeStep + 1 }));
                }
                if (progress < 100) {
                    let formCompleted = 100 / (steps.length)
                    setProgress(prev =>
                        prev += formCompleted
                    )
                    dispatch(storeManagementActions.setStepProgress({ type: progress + formCompleted }));
                }
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    };

    const uploadDocuments = async () => {
        const formData = new FormData();
        formData.append('storeFacade', uploadedFiles.storeFacade);
        formData.append('brandFloorSpace', uploadedFiles.brandFloorSpace);
        formData.append('adjacent', uploadedFiles.adjacent);
        formData.append('other', uploadedFiles.other);

        try {
            await fetch('/api/uploadDocuments', {
                method: 'POST',
                body: formData,
            });
        } catch (error) {
            console.error('Error uploading documents:', error);
        }
    };

    useEffect(() => {
        // setOpenSuccessPopup(true);
        // addNewContactDetailsForm();
        // if(createStoreOwnerDetails?._id){
        //     if(activeStep === 8){
        //         handleUpdateBrandDetailsBasedOnStoreId(createStoreOwnerDetails?._id, 'uploadButton');
        //     }else{
        //         handleUpdateBrandDetailsBasedOnStoreId(createStoreOwnerDetails?._id, '');
        //     }
        // }


        // dispatch(storeManagementActions.setStepActiveState({ type: 0 }));
        // dispatch(storeManagementActions.setStepProgress({ type: 0 }));
        // dispatch(storeManagementActions.setCreateStoreOwnerDetails({ type: {} }));
    }, []);

    const handleUpdateBrandDetailsBasedOnStoreId = (storeId, uploadButton) => {


        const tempStoreContractDetails = {
            ...storeContractDetails,
            store_floor_size: Number(storeContractDetails?.store_floor_size),
            discount_percentage: isDiscount ? "" : storeContractDetails?.discount_percentage,
            target_linked_insentives_percentagre: isTargetLinkedInsentives ? "" : storeContractDetails?.target_linked_insentives_percentagre,
            cash_discount_percentage: isCashDiscount ? "" : storeContractDetails?.cash_discount_percentage,

            // discount: storeContractDetails?.discount ? (capitalize(storeContractDetails?.discount) === 'Yes' ? 'true' : 'false') : 'false',
            // target_linked_insentives: storeContractDetails?.target_linked_insentives ? (capitalize(storeContractDetails?.target_linked_insentives) === 'Yes' ? 'true' : 'false') : 'false',
            // cash_discount: storeContractDetails?.cash_discount ? (capitalize(storeContractDetails?.cash_discount) === 'Yes' ? 'true' : 'false') : 'false',
            // is_security: storeContractDetails?.is_security ? (capitalize(storeContractDetails?.is_security) === 'Yes' ? 'true' : 'false') : 'false',

            discount: storeContractDetails?.discount ? (capitalize(storeContractDetails?.discount) === 'Yes' ? true : false) : false,
            target_linked_insentives: storeContractDetails?.target_linked_insentives ? (capitalize(storeContractDetails?.target_linked_insentives) === 'Yes' ? true : false) : false,
            cash_discount: storeContractDetails?.cash_discount ? (capitalize(storeContractDetails?.cash_discount) === 'Yes' ? true : false) : false,
            is_security: storeContractDetails?.is_security ? (capitalize(storeContractDetails?.is_security) === 'Yes' ? true : false) : false,
        }

        const tempStoreWallBrandsDetails = storeWallBrandsDetails?.map(details => ({
            ...details,
            wall_space: Number(details?.wall_space),
            enter_cdu: Number(details?.enter_cdu),
            default_length: Number(details?.default_length),
            total_sq_ft: Number(details?.total_sq_ft),
            display_options: Number(details?.display_options),
            per_running_feet: Number(details?.per_running_feet),
            display_qty: Number(details?.display_qty),
            depth_per_option: Number(details?.depth_per_option),
        }));


        let param = `?store_id=${storeId}`;
        let reqBody = {
            owner_detail: ownerDetails,
            // store_demographic: tempStoreDemographic,
            store_demographic: storeDemographic,
            parent_store: parentStore,
            store_contact_detail: contactDetails,
            // store_contract:storeContractDetails,
            store_contract: tempStoreContractDetails,
            brands: tempStoreWallBrandsDetails,
            billing_place_address: storeAddressesDetails.billing_place_address,
            shipping_place_address: storeAddressesDetails.shipping_place_address,
            warehouse_address: storeAddressesDetails.warehouse_address,
            return_warehouse_address: storeAddressesDetails.return_warehouse_address,
            // brand_staff_info: brandStaffDetails,
            store_doc_details: uploadedFiles,
            updated_by: 'true',
            is_profile_completed: allMandatoryFieldsFilledStore(ownerDetails, storeDemographic, parentStore, contactDetails, storeContractDetails, storeWallBrandsDetails, storeAddressesDetails, mouAggrementFile, gstDocumentParentStoreFile, gstDocumentStoreFile, panDocumentFile) ? true : false,
        };
        updateStoreDetailsBasedOnStoreId(reqBody, param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                let responseData = response?.data?.data;
                dispatch(storeManagementActions.setCreateStoreOwnerDetails({ type: responseData }));
                if (activeStep !== 0) {
                    if ((activeStep + 1) < steps.length) {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        dispatch(storeManagementActions.setStepActiveState({ type: activeStep + 1 }));
                    }
                    if (progress < 100) {
                        let formCompleted = 100 / (steps.length)
                        setProgress(prev =>
                            prev += formCompleted
                        )
                        dispatch(storeManagementActions.setStepProgress({ type: progress + formCompleted }));
                    }
                    if (uploadButton === "uploadButton") {
                        setOpenSuccessPopup(true);
                        if (timeoutRef.current) {
                            clearTimeout(timeoutRef.current);
                        }
                        // Set a new timeout to automatically close the popup
                        timeoutRef.current = setTimeout(() => {
                            dispatch(storeManagementActions.setSelectedPage({ type: 1 }));
                            dispatch(storeManagementActions.setStepActiveState({ type: 0 }));
                            dispatch(storeManagementActions.setStepProgress({ type: 0 }));
                            dispatch(storeManagementActions.setCreateStoreOwnerDetails({ type: {} }));
                            history.push('/app/storeManagement');
                            setOpenSuccessPopup(false);
                        }, 5000)
                    }
                }
            } else if (response.data.statusCode === 404) {
                ToastErrorService(response.data.message);
                dispatch(storeManagementActions.setStepActiveState({ type: 0 }));
                dispatch(storeManagementActions.setStepProgress({ type: 0 }));
                dispatch(storeManagementActions.setCreateStoreOwnerDetails({ type: {} }));
            } else {
                ToastErrorService(response.data.message);
            }
        }).catch(error => {
            ToastErrorService(error);
        }).finally(() => {
            // setLoading(false);
        })
    }

    return (
        <div className='notoSansFont'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='breadCrumbs'>
                    <span className='fontLight'>
                        <Link className="breadCrumbs" to={'/app/storeDashboard'}>Home</Link>
                    </span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>Create Store</span>
                </div>
            </div>
            <div className='pt-3'>
                <div className='textColor333 pb-1'>You Have Completed <span className='textColorGreen fontBold'>{progress < 10 ? '0' : ''}{Math.round(progress)}%</span> Store Profile!</div>
                <ProgressBarWithLabel progress={progress} />
            </div>
            <div className='pt-3'>
                <CustomHorizontalStepper steps={steps} isStepSkipped={isStepSkipped} isStepOptional={isStepOptional} activeStep={activeStep} />
            </div>
            <div className='pt-3'>
                {/* <div className='bg-white createStoreFormCard'>{displayFormContent()}</div> */}
                <div className='bg-white createStoreFormCard documentUploadCont'>
                    <CreateStoreInputForm
                        createStoreOwnerDetails={createStoreOwnerDetails}
                        STATUS={STATUS}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        progress={progress}
                        setProgress={setProgress}
                        skipped={skipped}
                        setSkipped={setSkipped}

                        storeOpeningDate={storeOpeningDate}
                        setStoreOpeningDate={setStoreOpeningDate}

                        // function
                        onChangeStoreOpeningDate={onChangeStoreOpeningDate}
                        addNewContactDetailsForm={addNewContactDetailsForm}
                        addNewStoreWallBrandsDetailsForm={addNewStoreWallBrandsDetailsForm}

                        // Error Data
                        ownerDetailsFormError={ownerDetailsFormError}
                        setOwnerDetailsFormError={setOwnerDetailsFormError}
                        storeDemographicFormError={storeDemographicFormError}
                        setStoreDemographicFormError={setStoreDemographicFormError}
                        parentStoreFormError={parentStoreFormError}
                        setParentStoreFormError={setParentStoreFormError}
                        contactDetailsFormError={contactDetailsFormError}
                        setContactDetailsFormError={setContactDetailsFormError}
                        storeContractDetailsFormError={storeContractDetailsFormError}
                        setStoreContractDetailsFormError={setStoreContractDetailsFormError}
                        storeWallBrandsDetailsFormError={storeWallBrandsDetailsFormError}
                        setStoreWallBrandsDetailsFormError={setStoreWallBrandsDetailsFormError}
                        storeAddressesDetailsFormError={storeAddressesDetailsFormError}
                        setStoreAddressesDetailsFormError={setStoreAddressesDetailsFormError}
                        // brandStaffDetailsFormError={brandStaffDetailsFormError}
                        // setBrandStaffDetailsFormError={setBrandStaffDetailsFormError}

                        // data
                        ownerDetails={ownerDetails}
                        setOwnerDetails={setOwnerDetails}
                        storeDemographic={storeDemographic}
                        setStoreDemographic={setStoreDemographic}
                        parentStore={parentStore}
                        setParentStore={setParentStore}
                        contactDetails={contactDetails}
                        setContactDetails={setContactDetails}
                        storeContractDetails={storeContractDetails}
                        setStoreContractDetails={setStoreContractDetails}
                        storeWallBrandsDetails={storeWallBrandsDetails}
                        setStoreWallBrandsDetails={setStoreWallBrandsDetails}
                        storeAddressesDetails={storeAddressesDetails}
                        setStoreAddressesDetails={setStoreAddressesDetails}
                        // brandStaffDetails={brandStaffDetails}
                        // setBrandStaffDetails={setBrandStaffDetails}

                        uploadedFiles={uploadedFiles}
                        setUploadedFiles={setUploadedFiles}

                        mouAggrementFile={mouAggrementFile}
                        setMouAggrementFile={setMouAggrementFile}
                        gstDocumentParentStoreFile={gstDocumentParentStoreFile}
                        setGstDocumentParentStoreFile={setGstDocumentParentStoreFile}
                        gstDocumentStoreFile={gstDocumentStoreFile}
                        setGstDocumentStoreFile={setGstDocumentStoreFile}
                        panDocumentFile={panDocumentFile}
                        setPanDocumentFile={setPanDocumentFile}
                        uploadStorePictureFile={uploadStorePictureFile}
                        setUploadStorePictureFile={setUploadStorePictureFile}
                        storeFacadeFile={storeFacadeFile}
                        setStoreFacadeFile={setStoreFacadeFile}
                        brandFloorSpaceFile={brandFloorSpaceFile}
                        setBrandFloorSpaceFile={setBrandFloorSpaceFile}
                        adjacentFile={adjacentFile}
                        setAdjacentFile={setAdjacentFile}
                        otherFile={otherFile}
                        setOtherFile={setOtherFile}
                        isFileDetailsEmpty={isFileDetailsEmpty}

                        isDiscount={isDiscount}
                        setIsDiscount={setIsDiscount}
                        isCashDiscount={isCashDiscount}
                        setIsCashDiscount={setIsCashDiscount}
                        isTargetLinkedInsentives={isTargetLinkedInsentives}
                        setIsTargetLinkedInsentives={setIsTargetLinkedInsentives}

                        categoryGroupDrop={categoryGroupDrop}
                        setCategoryGroupDrop={setCategoryGroupDrop}
                        categoryPresenceDrop={categoryPresenceDrop}
                        setCategoryPresenceDrop={setCategoryPresenceDrop}
                    />
                </div>
            </div>

            <div className='pt-3'>
                <div className='bg-white continueBtn d-flex'>
                    {activeStep === 0 && <div className='pe-3'>
                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                    </div>}

                    {activeStep === 0 && <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleNext} disabled={
                        (ownerDetailsFormError === "" ? true : false) ||
                        (ownerDetailsFormError &&
                            (ownerDetailsFormError?.constitution_of_firm !== null ||
                                ownerDetailsFormError?.authorise_signitory_name !== null ||
                                ownerDetailsFormError?.business_incharge_name !== null ||
                                ownerDetailsFormError?.business_incharge_mobile_number !== null ||
                                ownerDetailsFormError?.contact_detail.email !== null ||
                                ownerDetailsFormError?.contact_detail.mobile !== null ||
                                ownerDetailsFormError?.contact_detail.person_name !== null ||
                                ownerDetailsFormError?.address.address !== null ||
                                ownerDetailsFormError?.address.pincode !== null ||
                                ownerDetails?.address?.region === '' ||
                                ownerDetails?.address?.state === '' ||
                                ownerDetails?.address?.city === '' ||
                                ownerDetails?.address?.area === ''
                            ))} title={`${activeStep !== 7 ? 'Confirm & Next' : 'Upload Document'}`} />}

                    {activeStep === 1 && <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleNext} disabled={
                        (storeDemographicFormError === "" ? true : false) ||
                        (storeDemographicFormError &&
                            (storeDemographicFormError?.customer_name !== null ||
                                storeDemographicFormError?.store_legal_name !== null ||
                                storeDemographicFormError?.store_trade_name !== null ||
                                storeDemographicFormError?.latitude !== null ||
                                storeDemographicFormError?.longitude !== null ||
                                storeDemographic?.store_opening === '' ||
                                storeDemographicFormError?.store_opening_status !== null ||
                                storeDemographicFormError?.store_type !== null ||
                                storeDemographicFormError?.store_retail_billing !== null ||
                                storeDemographicFormError?.cust_no !== null ||
                                storeDemographicFormError?.gstin !== null ||
                                storeDemographicFormError?.gst_slab !== null ||
                                storeDemographicFormError?.pan_number !== null ||
                                storeDemographicFormError?.address.email !== null ||
                                storeDemographicFormError?.address.mobile !== null ||
                                storeDemographicFormError?.address.address !== null ||
                                storeDemographicFormError?.address.pincode !== null ||
                                storeDemographic?.address?.region === '' ||
                                storeDemographic?.address?.state === '' ||
                                storeDemographic?.address?.city === '' ||
                                storeDemographic?.address?.area === ''
                            ))} title={`${activeStep !== 7 ? 'Confirm & Next' : 'Upload Document'}`} />}

                    {activeStep === 2 && <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleNext} disabled={
                        (parentStoreFormError === "" ? true : false) ||
                        (parentStoreFormError &&
                            (parentStoreFormError?.parent_store_name !== null ||
                                parentStoreFormError?.parent_store_gstin !== null
                            ))} title={`${activeStep !== 7 ? 'Confirm & Next' : 'Upload Document'}`} />}

                    {activeStep === 3 && <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleNext} disabled={
                        (contactDetailsFormError === "" ? true : false) ||
                        (contactDetailsFormError &&
                            (contactDetailsFormError?.email !== null ||
                                contactDetailsFormError?.phone_number !== null ||
                                contactDetailsFormError?.person_name !== null ||
                                contactDetailsFormError?.person_dept !== null
                            ))} title={`${activeStep !== 7 ? 'Confirm & Next' : 'Upload Document'}`} />}

                    {activeStep === 4 && <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleNext} disabled={
                        (storeContractDetailsFormError === "" ? true : false) ||
                        (storeContractDetailsFormError &&
                            (storeContractDetailsFormError?.distribution_type !== null ||
                                storeContractDetailsFormError?.channel !== null ||
                                storeContractDetailsFormError?.fresh_margin_type !== null ||
                                storeContractDetailsFormError?.fresh_margin !== null ||
                                storeContractDetailsFormError?.eoss_margin_type !== null ||
                                storeContractDetailsFormError?.eoss_margin !== null ||
                                storeContractDetailsFormError?.eoss_threshold_value !== null ||
                                storeContractDetailsFormError?.base_margin !== null ||
                                storeContractDetailsFormError?.markdown_percentage_type !== null ||
                                storeContractDetailsFormError?.markdown_percentage !== null ||
                                storeContractDetailsFormError?.discount !== null ||
                                (!isDiscount && storeContractDetailsFormError?.discount_percentage !== null) ||
                                storeContractDetailsFormError?.target_linked_insentives !== null ||
                                (!isTargetLinkedInsentives && storeContractDetailsFormError?.target_linked_insentives_percentagre !== null) ||
                                storeContractDetailsFormError?.cash_discount !== null ||
                                storeContractDetailsFormError?.cash_discount_type !== null ||
                                (!isCashDiscount && storeContractDetailsFormError?.cash_discount_percentage !== null) ||
                                storeContractDetailsFormError?.payable_duration !== null ||
                                storeContractDetailsFormError?.stock_correction !== null ||
                                storeContractDetailsFormError?.promo_contribution !== null ||
                                storeContractDetailsFormError?.is_security !== null ||
                                storeContractDetailsFormError?.comments !== null ||
                                storeContractDetailsFormError?.credit_limit !== null ||
                                storeContractDetailsFormError?.business_model !== null

                            ))} title={`${activeStep !== 7 ? 'Confirm & Next' : 'Upload Document'}`} />}

                    {activeStep === 5 && <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleNext} disabled={
                        (storeWallBrandsDetailsFormError === "" ? true : false) ||
                        (storeWallBrandsDetailsFormError &&
                            (storeWallBrandsDetailsFormError?.brand_name !== null ||
                                storeWallBrandsDetailsFormError?.wall_space !== null ||
                                storeWallBrandsDetails[0]?.category_group?.length === 0 ||
                                storeWallBrandsDetails[0]?.category_division?.length === 0 ||
                                storeWallBrandsDetails[0]?.category_presence?.length === 0
                            ))} title={`${activeStep !== 7 ? 'Confirm & Next' : 'Upload Document'}`} />}

                    {activeStep === 6 && <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleNext} disabled={
                        (storeAddressesDetailsFormError === "" ? true : false) ||
                        (storeAddressesDetailsFormError &&
                            (storeAddressesDetailsFormError?.billing_place_address?.address !== null ||
                                storeAddressesDetailsFormError?.billing_place_address?.pincode !== null ||
                                storeAddressesDetails?.billing_place_address?.region === '' ||
                                storeAddressesDetails?.billing_place_address?.state === '' ||
                                storeAddressesDetails?.billing_place_address?.city === '' ||
                                storeAddressesDetails?.billing_place_address?.area === '' ||
                                storeAddressesDetailsFormError?.billing_place_address?.email !== null ||
                                storeAddressesDetailsFormError?.billing_place_address?.phone_number !== null ||
                                storeAddressesDetailsFormError?.billing_place_address?.mobile !== null ||

                                storeAddressesDetailsFormError?.shipping_place_address?.address !== null ||
                                storeAddressesDetailsFormError?.shipping_place_address?.pincode !== null ||
                                storeAddressesDetails?.shipping_place_address?.region === '' ||
                                storeAddressesDetails?.shipping_place_address?.state === '' ||
                                storeAddressesDetails?.shipping_place_address?.city === '' ||
                                storeAddressesDetails?.shipping_place_address?.area === '' ||
                                storeAddressesDetailsFormError?.shipping_place_address?.email !== null ||
                                storeAddressesDetailsFormError?.shipping_place_address?.phone_number !== null ||
                                storeAddressesDetailsFormError?.shipping_place_address?.mobile !== null ||

                                storeAddressesDetailsFormError?.warehouse_address?.address !== null ||
                                storeAddressesDetailsFormError?.warehouse_address?.pincode !== null ||
                                storeAddressesDetails?.warehouse_address?.region === '' ||
                                storeAddressesDetails?.warehouse_address?.state === '' ||
                                storeAddressesDetails?.warehouse_address?.city === '' ||
                                storeAddressesDetails?.warehouse_address?.area === '' ||
                                storeAddressesDetailsFormError?.warehouse_address?.email !== null ||
                                storeAddressesDetailsFormError?.warehouse_address?.phone_number !== null ||
                                storeAddressesDetailsFormError?.warehouse_address?.mobile !== null ||

                                storeAddressesDetailsFormError?.return_warehouse_address?.address !== null ||
                                storeAddressesDetailsFormError?.return_warehouse_address?.pincode !== null ||
                                storeAddressesDetails?.return_warehouse_address?.region === '' ||
                                storeAddressesDetails?.return_warehouse_address?.state === '' ||
                                storeAddressesDetails?.return_warehouse_address?.city === '' ||
                                storeAddressesDetails?.return_warehouse_address?.area === '' ||
                                storeAddressesDetailsFormError?.return_warehouse_address?.email !== null ||
                                storeAddressesDetailsFormError?.return_warehouse_address?.phone_number !== null ||
                                storeAddressesDetailsFormError?.return_warehouse_address?.mobile !== null
                            ))} title={`${activeStep !== 7 ? 'Confirm & Next' : 'Upload Document'}`} />}

                    {activeStep === 7 && <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleNext} disabled={(isFileDetailsEmpty(mouAggrementFile) || isFileDetailsEmpty(gstDocumentParentStoreFile) || isFileDetailsEmpty(panDocumentFile) || isFileDetailsEmpty(gstDocumentStoreFile))} title={`${activeStep !== 7 ? 'Confirm & Next' : 'Upload Document'}`} />}

                </div>
            </div>

            {/* <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button> */}

            <SuccessPopup open={openSuccessPopup} closable={false} onClose={() => { if (timeoutRef.current) { clearTimeout(timeoutRef.current); } setOpenSuccessPopup(false); history.push('/app/storeManagement'); }} content={<div>
                <div className='paddingTop32 text-center'>
                    <div className='textColorBlack fontSize24 montserratFont fontMedium'>Created Successfully!</div>
                    <div className='fontSize16 textColor333'>You have successfully created your Store</div>
                </div>
            </div>} />
            {loading && <Loader />}
        </div>
    )
}

export default CreateStore