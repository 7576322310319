import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CustomHorizontalStepper from '../../../../ReUsableComponent/CustomHorizontalStepper';
import RightArrowIcon from '../../../../assets/svg/rightArrow.svg';
import CustomBlueButton from '../../../../ReUsableComponent/CustomBlueButton';
import '../../storeManagement.css';
import ProgressBarWithLabel from '../../../../ReUsableComponent/ProgressBarWithLabel';
import InputTextField from '../../../../ReUsableComponent/InputTextField';
import CustomAutoComplete from '../../../../ReUsableComponent/CustomAutocomplete';
import MultiSelectCheckBox from '../../../../ReUsableComponent/MultiSelectCheckBox';
import CustomDatePicker from '../../../../ReUsableComponent/DatePickerComponent';
import dayjs from 'dayjs';
import CustomDropzone from '../../../../ReUsableComponent/CustomDropzone';
import ImportIcon from '../../../../assets/svg/uploadIconForDoc.svg';
import PlusIcon from '../../../../assets/svg/plus_icon.svg';
import SecondaryButton from '../../../../ReUsableComponent/SecondaryButton';
import '../../../TeamManagement/TeamManagement.css';
import { ToastErrorService, ToastSuccessService } from '../../../../ReUsableComponent/ToastNotification/ToastService';
import { validateTextInputAny, isNumberOrFloat, validateTextInputAnyMin, validateTextInputFree, validateOnlyTextInput, validateTextInput, validateEmailInput, validateNumberInput, validatePinCodeInput, validatePANInput, validateGSTINInput, validateArray, validateAccountNumberInput, validateIFSCCode, validateTextAddressInput, validateText2To200Input, validateNumberWithSpecialCharater, validateNumber2To6Input, validateDateFormat, formatDateToDDMMYYYY, validateCustomerNumberInput, validateNumber1To2Input, validateNumber1To3Input, validateNumber2To20Input, validateNumberAtLeast1Input, validateText3To80Input, validateTextDotAndAmpersand, validateTextDotAndAmpersandCommaWithNum, validateTextDotAndSpecialCharacter, validateFreeNumberInput, handleNumericInput, validateLatitude, validateLongitude, validateTextDotCommaSpace, validateTextAndAmpersandCommaWithHy, validateTextUnderscoreSlash } from './../../../../ReUsableComponent/CustomFormValidation';
import { CustomErrorMsg } from './../../../../ReUsableComponent/CustomErrorMsg';
import ExcellIcon from '../../../../assets/svg/pdf.svg';
import PngIcon from '../../../../assets/svg/png.svg';
import JpegIcon from '../../../../assets/svg/jpeg.svg';
import ProgressBarForFileUpload from './../../../../ReUsableComponent/ProgressBarForFileUpload';

import { getListStateCityByPincode, getDropdownList, fileUpload, getAllBrandList } from './../../StoreManagent.service';
import { useSelector } from 'react-redux';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const CreateStoreInputForm = ({
    createStoreOwnerDetails,
    STATUS,
    activeStep,
    setActiveStep,
    progress,
    setProgress,
    skipped,
    setSkipped,

    storeOpeningDate,
    setStoreOpeningDate,

    // function
    onChangeStoreOpeningDate,
    validateFileUploads,
    addNewContactDetailsForm,
    addNewStoreWallBrandsDetailsForm,

    // Error Data
    ownerDetailsFormError,
    setOwnerDetailsFormError,
    storeDemographicFormError,
    setStoreDemographicFormError,
    parentStoreFormError,
    setParentStoreFormError,
    contactDetailsFormError,
    setContactDetailsFormError,
    storeContractDetailsFormError,
    setStoreContractDetailsFormError,
    storeWallBrandsDetailsFormError,
    setStoreWallBrandsDetailsFormError,
    storeAddressesDetailsFormError,
    setStoreAddressesDetailsFormError,
    brandStaffDetailsFormError,
    setBrandStaffDetailsFormError,


    // data
    ownerDetails,
    setOwnerDetails,
    storeDemographic,
    setStoreDemographic,
    parentStore,
    setParentStore,
    contactDetails,
    setContactDetails,
    storeContractDetails,
    setStoreContractDetails,
    storeWallBrandsDetails,
    setStoreWallBrandsDetails,
    storeAddressesDetails,
    setStoreAddressesDetails,
    brandStaffDetails,
    setBrandStaffDetails,

    uploadedFiles,
    setUploadedFiles,

    mouAggrementFile,
    setMouAggrementFile,
    gstDocumentParentStoreFile,
    setGstDocumentParentStoreFile,
    gstDocumentStoreFile,
    setGstDocumentStoreFile,
    panDocumentFile,
    setPanDocumentFile,
    uploadStorePictureFile,
    setUploadStorePictureFile,
    storeFacadeFile,
    setStoreFacadeFile,
    brandFloorSpaceFile,
    setBrandFloorSpaceFile,
    adjacentFile,
    setAdjacentFile,
    otherFile,
    setOtherFile,
    isFileDetailsEmpty,

    isDiscount,
    setIsDiscount,
    isCashDiscount,
    setIsCashDiscount,
    isTargetLinkedInsentives,
    setIsTargetLinkedInsentives,

    categoryGroupDrop,
    setCategoryGroupDrop,
    categoryPresenceDrop,
    setCategoryPresenceDrop,
}) => {

    const zerothTabRef = useRef(null);
    const firstTabRef = useRef(null);
    const secondTabRef = useRef(null);
    const thirdTabRef = useRef(null);
    const forthTabRef = useRef(null);
    const fifthTabRef = useRef(null);
    const sixthTabRef = useRef(null);
    const seventhTabRef = useRef(null);

    const history = useHistory();
    // const CONSTITUTIONOFFIRM = [
    //     { label: 'Partnership', value: 'Partnership', name: 'constitution_of_firm' }, 
    //     { label: 'Proprietorship', value: 'Proprietorship', name: 'constitution_of_firm' },
    //     { label: 'Limited Liability Partnership', value: 'Limited Liability Partnership', name: 'constitution_of_firm' }, 
    //     { label: 'Private Limited Company', value: 'Private Limited Company', name: 'constitution_of_firm' },
    //     { label: 'Pubic Limited Company', value: 'Pubic Limited Company', name: 'constitution_of_firm' }, 
    //     { label: 'Company Limited by Guarantee', value: 'Company Limited by Guarantee', name: 'constitution_of_firm' },
    //     { label: 'Unlimited Company', value: 'Unlimited Company', name: 'constitution_of_firm' }, 
    //     { label: 'Others', value: 'Others', name: 'constitution_of_firm' },
    // ];
    // const STORE_TYPE = [
    //     { label: 'Discounted Store', value: 'Discounted Store', name: 'constitution_of_firm' }, 
    //     { label: 'Text 1', value: 'Text 1', name: 'constitution_of_firm' },
    //     { label: 'Text 2', value: 'Text 2', name: 'constitution_of_firm' }, 
    // ];
    // const STORE_OPENING_STATUS = [{ label: 'New Running', value: 'New Running', name: 'store_opening_status' }, { label: 'Old Running', value: 'Old Running', name: 'store_opening_status' }];


    const STORE_STATUS = [{ label: 'Active', value: true, name: 'store_status' }, { label: 'Inactive', value: false, name: 'store_status' }];
    const IS_BRAND_STAFF = [{ label: 'Yes', value: true, name: 'is_brand_staff' }, { label: 'No', value: false, name: 'is_brand_staff' }];
    const DISCOUNT = [{ label: 'Yes', value: 'Yes', name: 'discount' }, { label: 'No', value: 'No', name: 'discount' }];
    const TARGET_LINKED_INSENTIVES = [{ label: 'Yes', value: 'Yes', name: 'target_linked_insentives' }, { label: 'No', value: 'No', name: 'target_linked_insentives' }];
    const CASH_DISCOUNT = [{ label: 'Yes', value: 'Yes', name: 'cash_discount' }, { label: 'No', value: 'No', name: 'cash_discount' }];
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);

    const getStoreStatus = STORE_STATUS.find(option => option.value === storeDemographic?.store_status);
    const getIsBrandStaffStatus = storeWallBrandsDetails?.map(detail => {
        const matchedOption = IS_BRAND_STAFF?.find(option => option?.value === detail?.is_brand_staff);
        return matchedOption?.label;
    });
    const USER_ID = useSelector((state) => state.userAuthentication.userId);
    const [selectedStoreStatus, setSelectedStoreStatus] = useState(getStoreStatus || null);
    const [isBrandStaff, setIsBrandStaff] = useState(getIsBrandStaffStatus || null);

    const [inputConstitutionOfFirm, setInputConstitutionOfFirm] = useState('');
    const [storeStatus, setStoreStatus] = useState('');
    const [storeOpeningStatus, setStoreOpeningStatus] = useState('');
    const [dropService, setDropService] = useState('store');
    const [dropdownKey, setDropdownKey] = useState('');
    const [dropdownCategoryGroupKey, setDropdownCategoryGroupKey] = useState('category_group');
    const [dropdownCategoryPresenceKey, setDropdownCategoryPresenceKey] = useState('category_presence');
    const [categoryGroupKey, setCategoryGroupKey] = useState('');
    const [categoryDivisionKey, setCategoryDivisionKey] = useState('');

    const [iSBRANDSTAFFStatus, setISBRANDSTAFFStatus] = useState('');

    const [allBrandStatus, setAllBrandStatus] = useState();


    const [constitutionOfFirm, setConstitutionOfFirm] = useState([]);
    const [storeTypeDropData, setStoreTypeDropData] = useState([]);
    const [storeOpeningStatusDropData, setStoreOpeningStatusDropData] = useState([]);
    const [storeGstSlab, setStoreGstSlab] = useState([]);
    const [storeClassification, setStoreClassification] = useState([]);
    const [storeClass, setStoreClass] = useState([]);

    const [cityTier, setCityTier] = useState([]);
    const [storeRetailBillingSystem, setStoreRetailBillingSystem] = useState([]);
    const [channelDrop, setChannelDrop] = useState([]);
    const [distributionType, setDistributionType] = useState([]);
    const [freshMarginType, setFreshMarginType] = useState([]);
    const [eossMarginType, setEossMarginType] = useState([]);
    const [discountPercentage, setDiscountPercentage] = useState([]);
    const [cashDiscountType, setCashDiscountType] = useState([]);
    const [StockCorrection, setStockCorrection] = useState([]);
    const [promoContribution, setPromoContribution] = useState([]);
    const [securityDrop, setSecurityDrop] = useState([]);
    const [businessModelDrop, setBusinessModelDrop] = useState([]);
    const [floorPlacementDrop, setFloorPlacementDrop] = useState([]);
    const [categoryDivisionDrop, setCategoryDivisionDrop] = useState([]);
    const [allBrandsListData, setAllBrandsListData] = useState([]);


    const [storeNames, setStoreNames] = useState([]);
    const [productType, setProductType] = useState([]);
    const [channelServed, setChannelServed] = useState([]);
    // const [categoryDivisionDrop, setCategoryDivisionDrop]=useState([]);

    const [mouAggrementTypeIcon, setMouAggrementTypeIcon] = useState("");
    const [gstDocumentParentStoreFileTypeIcon, setGstDocumentParentStoreFileTypeIcon] = useState("");
    const [gstDocumentStoreFileTypeIcon, setGstDocumentStoreFileTypeIcon] = useState("");
    const [panDocumentFileTypeIcon, setPanDocumentFileTypeIcon] = useState("");
    const [uploadStorePictureFileTypeIcon, setUploadStorePictureFileTypeIcon] = useState("");
    const [otherFileTypeIcon, setOtherFileTypeIcon] = useState("");

    const [storeFacadeTypeIcon, setStoreFacadeTypeIcon] = useState("");
    const [brandFloorSpaceFileTypeIcon, setBrandFloorSpaceFileTypeIcon] = useState("");
    const [adjacentFileTypeIcon, setAdjacentFileTypeIcon] = useState("");

    const [mouAggrementProgress, setMouAggrementProgress] = React.useState(0);
    const [gstDocumentParentStoreProgress, setGstDocumentParentStoreProgress] = React.useState(0);
    const [gstDocumentStoreProgress, setGstDocumentStoreProgress] = React.useState(0);
    const [panDocumentProgress, setPanDocumentProgress] = React.useState(0);
    const [uploadStorePictureProgress, setUploadStorePictureProgress] = React.useState(0);

    const [storeFacadeProgress, setstoreFacadeProgress] = React.useState(0);
    const [brandFloorSpaceProgress, setbrandFloorSpaceProgress] = React.useState(0);
    const [adjacentProgress, setadjacentProgress] = React.useState(0);
    const [otherProgress, setotherProgress] = React.useState(0);

    const [mouAggrementUploadSpeed, setMouAggrementUploadSpeed] = useState(null);
    const [gstDocumentParentStoreUploadSpeed, setGstDocumentParentStoreUploadSpeed] = useState(null);
    const [gstDocumentStoreUploadSpeed, setGstDocumentStoreUploadSpeed] = useState(null);
    const [panDocumentUploadSpeed, setPanDocumentUploadSpeed] = useState(null);
    const [uploadStorePictureUploadSpeed, setUploadStorePictureUploadSpeed] = useState(null);

    const [storeFacadeUploadSpeed, setStoreFacadeUploadSpeed] = useState(null);
    const [brandFloorSpaceUploadSpeed, setBrandFloorSpaceUploadSpeed] = useState(null);
    const [adjacentUploadSpeed, setAdjacentUploadSpeed] = useState(null);
    const [otherUploadSpeed, setOtherUploadSpeed] = useState(null);

    const [startTime, setStartTime] = useState(null);

    const onChangeCOF = () => {

    }


    const formatCategories = (categories, name) => {
        return categories.map(category => ({
            label: category,
            value: category,
            name: name,
        }));
    };

    useEffect(() => {
        const scrollToTop = () => {
            if (activeStep === 0 && zerothTabRef.current) {
                zerothTabRef.current.scrollTop = 0;
            } else if (activeStep === 1 && firstTabRef.current) {
                firstTabRef.current.scrollTop = 0;
            } else if (activeStep === 2 && secondTabRef.current) {
                secondTabRef.current.scrollTop = 0;
            } else if (activeStep === 3 && thirdTabRef.current) {
                thirdTabRef.current.scrollTop = 0;
            } else if (activeStep === 4 && forthTabRef.current) {
                forthTabRef.current.scrollTop = 0;
            } else if (activeStep === 5 && fifthTabRef.current) {
                fifthTabRef.current.scrollTop = 0;
            } else if (activeStep === 6 && sixthTabRef.current) {
                sixthTabRef.current.scrollTop = 0;
            } else if (activeStep === 7 && seventhTabRef.current) {
                seventhTabRef.current.scrollTop = 0;
            }
        };
        setTimeout(scrollToTop, 100);
    }, [activeStep]);

    useEffect(() => {
        handleGetAllDropdownList();
        handleGetAllBrandsList();
    }, []);

    const handleGetAllDropdownList = () => {
        const param = `?service=${dropService}&dropdown_key=${dropdownKey}`
        getDropdownList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                response?.data?.data?.map((item) => {
                    if (item?.dropdown_key === 'constitution_of_firm') {
                        setConstitutionOfFirm(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'store_type') {
                        setStoreTypeDropData(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'store_opening_status') {
                        setStoreOpeningStatusDropData(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'gst_slab') {
                        setStoreGstSlab(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'store_classification') {
                        setStoreClassification(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'store_class') {
                        setStoreClass(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'floor_placement') {
                        setFloorPlacementDrop(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'city_tier') {
                        setCityTier(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'store_retail_billing_system') {
                        setStoreRetailBillingSystem(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'store_names') {
                        setStoreNames(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === 'ProductType') {
                        setProductType(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "channel") {
                        setChannelDrop(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "ChannelServed") {
                        setChannelServed(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "distribution_type") {
                        setDistributionType(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "fresh_margin_type") {
                        setFreshMarginType(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "eoss_margin_type") {
                        setEossMarginType(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "discount_percentage") {
                        setDiscountPercentage(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "cash_discount_type") {
                        setCashDiscountType(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "stock_correction") {
                        setStockCorrection(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "promo_contribution") {
                        setPromoContribution(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "Security") {
                        setSecurityDrop(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "business_model") {
                        setBusinessModelDrop(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }
                    else if (item?.dropdown_key === "category_division") {
                        setCategoryDivisionDrop(formatCategories(item?.dropdown_values, item?.dropdown_key));
                    }

                });
            } else {
                ToastErrorService(response.data.message)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            // setLoading(false);
        })
    }

    const handleGetAllDropdownCategoryGroup = (dropKey, category, index) => {
        let param;
        if (dropKey === 'category_group') {
            param = `?module=${dropService}&dropdown_key=${dropKey}&category_division=${category?.join(",")}`
        } else if (dropKey === 'category_presence') {
            param = `?module=${dropService}&dropdown_key=${dropKey}&category_group=${category?.join(",")}`
        }

        getDropdownList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                const data = response.data.data;
                if (dropKey === "category_group") {
                    const formattedCategories = category?.length === 0 ? [] : formatCategories(extractDropdownValues(data), dropKey);
                    setCategoryGroupDrop(prevState => {
                        const newState = [...prevState];
                        newState[index] = formattedCategories;
                        return newState;
                    });
                } else if (dropKey === "category_presence") {
                    const formattedCategories = category?.length === 0 ? [] : formatCategories(extractDropdownValues(data), dropKey);
                    setCategoryPresenceDrop(prevState => {
                        const newState = [...prevState];
                        newState[index] = formattedCategories;
                        return newState;
                    });
                }
            } else {
                ToastErrorService(response.data.message);
            }
        }).catch(error => {
            ToastErrorService(error);
        }).finally(() => {
            // setLoading(false);
        });
    };

    // Function to extract and combine dropdown values
    const extractDropdownValues = (docs) => {
        let combinedValues = new Set();
        docs.forEach(doc => {
            doc.dropdown_values.forEach(value => {
                combinedValues.add(value);
            });
        });
        return Array.from(combinedValues);
    };

    const formatAllBrandsList = (allBrandsLists) => {
        return allBrandsLists.map(allBrandsList => ({
            label: allBrandsList?.brand_name,
            value: allBrandsList?.brand_name,
            brand_name: allBrandsList?.brand_name,
            email_id: allBrandsList?.email_id,
            id: allBrandsList?._id,
        }));
    };

    const handleGetAllBrandsList = () => {
        let param = `?user_id=${USER_ID}`
        getAllBrandList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                setAllBrandsListData(formatAllBrandsList(response?.data?.data));
            } else {
                ToastErrorService(response.data.message)
            }
        }).catch(error => {
            ToastErrorService(error);
        }).finally(() => {
            // setLoading(false);
        })
    }

    const checkObject = (variable) => {
        if (typeof variable === 'object' && variable !== null) {
            return true
        } else {
            return false
        }
    }

    const handlePinCode = (name, value, field) => {
        const param = `?pincode=${value}`
        getListStateCityByPincode(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                let stateData = response?.data?.data?.map((item) => ({
                    label: item?.state,
                    value: item?.state,
                    name: 'state'
                }));
                const uniqueStateData = Array.from(new Set(stateData.map(item => item.value))).map(value => {
                    return stateData.find(item => item.value === value);
                });

                let cityData = response?.data?.data?.map((item) => ({
                    label: item?.city,
                    value: item?.city,
                    name: 'city'
                }));
                const uniqueCityData = Array.from(new Set(cityData.map(item => item.value))).map(value => {
                    return cityData.find(item => item.value === value);
                });

                let regionData = response?.data?.data?.map((item) => ({
                    label: item?.region,
                    value: item?.region,
                    name: 'region'
                }));
                const uniqueRegionData = Array.from(new Set(regionData.map(item => item.value))).map(value => {
                    return regionData.find(item => item.value === value);
                });

                let areaData = response?.data?.data?.map((item) => ({
                    label: item?.area,
                    value: item?.area,
                    name: 'area'
                }));
                const uniqueAreaData = Array.from(new Set(areaData.map(item => item.value))).map(value => {
                    return areaData.find(item => item.value === value);
                });

                if (name === 'ownerDetails.address.pincode') {
                    setOwnerDetails(prev => ({
                        ...prev, [field.parent]: {
                            ...prev[field.parent],
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : '',
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : '',
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : '',
                            area: uniqueAreaData[0]?.value ? uniqueAreaData[0]?.value : '',
                        }
                    }));
                } else if (name === "storeDemographic.address.pincode") {
                    setStoreDemographic(prev => ({
                        ...prev, [field.parent]: {
                            ...prev[field.parent],
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : '',
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : '',
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : '',
                            area: uniqueAreaData[0]?.value ? uniqueAreaData[0]?.value : '',
                        }
                    }));
                } else if (name === "storeAddressesDetails.billing_place_address.pincode") {
                    setStoreAddressesDetails(prev => ({
                        ...prev, [field.parent]: {
                            ...prev[field.parent],
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : '',
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : '',
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : '',
                            area: uniqueAreaData[0]?.value ? uniqueAreaData[0]?.value : '',
                        }
                    }));
                } else if (name === "storeAddressesDetails.shipping_place_address.pincode") {
                    setStoreAddressesDetails(prev => ({
                        ...prev, [field.parent]: {
                            ...prev[field.parent],
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : '',
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : '',
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : '',
                            area: uniqueAreaData[0]?.value ? uniqueAreaData[0]?.value : '',
                        }
                    }));
                } else if (name === "storeAddressesDetails.warehouse_address.pincode") {
                    setStoreAddressesDetails(prev => ({
                        ...prev, [field.parent]: {
                            ...prev[field.parent],
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : '',
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : '',
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : '',
                            area: uniqueAreaData[0]?.value ? uniqueAreaData[0]?.value : '',
                        }
                    }));
                } else if (name === "storeAddressesDetails.return_warehouse_address.pincode") {
                    setStoreAddressesDetails(prev => ({
                        ...prev, [field.parent]: {
                            ...prev[field.parent],
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : '',
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : '',
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : '',
                            area: uniqueAreaData[0]?.value ? uniqueAreaData[0]?.value : '',
                        }
                    }));
                }
            } else {
                ToastErrorService(response.data.message)
            }
        }).catch(error => {
            ToastErrorService(error);
        }).finally(() => {
            // setLoading(false);
        })
    }

    // const handleChannelChange = (name, event) => {
    //     const { target: { value } } = event;
    //     if (name === "product_type" || name === "business_model" ){
    //         setBrandDetails(prevState => ({
    //             ...prevState,
    //             [name]: value
    //         }))
    //     } else if(name === "channel_served" || name === "channel_offline" || name === "channel_online"){
    //         setChannelDetails(prevState => ({
    //             ...prevState,
    //             [name]: value
    //         }))
    //     }
    // };


    const handleSetInputDataInStore = (field, inputName, setState, value, validationFunc, errorInputName, setErrorState, errorMsg) => {
        const keys = errorInputName.split('.');
        // console.log("store_classification===>", keys,field, value);
        if (typeof field === 'string') {
            if (field === 'store_status') {
                setSelectedStoreStatus(value);
                setState(prev => ({ ...prev, [field]: value?.value }));
            } else {
                setState(prev => ({ ...prev, [field]: value }));
            }

        } else if (typeof field === 'object' && field.parent) {
            setState(prev => ({
                ...prev, [field.parent]: { ...prev[field.parent], [field.child]: value }
            }));
        }

        if (validationFunc(value)) {
            setErrorState(prevState => {
                let updatedState = { ...prevState };
                if (keys.length === 1) {
                    updatedState[keys[0]] = null;
                } else {
                    let nestedObj = updatedState;
                    for (let i = 0; i < keys.length - 1; i++) {
                        nestedObj = nestedObj[keys[i]];
                    }
                    nestedObj[keys[keys.length - 1]] = null;
                }
                return updatedState;
            });

            if (field?.child === 'pincode') {
                let conditionPinCode = (value) => {
                    if (value.length === 6 && /^\d+$/.test(value)) {
                        return true;
                    }
                    return false;
                }
                if (conditionPinCode) {
                    handlePinCode(inputName, value, field);
                    // if(inputName === "ownerDetails.address.pincode"){
                    //     handlePinCode(inputName, value, field);
                    // }else if(inputName === "storeDemographic.address.pincode"){
                    //     handlePinCode(inputName, value, field);
                    // }
                }
            }
        } else {
            setErrorState(prevState => {
                let updatedState = { ...prevState };
                if (keys.length === 1) {
                    updatedState[keys[0]] = errorMsg;
                } else {
                    let nestedObj = updatedState;
                    for (let i = 0; i < keys.length - 1; i++) {
                        nestedObj = nestedObj[keys[i]];
                    }
                    nestedObj[keys[keys.length - 1]] = errorMsg;
                }
                return updatedState;
            });
        }
    }

    const handleInputChange = (step, stateName, field, value, validationFunc, errorMsg) => {
        let inputName = checkObject(field) ? `${stateName}.${field?.parent}.${field?.child}` : `${stateName}.${field}`;
        let errorInputName = checkObject(field) ? `${field?.parent}.${field?.child}` : field;
        if (step === 0) {
            handleSetInputDataInStore(field, inputName, setOwnerDetails, value, validationFunc, errorInputName, setOwnerDetailsFormError, errorMsg);
        } else if (step === 1) {
            handleSetInputDataInStore(field, inputName, setStoreDemographic, value, validationFunc, errorInputName, setStoreDemographicFormError, errorMsg);
        } else if (step === 2) {
            handleSetInputDataInStore(field, inputName, setParentStore, value, validationFunc, errorInputName, setParentStoreFormError, errorMsg);
        } else if (step === 4) {
            if (field === 'discount') {
                setIsDiscount(value === 'Yes' ? false : true);
            } else if (field === 'cash_discount') {
                setIsCashDiscount(value === 'Yes' ? false : true);
            } else if (field === 'target_linked_insentives') {
                setIsTargetLinkedInsentives(value === 'Yes' ? false : true);
            }
            handleSetInputDataInStore(field, inputName, setStoreContractDetails, value, validationFunc, errorInputName, setStoreContractDetailsFormError, errorMsg);
        }
        // else if(step === 5){
        //     handleSetInputDataInStore(field, inputName, setStoreContractDetails, value, validationFunc, errorInputName, setStoreContractDetailsFormError, errorMsg);
        // }
        else if (step === 6) {
            handleSetInputDataInStore(field, inputName, setStoreAddressesDetails, value, validationFunc, errorInputName, setStoreAddressesDetailsFormError, errorMsg);
        } else if (step === 7) {
            handleSetInputDataInStore(field, inputName, setBrandStaffDetails, value, validationFunc, errorInputName, setBrandStaffDetailsFormError, errorMsg);
        }

    };


    const handleBrandContactsDetailsChange = (event, index) => {
        const { name, value } = event.target;
        if (name === 'person_name') {
            handleInputArrayChange(name, value, index, contactDetails, setContactDetails, validateTextInputAnyMin, setContactDetailsFormError, CustomErrorMsg?.name, '');
        } else if (name === 'person_dept') {
            handleInputArrayChange(name, value, index, contactDetails, setContactDetails, validateTextInputAnyMin, setContactDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'phone_number') {
            handleInputArrayChange(name, value, index, contactDetails, setContactDetails, validateNumberInput, setContactDetailsFormError, CustomErrorMsg.mobile, '');
        } else if (name === 'email') {
            handleInputArrayChange(name, value, index, contactDetails, setContactDetails, validateEmailInput, setContactDetailsFormError, CustomErrorMsg.email, '');
        } else if (name === 'person_other') {
            handleInputArrayChange(name, value, index, contactDetails, setContactDetails, validateTextInputAnyMin, setContactDetailsFormError, CustomErrorMsg.common, '');
        }
    }

    const handleStoreWallBrandsDetailsDetailsChange = (event, newValue, index, name) => {
        const { value } = event.target;
        const updatedDetails = [...storeWallBrandsDetails];
        if (name === 'brand_name') {
            handleInputArrayChange(name, newValue, index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateTextInputAnyMin, setStoreWallBrandsDetailsFormError, CustomErrorMsg?.name, '');
        } else if (name === 'wall_space') {
            handleInputArrayChange(name, handleNumericInput(value), index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateFreeNumberInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'enter_cdu') {
            handleInputArrayChange(name, handleNumericInput(value), index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateFreeNumberInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'default_length') {
            handleInputArrayChange(name, handleNumericInput(value), index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateFreeNumberInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'total_sq_ft') {
            handleInputArrayChange(name, handleNumericInput(value), index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateFreeNumberInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'display_options') {
            handleInputArrayChange(name, handleNumericInput(value), index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateFreeNumberInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'per_running_feet') {
            handleInputArrayChange(name, handleNumericInput(value), index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateFreeNumberInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'floor_placement') {
            handleInputArrayChange(name, value, index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateTextInputAny, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'display_qty') {
            handleInputArrayChange(name, handleNumericInput(value), index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateFreeNumberInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'depth_per_option') {
            handleInputArrayChange(name, handleNumericInput(value), index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateFreeNumberInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'category_division') {
            updatedDetails[index]['category_group'] = [];
            updatedDetails[index]['category_presence'] = [];
            updatedDetails[index]['brand_staff_name'] = '';
            setCategoryPresenceDrop(prevState => {
                const newState = [...prevState];
                newState[index] = [];
                return newState;
            });
            setStoreWallBrandsDetails(updatedDetails);
            handleGetAllDropdownCategoryGroup('category_group', value, index);
            handleInputArrayChange(name, value, index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateTextInputAny, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'category_group') {
            updatedDetails[index]['category_presence'] = [];
            setStoreWallBrandsDetails(updatedDetails);
            handleGetAllDropdownCategoryGroup('category_presence', value, index);
            handleInputArrayChange(name, value, index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateTextInputAny, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'category_presence') {
            handleInputArrayChange(name, value, index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateTextInputAny, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'is_brand_staff') {
            const newIsBrandStaff = [...isBrandStaff];
            newIsBrandStaff[index] = newValue?.label;
            setIsBrandStaff(newIsBrandStaff);
            handleInputArrayChange(name, newValue?.value, index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateTextInputAny, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        } else if (name === 'brand_staff_name') {
            handleInputArrayChange(name, value, index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateTextInput, setStoreWallBrandsDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'brand_staff_terms') {
            handleInputArrayChange(name, value, index, storeWallBrandsDetails, setStoreWallBrandsDetails, validateTextInputAny, setStoreWallBrandsDetailsFormError, CustomErrorMsg.common, '');
        }
    }

    const handleInputArrayChange = (name, value, index, state, setState, validationFunc, setErrorState, errorMsg, companyContactDetailsBranchPincode) => {
        if (name === 'brand_name') {
            const updatedDetails = [...state];
            updatedDetails[index]['brand_id'] = value?.id;
            updatedDetails[index][name] = value?.label;
            setState(updatedDetails);
        } else {
            const updatedDetails = [...state];
            updatedDetails[index][name] = value;
            setState(updatedDetails);
        }

        if (validationFunc(value)) {
            setErrorState(prevErrors => ({ ...prevErrors, [name]: null }));
        } else {
            setErrorState(prevErrors => ({ ...prevErrors, [name]: errorMsg }));
        }
    }


    // For File Upload Code Start
    const handleFileDrop = (name, files) => {
        const file = files[0];
        const size = files[0]?.size;
        const allowedTypesForPdf = ['application/pdf'];
        const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
        const allowedTypesForLogo = ['image/png', 'image/jpeg', 'image/jpg'];
        if (name === 'UploadStorePicture') {
            if (allowedTypesForLogo.includes(file.type)) {
                simulateUpload(name);
                if (file.type === 'image/png') {
                    handleFileUpload(name, file, 'Store Logo', PngIcon, size);
                } else if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
                    handleFileUpload(name, file, 'Store Logo', JpegIcon, size);
                }

                if (name === 'UploadStorePicture') {
                    setUploadStorePictureUploadSpeed(0);
                }
            } else {
                alert('Please select a JPEG, JPG or PNG file Only.');
            }
        } else if (name === 'MouAggrement') {
            if (allowedTypesForPdf.includes(file.type)) {
                simulateUpload(name);

                if (file.type === 'application/pdf') {
                    handleFileUpload(name, file, 'Store Document', ExcellIcon, size);
                }

                if (name === 'MouAggrement') {
                    setMouAggrementUploadSpeed(0);
                }
            } else {
                alert('Please select a PDF file Only.');
            }
        } else if (name === 'GstDocumentParentStore') {
            if (allowedTypesForPdf.includes(file.type)) {
                simulateUpload(name);

                if (file.type === 'application/pdf') {
                    handleFileUpload(name, file, 'Store Document', ExcellIcon, size);
                }

                if (name === 'GstDocumentParentStore') {
                    setGstDocumentParentStoreUploadSpeed(0);
                }
            } else {
                alert('Please select a PDF file Only.');
            }
        } else {
            if (allowedTypes.includes(file.type)) {
                simulateUpload(name);

                if (file.type === 'application/pdf') {
                    handleFileUpload(name, file, 'Store Document', ExcellIcon, size);
                } else if (file.type === 'image/png') {
                    handleFileUpload(name, file, 'Store Logo', PngIcon, size);
                } else if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
                    handleFileUpload(name, file, 'Store Logo', JpegIcon, size);
                }
                //if(name === 'MouAggrement' ){
                //     setMouAggrementUploadSpeed(0);
                // }else if(name === 'GstDocumentParentStore' ){
                //     setGstDocumentParentStoreUploadSpeed(0);
                // }
                else if (name === 'GstDocumentStore') {
                    setGstDocumentStoreUploadSpeed(0);
                } else if (name === 'PanDocument') {
                    setPanDocumentUploadSpeed(0);
                }
                // else if(name === 'UploadStorePicture' ){
                //     setUploadStorePictureUploadSpeed(0);
                // }
                else if (name === 'StoreFacade') {
                    setstoreFacadeProgress(0);
                } else if (name === 'BrandFloorSpace') {
                    setbrandFloorSpaceProgress(0);
                } else if (name === 'Adjacent') {
                    setotherProgress(0)
                } else if (name === 'Other') {
                    setotherProgress(0)
                }
            } else {
                alert('Please select a PDF, JPEG, JPG or PNG file Only.');
            }
        }
    };

    const simulateUpload = (name) => {
        if (name === 'MouAggrement') {
            const interval = setInterval(() => {
                setMouAggrementProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setMouAggrementUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setMouAggrementUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'GstDocumentParentStore') {
            const interval = setInterval(() => {
                setGstDocumentParentStoreProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setGstDocumentParentStoreUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setGstDocumentParentStoreUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'GstDocumentStore') {
            const interval = setInterval(() => {
                setGstDocumentStoreProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setGstDocumentStoreUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setGstDocumentStoreUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'PanDocument') {
            const interval = setInterval(() => {
                setPanDocumentProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setPanDocumentUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setPanDocumentUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'UploadStorePicture') {
            const interval = setInterval(() => {
                setUploadStorePictureProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setUploadStorePictureUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setUploadStorePictureUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'StoreFacade') {
            const interval = setInterval(() => {
                setstoreFacadeProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setStoreFacadeUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setStoreFacadeUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'BrandFloorSpace') {
            const interval = setInterval(() => {
                setbrandFloorSpaceProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setBrandFloorSpaceUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setBrandFloorSpaceUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'Adjacent') {
            const interval = setInterval(() => {
                setadjacentProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setAdjacentUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setAdjacentUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500);
        } else if (name === 'Other') {
            const interval = setInterval(() => {
                setotherProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setOtherUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setOtherUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500);
        }
    };

    const handleFileUpload = async (name, file, fileServiceType, fileTypeIcon, size) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('service', 'Brand_Documents');
        formData.append('file_name', 'Data');
        formData.append('service_type', fileServiceType);
        formData.append('size', size);
        formData.append('store_name', createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '');

        fileUpload(formData, USER_TOKEN).then(response => {

            if (response.data.statusCode === 400) {
                ToastErrorService(response.data.message)
            } else {
                console.log("created_at===>", response?.data?.data?.created_at)
                if (name === 'MouAggrement') {
                    setMouAggrementFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "MouAggrement",
                        }
                    );
                    setMouAggrementTypeIcon(fileTypeIcon);
                } else if (name === 'GstDocumentParentStore') {
                    setGstDocumentParentStoreFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "GstDocumentParentStore",
                        }
                    );
                    setGstDocumentParentStoreFileTypeIcon(fileTypeIcon);
                } else if (name === 'GstDocumentStore') {
                    setGstDocumentStoreFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "GstDocumentStore",
                        }
                    );
                    setGstDocumentStoreFileTypeIcon(fileTypeIcon);
                } else if (name === 'PanDocument') {
                    setPanDocumentFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "PanDocument",
                        }
                    );
                    setPanDocumentFileTypeIcon(fileTypeIcon);
                } else if (name === 'UploadStorePicture') {
                    setUploadStorePictureFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "UploadStorePicture",
                        }
                    );
                    setUploadStorePictureFileTypeIcon(fileTypeIcon);
                } else if (name === 'StoreFacade') {
                    setStoreFacadeFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "StoreFacade",
                        }
                    );
                    setStoreFacadeTypeIcon(fileTypeIcon);
                } else if (name === 'BrandFloorSpace') {
                    setBrandFloorSpaceFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "BrandFloorSpace",
                        }
                    );
                    setBrandFloorSpaceFileTypeIcon(fileTypeIcon);
                } else if (name === 'Adjacent') {
                    setAdjacentFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "Adjacent",
                        }
                    );
                    setAdjacentFileTypeIcon(fileTypeIcon);
                } else if (name === 'Other') {
                    setOtherFile(
                        {
                            store_id: createStoreOwnerDetails?._id ? createStoreOwnerDetails?._id : '',
                            brand_id: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_id : '',
                            brand_name: createStoreOwnerDetails?.brands ? createStoreOwnerDetails?.brands[0]?.brand_name : '',
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: response?.data?.data?.size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "Other",
                        }
                    );
                    setOtherFileTypeIcon(fileTypeIcon);
                }
            }
        }).catch(error => {
        }).finally(() => {
            // setLoading(false);
        })
    };

    const removeFile = (name) => {
        // setUploadedFile([]);
        if (name === 'MouAggrement') {
            setMouAggrementFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: ''
                }
            );
        } else if (name === 'GstDocumentParentStore') {
            setGstDocumentParentStoreFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: ''
                }
            );
        } else if (name === 'GstDocumentStore') {
            setGstDocumentStoreFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: ''
                }
            );
        } else if (name === 'PanDocument') {
            setPanDocumentFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: ''
                }
            );
        } else if (name === 'UploadStorePicture') {
            setUploadStorePictureFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: ''
                }
            );
        } else if (name === 'StoreFacade') {
            setStoreFacadeFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            )
        } else if (name === 'BrandFloorSpace') {
            setBrandFloorSpaceFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            )
        } else if (name === 'Adjacent') {
            setAdjacentFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            )
        } else if (name === 'Other') {
            setOtherFile(
                {
                    store_id: '',
                    brand_id: '',
                    brand_name: '',
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            )
        }
    }

    useEffect(() => {
        const filesToUpload = [
            mouAggrementFile,
            gstDocumentParentStoreFile,
            gstDocumentStoreFile,
            panDocumentFile,
            uploadStorePictureFile,
            storeFacadeFile,
            brandFloorSpaceFile,
            adjacentFile,
            otherFile
        ].filter(file => file);

        setUploadedFiles(filesToUpload);
    }, [mouAggrementFile, gstDocumentParentStoreFile, gstDocumentStoreFile, panDocumentFile, uploadStorePictureFile, storeFacadeFile, brandFloorSpaceFile, adjacentFile, otherFile])


    // const handleFileDrop = (files, fileType) => {
    //     const fileUploaded = files[0];
    //     if (fileUploaded) {
    //         setUploadedFiles(prevFiles => ({ ...prevFiles, [fileType]: fileUploaded }));
    //     }
    // };
    // End

    const [isSameAsBilling, setIsSameAsBilling] = useState(false);
    const [isSameShippingPlaceAddress, setIsSameShippingPlaceAddress] = useState(false);
    const [isSameWarehouseAddress, setIsSameWarehouseAddress] = useState(false);
    const [inputChecked, setInputChecked] = useState({ billingAddress: false, shippingAddress: false, });

    const initialState = {
        address: '',
        pincode: '',
        area: '',
        city: '',
        region: '',
        state: '',
        email: '',
        phone_number: '',
        mobile: '',
    };

    const initialStateNull = {
        address: null,
        pincode: null,
        area: null,
        city: null,
        region: null,
        state: null,
        email: null,
        phone_number: null,
        mobile: null,
    };

    const handleCopyAddress = (e, sourceKey, targetKey, setIsSame, formErrorKey) => {
        const isChecked = e.target.checked;
        setIsSame(isChecked);

        if (isChecked) {
            const sourceAddress = storeAddressesDetails?.[sourceKey];
            setStoreAddressesDetails((prevDetails) => ({
                ...prevDetails,
                [targetKey]: { ...sourceAddress },
            }));

            // setStoreAddressesDetailsFormError((prevErrors) => ({
            //     ...prevErrors,
            //     [formErrorKey]: initialStateNull,
            // }));

            const newFormError = {};
            for (let key in initialState) {
                if (!sourceAddress?.[key]) {
                    newFormError[key] = ''; // Set error as empty string
                } else {
                    newFormError[key] = null; // Clear the error
                }
            }

            // Set the form error for the target address
            setStoreAddressesDetailsFormError((prevErrors) => ({
                ...prevErrors,
                [formErrorKey]: { ...newFormError },
            }));
        } else {
            setStoreAddressesDetails((prevDetails) => ({
                ...prevDetails,
                [targetKey]: initialState,
            }));
            setStoreAddressesDetailsFormError((prevErrors) => ({
                ...prevErrors,
                [formErrorKey]: initialState,
            }));
        }
    };

    // Example usage for each checkbox:
    const handleCopyBillingAddress = (e) => {
        handleCopyAddress(e, 'billing_place_address', 'shipping_place_address', setIsSameAsBilling, 'shipping_place_address');
    }

    const handleCopyBillingAddressForWarehouse = (e) => {
        setInputChecked({
            billingAddress: !inputChecked.billingAddress,
            shippingAddress: false, // Uncheck the other checkbox
        });
        handleCopyAddress(e, 'billing_place_address', 'warehouse_address', setIsSameShippingPlaceAddress, 'warehouse_address');
    }

    const handleCopyShippingAddressForWarehouse = (e) => {
        setInputChecked({
            billingAddress: false, // Uncheck the other checkbox
            shippingAddress: !inputChecked.shippingAddress,
        });
        handleCopyAddress(e, 'shipping_place_address', 'warehouse_address', setIsSameShippingPlaceAddress, 'warehouse_address');
    }

    const handleCopyWarehouseAddress = (e) => {
        handleCopyAddress(e, 'warehouse_address', 'return_warehouse_address', setIsSameWarehouseAddress, 'return_warehouse_address');
    }






    const displayFormContent = () => {
        if (activeStep === 0) {
            return (
                <div>
                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                        Store Owner Details
                    </div>
                    <div ref={zerothTabRef} className='pt-3 formContainer customScroll'>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(0, 'ownerDetails', 'constitution_of_firm', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setInputConstitutionOfFirm(newInputValue)} inputValue={inputConstitutionOfFirm} value={ownerDetails?.constitution_of_firm} options={constitutionOfFirm} required label='Constitution of the firm*' />
                            {ownerDetailsFormError?.constitution_of_firm && <div className="error">{ownerDetailsFormError?.constitution_of_firm}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={ownerDetails?.contact_detail?.person_name} onChange={(e) => handleInputChange(0, 'ownerDetails', { parent: 'contact_detail', child: 'person_name' }, e.target.value, validateTextInputAnyMin, CustomErrorMsg?.name)} required minLength={3} maxLength={80} label='Owner Name' />
                            {ownerDetailsFormError?.contact_detail?.person_name && <div className="error">{ownerDetailsFormError?.contact_detail?.person_name}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={ownerDetails?.contact_detail?.mobile} onChange={(e) => handleInputChange(0, 'ownerDetails', { parent: 'contact_detail', child: 'mobile' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Owner Mobile Number' />
                            {ownerDetailsFormError?.contact_detail?.mobile && <div className="error">{ownerDetailsFormError?.contact_detail?.mobile}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={ownerDetails?.contact_detail?.email} onChange={(e) => handleInputChange(0, 'ownerDetails', { parent: 'contact_detail', child: 'email' }, e.target.value, validateEmailInput, CustomErrorMsg?.email)} required minLength={''} maxLength={''} label='Owner E-Mail Id' />
                            {ownerDetailsFormError?.contact_detail?.email && <div className="error">{ownerDetailsFormError?.contact_detail?.email}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={ownerDetails?.authorise_signitory_name} onChange={(e) => handleInputChange(0, 'ownerDetails', 'authorise_signitory_name', e.target.value, validateTextInputAnyMin, CustomErrorMsg?.name)} required minLength={2} maxLength={40} label='Authorise Signatory Name' />
                            {ownerDetailsFormError?.authorise_signitory_name && <div className="error">{ownerDetailsFormError?.authorise_signitory_name}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={ownerDetails?.address?.address} onChange={(e) => handleInputChange(0, 'ownerDetails', { parent: 'address', child: 'address' }, e.target.value, validateTextAddressInput, CustomErrorMsg?.address)} required minLength={10} maxLength={100} label='Principle Place Of Business Address' />
                            {ownerDetailsFormError?.address?.address && <div className="error">{ownerDetailsFormError?.address?.address}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={ownerDetails?.address?.pincode} onChange={(e) => handleInputChange(0, 'ownerDetails', { parent: 'address', child: 'pincode' }, e.target.value, validatePinCodeInput, CustomErrorMsg?.pincode)} required minLength={6} maxLength={6} label='Principle Place Pin Code' />
                            {ownerDetailsFormError?.address?.pincode && <div className="error">{ownerDetailsFormError?.address?.pincode}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={true} value={ownerDetails?.address?.area} required label='Principle Place Area' />
                            {ownerDetailsFormError?.address?.area && <div className="error">{ownerDetailsFormError?.address?.area}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={true} value={ownerDetails?.address?.city} required label='Principle Place City' />
                            {ownerDetailsFormError?.address?.city && <div className="error">{ownerDetailsFormError?.address?.city}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={true} value={ownerDetails?.address?.state} required label='Principle Place State' />
                            {ownerDetailsFormError?.address?.state && <div className="error">{ownerDetailsFormError?.address?.state}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={true} value={ownerDetails?.address?.region} required label='Principle Place Region' />
                            {ownerDetailsFormError?.address?.region && <div className="error">{ownerDetailsFormError?.address?.region}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={ownerDetails?.business_incharge_name} onChange={(e) => handleInputChange(0, 'ownerDetails', 'business_incharge_name', e.target.value, validateTextInputAnyMin, CustomErrorMsg?.name)} required minLength={3} maxLength={80} label='Business Incharge Name' />
                            {ownerDetailsFormError?.business_incharge_name && <div className="error">{ownerDetailsFormError?.business_incharge_name}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={ownerDetails?.business_incharge_mobile_number} onChange={(e) => handleInputChange(0, 'ownerDetails', 'business_incharge_mobile_number', e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Business Incharge Number' />
                            {ownerDetailsFormError?.business_incharge_mobile_number && <div className="error">{ownerDetailsFormError?.business_incharge_mobile_number}</div>}
                        </div>
                    </div>
                </div>
            );
        } else if (activeStep === 1) {
            return (
                <div>
                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                        Store Demographic Details
                    </div>
                    <div ref={firstTabRef} className='pt-3 formContainer customScroll'>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeDemographic?.customer_name} onChange={(e) => handleInputChange(1, 'storeDemographic', 'customer_name', e.target.value, validateTextInputAnyMin, CustomErrorMsg?.name)} required minLength={3} maxLength={100} label='Store Name' />
                            {storeDemographicFormError?.customer_name && <div className="error">{storeDemographicFormError?.customer_name}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeDemographic?.store_legal_name} onChange={(e) => handleInputChange(1, 'storeDemographic', 'store_legal_name', e.target.value, validateTextInputAnyMin, CustomErrorMsg?.name)} required minLength={3} maxLength={100} label='Store Legal Name' />
                            {storeDemographicFormError?.store_legal_name && <div className="error">{storeDemographicFormError?.store_legal_name}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeDemographic?.store_trade_name} onChange={(e) => handleInputChange(1, 'storeDemographic', 'store_trade_name', e.target.value, validateTextInputAnyMin, CustomErrorMsg?.name)} required minLength={3} maxLength={100} label='Store Trade Name' />
                            {storeDemographicFormError?.store_trade_name && <div className="error">{storeDemographicFormError?.store_trade_name}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeDemographic?.latitude} onChange={(e) => handleInputChange(1, 'storeDemographic', 'latitude', e.target.value, validateLatitude, CustomErrorMsg?.latitude)} required minLength={''} maxLength={''} label='Latitude' />
                            {storeDemographicFormError?.latitude && <div className="error">{storeDemographicFormError?.latitude}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeDemographic?.longitude} onChange={(e) => handleInputChange(1, 'storeDemographic', 'longitude', e.target.value, validateLongitude, CustomErrorMsg?.longitude)} required minLength={''} maxLength={''} label='Longitude' />
                            {storeDemographicFormError?.longitude && <div className="error">{storeDemographicFormError?.longitude}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(1, 'storeDemographic', 'store_status', newValue, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={selectedStoreStatus} options={STORE_STATUS} required label='Store Status*' />
                            {storeDemographicFormError?.store_status && <div className="error">{storeDemographicFormError?.store_status}</div>}
                        </div>
                        {/* <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeDemographic?.store_floor_size} onChange={(e) => handleInputChange(1, 'storeDemographic', 'store_floor_size', handleNumericInput(e.target.value), validateNumber2To6Input, CustomErrorMsg?.)} minLength={2} maxLength={6} label='Store Floor Size' />
                            {storeDemographicFormError?.store_floor_size && <div className="error">{storeDemographicFormError?.store_floor_size}</div>}
                        </div> */}
                        <div className='inputBottomSpace'>
                            <CustomDatePicker disabled={false}
                                // value={storeDemographic?.store_opening} 
                                selectedDate={storeDemographic?.store_opening ? dayjs(storeDemographic?.store_opening) : null}
                                defaultValue={storeDemographic?.store_opening ? dayjs(storeDemographic?.store_opening) : null}
                                onDateChange={(date) => {
                                    const newDate = new Date(date?.$d);
                                    handleInputChange(1, 'storeDemographic', 'store_opening', newDate.toISOString(), validateDateFormat, CustomErrorMsg?.date);
                                }} disableFutureDates={true} required minLength={''} maxLength={''} label='Store Opening Date*' />
                            {storeDemographicFormError?.store_opening && <div className="error">{storeDemographicFormError?.store_opening}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(1, 'storeDemographic', 'store_opening_status', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreOpeningStatus(newInputValue)} inputValue={storeOpeningStatus} value={storeDemographic?.store_opening_status} options={storeOpeningStatusDropData} required label='Store Opening Status*' />
                            {storeDemographicFormError?.store_opening_status && <div className="error">{storeDemographicFormError?.store_opening_status}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(1, 'storeDemographic', 'store_type', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeDemographic?.store_type} options={storeTypeDropData} required label='Store Type*' />
                            {storeDemographicFormError?.store_type && <div className="error">{storeDemographicFormError?.store_type}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <MultiSelectCheckBox handleChange={(e) => handleInputChange(1, 'storeDemographic', 'store_classification', e.target.value, validateTextInputAny, 'Please Select Any Value')} selectedValue={storeDemographic?.store_classification} options={storeClassification} MenuProps={MenuProps} label='Store Classification' />
                            {/* {storeDemographic?.store_classification?.length === 0 && <div className="error">Please Slect Any Value</div>} */}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(1, 'storeDemographic', 'store_class', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeDemographic?.store_class} options={storeClass} label='Store Class' />
                            {/* {storeDemographicFormError?.store_class && <div className="error">{storeDemographicFormError?.store_class}</div>} */}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(1, 'storeDemographic', 'store_retail_billing', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeDemographic?.store_retail_billing} options={storeRetailBillingSystem} required label='Store Retail Billing System*' />
                            {storeDemographicFormError?.store_retail_billing && <div className="error">{storeDemographicFormError?.store_retail_billing}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeDemographic?.store_details} onChange={(e) => handleInputChange(1, 'storeDemographic', 'store_details', e.target.value, validateText2To200Input, CustomErrorMsg?.details)} minLength={2} maxLength={100} label='Store Details' />
                            {/* {storeDemographicFormError?.store_details && <div className="error">{storeDemographicFormError?.store_details}</div>} */}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeDemographic?.cust_no} onChange={(e) => handleInputChange(1, 'storeDemographic', 'cust_no', e.target.value, validateTextUnderscoreSlash, CustomErrorMsg?.custNum)} required minLength={1} maxLength={""} label='Cust_No / Store Code' />
                            {storeDemographicFormError?.cust_no && <div className="error">{storeDemographicFormError?.cust_no}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeDemographic?.address?.email} onChange={(e) => handleInputChange(1, 'storeDemographic', { parent: 'address', child: 'email' }, e.target.value, validateEmailInput, CustomErrorMsg?.email)} required minLength={''} maxLength={''} label='E-Mail Id' />
                            {storeDemographicFormError?.address?.email && <div className="error">{storeDemographicFormError?.address?.email}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeDemographic?.address?.mobile} onChange={(e) => handleInputChange(1, 'storeDemographic', { parent: 'address', child: 'mobile' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Mobile Number' />
                            {storeDemographicFormError?.address?.mobile && <div className="error">{storeDemographicFormError?.address?.mobile}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeDemographic?.gstin} onChange={(e) => handleInputChange(1, 'storeDemographic', 'gstin', e.target.value, validateGSTINInput, CustomErrorMsg?.gstin)} required minLength={15} maxLength={15} label='GSTIN' />
                            {storeDemographicFormError?.gstin && <div className="error">{storeDemographicFormError?.gstin}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(1, 'storeDemographic', 'gst_slab', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeDemographic?.gst_slab} options={storeGstSlab} required label='GST Slab*' />
                            {storeDemographicFormError?.gst_slab && <div className="error">{storeDemographicFormError?.gst_slab}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeDemographic?.address?.address} onChange={(e) => handleInputChange(1, 'storeDemographic', { parent: 'address', child: 'address' }, e.target.value, validateTextAddressInput, CustomErrorMsg?.address)} required minLength={10} maxLength={100} label='Address' />
                            {storeDemographicFormError?.address?.address && <div className="error">{storeDemographicFormError?.address?.address}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeDemographic?.address?.pincode} onChange={(e) => handleInputChange(1, 'storeDemographic', { parent: 'address', child: 'pincode' }, e.target.value, validatePinCodeInput, CustomErrorMsg?.pincode)} required minLength={6} maxLength={6} label='Pin Code' />
                            {storeDemographicFormError?.address?.pincode && <div className="error">{storeDemographicFormError?.address?.pincode}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={true} value={storeDemographic?.address?.area} required minLength={''} maxLength={''} label='Area' />
                            {storeDemographicFormError?.address?.area && <div className="error">{storeDemographicFormError?.address?.area}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={true} value={storeDemographic?.address?.city} required minLength={''} maxLength={''} label='City' />
                            {storeDemographicFormError?.address?.city && <div className="error">{storeDemographicFormError?.address?.city}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(1, 'storeDemographic', 'city_tier', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreOpeningStatus(newInputValue)} inputValue={storeOpeningStatus} value={storeDemographic?.city_tier} options={cityTier} label='City Tier' />
                            {storeDemographicFormError?.city_tier && <div className="error">{storeDemographicFormError?.city_tier}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={true} value={storeDemographic?.address?.state} required minLength={''} maxLength={''} label='State' />
                            {storeDemographicFormError?.address?.state && <div className="error">{storeDemographicFormError?.address?.state}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={true} value={storeDemographic?.address?.region} required minLength={''} maxLength={''} label='Region' />
                            {storeDemographicFormError?.address?.region && <div className="error">{storeDemographicFormError?.address?.region}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabledClass={'disabledClass'} disabled={true} value={storeDemographic?.regional_manager} onChange={(e) => handleInputChange(1, 'storeDemographic', 'regional_manager', e.target.value, validateTextDotCommaSpace, CustomErrorMsg?.regionalManager)} minLength={3} maxLength={80} label='Regional Manager' />
                            {storeDemographicFormError?.regional_manager && <div className="error">{storeDemographicFormError?.regional_manager}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeDemographic?.pan_number} onChange={(e) => handleInputChange(1, 'storeDemographic', 'pan_number', e.target.value, validatePANInput, CustomErrorMsg?.pan)} required minLength={10} maxLength={10} label='Pan Card' />
                            {storeDemographicFormError?.pan_number && <div className="error">{storeDemographicFormError?.pan_number}</div>}
                        </div>
                    </div>
                </div>
            )
        } else if (activeStep === 2) {
            return (
                <div>
                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                        Parent Store Details
                    </div>
                    <div ref={secondTabRef} className='pt-3 formContainer customScroll ParentStoreDetails'>
                        {/* <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={parentStore?.parent_store_id} onChange={(e) => handleInputChange(1, 'parentStore', 'parent_store_id', e.target.value, validateTextInput, CustomErrorMsg?.)} required minLength={''} maxLength={''} label='Parent Store Id' />
                            {parentStoreFormError?.parent_store_id && <div className="error">{parentStoreFormError?.parent_store_id}</div>}
                        </div> */}
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={parentStore?.parent_store_name} onChange={(e) => handleInputChange(2, 'parentStore', 'parent_store_name', e.target.value, validateTextInputAnyMin, CustomErrorMsg?.name)} required minLength={3} maxLength={100} label='Parent Store Name' />
                            {parentStoreFormError?.parent_store_name && <div className="error">{parentStoreFormError?.parent_store_name}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={parentStore?.parent_store_gstin} onChange={(e) => handleInputChange(2, 'parentStore', 'parent_store_gstin', e.target.value, validateGSTINInput, CustomErrorMsg?.gstin)} required minLength={15} maxLength={15} label='Parent Store Gstin' />
                            {parentStoreFormError?.parent_store_gstin && <div className="error">{parentStoreFormError?.parent_store_gstin}</div>}
                        </div>
                    </div>
                </div>
            )
        } else if (activeStep === 3) {
            return (
                <div>
                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                        Store Contact’s Details
                    </div>
                    <div className='brandContactsDetals customScroll'>
                        <div ref={thirdTabRef} className='' style={{ position: 'relative' }}>
                            {
                                contactDetails && contactDetails?.map((item, index) => (
                                    <>
                                        <div className='pt-3'>
                                            <strong>Contacts {index + 1}</strong>
                                        </div>

                                        <div className='pt-3 formContainer' key={index}>

                                            <div className='inputBottomSpace'>
                                                <InputTextField type='text' disabled={false} name={'person_name'} value={item?.person_name} onChange={(event) => handleBrandContactsDetailsChange(event, index)} required minLength={3} maxLength={80} label='Contact Name' />
                                                {contactDetailsFormError?.person_name && <div className="error">{contactDetailsFormError?.person_name}</div>}
                                            </div>
                                            <div className='inputBottomSpace'>
                                                <InputTextField type='text' disabled={false} name={'person_dept'} value={item?.person_dept} onChange={(event) => handleBrandContactsDetailsChange(event, index)} required minLength={2} maxLength={40} label='Department' />
                                                {contactDetailsFormError?.person_dept && <div className="error">{contactDetailsFormError?.person_dept}</div>}
                                            </div>
                                            <div className='inputBottomSpace'>
                                                <InputTextField type='text' disabled={false} name={'phone_number'} value={item?.phone_number} onChange={(event) => handleBrandContactsDetailsChange(event, index)} required minLength={10} maxLength={10} label='Contact’s Number' />
                                                {contactDetailsFormError?.phone_number && <div className="error">{contactDetailsFormError?.phone_number}</div>}
                                            </div>
                                            <div className='inputBottomSpace'>
                                                <InputTextField type='text' disabled={false} name={'email'} value={item?.email} onChange={(event) => handleBrandContactsDetailsChange(event, index)} required minLength={''} maxLength={40} label='Contact’s Email Id' />
                                                {contactDetailsFormError?.email && <div className="error">{contactDetailsFormError?.email}</div>}
                                            </div>
                                            <div className='inputBottomSpace'>
                                                <InputTextField type='text' disabled={false} name={'person_other'} value={item?.person_other} onChange={(event) => handleBrandContactsDetailsChange(event, index)} label='Contact Person Other Details' />
                                                {/* {contactDetailsFormError.person_other && <div className="error">{contactDetailsFormError.person_other}</div>} */}
                                            </div>
                                        </div>
                                    </>
                                ))
                            }

                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', position: 'absolute', right: '15px', bottom: '-30px' }} >
                                <div onClick={addNewContactDetailsForm} className='' style={{ width: '30px', height: '30px', display: 'flex', border: '1px solid grey', borderRadius: '50%', cursor: 'pointer' }}>
                                    <img src={PlusIcon} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (activeStep === 4) {
            return (
                <div>
                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                        Store Contract Details
                    </div>
                    <div ref={forthTabRef} className='pt-3 formContainer customScroll'>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'distribution_type', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.distribution_type} options={distributionType} required label='Distributor/Direct*' />
                            {storeContractDetailsFormError?.distribution_type && <div className="error">{storeContractDetailsFormError?.distribution_type}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'channel', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.channel} options={channelDrop} required label='Channel*' />
                            {storeContractDetailsFormError?.channel && <div className="error">{storeContractDetailsFormError?.channel}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'fresh_margin_type', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.fresh_margin_type} options={freshMarginType} required label='Fresh Margin Type*' />
                            {storeContractDetailsFormError?.fresh_margin_type && <div className="error">{storeContractDetailsFormError?.fresh_margin_type}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeContractDetails.fresh_margin} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'fresh_margin', e.target.value, isNumberOrFloat, CustomErrorMsg?.margin)} required minLength={1} maxLength={6} label='Fresh Margin %' />
                            {storeContractDetailsFormError?.fresh_margin && <div className="error">{storeContractDetailsFormError?.fresh_margin}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'eoss_margin_type', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.eoss_margin_type} options={eossMarginType} required label='EOSS Margin Type*' />
                            {storeContractDetailsFormError?.eoss_margin_type && <div className="error">{storeContractDetailsFormError?.eoss_margin_type}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeContractDetails.eoss_margin} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'eoss_margin', e.target.value, isNumberOrFloat, CustomErrorMsg?.eossmargin)} required minLength={1} maxLength={6} label='EOSS Margin %' />
                            {storeContractDetailsFormError?.eoss_margin && <div className="error">{storeContractDetailsFormError?.eoss_margin}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeContractDetails.eoss_threshold_value} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'eoss_threshold_value', e.target.value, isNumberOrFloat, CustomErrorMsg?.eossthresholdvalue)} required minLength={1} maxLength={6} label='EOSS Threshold % Value' />
                            {storeContractDetailsFormError?.eoss_threshold_value && <div className="error">{storeContractDetailsFormError?.eoss_threshold_value}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeContractDetails.base_margin} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'base_margin', e.target.value, isNumberOrFloat, CustomErrorMsg?.basemargin)} required minLength={1} maxLength={6} label='BASE Margin %' />
                            {storeContractDetailsFormError?.base_margin && <div className="error">{storeContractDetailsFormError?.base_margin}</div>}
                        </div>

                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeContractDetails.markdown_percentage} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'markdown_percentage', e.target.value, isNumberOrFloat, CustomErrorMsg?.percent)} required minLength={1} maxLength={6} label='Markdown %' />
                            {storeContractDetailsFormError?.markdown_percentage && <div className="error">{storeContractDetailsFormError?.markdown_percentage}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeContractDetails.markdown_percentage_type} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'markdown_percentage_type', e.target.value, validateTextInputAny, CustomErrorMsg?.percentType)} required minLength={2} maxLength={20} label='Markdown % Type' />
                            {storeContractDetailsFormError?.markdown_percentage_type && <div className="error">{storeContractDetailsFormError?.markdown_percentage_type}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'discount', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.discount} options={DISCOUNT} required label='Discount*' />
                            {storeContractDetailsFormError?.discount && <div className="error">{storeContractDetailsFormError?.discount}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete disabled={isDiscount} onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'discount_percentage', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.discount_percentage} options={discountPercentage} required label='Discount Percentage*' />
                            {storeContractDetailsFormError?.discount_percentage && <div className="error">{storeContractDetailsFormError?.discount_percentage}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'target_linked_insentives', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.target_linked_insentives} options={TARGET_LINKED_INSENTIVES} required label='Target Linked Insentives*' />
                            {storeContractDetailsFormError?.target_linked_insentives && <div className="error">{storeContractDetailsFormError?.target_linked_insentives}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField disabledClass={isTargetLinkedInsentives ? 'disabledClass' : ''} type='text' disabled={isTargetLinkedInsentives} value={storeContractDetails.target_linked_insentives_percentagre} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'target_linked_insentives_percentagre', e.target.value, isNumberOrFloat, CustomErrorMsg?.percent)} required minLength={1} maxLength={6} label='Target Linked Incentives %' />
                            {storeContractDetailsFormError?.target_linked_insentives_percentagre && <div className="error">{storeContractDetailsFormError?.target_linked_insentives_percentagre}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'cash_discount', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.cash_discount} options={CASH_DISCOUNT} required label='Cash Discount*' />
                            {storeContractDetailsFormError?.cash_discount && <div className="error">{storeContractDetailsFormError?.cash_discount}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField disabledClass={isCashDiscount ? 'disabledClass' : ''} type='text' disabled={isCashDiscount} value={storeContractDetails.cash_discount_percentage} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'cash_discount_percentage', e.target.value, isNumberOrFloat, CustomErrorMsg?.percent)} required minLength={1} maxLength={6} label='Cash Discount %' />
                            {storeContractDetailsFormError?.cash_discount_percentage && <div className="error">{storeContractDetailsFormError?.cash_discount_percentage}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'cash_discount_type', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.cash_discount_type} options={cashDiscountType} required label='Cash Discount Type*' />
                            {storeContractDetailsFormError?.cash_discount_type && <div className="error">{storeContractDetailsFormError?.cash_discount_type}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeContractDetails.payable_duration} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'payable_duration', e.target.value, validateTextInputAny, CustomErrorMsg?.payableDuration)} required minLength={1} maxLength={3} label='Payable Duration' />
                            {storeContractDetailsFormError?.payable_duration && <div className="error">{storeContractDetailsFormError?.payable_duration}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'stock_correction', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.stock_correction} options={StockCorrection} required label='Stock Correction %*' />
                            {storeContractDetailsFormError?.stock_correction && <div className="error">{storeContractDetailsFormError?.stock_correction}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'promo_contribution', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.promo_contribution} options={promoContribution} required label='Promo Contribution %*' />
                            {storeContractDetailsFormError?.promo_contribution && <div className="error">{storeContractDetailsFormError?.promo_contribution}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete onChange={(e, newValue) => handleInputChange(4, 'storeContractDetails', 'is_security', newValue?.label, validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setStoreStatus(newInputValue)} inputValue={storeStatus} value={storeContractDetails?.is_security} options={securityDrop} required label='Security*' />
                            {storeContractDetailsFormError?.is_security && <div className="error">{storeContractDetailsFormError?.is_security}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeContractDetails.comments} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'comments', e.target.value, validateText2To200Input, CustomErrorMsg?.comment)} required minLength={2} maxLength={200} label='Security Comment' />
                            {storeContractDetailsFormError?.comments && <div className="error">{storeContractDetailsFormError?.comments}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeContractDetails.credit_limit} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'credit_limit', e.target.value, validateNumberAtLeast1Input, CustomErrorMsg?.creditLimit)} required minLength={2} maxLength={''} label='Credit Limit' />
                            {storeContractDetailsFormError?.credit_limit && <div className="error">{storeContractDetailsFormError?.credit_limit}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <MultiSelectCheckBox handleChange={(e) => handleInputChange(4, 'storeContractDetails', 'business_model', e.target.value, validateTextInputAny, 'Please Select Any Value')} selectedValue={storeContractDetails?.business_model} options={businessModelDrop} MenuProps={MenuProps} label='Business Model*' />
                            {/* {storeContractDetails?.business_model?.length === 0 && <div className="error">Please Slect Any Value</div>} */}
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeContractDetails.store_floor_size} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'store_floor_size', e.target.value, validateNumber2To6Input, CustomErrorMsg?.size)} minLength={2} maxLength={6} label='Store Floor Size' />
                            {/* {storeContractDetailsFormError?.store_floor_size && <div className="error">{storeContractDetailsFormError?.store_floor_size}</div>} */}
                        </div>
                        {/* <div className='inputBottomSpace'>
                            <InputTextField type='text' disabled={false} value={storeContractDetails.primary_billing_discount} onChange={(e) => handleInputChange(4, 'storeContractDetails', 'primary_billing_discount', e.target.value, validateNumberAtLeast1Input, CustomErrorMsg?.size)} minLength={2} maxLength={6} label='Primary Billing Discount' />
                        </div> */}
                    </div>
                </div>
            )
        } else if (activeStep === 5) {
            return (
                <div>
                    <div className='montSerratFont fontMedium fontSize18 textColorBlack' style={{ position: 'relative' }}>
                        Store Wall Details
                    </div>

                    <div ref={fifthTabRef} className='brandContactsDetals customScroll'>
                        {
                            storeWallBrandsDetails && storeWallBrandsDetails?.map((item, index) => (
                                <div key={index}>
                                    <div className='pt-3'>
                                        <strong>Store Wall {index + 1}</strong>
                                    </div>

                                    <div className='pt-3 formContainer'>
                                        <div className='inputBottomSpace'>
                                            <CustomAutoComplete onChange={(event, newValue) => handleStoreWallBrandsDetailsDetailsChange(event, newValue, index, 'brand_name')} onInputChange={(e, newInputValue) => { setAllBrandsListData(newInputValue) }} inputValue={allBrandStatus} value={item?.brand_name} options={allBrandsListData} required label='Brand Name*' />
                                            {storeWallBrandsDetailsFormError?.brand_name && <div className="error">{storeWallBrandsDetailsFormError?.brand_name}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} name={'wall_space'} value={item?.wall_space} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'wall_space')} required minLength={1} maxLength={3} label='Wall Space (ft)' />
                                            {storeWallBrandsDetailsFormError?.wall_space && <div className="error">{storeWallBrandsDetailsFormError?.wall_space}</div>}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} name={'enter_cdu'} value={item?.enter_cdu} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'enter_cdu')} minLength={1} maxLength={3} label='CDU (ft)' />
                                            {/* {storeWallBrandsDetailsFormError?.enter_cdu && <div className="error">{storeWallBrandsDetailsFormError?.enter_cdu}</div>} */}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} name={'default_length'} value={item?.default_length} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'default_length')} minLength={1} maxLength={3} label='Default Length (ft)' />
                                            {/* {storeWallBrandsDetailsFormError?.default_length && <div className="error">{storeWallBrandsDetailsFormError?.default_length}</div>} */}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} name={'total_sq_ft'} value={item?.total_sq_ft} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'total_sq_ft')} minLength={2} maxLength={6} label='Total Sq Ft' />
                                            {/* {storeWallBrandsDetailsFormError.total_sq_ft && <div className="error">{storeWallBrandsDetailsFormError.total_sq_ft}</div>} */}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} name={'display_options'} value={item?.display_options} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'display_options')} minLength={1} maxLength={3} label='Display Options (Qty)' />
                                            {/* {storeWallBrandsDetailsFormError?.display_options && <div className="error">{storeWallBrandsDetailsFormError?.display_options}</div>} */}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} name={'per_running_feet'} value={item?.per_running_feet} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'per_running_feet')} minLength={1} maxLength={3} label='Option Per Running Feet (Qty)' />
                                            {/* {storeWallBrandsDetailsFormError?.per_running_feet && <div className="error">{storeWallBrandsDetailsFormError?.per_running_feet}</div>} */}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <MultiSelectCheckBox disabled={false} name={'floor_placement'} handleChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'floor_placement')} selectedValue={item?.floor_placement} options={floorPlacementDrop} MenuProps={MenuProps} label='Floor Placement' />
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} name={'display_qty'} value={item?.display_qty} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'display_qty')} minLength={1} maxLength={6} label='Display Quantity (Qty)' />
                                            {/* {storeWallBrandsDetailsFormError.display_qty && <div className="error">{storeWallBrandsDetailsFormError.display_qty}</div>} */}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} name={'depth_per_option'} value={item?.depth_per_option} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'depth_per_option')} minLength={1} maxLength={3} label='Depth Per Option (Qty)' />
                                            {/* {storeWallBrandsDetailsFormError.depth_per_option && <div className="error">{storeWallBrandsDetailsFormError.depth_per_option}</div>} */}
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <MultiSelectCheckBox disabled={false} name={'category_division'} handleChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'category_division')} selectedValue={item?.category_division} options={categoryDivisionDrop} MenuProps={MenuProps} label='Category Division*' />
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <MultiSelectCheckBox disabled={categoryGroupDrop[index]?.length === 0} name={'category_group'} handleChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'category_group')} selectedValue={item?.category_group} options={categoryGroupDrop[index]} MenuProps={MenuProps} label='Category Group*' />
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <MultiSelectCheckBox disabled={categoryPresenceDrop[index]?.length === 0} name={'category_presence'} handleChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'category_presence')} selectedValue={item?.category_presence} options={categoryPresenceDrop[index]} MenuProps={MenuProps} label='Category Presence*' />
                                        </div>
                                        {/* <div className='inputBottomSpace'>
                                            <CustomAutoComplete disabled={true} disabledClass={'disabledClass'} name={'is_brand_staff'} onChange={(event, newValue) => { handleStoreWallBrandsDetailsDetailsChange(event, newValue, index, 'is_brand_staff') }} onInputChange={(e, newInputValue) => setISBRANDSTAFFStatus(newInputValue)} inputValue={iSBRANDSTAFFStatus} value={isBrandStaff[index]} options={IS_BRAND_STAFF} label='Brand Staff' />
                                        </div>
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabledClass={'disabledClass'} disabled={true} name={'brand_staff_name'} value={item?.brand_staff_name} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'brand_staff_name')} minLength={3} maxLength={80} label='Staff Name' />
                                        </div> */}
                                        <div className='inputBottomSpace'>
                                            <InputTextField type='text' disabled={false} name={'brand_staff_terms'} value={item?.brand_staff_terms} onChange={(event) => handleStoreWallBrandsDetailsDetailsChange(event, '', index, 'brand_staff_terms')} minLength={2} maxLength={100} label='Staff Terms' />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', paddingRight: '10px' }} >
                            <div onClick={addNewStoreWallBrandsDetailsForm} className='' style={{ width: '30px', height: '30px', display: 'flex', border: '1px solid grey', borderRadius: '50%', cursor: 'pointer' }}>
                                <img src={PlusIcon} alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            )
        } else if (activeStep === 6) {
            return (
                <div>
                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                        Address details
                    </div>
                    <div ref={sixthTabRef} className='pt-3 customScroll addressDetailsMainCont'>
                        <div className='addressDetailsCont'>
                            <div>
                                <div className='text-decoration-underline fontSize14 pb-3'>Billing Address</div>
                                <div className='formContainer addressesDetailsCont'>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.billing_place_address?.address} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'billing_place_address', child: 'address' }, e.target.value, validateTextAddressInput, CustomErrorMsg?.address)} required minLength={10} maxLength={100} label='Address' />
                                        {storeAddressesDetailsFormError?.billing_place_address?.address && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.address}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.billing_place_address?.pincode} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'billing_place_address', child: 'pincode' }, e.target.value, validatePinCodeInput, CustomErrorMsg?.pincode)} required minLength={6} maxLength={6} label='Pin Code' />
                                        {storeAddressesDetailsFormError?.billing_place_address?.pincode && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.pincode}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.billing_place_address?.area} required minLength={''} maxLength={''} label='Area' />
                                        {storeAddressesDetailsFormError?.billing_place_address?.area && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.area}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.billing_place_address?.city} required minLength={''} maxLength={''} label='City' />
                                        {storeAddressesDetailsFormError?.billing_place_address?.city && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.city}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.billing_place_address?.region} required minLength={''} maxLength={''} label='Region' />
                                        {storeAddressesDetailsFormError?.billing_place_address?.region && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.region}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.billing_place_address?.state} required minLength={''} maxLength={''} label='State' />
                                        {storeAddressesDetailsFormError?.billing_place_address?.state && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.state}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.billing_place_address?.email} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'billing_place_address', child: 'email' }, e.target.value, validateEmailInput, CustomErrorMsg?.email)} required minLength={''} maxLength={''} label='E-Mail Id' />
                                        {storeAddressesDetailsFormError?.billing_place_address?.email && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.email}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.billing_place_address?.phone_number} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'billing_place_address', child: 'phone_number' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Billing Place Mobile Number' />
                                        {storeAddressesDetailsFormError?.billing_place_address?.phone_number && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.phone_number}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.billing_place_address?.mobile} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'billing_place_address', child: 'mobile' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Person Mobile Number' />
                                        {storeAddressesDetailsFormError?.billing_place_address?.mobile && <div className="error">{storeAddressesDetailsFormError?.billing_place_address?.mobile}</div>}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='text-decoration-underline paddingTop24 fontSize14 pb-3'>
                                    Shipping Address
                                    <span className='copySameAsAbove'>
                                        <input type='checkbox' className='form-check-input' id='sameAsBilling' onChange={handleCopyBillingAddress} />
                                        <label className='form-check-label' htmlFor='sameAsBilling'>
                                            Same as Billing Address
                                        </label>
                                    </span>
                                </div>
                                <div className='formContainer addressesDetailsCont'>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.shipping_place_address?.address} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'shipping_place_address', child: 'address' }, e.target.value, validateTextAddressInput, CustomErrorMsg?.address)} required minLength={10} maxLength={100} label='Address' />
                                        {storeAddressesDetailsFormError?.shipping_place_address?.address && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.address}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.shipping_place_address?.pincode} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'shipping_place_address', child: 'pincode' }, e.target.value, validatePinCodeInput, CustomErrorMsg?.pincode)} required minLength={6} maxLength={6} label='Pin Code' />
                                        {storeAddressesDetailsFormError?.shipping_place_address?.pincode && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.pincode}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.shipping_place_address?.area} required minLength={''} maxLength={''} label='Area' />
                                        {storeAddressesDetailsFormError?.shipping_place_address?.area && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.area}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.shipping_place_address?.city} required minLength={''} maxLength={''} label='City' />
                                        {storeAddressesDetailsFormError?.shipping_place_address?.city && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.city}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.shipping_place_address?.region} required minLength={''} maxLength={''} label='Region' />
                                        {storeAddressesDetailsFormError?.shipping_place_address?.region && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.region}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.shipping_place_address?.state} required minLength={''} maxLength={''} label='State' />
                                        {storeAddressesDetailsFormError?.shipping_place_address?.state && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.state}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.shipping_place_address?.email} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'shipping_place_address', child: 'email' }, e.target.value, validateEmailInput, CustomErrorMsg?.email)} required minLength={''} maxLength={''} label='E-Mail Id' />
                                        {storeAddressesDetailsFormError?.shipping_place_address?.email && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.email}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.shipping_place_address?.phone_number} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'shipping_place_address', child: 'phone_number' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Shipping Place Phone Number' />
                                        {storeAddressesDetailsFormError?.shipping_place_address?.phone_number && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.phone_number}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.shipping_place_address?.mobile} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'shipping_place_address', child: 'mobile' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Person Mobile Number' />
                                        {storeAddressesDetailsFormError?.shipping_place_address?.mobile && <div className="error">{storeAddressesDetailsFormError?.shipping_place_address?.mobile}</div>}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='text-decoration-underline paddingTop24 fontSize14 pb-3'>
                                    Warehouse Address
                                    <span className='copySameAsAbove'>
                                        <input type='checkbox' className='form-check-input' id='sameAsBilling' checked={inputChecked.billingAddress} onChange={handleCopyBillingAddressForWarehouse} />
                                        <label className='form-check-label' htmlFor='sameAsBilling'>
                                            Same as Billing Address
                                        </label>
                                    </span>
                                    <span className='copySameAsAbove'>
                                        <input type='checkbox' className='form-check-input' id='sameAsBilling' checked={inputChecked.shippingAddress} onChange={handleCopyShippingAddressForWarehouse} />
                                        <label className='form-check-label' htmlFor='sameAsBilling'>
                                            Same as Shipping Address
                                        </label>
                                    </span>
                                </div>
                                <div className='formContainer addressesDetailsCont'>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.warehouse_address?.address} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'warehouse_address', child: 'address' }, e.target.value, validateTextAddressInput, CustomErrorMsg?.address)} required minLength={10} maxLength={100} label='Address' />
                                        {storeAddressesDetailsFormError?.warehouse_address?.address && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.address}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.warehouse_address?.pincode} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'warehouse_address', child: 'pincode' }, e.target.value, validatePinCodeInput, CustomErrorMsg?.pincode)} required minLength={6} maxLength={6} label='Pin Code' />
                                        {storeAddressesDetailsFormError?.warehouse_address?.pincode && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.pincode}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.warehouse_address?.area} required minLength={''} maxLength={''} label='Area' />
                                        {storeAddressesDetailsFormError?.warehouse_address?.area && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.area}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.warehouse_address?.city} required minLength={''} maxLength={''} label='City' />
                                        {storeAddressesDetailsFormError?.warehouse_address?.city && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.city}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.warehouse_address?.region} required minLength={''} maxLength={''} label='Region' />
                                        {storeAddressesDetailsFormError?.warehouse_address?.region && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.region}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.warehouse_address?.state} required minLength={''} maxLength={''} label='State' />
                                        {storeAddressesDetailsFormError?.warehouse_address?.state && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.state}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.warehouse_address?.email} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'warehouse_address', child: 'email' }, e.target.value, validateEmailInput, CustomErrorMsg?.email)} required minLength={''} maxLength={''} label='E-Mail Id' />
                                        {storeAddressesDetailsFormError?.warehouse_address?.email && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.email}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.warehouse_address?.phone_number} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'warehouse_address', child: 'phone_number' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Warehouse Phone Number' />
                                        {storeAddressesDetailsFormError?.warehouse_address?.phone_number && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.phone_number}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.warehouse_address?.mobile} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'warehouse_address', child: 'mobile' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Person Mobile Number' />
                                        {storeAddressesDetailsFormError?.warehouse_address?.mobile && <div className="error">{storeAddressesDetailsFormError?.warehouse_address?.mobile}</div>}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='text-decoration-underline paddingTop24 fontSize14 pb-3'>
                                    Return Warehouse Address
                                    <span className='copySameAsAbove'>
                                        <input type='checkbox' className='form-check-input' id='sameAsBilling' onChange={handleCopyWarehouseAddress} />
                                        <label className='form-check-label' htmlFor='sameAsBilling'>
                                            Same as Warehouse Address
                                        </label>
                                    </span>
                                </div>
                                <div className='formContainer addressesDetailsCont'>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.return_warehouse_address?.address} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'return_warehouse_address', child: 'address' }, e.target.value, validateTextAddressInput, CustomErrorMsg?.address)} required minLength={10} maxLength={100} label='Address' />
                                        {storeAddressesDetailsFormError?.return_warehouse_address?.address && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.address}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.return_warehouse_address?.pincode} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'return_warehouse_address', child: 'pincode' }, e.target.value, validatePinCodeInput, CustomErrorMsg?.pincode)} required minLength={6} maxLength={6} label='Pin Code' />
                                        {storeAddressesDetailsFormError?.return_warehouse_address?.pincode && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.pincode}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.return_warehouse_address?.area} required minLength={''} maxLength={''} label='Area' />
                                        {storeAddressesDetailsFormError?.return_warehouse_address?.area && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.area}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.return_warehouse_address?.city} required minLength={''} maxLength={''} label='City' />
                                        {storeAddressesDetailsFormError?.return_warehouse_address?.city && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.city}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.return_warehouse_address?.region} required minLength={''} maxLength={''} label='Region' />
                                        {storeAddressesDetailsFormError?.return_warehouse_address?.region && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.region}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={true} value={storeAddressesDetails?.return_warehouse_address?.state} required minLength={''} maxLength={''} label='State' />
                                        {storeAddressesDetailsFormError?.return_warehouse_address?.state && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.state}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.return_warehouse_address?.email} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'return_warehouse_address', child: 'email' }, e.target.value, validateEmailInput, CustomErrorMsg?.email)} required minLength={''} maxLength={''} label='E-Mail Id' />
                                        {storeAddressesDetailsFormError?.return_warehouse_address?.email && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.email}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.return_warehouse_address?.phone_number} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'return_warehouse_address', child: 'phone_number' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label="Return Warehouse's Phone Number" />
                                        {storeAddressesDetailsFormError?.return_warehouse_address?.phone_number && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.phone_number}</div>}
                                    </div>
                                    <div className='inputBottomSpace'>
                                        <InputTextField type='text' disabled={false} value={storeAddressesDetails?.return_warehouse_address?.mobile} onChange={(e) => handleInputChange(6, 'storeAddressesDetails', { parent: 'return_warehouse_address', child: 'mobile' }, e.target.value, validateNumberInput, CustomErrorMsg?.mobile)} required minLength={10} maxLength={10} label='Person Mobile Number' />
                                        {storeAddressesDetailsFormError?.return_warehouse_address?.mobile && <div className="error">{storeAddressesDetailsFormError?.return_warehouse_address?.mobile}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        // else if (activeStep === 7) {
        //     return <div>
        //         <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
        //             Brand Staff Details
        //         </div>
        //         <div className='pt-3 formContainer customScroll'>
        //             <div className='inputBottomSpace'>
        //                 <CustomAutoComplete onChange={(e, newValue) => handleInputChange(7, 'brandStaffDetails' , 'is_brand_staff', newValue?.label , validateTextInputAny, 'Please Select Any Value')} onInputChange={(e, newInputValue) => setISBRANDSTAFFStatus(newInputValue)} inputValue={iSBRANDSTAFFStatus}  value={brandStaffDetails?.is_brand_staff} options={IS_BRAND_STAFF} required  label='Brand Staff' />
        //             </div>
        //             <div className='inputBottomSpace'>
        //                 <InputTextField type='text' disabled={false} value={brandStaffDetails?.brand_staff_name} onChange={(e) => handleInputChange(7, 'brandStaffDetails', 'brand_staff_name', e.target.value, validateTextInput, CustomErrorMsg?.)} label='Staff Name' />
        //             </div>
        //             <div className='inputBottomSpace'>
        //                 <InputTextField type='text' disabled={false} value={brandStaffDetails?.brand_staff_terms} onChange={(e) => handleInputChange(7, 'brandStaffDetails', 'brand_staff_terms', e.target.value, validateTextInput, CustomErrorMsg?.)} label='Staff Terms' />
        //             </div>
        //         </div>
        //     </div>
        // } 
        else if (activeStep === 7) {
            return <div className='saveChangeCont scrollChange'>
                {/* <div className='bg-white storeMagDetailsContainer'> */}
                <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                    Uploaded Documents
                </div>
                <div ref={seventhTabRef} className='pt-3 formContainerNew customScroll'>
                    <div className='documentUpload'>

                        <div className='uploadCard'>
                            {isFileDetailsEmpty(mouAggrementFile) ?
                                <CustomDropzone name={'MouAggrement'} onFileDrop={(file) => handleFileDrop('MouAggrement', file)} displayContent={<div>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                            <label className="cursorPointer">
                                                <img src={ImportIcon} alt="" />
                                            </label>
                                        </div>
                                        <div>
                                            <div className='fontMedium textColorBlack fontSize16'>Mou/ Aggrement</div>
                                            <div className='fontSize14 textColorGray'>
                                                <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                <div className='pt-2'>PDF</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>} />
                                :
                                <div style={{ width: '100%' }}>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                            <label className="cursorPointer">
                                                <img src={mouAggrementTypeIcon} alt="" />
                                            </label>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <div className='fontMedium textColorBlack fontSize16'>Mou/ Aggrement</div>
                                            <div className='fontSize14 textColorGray'>
                                                <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('MouAggrement')} progress={mouAggrementProgress} File={mouAggrementFile} uploadSpeed={mouAggrementUploadSpeed} notShowBrand={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='uploadCard'>
                            {isFileDetailsEmpty(gstDocumentParentStoreFile) ?
                                <CustomDropzone name={'GstDocumentParentStore'} onFileDrop={(file) => handleFileDrop('GstDocumentParentStore', file)} displayContent={<div>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                            <label className="cursorPointer">
                                                <img src={ImportIcon} alt="" />
                                            </label>
                                        </div>
                                        <div>
                                            <div className='fontMedium textColorBlack fontSize16'>GST document (Parent Store)</div>
                                            <div className='fontSize14 textColorGray'>
                                                <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                <div className='pt-2'>PDF</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>} />
                                :
                                <div style={{ width: '100%' }}>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                            <label className="cursorPointer">
                                                <img src={gstDocumentParentStoreFileTypeIcon} alt="" />
                                            </label>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <div className='fontMedium textColorBlack fontSize16'>GST document (Parent Store)</div>
                                            <div className='fontSize14 textColorGray'>
                                                <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('GstDocumentParentStore')} progress={gstDocumentParentStoreProgress} File={gstDocumentParentStoreFile} uploadSpeed={gstDocumentParentStoreUploadSpeed} notShowBrand={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='uploadCard'>
                            {isFileDetailsEmpty(gstDocumentStoreFile) ?
                                <CustomDropzone name={'GstDocumentStore'} onFileDrop={(file) => handleFileDrop('GstDocumentStore', file)} displayContent={<div>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                            <label className="cursorPointer">
                                                <img src={ImportIcon} alt="" />
                                            </label>
                                        </div>
                                        <div>
                                            <div className='fontMedium textColorBlack fontSize16'>GST document (Store)</div>
                                            <div className='fontSize14 textColorGray'>
                                                <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>} />
                                :
                                <div style={{ width: '100%' }}>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                            <label className="cursorPointer">
                                                <img src={gstDocumentStoreFileTypeIcon} alt="" />
                                            </label>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <div className='fontMedium textColorBlack fontSize16'>GST document (Store)</div>
                                            <div className='fontSize14 textColorGray'>
                                                <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('GstDocumentStore')} progress={gstDocumentStoreProgress} File={gstDocumentStoreFile} uploadSpeed={gstDocumentStoreUploadSpeed} notShowBrand={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='uploadCard'>
                            {isFileDetailsEmpty(panDocumentFile) ?
                                <CustomDropzone name={'PanDocument'} onFileDrop={(file) => handleFileDrop('PanDocument', file)} displayContent={<div>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                            <label className="cursorPointer">
                                                <img src={ImportIcon} alt="" />
                                            </label>
                                        </div>
                                        <div>
                                            <div className='fontMedium textColorBlack fontSize16'>PAN document</div>
                                            <div className='fontSize14 textColorGray'>
                                                <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>} />
                                :
                                <div style={{ width: '100%' }}>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                            <label className="cursorPointer">
                                                <img src={panDocumentFileTypeIcon} alt="" />
                                            </label>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <div className='fontMedium textColorBlack fontSize16'>PAN document</div>
                                            <div className='fontSize14 textColorGray'>
                                                <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('PanDocument')} progress={panDocumentProgress} File={panDocumentFile} uploadSpeed={panDocumentUploadSpeed} notShowBrand={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='uploadCard'>
                            {isFileDetailsEmpty(uploadStorePictureFile) ?
                                <CustomDropzone name={'UploadStorePicture'} onFileDrop={(file) => handleFileDrop('UploadStorePicture', file)} displayContent={<div>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                            <label className="cursorPointer">
                                                <img src={ImportIcon} alt="" />
                                            </label>
                                        </div>
                                        <div>
                                            <div className='fontMedium textColorBlack fontSize16'>Upload Store (Picture)</div>
                                            <div className='fontSize14 textColorGray'>
                                                <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                <div className='pt-2'>JPEG, JPG or PNG</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>} />
                                :
                                <div style={{ width: '100%' }}>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                            <label className="cursorPointer">
                                                <img src={uploadStorePictureFileTypeIcon} alt="" />
                                            </label>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <div className='fontMedium textColorBlack fontSize16'>Upload Store (Picture)</div>
                                            <div className='fontSize14 textColorGray'>
                                                <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('UploadStorePicture')} progress={uploadStorePictureProgress} File={uploadStorePictureFile} uploadSpeed={uploadStorePictureUploadSpeed} notShowBrand={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className='uploadCard'>
                            {isFileDetailsEmpty(otherFile) ?
                                <CustomDropzone name={'Other'} onFileDrop={(file) => handleFileDrop('Other', file)} displayContent={<div>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                            <label className="cursorPointer">
                                                <img src={ImportIcon} alt="" />
                                            </label>
                                        </div>
                                        <div>
                                            <div className='fontMedium textColorBlack fontSize16'>Other</div>
                                            <div className='fontSize14 textColorGray'>
                                                <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>} />
                                :
                                <div style={{ width: '100%' }}>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                            <label className="cursorPointer">
                                                <img src={otherFileTypeIcon} alt="" />
                                            </label>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <div className='fontMedium textColorBlack fontSize16'>Other</div>
                                            <div className='fontSize14 textColorGray'>
                                                <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('Other')} progress={otherProgress} File={otherFile} uploadSpeed={otherUploadSpeed} notShowBrand={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>


                        {/* <div className='uploadCard'>
                                        {isFileDetailsEmpty(storeFacadeFile) ?
                                        <CustomDropzone name={'StoreFacade'} onFileDrop={(file) => handleFileDrop('StoreFacade', file)} displayContent={<div>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                    <label className="cursorPointer">
                                                        <img src={ImportIcon} alt="" />
                                                    </label>
                                                </div>
                                                <div>
                                                    <div className='fontMedium textColorBlack fontSize16'>Store Facade</div>
                                                    <div className='fontSize14 textColorGray'>
                                                        <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                        <div className='pt-2'>PNG or JPG</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>} />
                                        :
                                        <div style={{width : '100%'}}>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                    <label className="cursorPointer">
                                                        <img src={storeFacadeTypeIcon} alt="" />
                                                    </label>
                                                </div>
                                                <div style={{width : '100%'}}>
                                                    <div className='fontMedium textColorBlack fontSize16'>Store Facade</div>
                                                    <div className='fontSize14 textColorGray'>
                                                        <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('StoreFacade')} progress={storeFacadeProgress} File={storeFacadeFile} uploadSpeed={storeFacadeUploadSpeed} notShowBrand={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <div className='uploadCard'>
                                        {isFileDetailsEmpty(brandFloorSpaceFile) ?
                                        <CustomDropzone name={'BrandFloorSpace'} onFileDrop={(file) => handleFileDrop('BrandFloorSpace', file)} displayContent={<div>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                    <label className="cursorPointer">
                                                        <img src={ImportIcon} alt="" />
                                                    </label>
                                                </div>
                                                <div>
                                                    <div className='fontMedium textColorBlack fontSize16'>Brand Floor Space</div>
                                                    <div className='fontSize14 textColorGray'>
                                                        <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                        <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>} />
                                        :
                                        <div style={{width : '100%'}}>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                    <label className="cursorPointer">
                                                        <img src={brandFloorSpaceFileTypeIcon} alt="" />
                                                    </label>
                                                </div>
                                                <div style={{width : '100%'}}>
                                                    <div className='fontMedium textColorBlack fontSize16'>Brand Floor Space</div>
                                                    <div className='fontSize14 textColorGray'>
                                                        <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('BrandFloorSpace')} progress={brandFloorSpaceProgress} File={brandFloorSpaceFile} uploadSpeed={brandFloorSpaceUploadSpeed} notShowBrand={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <div className='uploadCard'>
                                        {isFileDetailsEmpty(adjacentFile) ?
                                        <CustomDropzone name={'Adjacent'} onFileDrop={(file) => handleFileDrop('Adjacent', file)} displayContent={<div>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                    <label className="cursorPointer">
                                                        <img src={ImportIcon} alt="" />
                                                    </label>
                                                </div>
                                                <div>
                                                    <div className='fontMedium textColorBlack fontSize16'>Adjacent</div>
                                                    <div className='fontSize14 textColorGray'>
                                                        <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                        <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>} />
                                        :
                                        <div style={{width : '100%'}}>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-3 importExportFile cursorPointer' title='Import' onClick={null} >
                                                    <label className="cursorPointer">
                                                        <img src={adjacentFileTypeIcon} alt="" />
                                                    </label>
                                                </div>
                                                <div style={{width : '100%'}}>
                                                    <div className='fontMedium textColorBlack fontSize16'>Adjacent</div>
                                                    <div className='fontSize14 textColorGray'>
                                                        <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('Adjacent')} progress={adjacentProgress} File={adjacentFile} uploadSpeed={adjacentUploadSpeed} notShowBrand={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div> */}
                    </div>
                </div>
            </div>
            // </div>
        }
    }
    return (
        <>
            {displayFormContent()}
        </>
    );
}

export default CreateStoreInputForm;
