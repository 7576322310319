import React, { useEffect, useRef, useState, useMemo } from 'react';
import ImportIcon from '../../../assets/svg/uploadIcon.svg';
import ExportIcon from '../../../assets/svg/downloadIcon.svg';
import CustomTableWithFilter from '../../../ReUsableComponent/CustomTableWithFilter';
import CustomPagination from '../../../ReUsableComponent/Pagination';
import { TargetVsAchievementTableHeader } from '../../TableHeaders';
import { useDispatch, useSelector } from 'react-redux';
import { targetVsAchievementActions } from '../../../store/reducers/TargetVsAchievement.reducer';
import ProgressBarForFileUpload from '../../../ReUsableComponent/ProgressBarForFileUpload';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import CustomDropzone from '../../../ReUsableComponent/CustomDropzone';
import CustomModal from '../../../ReUsableComponent/CustomModal';
import CloseIcon from '../../../assets/svg/closeIconofmodal.svg';
import Loader from '../../../ReUsableComponent/Loader';
import CustomFilterDropDown from '../../../ReUsableComponent/CustomFilterDropdown';
import { BulkInsert, DownloadList, GetList, DeleteList, DownloadTempleteList } from '../TargetVsAchievement.serive';
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import { saveAs } from 'file-saver';
import SuccessPopup from '../../../ReUsableComponent/SuccessPopupMsg';
import { useHistory } from 'react-router-dom';
import { userAuthActions } from '../../../store/reducers/UserAuthReducers';
import { DownloadTemplate } from '../../../Configurations/Core.service';
import CustomFilterSearchDropdown from '../../../ReUsableComponent/CustomFilterSearchDropdown';
import { GetBrandNameList } from '../../Promotion/promotion.service';
import dropDownIcon from '../../../assets/svg/dropDownIcon.svg';
import DeleteMsgPopup from '../../../ReUsableComponent/DeleteMsgPopup';
import dayjs from 'dayjs';
import BrandListDropdown from '../../../ReUsableComponent/BrandListDropdown';
import CustomMonthYearPicker from '../../../ReUsableComponent/CustomMonthYearPicker';
import moment from 'moment/moment';
import { RefreshOutlined } from '@mui/icons-material';

const uploadFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};
const SuccessUploadModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 390,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const uploadedFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 835,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};
const downloadType = [{ label: 'Template Download', value: 'Template' }, { label: 'Report Download', value: 'Report' }];

const TargetVsAchievementListView = () => {
    const gridRef = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const today = new Date();
    const [loading, setLoading] = React.useState(false);
    const [anchorElForExport, setAnchorElForExport] = useState(null);
    const openDownloadType = Boolean(anchorElForExport);
    const tableFilterValue = useSelector((state) => state.target.filterModel);
    const selectedPage = useSelector(state => state.target.selectedPage);
    const brandId = useSelector(state => state.target.brandId);
    const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
    const [openTemplateDownloadModal, setOpenTemplateDownloadModal] = useState(false);
    const [openFileSelectedModal, setOpenFileSelectedModal] = useState(false);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [listData, setListData] = useState([]);
    const [progress, setProgress] = useState(25);
    const [uploadSpeed, setUploadSpeed] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [openDeleteErrorPopup, setOpenDeleteErrorPopup] = useState(false);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const userId = useSelector((state) => state.userAuthentication.userId);
    const [brandList, setBrandList] = useState([]);
    const [anchorElForBrand, setAnchorElForBrand] = useState(null);
    const openBrandFilter = Boolean(anchorElForBrand);
    const [dropSearchValue, setdropSearchValue] = useState('');
    const [selectedBrand, setSelectedBrand] = useState({ label: useSelector(state => state?.target?.defaultBrandForRecon?.label), value: useSelector(state => state?.target?.defaultBrandForRecon?.value) });
    const [currentDeleteId, setCurrentDeleteId] = useState('');
    const [selectedBrandListData, setSelectedBrandListData] = useState('');
    const [uploadMonthYearData, setUploadMonthYearData] = useState('');
    const USER_ID = useSelector((state) => state.userAuthentication.userId);

    const defaultColDef = useMemo(() => {
        return {
            filter: true,
        };
    }, []);

    useEffect(() => {
        // getTargetAchievementList('');
        getAllBrand();
    }, []);

    useEffect(() => {
        let param = `?brand_id=${brandId}&page=${selectedPage}&limit=${'20'}`
        if (selectedPage) {
            getTargetAchievementList(param);
        }
    }, [brandId, selectedPage]);


    const onClickImport = () => {
        setOpenFileUploadModal(true);
    }
    const onClickDownloadReport = (event) => {
        setAnchorElForExport(event.currentTarget)
    }

    const handleExportClose = () => {
        setAnchorElForExport(null);
    }
    const onClickView = (id) => {
        history.push('/app/targetVsAchievementViewDetails');
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
        let param = `?id=${id}`;
        getTargetAchievementList(param);
    }
    const onClickDelete = (id) => {
        setCurrentDeleteId(id);
        setOpenDeletePopup(true);
    }
    const submitForDeleteRecord = () => {
        let paramId = `?id=${currentDeleteId}`;
        setLoading(true);
        DeleteList(paramId, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                ToastSuccessService('Delete Successfully.')
                let param = `?brand_id=${brandId}`;
                getTargetAchievementList(param);
                setLoading(false);
                setOpenDeletePopup(false);
            }
        }).catch(error => {
            setOpenDeletePopup(false);
            setOpenDeleteErrorPopup(true);
        }).finally(() => {
            setLoading(false);
        })
    }
    const onGridReady = (params) => {
        params.api.setFilterModel(tableFilterValue);
        gridRef.current = params.api;
        gridRef.current.sizeColumnsToFit();
    };
    const onFilterChanged = (event) => {
        dispatch(targetVsAchievementActions.setFilterModel({ type: event.api.getFilterModel() }));
    };
    const handlePageChange = (pageValue) => {
        dispatch(targetVsAchievementActions.setSelectedPage({ type: pageValue }));
    }

    const simulateUpload = () => {
        setUploadButtonDisabled(true);
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                const nextProgress = prevProgress + 25;
                if (nextProgress >= 100) {
                    clearInterval(interval);
                    setUploadSpeed(null);
                    setUploadButtonDisabled(false);
                    return 100;
                }
                if (!startTime) {
                    setStartTime(Date.now());
                }
                const elapsedTime = (Date.now() - startTime) / 1000; // in seconds
                const speed = (nextProgress / elapsedTime).toFixed(2); // in % per second
                setUploadSpeed(speed);
                return nextProgress;
            });
        }, 500);
    };

    const getAllBrand = () => {
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                let brands = []
                response.data.data.forEach(element => {
                    let brandObject = {
                        label: element.brand_name,
                        value: element._id
                    }
                    brands.push(brandObject)
                });
                setBrandList(brands);

                // getStoreList(brands[0]?.value)
                // dispatch(targetVsAchievementActions.setDefaultBrandForRecon({ type:  brands[0] }));
                // let param = `?brand_id=${brands[0]?.value}`
                let param = `?brand_id=${brandId !== '' ? brandId : brands[0]?.value}`
                getTargetAchievementList(param);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
        })
    }

    const handleFileDrop = (files) => {
        const fileUploaded = files[0];
        if (fileUploaded) {
            setUploadedFile(fileUploaded);
            setProgress(0);
            simulateUpload();
            setOpenFileSelectedModal(true);
            setOpenFileUploadModal(false);
        }
    };

    const removeFile = () => {
        setUploadedFile([]);
    }

    const valueCheck = (value) => {
        if ((value && value !== 'null')) {
            return value;
        } else {
            return '-';
        }
    }

    const getTargetAchievementList = (param) => {
        setLoading(true);
        GetList(param, USER_TOKEN).then(response => {
            setTotalCount(response.data.data.count);
            let rowData = [];
            response.data.data.targetAchievementsDataArray.map(item => {
                let row = {
                    month: valueCheck(item?.month),
                    nameBrand: valueCheck(item?.brand_name),
                    storeCode: valueCheck(item?.store_code),
                    nameStore: valueCheck(item?.store_name),
                    region: valueCheck(item?.store?.store_address?.region),
                    state: valueCheck(item?.store?.store_address?.state),
                    reporteeName: valueCheck(item?.store?.regional_manager),
                    flStatus: item?.store?.brands?.is_brand_staff ? 'Yes' : 'No',
                    flName: valueCheck(item?.store?.brands?.brand_staff_name),
                    sellThru: `${item?.sell_through ? item?.sell_through : 0}%`,
                    totalTgtValue: valueCheck(item?.total_value),
                    totalTgtQty: valueCheck(item?.total_quantity),
                    achievementValue: valueCheck(item?.achievementValue),
                    achievementPercentage: `${valueCheck(((item?.achievementValue === undefined ? 0 : item?.achievementValue) / (item?.total_value === undefined ? 0 : item?.total_value) * 100).toFixed(2))}%`,
                    id: valueCheck(item?._id),
                }
                rowData = [...rowData, row]
            })
            setListData(rowData);
            dispatch(targetVsAchievementActions.setViewDetailsData({ type: response?.data?.data?.targetAchievementsDataArray }));
            dispatch(targetVsAchievementActions.setViewDetailsAllDataByID({ type: response?.data?.data }));
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        })
    }

    const uploadBulkList = () => {
        setLoading(true)
        // let param = `?user_id=${userId}`
        let param = ``
        const formData = new FormData();
        formData.append('file', uploadedFile);
        formData.append('user_id', userId);
        formData.append('brand', selectedBrandListData?.id);
        formData.append('month_year', uploadMonthYearData);
        BulkInsert(formData, param, USER_TOKEN).then(response => {
            if ((response?.status === 200 || response?.status === 201) && response?.data?.statusCode !== 400) {
                setOpenSuccessPopup(true);
                setOpenFileSelectedModal(false);
                setSelectedBrandListData('');
                setUploadMonthYearData('');
                setTimeout(() => {
                    setOpenSuccessPopup(false);
                }, 5000)
            } else if (response?.data?.statusCode === 400) {
                ToastErrorService(response?.data?.message)
            }

        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        })
    };

    const onSelectDownloadCategory = (event) => {
        if (event?.target.value.toUpperCase() === 'Template'.toUpperCase()) {
            // downloadTargetVsAchievementTemplate();
            setOpenTemplateDownloadModal(true);
        } else {
            let requestBody = `?store_name=${tableFilterValue?.nameStore ? tableFilterValue?.nameStore?.filter : ''}&month=${tableFilterValue?.month ? tableFilterValue?.month?.filter : ''}&brand_id=${selectedBrand?.value}`
            downloadTargetVsAchievementList(requestBody);
        }
        setAnchorElForExport(null);
    }

    // const downloadTargetVsAchievementTemplate = () => {
    //     setLoading(true);
    //     let param = `?template_type=target_achievement_templet`
    //     DownloadTemplate(param, USER_TOKEN).then(response => {
    //         if (response.data.statusCode === 200) {
    //             window.location.href = response.data.data
    //             ToastSuccessService('Template Downloaded Successfully.')
    //         } else {
    //             ToastErrorService(response.data.message);
    //         }
    //     }).catch(error => {
    //         ToastErrorService('Something went wrong, Please try again after sometime.');
    //     }).finally(() => {
    //         setLoading(false);
    //     })
    // }

    const downloadTargetVsAchievementTemplate = () => {
        setLoading(true);
        let param = `?brand=${selectedBrandListData?.id}&month_year=${uploadMonthYearData}`
        DownloadTempleteList(param, USER_TOKEN).then(response => {
            const responseInUnit = new Uint8Array(response.data.buffer.data);
            const blob = new Blob([responseInUnit], { type: 'application/octet-stream' });
            saveAs(blob, response.data.filename);
            if (response?.data?.buffer?.statusCode === 400) {
                ToastErrorService(response?.data?.buffer?.message);
            } else {
                setSelectedBrandListData('');
                setUploadMonthYearData('');
                setOpenTemplateDownloadModal(false);
                setOpenFileUploadModal(false);
                ToastSuccessService('Report downloaded successfully.');
            }
        }).catch(error => {
            ToastErrorService('Something went wrong, Please try again after sometime.');
            if (error.response.status === 401) {
                history.push('/')
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const downloadTargetVsAchievementList = (reqBody) => {
        setLoading(true);
        DownloadList(reqBody, USER_TOKEN).then(response => {
            const responseInUnit = new Uint8Array(response.data.buffer.data);
            const blob = new Blob([responseInUnit], { type: 'application/octet-stream' });
            saveAs(blob, response.data.filename);
            if (response?.data?.buffer?.statusCode === 400) {
                ToastErrorService(response?.data?.buffer?.message);
            } else {
                ToastSuccessService('Report downloaded successfully.');
            }
        }).catch(error => {
            ToastErrorService('Something went wrong, Please try again after sometime.');
        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickDownloadTemplate = () => {
        downloadTargetVsAchievementTemplate();
    }
    const handleBrandClick = (event) => {
        setAnchorElForBrand(event.currentTarget);
    };
    const handleBrandClose = () => {
        setAnchorElForBrand(null);
    };


    const onSelectBrand = (event) => {
        brandList.forEach(brand => {
            if (event.target.value === brand?.value) {
                setSelectedBrand({ label: brand?.label, value: event.target.value })
                // dispatch(targetVsAchievementActions.setDefaultBrandForRecon({ type: brand}));
                setAnchorElForBrand(null);
                dispatch(targetVsAchievementActions.setDefaultBrandForRecon({ type: brand }));
                dispatch(targetVsAchievementActions.setBrandId({ type: event.target.value }));
            }
        });
        // getStoreList(event.target.value)
    }

    const handleSearchChange = (event) => {
        setdropSearchValue(event.target.value);
    };

    const onDateSelect = (value) => {

        let date = new Date(value);
        let slectedDate = moment(date).format("MMMM YYYY");
        setUploadMonthYearData(slectedDate);
    }

    const onClickReset = () => {
        const gridApi = gridRef.current;
        gridApi.setFilterModel(null); // Clears all the filters
        gridApi.onFilterChanged();    // Apply the changes
    }

    return (
        <div className='list-view-main'>
            <div className='d-flex justify-content-end notoSansFont'>
                <button onClick={onClickReset} className='me-2 reset_filters_button'>
                    <span className='me-1 reset_filters_text'>Reset Filters</span>
                    <RefreshOutlined className='RefreshOutlined' />
                </button>
                <div className='d-flex align-items-center first-filter'>
                    <div className='position-relative min-w-150'>
                        <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={handleBrandClick}>
                            <p className='text-truncate' title={selectedBrand.label}>{selectedBrand?.label}</p>
                            <img className={`${openBrandFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </div>
                        <CustomFilterSearchDropdown customClass='upload-file' data={brandList} handleClose={handleBrandClose} open={openBrandFilter} anchorEl={anchorElForBrand}
                            onChange={onSelectBrand} selectedValue={selectedBrand?.value} category={'Brand'}
                            dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                    </div>
                </div>
                <button className='me-3 importExportFile cursorPointer' title='Import' onClick={onClickImport} >
                    <div className="cursorPointer">
                        <img src={ImportIcon} alt="" />
                    </div>
                </button>
                <button className='me-3 importExportFile' title='Export' onClick={onClickDownloadReport}>
                    <div>
                        <img src={ExportIcon} alt="" />
                    </div>
                </button>
                <CustomFilterDropDown data={downloadType} handleClose={handleExportClose} open={openDownloadType} anchorEl={anchorElForExport}
                    onChange={onSelectDownloadCategory} category={'Category'} />
            </div>
            <div className='pt-3'>
                <div className='tableContainer'>
                    <CustomTableWithFilter refGrid={gridRef} onFilterChanged={onFilterChanged} filterModel={tableFilterValue} onGridReady={onGridReady} column={TargetVsAchievementTableHeader} tableRowData={listData} onClickView={onClickView} onClickDelete={onClickDelete} customeHeightClass={'tableCustomeHeightClass'} filter={defaultColDef} />
                </div>
                <div className='paddingTop16 d-flex justify-content-end customPagination'>
                    <CustomPagination onClick={handlePageChange} activePage={selectedPage} pageSize={20} currentPage={selectedPage} totalItems={totalCount} />
                </div>
            </div>


            {/* Upload File Modal */}
            <CustomModal open={openFileUploadModal} style={uploadFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Upload File</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => { setOpenFileUploadModal(false); setSelectedBrandListData(''); setUploadMonthYearData(''); }}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex paddingTop24 align-items-center justify-content-between'>
                        <div className='d-flex'>
                            <div>
                                <BrandListDropdown extraClass={'uploadBrandDrop'} selectedBrandListData={selectedBrandListData} setSelectedBrandListData={setSelectedBrandListData} />
                            </div>
                            <div>
                                <CustomMonthYearPicker disabled={false} value={uploadMonthYearData} onDateChange={(newValue) => onDateSelect(newValue)} required label='Date*' />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className=''>
                            <div className={`channelDisplayData ${selectedBrandListData === '' || uploadMonthYearData === '' ? 'disabled' : ''}`}>
                                <CustomDropzone onFileDrop={handleFileDrop} displayContent={<div className='dropZoneContent'>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                        <div className='textColorGray fontSize12 pt-3'>Or</div>
                                    </div>
                                    <div className='pt-3'>
                                        <CustomBlueButton onClick={null} title={'Choose File'} />
                                    </div>
                                </div>} />
                            </div>
                            <div className='pt-3 d-flex justify-content-between align-items-center'>
                                <div className='fontSize12 textColorGray'>Download excel file template</div>
                                <div className={`downloadButton ${selectedBrandListData === '' || uploadMonthYearData === '' ? 'disabled' : ''}`}>
                                    <SecondaryButton onClick={onClickDownloadTemplate} title={<div className='d-flex align-items-center'>
                                        <div>
                                            <img src={ExportIcon} alt="" />
                                        </div>
                                        <div className='fontBold'>
                                            Download Template
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>} />

            {/* Templaete Download Modal */}
            <CustomModal open={openTemplateDownloadModal} style={uploadFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Download Template</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => { setOpenTemplateDownloadModal(false); setSelectedBrandListData(''); setUploadMonthYearData(''); }}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex paddingTop24 align-items-center justify-content-between'>
                        <div className='d-flex'>
                            <div>
                                <BrandListDropdown extraClass={'uploadBrandDrop'} selectedBrandListData={selectedBrandListData} setSelectedBrandListData={setSelectedBrandListData} />
                            </div>
                            <div>
                                <CustomMonthYearPicker disabled={false} value={uploadMonthYearData} onDateChange={(newValue) => onDateSelect(newValue)} required label='Date*' />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className=''>
                            <div className='pt-3 d-flex justify-content-between align-items-center'>
                                <div className='fontSize12 textColorGray'>Download excel file template</div>
                                <div className={`downloadButton ${selectedBrandListData === '' || uploadMonthYearData === '' ? 'disabled' : ''}`}>
                                    <SecondaryButton onClick={onClickDownloadTemplate} title={<div className='d-flex align-items-center'>
                                        <div>
                                            <img src={ExportIcon} alt="" />
                                        </div>
                                        <div className='fontBold'>
                                            Download Template
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>} />

            {/* Upload file progress Modal */}
            <CustomModal classes="customeProgressModal" open={openFileSelectedModal} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Upload File</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenFileSelectedModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className='dropZoneContentContainer'>
                            <div className='channelDisplayData'>
                                <CustomDropzone onFileDrop={handleFileDrop} displayContent={<div className='dropZoneContent uploadedFileDropZoneContent'>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <SecondaryButton onClick={handleFileDrop} title={<span className='fontSemiBold textColorBlue'>Choose File</span>} />
                                    </div>
                                </div>} />
                            </div>
                            <div className='dropZoneContentRight'>
                                {uploadedFile?.path && <div>
                                    <p className='uploadingP'>Uploading</p>
                                    <div className='paddingTop24'>
                                        <ProgressBarForFileUpload onClickImageCloseIcon={removeFile} progress={progress} File={uploadedFile} uploadSpeed={uploadSpeed} />
                                    </div>
                                    <div className='pt-3 CustomBlueButtonConatiner' >
                                        <CustomBlueButton customCreatePropsBtnClass="customCreatePropsBtnClass" disabled={uploadButtonDisabled} onClick={uploadBulkList} title={'Upload'} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>} />

            {/* File Uploaded Successfully Modal */}
            <SuccessPopup open={openSuccessPopup} closable={false} onClose={() => setOpenSuccessPopup(false)} content={<div>
                <div className='paddingTop32 text-center'>
                    <div className='textColorBlack fontSize24 montserratFont fontMedium'>File Uploaded Successfully!</div>
                    <div className='fontSize12 textColor333'>We will be processing the data in background, you can continue with your work.</div>
                </div>
            </div>} />

            <DeleteMsgPopup open={openDeletePopup} closable={false} onClose={() => setOpenDeletePopup(false)} content={<div>
                <div className='paddingTop32 text-center'>
                    {/* <div className='textColorBlack fontSize24 montserratFont fontMedium'>Created Successfully!</div> */}
                    <div className='fontSize16 textColor333'>Heads Up! want to delete the Record</div>
                    <div className='paddingTop24'>
                        <CustomBlueButton onClick={submitForDeleteRecord} title={'YES'} />
                    </div>
                </div>
            </div>} />

            <DeleteMsgPopup open={openDeleteErrorPopup} closable={false} onClose={() => setOpenDeleteErrorPopup(false)} content={<div>
                <div className='paddingTop32 text-center'>
                    {/* <div className='textColorBlack fontSize24 montserratFont fontMedium'>Created Successfully!</div> */}
                    <div className='fontSize16 textColor333'>Unable to delete the record for previous or current month</div>
                </div>
            </div>} />
            {loading && <Loader />}
        </div>
    )
}

export default TargetVsAchievementListView