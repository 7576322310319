import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    promotionId: '',
    promotionDataList: [],
    promotionDataListById: {},
    isEditFormVisible: true,
    createPromotionDetails: {},
    startDate: '',
    endDate: '',
    filterModel: ''
};

const promotionSlice = createSlice({
    name: 'promotion',
    initialState,
    reducers: {
        setPromotionId: (state, action) => {
            state.promotionId = action.payload.type
        },
        setPromotionDataList: (state, action) => {
            state.promotionDataList = action.payload.type
        },
        setPromotionDataListById: (state, action) => {
            state.promotionDataListById = action.payload.type
        },
        setIsEditFormVisible: (state, action) => {
            state.isEditFormVisible = action.payload.type
        },
        setCreatePromotionDetails: (state, action) => {
            state.createPromotionDetails = action.payload.type
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload.type
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload.type
        },
        setFilterModel: (state, action) => {
            state.filterModel = action.payload.type
        },
    }
});

export const promotionActions = promotionSlice.actions;
export default promotionSlice;
