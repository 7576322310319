import React from "react";
import { Route } from "react-router-dom";

// DASHBOARDS

import Dashboard from "../../Pages/Dashboard";
import StockOverview from "../../Pages/StockOverview";
import StoreManagement from "../../Pages/StoreManagement";
import SalesOverview from "../../Pages/SalesOverview";
import BrandManagement from "../../Pages/BrandManagement";
import ViewCardBrandManagement from "./../../Pages/BrandManagement/ViewCardBrandManagement";
import TargetVsAchievement from "../../Pages/TargetVsAchievement";
import Incentive from "../../Pages/Incentive";
import Promotion from "../../Pages/Promotion";
import TeamManagement from "../../Pages/TeamManagement";
import Sidebar from "../Sidebar";
import Header from "../Header";
import StoreDashboard from "../../Pages/StoreManagement/StoreDashboard";
import EditStoreDetails from "../../Pages/StoreManagement/EditStoreDetails"
import ViewStoreDetails from "../../Pages/StoreManagement/ViewStoreDetails";
import BrandDashboard from "../../Pages/BrandManagement/BrandDashboard";
import ViewBrandDetails from "../../Pages/BrandManagement/ViewBrandDetails";
import EditBrandDetails from "../../Pages/BrandManagement/EditBrandDetails";
import BrandStoreView from "../../Pages/StoreManagement/BrandStoreView";
import ViewUserDetails from "../../Pages/TeamManagement/ViewUserDetails";
import CreateUser from "../../Pages/TeamManagement/CreateUser";
import EditUser from "../../Pages/TeamManagement/EditUser";
import CreateStore from "../../Pages/StoreManagement/CreateStore";
import CreateBrand from "../../Pages/BrandManagement/CreateBrand";
import SalesOverviewList from "../../Pages/SalesOverview/SalesOverviewList";
import RuleManagement from "../../Pages/RuleManagement";
import Feedback from "../../Pages/Feedback";
import ViewFeedbackDetails from "../../Pages/Feedback/ViewFeedbackDetails";
import ShareFeedback from "../../Pages/Feedback/ShareFeedback";
import PropTypes from 'prop-types';
import StockDashboard from "../../Pages/StockOverview/StockDashboard";
import TargetVsAchievementListView from "../../Pages/TargetVsAchievement/ListView/Index";
import TargetVsAchievementViewDetails from "../../Pages/TargetVsAchievement/ViewDetails";
import BrandMasterData from "../../Pages/BrandManagement/BrandMasterData";
import CreatePromotion from "../../Pages/Promotion/CreatePromotion";
import ViewEditPromotion from "../../Pages/Promotion/ViewEditPromotion";
import IncentiveDashboard from "../../Pages/Incentive/IncentiveDashboard";
import CreateIncentive from "../../Pages/Incentive/CreateIncentive";
import ReconReport from "../../Pages/SalesOverview/ReconReport";
import EmployeeStatus from "../../Pages/EmployeeStatus";
import ViewEmployeeStatusDetails from "../../Pages/EmployeeStatus/ViewEmployeeStatusDetails";
import Notifications from "../../Pages/Notifications";
import CreateNotifications from "../../Pages/Notifications/CreateNotifications";


const PostAuthLayout = ({ match }) => {
    PostAuthLayout.propTypes = {
        match: PropTypes.string
    }
    return <div className="appBackground">
        <div className="d-flex position-relative w-100">
            <div className="">
                <Sidebar />
            </div>
            <div className="headerPosition appContainerMaxWidth">
                <div className="pr-30">
                    <Header />
                </div>
                <div className="app-main-custom">
                    <div className="app-main-outer-custom">
                        <div className="app-main__inner-custom">
                            <Route path={`${match.url}/dashboard`} component={Dashboard} />
                            <Route path={`${match.url}/salesOverview`} component={SalesOverview} />
                            <Route path={`${match.url}/stockOverview`} component={StockOverview} />
                            <Route path={`${match.url}/stockOverviewDashboard`} component={StockDashboard} />
                            <Route path={`${match.url}/storeManagement`} component={StoreManagement} />
                            <Route path={`${match.url}/storeDashboard`} component={StoreDashboard} />
                            <Route path={`${match.url}/viewStoreDetails`} component={ViewStoreDetails} />
                            <Route path={`${match.url}/createStoreDetails`} component={CreateStore} />
                            <Route path={`${match.url}/editStoreDetails`} component={EditStoreDetails} />
                            <Route path={`${match.url}/brandStoreView`} component={BrandStoreView} />
                            <Route path={`${match.url}/update-brand-master`} component={BrandMasterData} />
                            <Route path={`${match.url}/brandManagement`} component={BrandManagement} />
                            <Route path={`${match.url}/createBrand`} component={CreateBrand} />
                            <Route path={`${match.url}/viewCardBrandManagement`} component={ViewCardBrandManagement} />
                            <Route path={`${match.url}/brandDashboard`} component={BrandDashboard} />
                            <Route path={`${match.url}/viewBrandDetails`} component={ViewBrandDetails} />
                            <Route path={`${match.url}/editBrandDetails`} component={EditBrandDetails} />
                            <Route path={`${match.url}/targetVsAchievement`} component={TargetVsAchievement} />
                            <Route path={`${match.url}/targetVsAchievementListView`} component={TargetVsAchievementListView} />
                            <Route path={`${match.url}/targetVsAchievementViewDetails`} component={TargetVsAchievementViewDetails} />
                            <Route path={`${match.url}/incentive`} component={Incentive} />
                            <Route path={`${match.url}/createIncentive`} component={CreateIncentive} />
                            <Route path={`${match.url}/promotion`} component={Promotion} />
                            <Route path={`${match.url}/createpromotion`} component={CreatePromotion} />
                            <Route path={`${match.url}/vieweditpromotion`} component={ViewEditPromotion} />
                            <Route path={`${match.url}/teamManagement`} component={TeamManagement} />
                            <Route path={`${match.url}/viewUserDetails`} component={ViewUserDetails} />
                            <Route path={`${match.url}/createUser`} component={CreateUser} />
                            <Route path={`${match.url}/editUserDetails`} component={EditUser} />
                            <Route path={`${match.url}/salesOverviewList`} component={SalesOverviewList} />
                            <Route path={`${match.url}/ruleManagement`} component={RuleManagement} />
                            <Route path={`${match.url}/feedback`} component={Feedback} />
                            <Route path={`${match.url}/viewFeedbackDetails`} component={ViewFeedbackDetails} />
                            <Route path={`${match.url}/shareFeedback`} component={ShareFeedback} />
                            <Route path={`${match.url}/recon-report`} component={ReconReport} />
                            <Route path={`${match.url}/employeeStatus`} component={EmployeeStatus} />
                            <Route path={`${match.url}/viewEmployeeStatusDetails`} component={ViewEmployeeStatusDetails} />
                            <Route path={`${match.url}/notifications`} component={Notifications} />
                            <Route path={`${match.url}/createNotifications`} component={CreateNotifications} />
                            {/* <Route path={`${match.url}/hierarchyManagement`} component={HierarchyManagement} /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
};

export default PostAuthLayout;
