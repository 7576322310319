import React, { useEffect, useState } from 'react';
import RightArrowIcon from '../../../assets/svg/rightArrow.svg';
import { Link } from 'react-router-dom';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import Loader from '../../../ReUsableComponent/Loader';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import SuccessPopup from '../../../ReUsableComponent/SuccessPopupMsg';
import './CreateIncentive.css';
import InputTextField from '../../../ReUsableComponent/InputTextField';
import CustomAutoComplete from '../../../ReUsableComponent/CustomAutocomplete';
import MultiSelectCheckBox from '../../../ReUsableComponent/MultiSelectCheckBox';
import CustomDateRangePicker from '../../../ReUsableComponent/CustomDaterangePicker';
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import moment from "moment";
import { GetBarcodes, GetBrandNameList, GetStoreCustomerNameList } from './../Insentive.service';
import PlusIcon from '../../../assets/svg/plus_icon.svg';
import { validNumericInRange } from '../../../ReUsableComponent/CustomFormValidation';
import { CreateNewIncentive } from './../Insentive.service';
import { GetCategoryByBrand } from '../../Promotion/promotion.service';
import { userAuthActions } from '../../../store/reducers/UserAuthReducers';
import { CancelOutlined } from '@mui/icons-material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const CreateIncentive = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const createdBy = useSelector((state) => state.brandManagement.brandCreatedBy);

    const INCENTIVETYPE = [{ label: 'WOW Bill Incentive', value: "wow_bill", name: 'incentive_type' }, { label: 'Monthly Incentive', value: 'monthly_bill', name: 'incentive_type' }, { label: 'Special Incentive', value: 'special_bill', name: 'incentive_type' }];
    const CONDITIONLIST = [{ label: 'Greater Than', value: "Greater Than", name: 'conditionlist' }, { label: 'Less Than', value: 'Less Than', name: 'conditionlist' }, { label: 'Greater Than or Equals', value: 'Greater Than or Equals', name: 'conditionlist' }, { label: 'Less Than or Equals', value: 'Less Than or Equals', name: 'conditionlist' }];
    const TYPELIST = [{ label: 'Per Piece', value: "per_peice", name: 'typelist' }, { label: 'Category', value: 'category', name: 'typelist' }, { label: 'Barcode', value: 'barcode', name: 'typelist' }];

    // Types Of Incentive

    // Brand
    const [brandTypeList, setBrandTypeList] = useState([]);

    // Store
    const [storeTypeList, setStoreTypeList] = useState([]);
    const [categoryTypeList, setCategoryTypeList] = useState([]);
    const [barcodeList, setBarcodeList] = useState([]);

    // date range
    const today = new Date();
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
    // const firstDayOfMonth = new Date(today.getFullYear(), (today.getMonth()));
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedRange, setSelectedRange] = useState([today, '']);

    // Common
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [wowBillError, setWowBillError] = useState({ wow_bill: '' });
    const [monthlyBillError, setMonthlyBillError] = useState({ monthly_bill: '' });
    const USER_ID = useSelector((state) => state.userAuthentication.userId);


    const [formData, setFormData] = useState({
        incentive_type: "",
        brand_details: [{
            brand_name: "",
            brand_id: ""
        }],
        store_details: [],
        from_date: "",
        to_date: "",
        wow_bill: [
            {
                bill_amount: "",
                condition: "",
                set_incentive_amount: ""
            },
        ],
        monthly_bill: [
            {
                achievement_percent: "",
                condition: "",
                set_incentive_percent: ""
            },
        ],
        special_bill: {
            type: "",
            category: [],
            barcode: [],
            set_incentive_amount: ''
        },

    });

    const [formDataError, setFormDataError] = useState({
        wow_bill: [],
        monthly_bill: []
    });

    useEffect(() => {
        dispatch(userAuthActions.setHeaderName({ type: 'Incentive' }));
        dispatch(userAuthActions.setActivePage({ type: '/incentive' }));
        getAllBrandList();
        getAllStoreCustomerNameList();
    }, []);

    useEffect(() => {
        setIsSubmitBtnDisabled(false)
        if (formData?.incentive_type === '' || formData?.brand_details[0]?.brand_id === '' || formData?.store_details?.length === 0
            || formData?.from_date === '' || formData?.to_date === '') {
            setIsSubmitBtnDisabled(true);
        } else {
            setIsSubmitBtnDisabled(false);
        }
        if (formData?.incentive_type === 'wow_bill') {
            formData?.wow_bill?.map((row, index) => {
                if (row?.bill_amount === "" || row?.condition === "" || row?.set_incentive_amount === "") {
                    setIsSubmitBtnDisabled(true);
                } else {
                    setIsSubmitBtnDisabled(false);
                }
            });
            formDataError?.wow_bill?.map((row, index) => {
                if (row?.set_incentive_amount !== "") {
                    setIsSubmitBtnDisabled(true);
                }
            })
        }
        if (formData?.incentive_type === 'monthly_bill') {
            formData?.monthly_bill?.map((row, index) => {
                if (row?.achievement_percent === "" || row?.condition === "" || row?.set_incentive_percent === "") {
                    setIsSubmitBtnDisabled(true);
                } else {
                    setIsSubmitBtnDisabled(false);
                }
            });
            formDataError?.monthly_bill?.map((errorRow, index) => {
                if (errorRow?.set_incentive_percent !== "") {
                    setIsSubmitBtnDisabled(true);
                }
            })
        }
        if (formData?.incentive_type === 'special_bill') {
            if (formData?.special_bill?.type === "" || formData?.special_bill?.set_incentive_amount === "") {
                setIsSubmitBtnDisabled(true);
            } else if ((formData?.special_bill?.type === 'category' && formData?.special_bill?.category && formData?.special_bill?.category?.length === 0) || (formData?.special_bill?.type === 'barcode' && formData?.special_bill?.barcode?.length === 0)) {
                setIsSubmitBtnDisabled(true);
            } else {
                setIsSubmitBtnDisabled(false);
            }
        }
    }, [formData, formDataError])


    // Get All Brand
    const getAllBrandList = () => {
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then((response) => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item?.brand_name,
                    value: item?.brand_name,
                    email: item?.email_id,
                    id: item?._id,
                }
                dropdownData.push(row);
            });
            setBrandTypeList(dropdownData);
        }).catch(error => {
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    // Get All Store
    const getAllStoreCustomerNameList = (id) => {
        let queryParams = `?brand_id=${id}`;
        GetStoreCustomerNameList(queryParams, USER_TOKEN).then(response => {
            let dropdownData = [];
            let seenNames = new Set();
            response?.data?.data?.forEach(item => {
                if (!seenNames.has(item?.customer_name)) {
                    seenNames.add(item?.customer_name);

                    let row = {
                        label: item?.customer_name,
                        value: item?.customer_name,
                        email: item?.store_email_id,
                        store_code: item?.store_code,
                        id: item?._id,
                    };
                    dropdownData.push(row);
                }
            });

            setStoreTypeList(dropdownData);
            let storeObject = [];
            dropdownData.map(store => {
                let selectedStore = {
                    store_id: store.id,
                    store_code: store.store_code,
                    store_name: store.value,
                };
                storeObject.push(selectedStore);
            })
            setFormData(prevState => ({
                ...prevState,
                store_details: storeObject
            }));
        }).catch(error => {
            if (error?.response?.status === 401) {
                history.push('/')
            }
            ToastErrorService(error?.response?.data?.message)
        });
    };

    // Get Category List 
    const getCategoryGroupList = (brand_id) => {
        let param = `?brand_id=${brand_id}`
        GetCategoryByBrand(param, USER_TOKEN).then(response => {
            let dropdownItem = [];
            response?.data?.data.forEach(item => {
                let row = {
                    label: item,
                    value: item
                }
                dropdownItem.push(row)
            })
            setCategoryTypeList(dropdownItem)
        }).catch(error => {
            console.log(error)
        }).finally(() => {

        })
    }

    // Get Barcode List 
    const getBarcodeList = (brand_name) => {
        let param = `?brand=${brand_name}`
        GetBarcodes(param, USER_TOKEN).then(response => {
            let dropdownItem = [];
            response?.data?.data.forEach(item => {
                let row = {
                    label: item,
                    value: item
                }
                dropdownItem.push(row)
            })
            setBarcodeList(dropdownItem)
        }).catch(error => {
            console.log(error)
        }).finally(() => {

        })
    }

    const addNewDetailsForm = (incentive_type) => {
        if (!incentive_type) {
            ToastErrorService('First please select the type of incentive.')
        } else {
            setFormData(prevState => {
                if (incentive_type === 'wow_bill') {
                    if (prevState?.wow_bill.length < 5) {
                        const newRow = {
                            bill_amount: "",
                            condition: "",
                            set_incentive_amount: ""
                        };
                        return {
                            ...prevState,
                            wow_bill: [...prevState.wow_bill, newRow]
                        };
                    } else {
                        ToastErrorService("Maximum number of rows reached.");
                        return prevState;
                    }
                } else if (incentive_type === 'monthly_bill') {
                    if (prevState?.monthly_bill.length < 5) {
                        const newRow = {
                            achievement_percent: "",
                            condition: "",
                            set_incentive_percent: ""
                        };
                        return {
                            ...prevState,
                            monthly_bill: [...prevState.monthly_bill, newRow]
                        };
                    } else {
                        ToastErrorService("Maximum number of rows reached.");
                        return prevState;
                    }
                }
            });
        }
    };


    // const removeRow = (rowId) => {
    //     setFormData(prevState => ({
    //         ...prevState,
    //         wow_bill: prevState.wow_bill.filter(row => row.id !== rowId)
    //     }));
    // };

    // daterange 
    const openCalendar = () => {
        setCalendarOpen(prev => !prev)
    }

    const convertDate = (openingDate) => {
        let date = new Date(openingDate);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        return moment(dateString).format("DD MMM YYYY");
    }

    const handleDateRangeChange = (key, arryKey, value, optionsList, index, update) => {

        if (!startDate || (startDate && endDate)) {
            setStartDate(value);
            setEndDate(null);
            setSelectedRange([value, null]);
        } else if (startDate && !endDate && value >= startDate) {
            if (formData?.incentive_type === 'monthly_bill' &&
                startDate &&
                value &&
                startDate.getMonth() !== value.getMonth()
            ) {
                setStartDate(null);
                setEndDate(null);
                setSelectedRange([startDate, null]);
                ToastErrorService('You can only select the range within the month.')
            } else {
                setEndDate(value);
                setSelectedRange([startDate, value]);
                setFormData(prevState => ({
                    ...prevState,
                    from_date: startDate.toISOString(),
                    to_date: value.toISOString()
                }));
            }
        } else if (startDate && !endDate && value < startDate) {
            setStartDate(value);
            setSelectedRange([value, null]);
        }
    };

    const handleFormDataChange = (key, arryKey, value, optionsList, index, validationFunc, setErrorState, errorMsg) => {
        setFormData(prevState => {
            let updatedFormData = { ...prevState };
            if (key === 'incentive_type') {
                updatedFormData.wow_bill = [];
                updatedFormData.monthly_bill = [];
                updatedFormData.special_bill = {};
                updatedFormData[key] = value;
                if (value === 'wow_bill') {
                    updatedFormData.wow_bill = [
                        {
                            bill_amount: "",
                            condition: "",
                            set_incentive_amount: ""
                        },
                    ];
                } else if (value === 'monthly_bill') {
                    setStartDate(null);
                    setEndDate(null);
                    setSelectedRange([null, null]);
                    updatedFormData.from_date = '';
                    updatedFormData.to_date = '';
                    updatedFormData.monthly_bill = [
                        {
                            achievement_percent: "",
                            condition: "",
                            set_incentive_percent: ""
                        }
                    ];
                } else if (value === 'special_bill') {
                    updatedFormData.special_bill = {
                        type: "",
                        set_incentive_amount: ""
                    }

                }
            } else if (key === 'from_date' || key === 'to_date') {
                updatedFormData[key] = value;
            }
            else if (key === 'brand_details') {
                updatedFormData.store_details = [];
                const selectedBrand = optionsList?.find(option => option?.value === value?.value);
                if (selectedBrand) {
                    updatedFormData[key] = [{ brand_name: selectedBrand.value, brand_id: selectedBrand.id }];
                }
                getAllStoreCustomerNameList(selectedBrand.id);
                getCategoryGroupList(selectedBrand?.id);
                getBarcodeList(selectedBrand.value);
                updatedFormData.special_bill.category = [];
                updatedFormData.special_bill.barcode = [];
            } else if (key === 'store_details') {
                if (value[value.length - 1] === "all") {
                    if (formData.store_details?.length === optionsList?.length) {
                        setFormData(prevState => ({
                            ...prevState,
                            store_details: []
                        }));
                        return;
                    } else {
                        let storeDetails = []
                        storeTypeList.map(store => {
                            let selectedStore = {
                                store_id: store.id,
                                store_code: store.store_code,
                                store_name: store.value,
                            };
                            storeDetails.push(selectedStore);
                        })
                        setFormData(prevState => ({
                            ...prevState,
                            store_details: storeDetails
                        }));
                        return;
                    }
                }
                updatedFormData[key] = value.map(val => {
                    const selectedOption = optionsList.find(option => option.value === val);
                    return { store_name: selectedOption?.value || "", store_id: selectedOption?.id || "", store_code: selectedOption?.id };
                }).filter(store => store);
            }
            else if (key === 'wow_bill') {
                const currentFormData = formData?.wow_bill?.[index];
                const updatedFormDataItem = updatedFormData?.wow_bill?.[index];
                const formError = formDataError?.wow_bill?.[index];
                if (currentFormData && updatedFormDataItem) {
                    if (arryKey === "set_incentive_amount" || arryKey === 'bill_amount') {
                        value = value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1')
                    }
                    if (arryKey === "set_incentive_amount") {
                        const billAmount = Number(currentFormData.bill_amount) || 0;
                        const incentiveAmount = Number(value) || 0;
                        if (!currentFormData.bill_amount) {
                            updatedFormData.wow_bill[index] = {
                                ...updatedFormDataItem,
                                [arryKey]: null,
                            };
                            return prevState;
                        }
                        if (incentiveAmount > billAmount) {
                            setFormDataError(prev => {
                                let prevError = { ...prev }
                                prevError.wow_bill[index] = {
                                    ...formError,
                                    [arryKey]: 'Incentive amount can not be more than bill amount'
                                }
                                return prevError
                            })
                            // setWowBillError({ wow_bill: 'Incentive amount can not be more than bill amount' });
                            // return prevState;
                        } else {
                            setFormDataError(prev => {
                                let prevError = { ...prev }
                                prevError.wow_bill[index] = {
                                    ...formError,
                                    [arryKey]: ''
                                }
                                return prevError
                            })
                        }
                    }
                    updatedFormData.wow_bill[index] = {
                        ...updatedFormDataItem,
                        [arryKey]: value,
                    };
                }
            }
            else if (key === 'monthly_bill') {

                if (arryKey === "set_incentive_percent" || arryKey === 'achievement_percent') {
                    value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
                }
                const currentFormData = formData?.monthly_bill?.[index];
                const updatedFormDataItem = updatedFormData?.monthly_bill?.[index];
                const formError = formDataError?.wow_bill?.[index];
                if (arryKey === "set_incentive_percent") {
                    const achPercent = Number(currentFormData.achievement_percent) || 0;
                    const incPercent = Number(value) || 0;
                    if (incPercent > achPercent) {
                        setFormDataError(prev => {
                            let prevError = { ...prev }
                            prevError.monthly_bill[index] = {
                                ...formError,
                                [arryKey]: 'Incentive percent can not be more than achievement percent'
                            }
                            return prevError
                        })
                        // setMonthlyBillError({ monthly_bill: 'Incentive percent can not be more than achievement percent' });
                        // return prevState;
                    } else {
                        setFormDataError(prev => {
                            let prevError = { ...prev }
                            prevError.monthly_bill[index] = {
                                ...formError,
                                [arryKey]: ''
                            }
                            return prevError
                        })
                    }
                }
                updatedFormData.monthly_bill[index] = {
                    ...updatedFormDataItem,
                    [arryKey]: value,
                };
            }
            else if (key === 'special_bill') {

                if (arryKey === "set_incentive_amount" || arryKey === 'bill_amount') {
                    value = value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1')
                }
                const updatedFormDataItem = updatedFormData?.special_bill;
                updatedFormData.special_bill = {
                    ...updatedFormDataItem,
                    [arryKey]: arryKey === "set_incentive_amount" ? Number(value) : value,
                };
            }
            return updatedFormData;
        });
    };

    // Define a function to calculate incentive amount based on condition and bill amount
    const calculateIncentiveAmount = (condition, billAmount) => {
        if (isNaN(billAmount)) return 0;

        switch (condition) {
            case 'Greater Than':
                return billAmount * 0.1; // Example: 10% of billAmount
            case 'Less Than':
                return billAmount * 0.05; // Example: 5% of billAmount
            case 'Equal To':
                return 50; // Example fixed amount
            default:
                return 0;
        }
    };

    // Handle Submit
    const handleSubmit = () => {
        setLoading(true);
        CreateNewIncentive(formData, USER_TOKEN)
            .then(response => {
                setOpenSuccessPopup(true);
            })
            .catch(error => {
                ToastErrorService(error.response?.data?.message || 'An error occurred.');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const onClickCancel = () => {
        history.push('/app/incentive');
    }

    const onClickDeleteRow = (id, data, billType) => {
        if (formData[billType]?.length === 1) {
            ToastErrorService('You can not delete the last row.')
        } else {
            setFormData(prevData => {
                const selectedCopy = { ...prevData };
                const newSelected = selectedCopy[billType].splice(id, 1);
                return { ...selectedCopy }
            });
            setFormDataError(prevData => {
                const selectedCopy = { ...prevData };
                const newSelected = selectedCopy[billType].splice(id, 1);
                return { ...selectedCopy }
            }

            )
        }
    }

    const renderConditionalForm = (billType) => {
        if (billType && billType === 'wow_bill') {
            return <div className='incentiveMultiFormContainer'>
                {formData?.wow_bill?.map((row, index) => (
                    <div key={index} className='incentiveFormContainer'>
                        <div className='inputBottomSpace'>
                            <InputTextField
                                onChange={(event, newVal) => handleFormDataChange('wow_bill', 'bill_amount', event?.target?.value, '', index, validNumericInRange, setFormDataError, 'Please Enter Valid Amount')}
                                value={row?.bill_amount}
                                type='text'
                                disabled={false}
                                required
                                minLength={1}
                                // maxLength={5}
                                label='Total Bill Amount'
                            />
                            {formDataError?.wow_bill && <div className="error">{formDataError?.wow_bill[index]?.bill_amount}</div>}
                        </div>
                        <div className='inputBottomSpace'>
                            <CustomAutoComplete
                                onChange={(event, newVal) => handleFormDataChange('wow_bill', 'condition', newVal?.value, '', index, validNumericInRange, setFormDataError, 'Please Select Any Value')}
                                value={row?.condition}
                                options={CONDITIONLIST}
                                required
                                label='Condition*'
                            />
                        </div>
                        <div className='inputBottomSpace'>
                            <InputTextField
                                onChange={(event, newVal) => handleFormDataChange('wow_bill', 'set_incentive_amount', event?.target?.value, '', index, validNumericInRange, setFormDataError, 'Please Enter Valid Amount')}
                                value={row?.set_incentive_amount}
                                type='text'
                                disabled={false}
                                required
                                minLength={1}
                                // maxLength={5}
                                label='Set Incentive Amount'
                            />
                            {formDataError?.wow_bill && <div className="error">{formDataError?.wow_bill[index]?.set_incentive_amount}</div>}
                        </div>
                        {formData?.wow_bill?.length > 1 && <button className='delete-row' onClick={() => onClickDeleteRow(index, row, 'wow_bill')}>
                            <CancelOutlined height={24} />
                        </button>}
                    </div>
                ))}
            </div>
        } else if (billType && billType === 'monthly_bill') {
            return <div className='incentiveMultiFormContainer'>
                {formData?.monthly_bill?.map((row, index) => (<div key={index} className='incentiveFormContainer'>
                    <div className='inputBottomSpace'>
                        <InputTextField
                            onChange={(event, newVal) => handleFormDataChange('monthly_bill', 'achievement_percent', event?.target?.value, '', index, validNumericInRange, setFormDataError, 'Please Enter Valid Amount')}
                            value={row?.achievement_percent}
                            type='text'
                            disabled={false}
                            required
                            label='Achievement %'
                        />
                    </div>
                    <div className='inputBottomSpace'>
                        <CustomAutoComplete
                            onChange={(event, newVal) => handleFormDataChange('monthly_bill', 'condition', newVal?.value, '', index, validNumericInRange, setFormDataError, 'Please Enter Valid Amount')}
                            value={row?.condition}
                            options={CONDITIONLIST}
                            required
                            label='Condition*'
                        />
                    </div>
                    <div className='inputBottomSpace'>
                        <InputTextField
                            onChange={(event, newVal) => handleFormDataChange('monthly_bill', 'set_incentive_percent', event?.target?.value, '', index, validNumericInRange, setFormDataError, 'Please Enter Valid Amount')}
                            value={row?.set_incentive_percent}
                            type='text'
                            disabled={false}
                            required
                            label='Set Incentive %'
                        />
                        {formDataError?.monthly_bill && <div className="error">{formDataError?.monthly_bill[index]?.set_incentive_percent}</div>}
                    </div>
                    {formData?.monthly_bill?.length > 1 && <button className='delete-row' onClick={() => onClickDeleteRow(index, row, 'monthly_bill')}>
                        <CancelOutlined height={24} />
                    </button>}
                </div>))}
            </div>
        } else if (billType && billType === 'special_bill') {
            return <div className='incentiveMultiFormContainer'>
                <div className='incentiveFormContainer'>
                    <div className='inputBottomSpace'>
                        <CustomAutoComplete
                            onChange={(event, newVal) => handleFormDataChange('special_bill', 'type', newVal?.value, '', '', validNumericInRange, setFormDataError, 'Please Enter Valid Amount')}
                            value={TYPELIST.find(option => option.value === formData?.special_bill?.type) || ''}
                            options={TYPELIST}
                            required
                            label='Type'
                        />
                    </div>
                    {formData?.special_bill?.type === 'category' &&
                        <div className='inputBottomSpace'>
                            <MultiSelectCheckBox
                                handleChange={(event, newVal) => handleFormDataChange('special_bill', 'category', event?.target?.value, categoryTypeList, '', validNumericInRange, setFormDataError, 'Please Select Any Value')}
                                selectedValue={formData?.special_bill?.category?.map(item => item)}
                                options={categoryTypeList}
                                MenuProps={MenuProps}
                                label='Category*'
                            />
                        </div>}
                    {formData?.special_bill?.type === 'barcode' && <div className='inputBottomSpace'>
                        <MultiSelectCheckBox
                            handleChange={(event, newVal) => handleFormDataChange('special_bill', 'barcode', event?.target?.value, barcodeList, '', validNumericInRange, setFormDataError, 'Please Select Any Value')}
                            selectedValue={formData?.special_bill?.barcode?.map(item => item)}
                            options={barcodeList}
                            MenuProps={MenuProps}
                            label='Barcode*'
                        />
                    </div>}
                    <div className='inputBottomSpace'>
                        <InputTextField
                            onChange={(event, newVal) => handleFormDataChange('special_bill', 'set_incentive_amount', event?.target?.value, '', '', validNumericInRange, setFormDataError, 'Please Enter Valid Amount')}
                            value={formData?.special_bill?.set_incentive_amount}
                            type='text'
                            disabled={false}
                            required
                            label='Set Incentive Amount'
                        />
                    </div>
                </div>
            </div>
        }
    }

    return (
        <div className='notoSansFont'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='breadCrumbs'>
                    <span className='fontLight'>
                        <Link className="breadCrumbs" to={'/app/incentive'}>Home</Link>
                    </span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>Create Incentive</span>
                </div>
            </div>

            <div className='pt-3'>
                <div className='bg-white createIncentiveFormCard'>
                    <div className='createIncentiveHeader montSerratFont fontMedium fontSize18 textColorBlack'>
                        Create Incentive
                    </div>

                    <div className='pt-3 createIncentiveFormCont customScroll'>
                        <div className='incentiveFormContainer'>
                            <div className='inputBottomSpace'>
                                <CustomAutoComplete
                                    onChange={(event, newVal) => handleFormDataChange('incentive_type', '', newVal?.value, INCENTIVETYPE, '', validNumericInRange, setFormDataError, 'Please Select Any Value')}
                                    value={INCENTIVETYPE.find(option => option.value === formData?.incentive_type)?.label || ''}
                                    options={INCENTIVETYPE}
                                    required
                                    label='Select Type Of Incentive*'
                                />

                            </div>
                        </div>
                        <div className='incentiveFormContainer'>
                            <div className='inputBottomSpace'>
                                <CustomAutoComplete
                                    onChange={(event, newVal) => handleFormDataChange('brand_details', '', newVal, brandTypeList, '', validNumericInRange, setFormDataError, 'Please Select Any Value')}
                                    selectedValue={formData?.brand_details}
                                    options={brandTypeList}
                                    required
                                    label='Brand Name*'
                                />
                            </div>
                            <div className='inputBottomSpace'>
                                <MultiSelectCheckBox showSelectAll={true}
                                    handleChange={(event, newVal) => handleFormDataChange('store_details', '', event?.target?.value, storeTypeList, '', validNumericInRange, setFormDataError, 'Please Select Any Value')}
                                    selectedValue={formData?.store_details?.map(item => item?.store_name)}
                                    options={storeTypeList}
                                    MenuProps={MenuProps}
                                    label='Store Name*'
                                />
                            </div>
                            <div className='inputDateRangeBottomSpace'>
                                <div onClick={openCalendar} className='open_calendar_open'>
                                    <InputTextField
                                        type='text'
                                        disabled={false}
                                        name='dateRange'
                                        value={
                                            startDate || endDate
                                                ? `${startDate ? convertDate(startDate) : ''} ${(startDate && endDate) ? '-' : ''} ${endDate ? convertDate(endDate) : ''}`
                                                : ''
                                        }
                                        onChange={() => { }}
                                        required
                                        minLength={10}
                                        maxLength={10}
                                        label='Date'
                                        calendarIcon={true}
                                    />
                                </div>
                                <div className=''>
                                    <CustomDateRangePicker
                                        startDate={startDate}
                                        endDate={endDate}
                                        // minDate={new Date()}
                                        calendarOpen={calendarOpen}
                                        selectedRange={selectedRange}
                                        handleSelect={(date, newVal) => handleDateRangeChange('dateRange', '', date, '', '', validNumericInRange, setFormDataError, 'Please Select Any Value')}
                                        closeOnOutSide={() => setCalendarOpen(false)}
                                    />
                                </div>
                            </div>
                        </div>

                        <>
                            <div>
                                {renderConditionalForm(formData?.incentive_type)}
                            </div>
                            {formData?.incentive_type !== 'special_bill' && <div className='incentiveLastFormContainer'>
                                <div className='inputBottomSpace' style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                                    <div onClick={() => addNewDetailsForm(formData?.incentive_type)} className='' style={{ width: '30px', height: '30px', display: 'flex', border: '1px solid grey', borderRadius: '50%', cursor: 'pointer', paddingRight: '12px' }}>
                                        <img src={PlusIcon} alt="" />
                                    </div>
                                </div>
                            </div>}
                        </>
                    </div>
                </div>
            </div>

            <div className='pt-3'>
                <div className='bg-white continueBtn d-flex'>
                    <div className='pe-3'>
                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                    </div>
                    <div>
                        <CustomBlueButton disabled={isSubmitBtnDisabled} customCreatePropsBtnClass={'confirmButton'} onClick={handleSubmit} title={`Submit`} />
                    </div>
                </div>
            </div>

            <SuccessPopup open={openSuccessPopup} closable={false} onClose={() => { setOpenSuccessPopup(false); history.push('/app/incentive'); }} content={<div>
                <div className='paddingTop32 text-center'>
                    <div className='textColorBlack fontSize24 montserratFont fontMedium'>Created Successfully!</div>
                    <div className='fontSize16 textColor333'>You have successfully created the incentive.</div>
                </div>
            </div>} />
            {loading && <Loader />}
        </div >
    )
}

export default CreateIncentive