import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { userAuthActions } from '../../../store/reducers/UserAuthReducers';
import { useHistory } from 'react-router-dom';
import dropDownIcon from '../../../assets/svg/dropDownIcon.svg';
import CalendarIcon from '../../../assets/svg/calendar_2_fill.svg';
import CustomFilterDropDown from '../../../ReUsableComponent/CustomFilterDropdown';
import '../BrandManagement.css'
import CustomDateRangePicker from '../../../ReUsableComponent/CustomDaterangePicker';
import moment from "moment";
import CategoryIcon from '../../../assets/svg/category_9532032.svg';
import hrxLogo from './../../../assets/png/hrxLogo.png';
import UpArrowIcon from './../../../assets/svg/discount_879859.svg';
import GrowingBars from './../../../assets/svg/growingBars.svg';
import IncreasignBars from './../../../assets/svg/increasingbars.svg';
import TopCategories from './../../../assets/svg/usa_2880351.svg';
import BottomCategory from './../../../assets/svg/Layer2.svg';
import CategoryPresence from './../../../assets/svg/categoryPresence.svg';
import StoreIcon from './../../../assets/svg/store_726569.svg';
import graphUp from '../../../assets/svg/increaseGraph.svg';
import graphDown from '../../../assets/svg/decreaseGraph.svg';
import { GetBrandDasboardData } from '../BrandManagement.service';
import Loader from '../../../ReUsableComponent/Loader';
import { ToCrores, ToLacs, ToThousands } from '../../../utils/AmountCalculations';
import CustomModal from '../../../ReUsableComponent/CustomModal';
import CloseIcon from '../../../assets/svg/crossIcon.svg';
import { brandManagementActions } from '../../../store/reducers/BrandManagementReducer';

const channelStoreCountModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 736,
    height: 568,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};



const BrandDashboard = () => {

    const today = new Date();
    // today.setDate(1)
    // today.setHours(-1)
    const firstDayOfMonth = new Date(today.getFullYear(), (today.getMonth()));
    const brandLogo = useSelector((state) => state.brandManagement.selectedBrandLogo);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const dispatch = useDispatch();
    const history = useHistory();
    const [anchorElForBrand, setAnchorElForBrand] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const openBrandFilter = Boolean(anchorElForBrand);
    const [selectedBrand, setSelectedBrand] = React.useState({ label: useSelector((state) => state.brandManagement.selectedBrand), value: useSelector((state) => state.brandManagement.selectedBrand) });
    const [startDate, setStartDate] = useState(firstDayOfMonth);
    const [endDate, setEndDate] = useState(today);
    const [startDateDisplay, setStartDateDisplay] = useState(firstDayOfMonth);
    const [endDateDisplay, setEndDateDisplay] = useState(today);
    const [selectedRange, setSelectedRange] = useState([firstDayOfMonth, today]);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const brandList = useSelector((state) => state.brandManagement.brandDropdownList);
    const [brandDashboardData, setBrandDashboardData] = useState([]);
    const [openStateStoreCountModal, setOpenStateStoreCountModal] = React.useState(false);
    const [openCategorypresenceModal, setOpenCategorypresenceModal] = React.useState(false);
    const [openStoreFreshAndEossModal, setOpenStoreFreshAndEossModal] = React.useState(false);
    const USER_ID = useSelector(state => state.userAuthentication.userId);


    React.useEffect(() => {
        dispatch(userAuthActions.setActivePage({ type: '/brandManagement' }));
        dispatch(userAuthActions.setHeaderName({ type: 'Brand Management' }));

        getDashboardData(selectedBrand.value, startDate, endDate);
    }, [])


    const onClickGotoMaster = () => {
        dispatch(brandManagementActions.setSelectedPage({ type: 1 }));
        history.push('/app/brandManagement');
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
        // dispatch(storeManagementActions.setStoreStatus({ type: selectedStatus?.value }));
        // dispatch(storeManagementActions.setStoreClass({ type: '' }));
        // alert('coming soon!!!');
    }

    const handleBrandClose = () => {
        setAnchorElForBrand(null);
    }

    const onSelectBrand = (event) => {
        brandList.forEach(brand => {
            if (event.target.value === brand?.value) {
                setSelectedBrand({ label: brand?.label, value: event.target.value });
                dispatch(brandManagementActions.setSelectedBrand({ type: event.target.value }));
                dispatch(brandManagementActions.setDefaultBrand({ type: brand?.label }));
                setAnchorElForBrand(null);
            }
        })
        // dispatch(storeManagementActions.setStoreStatus({ type: event.target.value === 'true' ? true : false }));
        // let Status = event.target.value === 'true' ? true : false;
        getDashboardData(event.target.value, startDate, endDate);
    }

    const handleBrandClick = (event) => {
        setAnchorElForBrand(event.currentTarget);
    };

    const openCalendar = () => {
        setCalendarOpen(prev => !prev)
    }

    const handleDateRangeSelect = (date) => {
        if (!startDate || (startDate && endDate)) {
            setStartDate(date);
            setEndDate(null);
            setStartDateDisplay(date);
            setSelectedRange([]);
        } else if (startDate && !endDate && date > startDate) {
            setEndDate(date);
            setEndDateDisplay(date);
            setSelectedRange([startDate, date]);
            setCalendarOpen(false)
            getDashboardData(selectedBrand.value, startDate, date);
        } else if (startDate && !endDate && date < startDate) {
            setStartDate(date);
            setStartDateDisplay(date);
        }
    };

    const convertDates = (date) => {
        const startDate = new Date(date);
        const yyyy = startDate.getFullYear();
        const mm = String(startDate.getMonth() + 1).padStart(2, "0");
        const dd = String(startDate.getDate()).padStart(2, "0");
        const convertedDate = `${yyyy}-${mm}-${dd}`;
        return convertedDate
    }

    const getDashboardData = (selectedBrand, startDates, endDates) => {
        setLoading(true);
        let requestBody = {
            "brand_name": selectedBrand,
            "start_date": convertDates(startDates),
            "end_date": convertDates(endDates),
            "user_id": USER_ID
        }
        GetBrandDasboardData(requestBody, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                setBrandDashboardData(response.data.data)
            }
        }).catch(error => {

        }).finally(() => {
            setLoading(false);
        })
    }

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    const convertDate = (openingDate) => {
        // let fullDateTimeString = '2024-03-26T13:30:00';
        let date = new Date(openingDate);
        // Extract the date part
        let year = date.getFullYear();
        let month = date.getMonth() + 1; // Month is zero-based, so we add 1
        let day = date.getDate();

        // Create a new date string in the format YYYY-MM-DD
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

        return moment(dateString).format("DD MMM YYYY");
    }

    const onClickStateViewAll = () => {
        setOpenStateStoreCountModal(true);
    }
    const onClickCategoryPresenceViewAll = () => {
        setOpenCategorypresenceModal(true);
    }
    const onClickStoreFreshEossViewAll = () => {
        setOpenStoreFreshAndEossModal(true);
    }

    const onClickUpdateMasterData = () => {
        history.push('/app/update-brand-master')
    }

    return (
        <div className='mainContainer notoSansFont pt-3 pb-4'>
            <div className='storeDashboardFilter'>
                <div className='FilterSubCont d-flex align-items-end '>
                    <div className='goToMasterBtn me-3' onClick={onClickGotoMaster}>Go to Master</div>
                    <div className='position-relative'>
                        <div className='d-flex statusFilter brandFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={handleBrandClick}>
                            <p className='text-truncate' title={selectedBrand.label}>{selectedBrand?.label}</p>
                            <img className={`${openBrandFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </div>
                        <CustomFilterDropDown data={brandList} handleClose={handleBrandClose} open={openBrandFilter} anchorEl={anchorElForBrand}
                            onChange={onSelectBrand} selectedValue={selectedBrand?.value} category={'Brand'} />
                    </div>
                    <div className='lastFilterSubCont position-relative'>
                        <div className='d-flex dateRangeFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={openCalendar} >
                            <img src={CalendarIcon} alt="" />
                            <div>{`${convertDate(startDateDisplay)} - ${convertDate(endDateDisplay)}`}</div>
                        </div>
                        <div className=''>
                            <CustomDateRangePicker startDate={startDate} endDate={endDate} calendarOpen={calendarOpen} selectedRange={selectedRange} handleSelect={handleDateRangeSelect}
                                closeOnOutSide={() => setCalendarOpen(false)} />
                        </div>
                    </div>
                </div>
                <div className='paddingTop20'>
                    <div className='goToMasterBtn updateMaster widthFit float-right' onClick={onClickUpdateMasterData}>Update Master Data</div>
                </div>
            </div>
            <div className='paddingTop32'>
                <div className='cardsContainer'>
                    <div className='cardBody cardBodyCustomContForOnlyGreen'>
                        {brandDashboardData && <div className=''>
                            <div className='d-flex align-items-baseline '>
                                <div className='imgDiv'>
                                    <img src={brandDashboardData?.region_store?.totalStoreCountDetails?.brand_url} alt="" width={27} />
                                </div>
                                <div className='w-100'>
                                    <div className='ps-3 d-flex justify-content-between w-100 total_Count_Present_cont'>
                                        <div className='text-white total_Count_Pdding'>
                                            <div className='montSerratFont fontSize18 text-white'>{selectedBrand.label}</div>
                                            {activeTab === 1 && <div>
                                                <div>
                                                    <span id='storeCountLineHeight' className='notoSansFont fontSemiBold font32'>{brandDashboardData?.region_store?.totalStoreCountDetails?.totalStoreCount}</span>
                                                    {/* <span className='ps-1 fontSize12'>{brandDashboardData?.region_store?.totalStoreCountDetails?.totalPercentage}%</span>
                                                    <span className='ps-1'>
                                                        <img src={brandDashboardData?.region_store?.totalStoreCountDetails?.total_trend === 'Increased' ? graphUp : brandDashboardData?.region_store?.totalStoreCountDetails?.total_trend === 'Decrease' ? graphDown : ''} alt="" />
                                                    </span> */}
                                                </div>
                                                <div className='fontSize12 textColorLighterGray'>Total Store Count Present</div>
                                            </div>}
                                            {activeTab === 2 && <div>
                                                <div>
                                                    <span id='storeCountLineHeight' className='notoSansFont fontSemiBold font32 pe-1'>{ToCrores(brandDashboardData?.region_wise_revenue?.totalMRPAllRegions)}</span>Cr
                                                    {/* <span className='ps-1 fontSize12'>{brandDashboardData?.region_wise_revenue?.percentage}%</span>
                                                    <span className='ps-1'>
                                                        <img src={brandDashboardData?.region_wise_revenue?.trend === 'Increased' ? graphUp : brandDashboardData?.region_wise_revenue?.trend === 'Decreased' ? graphDown : ''} alt="" />
                                                    </span> */}
                                                </div>
                                                <div className='fontSize12 textColorLighterGray'>Total Gross Sales</div>
                                            </div>}
                                        </div>
                                        <div>
                                            <div className='storeAndRevenue'>
                                                <div value={1} className={`tab ${activeTab === 1 ? 'tabBackground fontBold textColorBlack' : 'bg-white textColorGary'}`} onClick={() => handleTabClick(1)}>
                                                    Store
                                                </div>
                                                <div value={2} className={`tab ${activeTab === 2 ? 'tabBackground fontBold textColorBlack' : 'bg-white textColorGary'}`} onClick={() => handleTabClick(2)}>
                                                    Revenue
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Regional Count */}
                            {activeTab === 1 && <div className='d-flex pt-3 gap-3 justify-content-between cardBgGreenCustomCont'>
                                {brandDashboardData?.region_store?.regionStoreDetails.map(item => (<div className={`regionBox ${item?.trend === 'Increased' ? 'cardBgGreen' : item?.trend === 'Decreased' ? 'cardBgRed' : 'cardBgGray'}`}>
                                    <div className='d-flex align-items-center '>
                                        <div className='text-capitalize fontSize13 minWidthCustom'>{item?.region}
                                            <span className={`ps-1 ${item?.trend === 'Same' ? 'd-none' : ''} ${item?.trend === 'Increased' ? 'textColorGreen' : item?.trend === 'Decreased' ? 'textColorRed' : 'textColorGary'}`}>
                                                {item?.percentageChange}
                                                <span className={`${item?.trend === 'Same' ? 'd-none' : ''}`}>%</span>
                                            </span>
                                            <span className='ps-1'>
                                                <img className="imgCustomWidth" src={item?.trend === 'Increased' ? graphUp : item?.trend === 'Decreased' ? graphDown : ''} alt="" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className='pt-1 fontSizeCustom fontSemiBold'>{item?.currentCount === 0 ? '-' : item?.currentCount}</div>
                                </div>))}
                            </div>}
                            {/* Regional Revenue */}
                            {activeTab === 2 && <div className='d-flex pt-3 gap-3 justify-content-between IncreaseCustomCont'>
                                {brandDashboardData?.region_wise_revenue?.regionWiseData.map(item => (<div className={`regionBox ${item?.trend === 'Increase' ? 'cardBgGreen' : item?.trend === 'Decrease' ? 'cardBgRed' : 'cardBgGray'}`}>
                                    <div className='d-flex align-items-center '>
                                        <div className='text-capitalize fontSize13 minWidthCustom'>{item?.region}
                                            {item?.percentage > 0 && <span className={`ps-1 ${item?.trend === 'Increased' ? 'textColorGreen' : item?.trend === 'Decreased' ? 'textColorRed' : 'textColorGary'} ${item?.trend === 'Same' ? 'd-none' : ''}`}>
                                                {item?.percentage}%
                                            </span>}
                                            <span className='ps-1'>
                                                <img className='imgCustomWidth' src={item?.trend === 'Increase' ? graphUp : item?.trend === 'Decrease' ? graphDown : ''} alt="" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-baseline'>
                                        <div className='pt-1 fontSizeCustom fontSemiBold'>{ToLacs(item?.totalMRP)}</div><div className='ps-1 fontSizeSubCustom'>Lac</div>
                                    </div>
                                </div>))}
                            </div>}
                        </div>}
                    </div>
                    {brandDashboardData?.category_division_wise && <div className='cardBody categoryDivisionBg'>
                        <div className='cardHeadingDiv'>
                            <div className='imgDiv'>
                                <img src={CategoryIcon} alt="" />
                            </div>
                            <p>Category Division - Sales & Contribution %</p>
                        </div>
                        <div className='dataDisplay pt-3'>
                            <div id='top5States' className='categoryDivision'>
                                {brandDashboardData?.category_division_wise.map(item => (
                                    <div>
                                        <p className='d-flex align-items-center'>
                                            <span className='grayClassDot'></span>
                                            <span id='storeName' className='text-truncate'>{item?.division}</span>
                                        </p>
                                        <p className='pt-3 fontSemiBold d-flex'>₹{ToLacs(item?.totalMRP)} <span className='fontWeightNormal'>Lac</span></p>
                                        {item?.percentage > 0 ? <p>{item?.percentage}%</p> : <p></p>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>}
                    {brandDashboardData?.state_wise_status?.topStatesStores && <div className='cardBody'>
                        <div className='cardHeadingDiv'>
                            <div className='imgDiv'>
                                <img src={UpArrowIcon} alt="" />
                            </div>
                            <p>Top 5 State - Sales & Contribution %</p>
                        </div>
                        <div className='dataDisplay pt-3'>
                            <div id='top5States'>
                                {brandDashboardData?.state_wise_status?.topStatesStores.slice(0, 5).map(item => (
                                    <div>
                                        <p title={item?.state} className='text-truncate'>{item?.state}</p>
                                        <p>₹{ToLacs(item?.totalMRP)} <span>Lac</span></p>
                                        {item?.percentage > 0 ? <p>{item?.percentage ? item.percentage : 0}%</p> : <p></p>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>}
                    {brandDashboardData?.state_wise_status?.bottomStatesStores && <div className='cardBody'>
                        <div className='cardHeadingDiv'>
                            <div className='imgDiv'>
                                <img id='rotateArrow' src={UpArrowIcon} alt="" />
                            </div>
                            <p>Bottom 5 State - Sales & Contribution %</p>
                        </div>
                        <div className='dataDisplay pt-3'>
                            <div id='top5States' className='bottom5'>
                                {brandDashboardData?.state_wise_status?.bottomStatesStores.slice(0, 5).map(item => (
                                    <div>
                                        <p title={item?.state} className='text-truncate'>{item?.state}</p>
                                        <p>₹{ToLacs(item?.totalMRP)} <span>Lac</span></p>
                                        {item?.percentage > 0 ? <p>{item?.percentage ? item.percentage : 0}%</p> : <p></p>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>}
                    <div className='cardBody'>
                        <div className='cardHeadingDiv'>
                            <div className='imgDiv'>
                                <img src={CategoryPresence} alt="" />
                            </div>
                            <p>Category Presence - (SOH & Sales) Contribution %</p>
                        </div>
                        <div className='dataDisplay categoryPresenceDataHeading pt-3'>
                            <div>Category Presence</div>
                            <div>Stock</div>
                            <div>Sale</div>
                        </div>
                        {brandDashboardData?.category_presence_sls_soh && <div>
                            {brandDashboardData?.category_presence_sls_soh.slice(0, 4).map(item => (<div className='dataDisplay categoryPresenceData'>
                                <div className='d-flex align-items-center' title={item?.category_presence === 'null' ? 'Other' : item?.category_presence}>
                                    <p className='fontSize16 text-truncate'>{item?.category_presence === 'null' ? 'Other' : item?.category_presence}</p>
                                </div>
                                <div className='fontMedium fontSize18'>{item?.stock}%</div>
                                <div className='fontMedium fontSize18'>{item?.sales}%</div>
                            </div>))}
                        </div>}
                        <div id='viewAllBtn'>
                            <div className='text-decoration-underline textColorBlue pt-2 text-right cursorPointer' onClick={onClickCategoryPresenceViewAll}>View All</div>
                        </div>
                    </div>
                    {brandDashboardData?.store_fresh_eoss && <div className='cardBody'>
                        <div className='cardHeadingDiv'>
                            <div className='imgDiv'>
                                <img src={StoreIcon} alt="" />
                            </div>
                            <p>Store - (Fresh & EOSS) Sales Contribution %</p>
                        </div>
                        <div className='dataDisplay categoryPresenceDataHeading pt-3'>
                            <div>Store</div>
                            <div>Fresh</div>
                            <div>EOSS</div>
                        </div>
                        <div>
                            {brandDashboardData?.store_fresh_eoss.slice(0, 4)?.map(item => (<div className='dataDisplay categoryPresenceData'>
                                <div className='d-flex align-items-center' title={item?.customer_name === 'null' ? 'Other' : item?.customer_name}>
                                    <p className='fontSize16 text-truncate'>{item?.customer_name === 'null' ? 'Other' : item?.customer_name}</p>
                                </div>
                                <div className='d-flex'><span className='fontMedium fontSize18'>{Math.round(item?.fresh_margin)}%</span> <span>{item?.fresh_margin_type}</span></div>
                                <div className='d-flex'><span className='fontMedium fontSize18'>{Math.round(item?.eoss_margin)}%</span> <span>{item?.eoss_margin_type}</span></div>
                            </div>))}
                        </div>
                        <div id='viewAllBtn'>
                            <div className='text-decoration-underline textColorBlue pt-2 text-right cursorPointer' onClick={onClickStoreFreshEossViewAll}>View All</div>
                        </div>
                    </div>}
                    {brandDashboardData?.store_wise_status && <div className='cardBody'>
                        <div className='cardHeadingDiv'>
                            <div className='imgDiv'>
                                <img src={TopCategories} alt="" />
                            </div>
                            <p>Top Stores - Sales & Contribution %</p>
                        </div>
                        <div className='dataDisplay pt-3'>
                            <div id='top5States' className='categoryDivision doorSales'>
                                {brandDashboardData?.store_wise_status.slice(0, 4).map(item => (
                                    <div className='d-flex top-stores'>
                                        <div className='grayDotClass'></div>
                                        <div className='minWidth'>
                                            <p className='d-flex align-items-center' title={item?.store_name}>
                                                <span id='storeName' className='text-truncate'>{item?.store_name ? item?.store_name : '-'}</span></p>
                                            <p>₹{ToLacs(item?.totalMRP)} <span className='fontWeightNormal'>Lac</span></p>
                                            {item?.percentage > 0 ? <p>{item?.percentage ? item.percentage : 0}%</p> : <p></p>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>}
                    {brandDashboardData?.store_wise_status && < div className='cardBody'>
                        <div className='cardHeadingDiv'>
                            <div className='imgDiv'>
                                <img src={BottomCategory} alt="" />
                            </div>
                            <p>Bottom Stores - Sales & Contribution %</p>
                        </div>
                        <div className='dataDisplay pt-3'>
                            <div id='top5States' className='categoryDivision doorSales'>
                                {brandDashboardData?.store_wise_status.slice(-4).map(item => (
                                    <div className='d-flex top-stores'>
                                        <div className='grayDotClass'></div>
                                        <div className='minWidth'>
                                            <p className='d-flex align-items-center' title={item?.store_name}>
                                                <span id='storeName' className='text-truncate'>{item?.store_name ? item?.store_name : '-'}</span>
                                            </p>
                                            <p>₹{ToLacs(item?.totalMRP)} <span className='fontWeightNormal'>Lac</span></p>
                                            {item?.percentage > 0 ? <p>{item?.percentage ? item.percentage : 0}%</p> : <p></p>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>}
                    {brandDashboardData?.state_wise_store_count && <div className='cardBody'>
                        <div className='cardHeadingDiv'>
                            <div className='imgDiv'>
                                <img src={GrowingBars} alt="" />
                            </div>
                            <p>State - Average Business Per Store</p>
                        </div>
                        <div className='dataDisplay stateBusinessDataHeading pt-3'>
                            <div>State</div>
                            <div>Store Count</div>
                            <div>Grand Total</div>
                            <div>Per Store Sale</div>
                        </div>
                        <div>
                            {brandDashboardData?.state_wise_store_count.slice(0, 4).map(item => (<div className='dataDisplay stateBusinessData'>
                                <div className='d-flex align-items-center' title={item?.state}>
                                    <p className='fontSize16 text-truncate'>{item?.state}</p>
                                </div>
                                <div className='fontMedium fontSize18'>{item?.storeCount === 0 ? '-' : item?.storeCount}</div>
                                <div className='fontMedium fontSize18 d-flex align-items-baseline'>₹{ToLacs(item?.totalMRP)} <span className='fontWeightNormal fontSize14 ps-1'>Lac</span></div>
                                <div className='fontMedium fontSize18'>{ToThousands(item?.perStoreSale)} <span className='textColorBlack fontSize14'>K</span></div>
                            </div>))}
                        </div>
                        <div id='viewAllBtn'>
                            <div className='text-decoration-underline textColorBlue pt-2 text-right cursorPointer' onClick={onClickStateViewAll}>View All</div>
                        </div>
                    </div>}
                    {brandDashboardData?.region_wise_store_count && <div className='cardBody'>
                        <div className='cardHeadingDiv'>
                            <div className='imgDiv'>
                                <img src={IncreasignBars} alt="" />
                            </div>
                            <p>Region - Average Business Per Store</p>
                        </div>
                        <div className='dataDisplay stateBusinessDataHeading pt-3'>
                            <div>Region</div>
                            <div>Store Count</div>
                            <div>Grand Total</div>
                            <div>Per Store Sale</div>
                        </div>
                        <div>
                            {brandDashboardData?.region_wise_store_count.map(item => (<div className='dataDisplay stateBusinessData'>
                                <div className='d-flex align-items-center'>
                                    <p className='fontSize16'>{item?.region}</p>
                                </div>
                                <div className='fontMedium fontSize18'>{item?.storeCount === 0 ? '-' : item?.storeCount}</div>
                                <div className='fontMedium fontSize18 d-flex align-items-baseline'>₹{ToLacs(item?.totalMRP)} <span className='fontWeightNormal fontSize14 ps-1'>Lac</span></div>
                                <div className='fontMedium fontSize18'>{ToThousands(item?.perStoreSale)} <span className='textColorBlack fontSize14'>K</span></div>
                            </div>))}
                        </div>
                    </div>}
                </div>
            </div>

            {/* State - Average Business Per Store Modal */}
            <CustomModal open={openStateStoreCountModal} style={channelStoreCountModalStyle} handleClose={null}
                content={<div>
                    {brandDashboardData?.state_wise_store_count && <div className='modalBody'>
                        <div className='cardHeadingDiv'>
                            <div className='imgDiv'>
                                <img src={GrowingBars} alt="" />
                            </div>
                            <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                                <p>State - Average Business Per Store</p>
                                <div className='modalCloseIconPlacement cursorPointer' onClick={() => setOpenStateStoreCountModal(false)}>
                                    <img src={CloseIcon} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className='dataDisplay stateBusinessDataHeading pt-3'>
                            <div>State</div>
                            <div>Store Count</div>
                            <div>Grand Total</div>
                            <div>Per Store Sale</div>
                        </div>
                        <div className='dataInsideModal customScroll'>
                            {brandDashboardData?.state_wise_store_count.map(item => (<div className='dataDisplay stateBusinessData'>
                                <div className='d-flex align-items-center' title={item?.state}>
                                    <p className='fontSize16 text-truncate'>{item?.state}</p>
                                </div>
                                <div className='fontMedium fontSize18'>{item?.storeCount === 0 ? '-' : item?.storeCount}</div>
                                <div className='fontMedium fontSize18 d-flex align-items-baseline'>₹{ToLacs(item?.totalMRP)} <span className='fontWeightNormal fontSize14 ps-1'>Lac</span></div>
                                <div className='fontMedium fontSize18'>{ToThousands(item?.perStoreSale)} <span className='textColorBlack fontSize14'>K</span></div>
                            </div>))}
                        </div>
                    </div>}
                </div>} />

            {/* Category Presence - (SOH & Sales) Contribution % Modal */}
            <CustomModal open={openCategorypresenceModal} style={channelStoreCountModalStyle} handleClose={null}
                content={<div>
                    <div className='modalBody'>
                        <div className='cardHeadingDiv'>
                            <div className='imgDiv'>
                                <img src={CategoryPresence} alt="" />
                            </div>
                            <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                                <p>Category Presence - (SOH & Sales) Contribution %</p>
                                <div className='modalCloseIconPlacement cursorPointer' onClick={() => setOpenCategorypresenceModal(false)}>
                                    <img src={CloseIcon} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='dataDisplay categoryPresenceDataHeading pt-3'>
                            <div>Category Presence</div>
                            <div>Stock</div>
                            <div>Sale</div>
                        </div>
                        <div className='dataInsideModal customScroll'>
                            {brandDashboardData?.category_presence_sls_soh && <div>
                                {brandDashboardData?.category_presence_sls_soh.map(item => (<div className='dataDisplay categoryPresenceData'>
                                    <div className='d-flex align-items-center' title={item?.category_presence === 'null' ? 'Other' : item?.category_presence}>
                                        <p className='fontSize16 text-truncate'>{item?.category_presence === 'null' ? 'Other' : item?.category_presence}</p>
                                    </div>
                                    <div className='fontMedium fontSize18'>{item?.stock}%</div>
                                    <div className='fontMedium fontSize18'>{item?.sales}%</div>
                                </div>))}
                            </div>}
                        </div>
                    </div>
                </div>} />

            {/* Store - (Fresh & EOSS) Sales Contribution % */}
            <CustomModal open={openStoreFreshAndEossModal} style={channelStoreCountModalStyle} handleClose={null}
                content={<div>
                    {brandDashboardData?.store_fresh_eoss && <div className='modalBody'>
                        <div className='cardHeadingDiv'>
                            <div className='imgDiv'>
                                <img src={StoreIcon} alt="" />
                            </div>
                            <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                                <p>Store - (Fresh & EOSS) Sales Contribution %</p>
                                <div className='modalCloseIconPlacement cursorPointer' onClick={() => setOpenStoreFreshAndEossModal(false)}>
                                    <img src={CloseIcon} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='dataDisplay categoryPresenceDataHeading pt-3'>
                            <div>Store</div>
                            <div>Fresh</div>
                            <div>EOSS</div>
                        </div>
                        <div className='dataInsideModal customScroll'>
                            {brandDashboardData?.store_fresh_eoss.map(item => (<div className='dataDisplay categoryPresenceData'>
                                <div className='d-flex align-items-center' title={item?.customer_name === 'null' ? 'Other' : item?.customer_name}>
                                    <p className='fontSize16 text-truncate'>{item?.customer_name === 'null' ? 'Other' : item?.customer_name}</p>
                                </div>
                                <div className='d-flex'><span className='fontMedium fontSize18'>{Math.round(item?.fresh_margin)}%</span> <span>{item?.fresh_margin_type}</span></div>
                                <div className='d-flex'><span className='fontMedium fontSize18'>{Math.round(item?.eoss_margin)}%</span> <span>{item?.eoss_margin_type}</span></div>
                            </div>))}
                        </div>
                    </div>}
                </div>} />

            {loading && <Loader />}
        </div >
    )
}

export default BrandDashboard
