import React, { useEffect, useState, useRef } from 'react';
import CustomHorizontalStepper from '../../../ReUsableComponent/CustomHorizontalStepper';
import RightArrowIcon from '../../../assets/svg/rightArrow.svg';
import { Link } from 'react-router-dom';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import '../../StoreManagement/storeManagement.css';
import ProgressBarWithLabel from '../../../ReUsableComponent/ProgressBarWithLabel';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import '../../TeamManagement/TeamManagement.css';
import Loader from '../../../ReUsableComponent/Loader';
import BrandInputForm from './BrandInputForm';
import { useDispatch, useSelector } from "react-redux";
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import { creatBrands, updateBrandDetailsBasedOnBrandId, getCreateBrandDropdownList } from './../BrandManagement.service';
import { brandManagementActions } from '../../../store/reducers/BrandManagementReducer';
import { useHistory } from 'react-router-dom';
import SuccessPopup from '../../../ReUsableComponent/SuccessPopupMsg';
import { isFileDetailsEmpty, allMandatoryFieldsFilledBrand } from './../../../ReUsableComponent/CustomFormValidation';
import { userAuthActions } from '../../../store/reducers/UserAuthReducers';


const CreateBrand = () => {
    const timeoutRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const createdBy = useSelector((state) => state.brandManagement.brandCreatedBy);
    const createBrandOwnerDetails = useSelector((state) => state.brandManagement.createBrandOwnerDetails);
    const getBrandDetails = useSelector((state) => state.brandManagement.createBrandDetails);
    const getActiveStep = useSelector((state) => state.brandManagement.stepActiveState);
    const getProgress = useSelector((state) => state.brandManagement.stepProgress);
    const steps = ['Brand Owner Details', 'Brand Details', 'Brand Contacts', 'Channel Details', 'Address', 'Tekx Contact Details', 'Upload Documents'];
    const [activeStep, setActiveStep] = React.useState(getActiveStep);
    // const [activeStep, setActiveStep] = React.useState(4);
    const [progress, setProgress] = React.useState(getProgress);
    const [skipped, setSkipped] = React.useState(new Set());
    const [loading, setLoading] = useState(false);
    const [disabledTrue, setDisabledTrue] = useState(true);
    const [brandHandleFormError, setBrandHandleFormError] = useState('');
    const [brandOwnerDetailsFormError, setBrandOwnerDetailsFormError] = useState('');
    const [brandDetailsFormError, setBrandDetailsFormError] = useState('');
    const [contactDetailsFormError, setContactDetailsFormError] = useState('');
    const [channelDetailsFormError, setChannelDetailsFormError] = useState('');
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [addressFormError, setAddressFormError] = useState({
        registered_office_address: {},
        warehouse_address: {},
        return_warehouse_address: {},
        billing_address: {},
    });
    const [companyContactDetailsFormError, setCompanyContactDetailsFormError] = useState('');
    const [documentUrlsFormError, setDocumentUrlsFormError] = useState('');
    const [brandOwnerDetails, setBrandOwnerDetails] = useState({
        person_name: createBrandOwnerDetails?.owner_contact?.person_name ? createBrandOwnerDetails?.owner_contact?.person_name : '',
        mobile: createBrandOwnerDetails?.owner_contact?.mobile ? createBrandOwnerDetails?.owner_contact?.mobile : '',
        email: createBrandOwnerDetails?.owner_contact?.email ? createBrandOwnerDetails?.owner_contact?.email : '',
    });
    const [brandDetails, setBrandDetails] = useState({
        brand_name: getBrandDetails?.brand_name ? getBrandDetails?.brand_name : '',
        status: getBrandDetails?.status ? getBrandDetails?.status : 'false',
        brand_deck: getBrandDetails?.brand_deck ? getBrandDetails?.brand_deck : '',
        email_id: getBrandDetails?.email_id ? getBrandDetails?.email_id : '',
        brand_mobile: getBrandDetails?.brand_mobile ? getBrandDetails?.brand_mobile : '',
        product_type: getBrandDetails?.product_type ? getBrandDetails?.product_type : [],
        business_model: getBrandDetails?.business_model ? getBrandDetails?.business_model : [],
        pan: getBrandDetails?.pan ? getBrandDetails?.pan : '',
        gstin: getBrandDetails?.gstin ? getBrandDetails?.gstin : '',
        security: getBrandDetails?.security ? getBrandDetails?.security : 'false',
        security_comment: getBrandDetails?.security_comment ? getBrandDetails?.security_comment : '',
        account_name: getBrandDetails?.bank_detail?.account_name ? getBrandDetails?.bank_detail?.account_name : '',
        bank_name: getBrandDetails?.bank_detail?.bank_name ? getBrandDetails?.bank_detail?.bank_name : '',
        account_number: getBrandDetails?.bank_detail?.bank_no ? getBrandDetails?.bank_detail?.bank_no : '',
        ifsc_code: getBrandDetails?.bank_detail?.ifsc_code ? getBrandDetails?.bank_detail?.ifsc_code : '',
        branch: getBrandDetails?.bank_detail?.branch ? getBrandDetails?.bank_detail?.branch : '',
        branch_address: getBrandDetails?.bank_detail?.branch_address ? getBrandDetails?.bank_detail?.branch_address : '',
        branch_pincode: getBrandDetails?.bank_detail?.branch_pincode ? getBrandDetails?.bank_detail?.branch_pincode : '',
        branch_city: getBrandDetails?.bank_detail?.branch_city ? getBrandDetails?.bank_detail?.branch_city : '',
        branch_state: getBrandDetails?.bank_detail?.branch_state ? getBrandDetails?.bank_detail?.branch_state : '',
    });
    const [contactDetails, setContactDetails] = useState([
        {
            person_dept: getBrandDetails?.contact_person?.person_dept ? getBrandDetails?.contact_person?.person_dept : '',
            person_name: getBrandDetails?.contact_person?.person_name ? getBrandDetails?.contact_person?.person_name : '',
            person_mobile: getBrandDetails?.contact_person?.person_mobile ? getBrandDetails?.contact_person?.mobile : '',
            email: getBrandDetails?.contact_person?.email ? getBrandDetails?.contact_person?.email : '',
            person_other: getBrandDetails?.contact_person?.person_other ? getBrandDetails?.contact_person?.person_other : '',
        }
    ]);
    const [channelDetails, setChannelDetails] = useState({
        channel_served: getBrandDetails?.channel_served ? getBrandDetails?.channel_served : [],
        channel_offline: getBrandDetails?.channel_offline ? getBrandDetails?.channel_offline : [],
        channel_online: getBrandDetails?.channel_online ? getBrandDetails?.channel_online : [],
    });
    const [address, setAddress] = useState({
        registered_office_address: {
            address: getBrandDetails?.registered_office_address?.address ? getBrandDetails?.registered_office_address?.address : '',
            region: getBrandDetails?.registered_office_address?.region ? getBrandDetails?.registered_office_address?.region : '',
            state: getBrandDetails?.registered_office_address?.state ? getBrandDetails?.registered_office_address?.state : '',
            city: getBrandDetails?.registered_office_address?.city ? getBrandDetails?.registered_office_address?.city : '',
            area: getBrandDetails?.registered_office_address?.area ? getBrandDetails?.registered_office_address?.area : '',
            pincode: getBrandDetails?.registered_office_address?.pincode ? getBrandDetails?.registered_office_address?.pincode : '',
            email: getBrandDetails?.registered_office_contact?.email ? getBrandDetails?.registered_office_contact?.email : '',
        },
        warehouse_address: {
            address: getBrandDetails?.warehouse_address?.address ? getBrandDetails?.warehouse_address?.address : '',
            region: getBrandDetails?.warehouse_address?.region ? getBrandDetails?.warehouse_address?.region : '',
            state: getBrandDetails?.warehouse_address?.state ? getBrandDetails?.warehouse_address?.state : '',
            city: getBrandDetails?.warehouse_address?.city ? getBrandDetails?.warehouse_address?.city : '',
            area: getBrandDetails?.warehouse_address?.area ? getBrandDetails?.warehouse_address?.area : '',
            pincode: getBrandDetails?.warehouse_address?.pincode ? getBrandDetails?.warehouse_address?.pincode : '',
            email: getBrandDetails?.warehouse_contact?.email ? getBrandDetails?.warehouse_contact?.email : '',
            mobile: getBrandDetails?.warehouse_contact?.mobile ? getBrandDetails?.warehouse_contact?.mobile : '',
            person_mobile: getBrandDetails?.warehouse_contact?.person_mobile ? getBrandDetails?.warehouse_contact?.person_mobile : '',
        },
        return_warehouse_address: {
            address: getBrandDetails?.return_warehouse_address?.address ? getBrandDetails?.return_warehouse_address?.address : '',
            region: getBrandDetails?.return_warehouse_address?.region ? getBrandDetails?.return_warehouse_address?.region : '',
            state: getBrandDetails?.return_warehouse_address?.state ? getBrandDetails?.return_warehouse_address?.state : '',
            city: getBrandDetails?.return_warehouse_address?.city ? getBrandDetails?.return_warehouse_address?.city : '',
            area: getBrandDetails?.return_warehouse_address?.area ? getBrandDetails?.return_warehouse_address?.area : '',
            pincode: getBrandDetails?.return_warehouse_address?.pincode ? getBrandDetails?.return_warehouse_address?.pincode : '',
            email: getBrandDetails?.return_warehouse_contact?.email ? getBrandDetails?.return_warehouse_contact?.email : '',
            mobile: getBrandDetails?.return_warehouse_contact?.mobile ? getBrandDetails?.return_warehouse_contact?.mobile : '',
            person_mobile: getBrandDetails?.return_warehouse_contact?.person_mobile ? getBrandDetails?.return_warehouse_contact?.person_mobile : '',
        },
        billing_address: {
            address: getBrandDetails?.billing_address?.address ? getBrandDetails?.billing_address?.address : '',
            region: getBrandDetails?.billing_address?.region ? getBrandDetails?.billing_address?.region : '',
            state: getBrandDetails?.billing_address?.state ? getBrandDetails?.billing_address?.state : '',
            city: getBrandDetails?.billing_address?.city ? getBrandDetails?.billing_address?.city : '',
            area: getBrandDetails?.billing_address?.area ? getBrandDetails?.billing_address?.area : '',
            pincode: getBrandDetails?.billing_address?.pincode ? getBrandDetails?.billing_address?.pincode : '',
            email: getBrandDetails?.billing_contact?.email ? getBrandDetails?.billing_contact?.email : '',
            mobile: getBrandDetails?.billing_contact?.mobile ? getBrandDetails?.billing_contact?.mobile : '',
            person_mobile: getBrandDetails?.billing_contact?.person_mobile ? getBrandDetails?.billing_contact?.person_mobile : '',
        }
    });
    const [companyContactDetails, setCompanyContactDetails] = useState({
        contacts_name: getBrandDetails?.company_contact_detail?.person_name ? getBrandDetails?.company_contact_detail?.person_name : '',
        contacts_mobile: getBrandDetails?.company_contact_detail?.mobile ? getBrandDetails?.company_contact_detail?.mobile : '',
        contacts_email_id: getBrandDetails?.company_contact_detail?.email ? getBrandDetails?.company_contact_detail?.email : '',
        contacts_person_dept: getBrandDetails?.company_contact_detail?.person_dept ? getBrandDetails?.company_contact_detail?.person_dept : '',
        account_name: getBrandDetails?.company_bank_detail?.account_name ? getBrandDetails?.company_bank_detail?.account_name : '',
        bank_name: getBrandDetails?.company_bank_detail?.bank_name ? getBrandDetails?.company_bank_detail?.bank_name : '',
        account_number: getBrandDetails?.company_bank_detail?.bank_no ? getBrandDetails?.company_bank_detail?.bank_no : '',
        ifsc_code: getBrandDetails?.company_bank_detail?.ifsc_code ? getBrandDetails?.company_bank_detail?.ifsc_code : '',
        branch: getBrandDetails?.company_bank_detail?.branch ? getBrandDetails?.company_bank_detail?.branch : '',
        branch_address: getBrandDetails?.company_bank_detail?.branch_address ? getBrandDetails?.company_bank_detail?.branch_address : '',
        branch_pincode: getBrandDetails?.company_bank_detail?.branch_pincode ? getBrandDetails?.company_bank_detail?.branch_pincode : '',
        branch_city: getBrandDetails?.company_bank_detail?.branch_city ? getBrandDetails?.company_bank_detail?.branch_city : '',
        branch_state: getBrandDetails?.company_bank_detail?.branch_state ? getBrandDetails?.company_bank_detail?.branch_state : '',
    });

    const [documentUrls, setDocumentUrls] = useState(
        getBrandDetails?.document_details ? getBrandDetails?.document_details : []
    );

    const [brandLogoImage, setBrandLogoImage] = useState('');


    const [panDocumentFile, setPanDocumentFile] = useState({
        file_id: '',
        doc_name: '',
        doc_url: '',
        size: '',
        created_at: '',
        doc_type: '',
    });
    const [gstDocumentFile, setGstDocumentFile] = useState({
        file_id: '',
        doc_name: '',
        doc_url: '',
        size: '',
        created_at: '',
        doc_type: '',
    });
    const [agreementDocumentFile, setAgreementDocumentFile] = useState({
        file_id: '',
        doc_name: '',
        doc_url: '',
        size: '',
        created_at: '',
        doc_type: '',
    });
    const [uploadBrandFileLogo, setUploadBrandFileLogo] = useState({
        file_id: '',
        doc_name: '',
        doc_url: '',
        size: '',
        created_at: '',
        doc_type: '',
    });
    const [brandFloorSpaceFile, setBrandFloorSpaceFile] = useState({
        file_id: '',
        doc_name: '',
        doc_url: '',
        size: '',
        created_at: '',
        doc_type: '',
    });
    const [adjacentFile, setAdjacentFile] = useState({
        file_id: '',
        doc_name: '',
        doc_url: '',
        size: '',
        created_at: '',
        doc_type: '',
    });
    const [otherFile, setOtherFile] = useState({
        file_id: '',
        doc_name: '',
        doc_url: '',
        size: '',
        created_at: '',
        doc_type: '',
    });

    const [channelOfflineStatus, setChannelOfflineStatus] = useState(true);
    const [channelOnlineStatus, setChannelOnlineStatus] = useState(true);

    const isObjectNotEmpty = (obj) => {
        return Object.values(obj).every(value => {
            if (Array.isArray(value)) {
                return value.length > 0;
            }
            return value !== "";
        });
    };
    const isObjectValid = (obj) => {
        for (const [key, value] of Object.entries(obj)) {
            if (Array.isArray(value) && value.length === 0) {
                console.error(`The field ${key} cannot be an empty array.`);
                return false;
            }
            if (!Array.isArray(value) && value === "") {
                console.error(`The field ${key} cannot be an empty string.`);
                return false;
            }
        }
        return true;
    };
    const isAddressObjectNotEmpty = (obj) => {
        if (!obj || typeof obj !== 'object') {
            return false;
        }
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if (typeof value === 'object') {
                    if (isObjectNotEmpty(value)) {
                        return true;
                    }
                } else if (value !== "") {
                    return true;
                }
            }
        }
        return false;
    };

    useEffect(() => {
        dispatch(userAuthActions.setActivePage({ type: '/brandManagement' }));
        dispatch(userAuthActions.setHeaderName({ type: 'Brand Management' }));
        if (createBrandOwnerDetails?._id && isObjectValid(brandOwnerDetails) && isObjectValid(brandDetails) && isObjectValid(channelDetails) && isAddressObjectNotEmpty(address) && isObjectValid(companyContactDetails) && (documentUrls?.length !== 0)) {
            if (activeStep === 5) {
                handleUpdateBrandDetailsBasedOnBrandId(createBrandOwnerDetails?._id, '');
            }
        } else if (createBrandOwnerDetails?._id && isObjectValid(brandOwnerDetails) && isObjectValid(brandDetails) && isObjectValid(channelDetails) && isAddressObjectNotEmpty(address) && isObjectValid(companyContactDetails)) {
            if (activeStep === 4) {
                handleUpdateBrandDetailsBasedOnBrandId(createBrandOwnerDetails?._id, '');
            }
        } else if (createBrandOwnerDetails?._id && isObjectValid(brandOwnerDetails) && isObjectValid(brandDetails) && isObjectValid(channelDetails) && isAddressObjectNotEmpty(address)) {
            if (activeStep === 3) {
                handleUpdateBrandDetailsBasedOnBrandId(createBrandOwnerDetails?._id, '');
            }
        } else if (createBrandOwnerDetails?._id && isObjectValid(brandOwnerDetails) && isObjectValid(brandDetails) && isObjectValid(channelDetails)) {
            if (activeStep === 2) {
                handleUpdateBrandDetailsBasedOnBrandId(createBrandOwnerDetails?._id, '');
            }
        } else if (createBrandOwnerDetails?._id && isObjectValid(brandOwnerDetails) && isObjectValid(brandDetails)) {
            if (activeStep === 1) {
                handleUpdateBrandDetailsBasedOnBrandId(createBrandOwnerDetails?._id, '');
            }
        }
        // dispatch(brandManagementActions.setStepActiveState({ type: 0 }));
        // dispatch(brandManagementActions.setStepProgress({ type: 0 }));
        // dispatch(brandManagementActions.setCreateBrandOwnerDetails({ type: {}}));
        // dispatch(brandManagementActions.setCreateBrandDetails({ type: {} }));
    }, [createBrandOwnerDetails?._id])

    const isStepOptional = (step) => {
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const onClickCancel = () => {
        dispatch(brandManagementActions.setStepActiveState({ type: 0 }));
        dispatch(brandManagementActions.setStepProgress({ type: 0 }));
        dispatch(brandManagementActions.setCreateBrandOwnerDetails({ type: {} }));
        dispatch(brandManagementActions.setCreateBrandDetails({ type: {} }));
        history.push('/app/brandManagement');
    }

    const handleNext = () => {
        if (activeStep === 0) {
            handleCreateBrand();
        } else {
            handleUpdateBrandDetailsBasedOnBrandId(createBrandOwnerDetails?._id, '');
        }
    };

    const handleFinalNext = () => {
        handleUpdateBrandDetailsBasedOnBrandId(createBrandOwnerDetails?._id, 'uploadButton');
    }

    const handleCreateBrand = () => {
        setLoading(true);
        let requestBody = {
            created_by: createdBy,
            owner_contact: brandOwnerDetails,
        }
        creatBrands(requestBody, USER_TOKEN).then(response => {
            if (response.data.statusCode === 400) {
                ToastErrorService(response.data.message);
            } else if (response.data.statusCode === 200) {
                ToastSuccessService(response.data.message);
                dispatch(brandManagementActions.setCreateBrandOwnerDetails({ type: response?.data?.data }));
                if ((activeStep + 1) < steps.length) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    dispatch(brandManagementActions.setStepActiveState({ type: activeStep + 1 }));
                }
                if (progress < 100) {
                    let formCompleted = 100 / (steps.length);
                    setProgress(prev =>
                        prev += formCompleted
                    )
                    dispatch(brandManagementActions.setStepProgress({ type: progress + formCompleted }));
                }
            }
        }).catch(error => {
            ToastErrorService(error);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleUpdateBrandDetailsBasedOnBrandId = (brandId, uploadButton) => {
        let param = `?brand_id=${brandId}`;

        const tempChannelDetails = {
            ...channelDetails,
            channel_offline: channelOfflineStatus ? [] : channelDetails?.channel_offline,
            channel_online: channelOnlineStatus ? [] : channelDetails?.channel_online
        }

        let reqBody = {
            company: {
                company_id: "65f2c26359a84f7e7dc548ac",
                company_name: "Tekexcelarator",
            },
            owner_contact: brandOwnerDetails,
            brand_details: brandDetails,
            contact_details: contactDetails,
            channel_details: tempChannelDetails,
            address: address,
            company_contact_details: companyContactDetails,
            document_details: documentUrls,
            brand_logo: brandLogoImage,
            is_profile_completed: allMandatoryFieldsFilledBrand(brandOwnerDetails, brandDetails, contactDetails, channelDetails, address, companyContactDetails, panDocumentFile, gstDocumentFile) ? true : false,
        };
        updateBrandDetailsBasedOnBrandId(reqBody, param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                let responseData = response?.data?.data;
                dispatch(brandManagementActions.setCreateBrandDetails({ type: responseData }));
                if ((activeStep + 1) < steps.length) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    dispatch(brandManagementActions.setStepActiveState({ type: activeStep + 1 }));
                }
                if (progress < 100) {
                    let formCompleted = 100 / (steps.length);
                    setProgress(prev =>
                        prev += formCompleted
                    )
                    dispatch(brandManagementActions.setStepProgress({ type: progress + formCompleted }));
                }
                if (uploadButton === "uploadButton") {
                    setOpenSuccessPopup(true);
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    // Set a new timeout to automatically close the popup
                    timeoutRef.current = setTimeout(() => {
                        dispatch(brandManagementActions.setSelectedPage({ type: 1 }));
                        dispatch(brandManagementActions.setStepActiveState({ type: 0 }));
                        dispatch(brandManagementActions.setStepProgress({ type: 0 }));
                        dispatch(brandManagementActions.setCreateBrandOwnerDetails({ type: {} }));
                        dispatch(brandManagementActions.setCreateBrandDetails({ type: {} }));
                        history.push('/app/brandManagement');
                        setOpenSuccessPopup(false);
                    }, 5000)
                    // setTimeout(() => {
                    //     dispatch(brandManagementActions.setSelectedPage({ type: 1 }));
                    //     dispatch(brandManagementActions.setStepActiveState({ type: 0 }));
                    //     dispatch(brandManagementActions.setStepProgress({ type: 0 }));
                    //     dispatch(brandManagementActions.setCreateBrandOwnerDetails({ type: {} }));
                    //     dispatch(brandManagementActions.setCreateBrandDetails({ type: {} }));
                    //     history.push('/app/brandManagement');
                    //     setOpenSuccessPopup(false);
                    // }, 5000)
                }
            } else if (response.data.statusCode === 404) {
                ToastErrorService(response.data.message);
                dispatch(brandManagementActions.setStepActiveState({ type: 0 }));
                dispatch(brandManagementActions.setStepProgress({ type: 0 }));
                dispatch(brandManagementActions.setCreateBrandOwnerDetails({ type: {} }));
                dispatch(brandManagementActions.setCreateBrandDetails({ type: {} }));
            } else {
                ToastErrorService(response.data.message);
            }
        }).catch(error => {
            ToastErrorService(error);
        }).finally(() => {
            setLoading(false);
        })
    }

    const addNewContactDetailsForm = () => {
        const newContact = {
            person_dept: getBrandDetails?.contact_person?.person_dept ? getBrandDetails?.contact_person?.person_dept : '',
            person_name: getBrandDetails?.contact_person?.person_name ? getBrandDetails?.contact_person?.person_name : '',
            person_mobile: getBrandDetails?.contact_person?.person_mobile ? getBrandDetails?.contact_person?.person_mobile : '',
            email: getBrandDetails?.contact_person?.email ? getBrandDetails?.contact_person?.email : '',
            person_other: getBrandDetails?.contact_person?.person_other ? getBrandDetails?.contact_person?.person_other : '',
        };

        if (contactDetails?.length <= 4) {
            setContactDetails([...contactDetails, newContact]);
        }
    }

    const isFileContactEmpty = (obj) => {
        return (
            !obj.person_dept ||
            !obj.person_name ||
            !obj.person_mobile ||
            !obj.email
        );
    }

    const isDisabled = () => {
        if (isFileContactEmpty(contactDetails[0]) || (contactDetailsFormError.person_dept !== null || contactDetailsFormError.person_name !== null || contactDetailsFormError.person_mobile !== null || contactDetailsFormError.email !== null)) {
            if (contactDetailsFormError === "") {
                return true;
            } else if (contactDetailsFormError) {
                return (
                    contactDetailsFormError.person_dept !== null ||
                    contactDetailsFormError.person_name !== null ||
                    contactDetailsFormError.person_mobile !== null ||
                    contactDetailsFormError.email !== null
                );
            }
        }
        return false;
    };
    // console.log("isFileDetailsEmpty=====>", contactDetails[0], isFileDetailsEmpty(contactDetails[0]), contactDetails?.length <= 1)


    return (
        <div className='notoSansFont'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='breadCrumbs'>
                    <span className='fontLight'>
                        <Link className="breadCrumbs" to={'/app/viewCardBrandManagement'}>Home</Link>
                    </span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>Create Brand</span>
                </div>
            </div>
            <div className='pt-3'>
                <div className='textColor333 pb-1'>You Have Completed <span className='textColorGreen fontBold'>{progress < 10 ? '0' : ''}{Math.round(progress)}%</span> Brand Profile!</div>
                <ProgressBarWithLabel progress={progress} />
            </div>
            <div className='pt-3'>
                <CustomHorizontalStepper steps={steps} isStepSkipped={isStepSkipped} isStepOptional={isStepOptional} activeStep={activeStep} />
            </div>
            <div className='pt-3'>
                <div className='bg-white createStoreFormCard'>
                    <BrandInputForm
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        brandOwnerDetails={brandOwnerDetails}
                        setBrandOwnerDetails={setBrandOwnerDetails}
                        brandDetails={brandDetails}
                        setBrandDetails={setBrandDetails}
                        contactDetails={contactDetails}
                        setContactDetails={setContactDetails}
                        channelDetails={channelDetails}
                        setChannelDetails={setChannelDetails}
                        address={address}
                        setAddress={setAddress}
                        companyContactDetails={companyContactDetails}
                        setCompanyContactDetails={setCompanyContactDetails}
                        documentUrls={documentUrls}
                        setDocumentUrls={setDocumentUrls}
                        brandHandleFormError={brandHandleFormError}
                        setBrandHandleFormError={setBrandHandleFormError}
                        brandOwnerDetailsFormError={brandOwnerDetailsFormError}
                        setBrandOwnerDetailsFormError={setBrandOwnerDetailsFormError}
                        brandDetailsFormError={brandDetailsFormError}
                        setBrandDetailsFormError={setBrandDetailsFormError}
                        contactDetailsFormError={contactDetailsFormError}
                        setContactDetailsFormError={setContactDetailsFormError}
                        channelDetailsFormError={channelDetailsFormError}
                        setChannelDetailsFormError={setChannelDetailsFormError}
                        addressFormError={addressFormError}
                        setAddressFormError={setAddressFormError}
                        companyContactDetailsFormError={companyContactDetailsFormError}
                        setCompanyContactDetailsFormError={setCompanyContactDetailsFormError}
                        documentUrlsFormError={documentUrlsFormError}
                        setDocumentUrlsFormError={setDocumentUrlsFormError}
                        addNewContactDetailsForm={addNewContactDetailsForm}
                        getBrandDetails={getBrandDetails}
                        setBrandLogoImage={setBrandLogoImage}

                        panDocumentFile={panDocumentFile}
                        setPanDocumentFile={setPanDocumentFile}
                        gstDocumentFile={gstDocumentFile}
                        setGstDocumentFile={setGstDocumentFile}
                        agreementDocumentFile={agreementDocumentFile}
                        setAgreementDocumentFile={setAgreementDocumentFile}
                        uploadBrandFileLogo={uploadBrandFileLogo}
                        setUploadBrandFileLogo={setUploadBrandFileLogo}
                        brandFloorSpaceFile={brandFloorSpaceFile}
                        setBrandFloorSpaceFile={setBrandFloorSpaceFile}
                        adjacentFile={adjacentFile}
                        setAdjacentFile={setAdjacentFile}
                        otherFile={otherFile}
                        setOtherFile={setOtherFile}

                        isFileDetailsEmpty={isFileDetailsEmpty}

                        channelOfflineStatus={channelOfflineStatus}
                        setChannelOfflineStatus={setChannelOfflineStatus}
                        channelOnlineStatus={channelOnlineStatus}
                        setChannelOnlineStatus={setChannelOnlineStatus}
                    />
                </div>
            </div>

            <div className='pt-3'>
                <div className='bg-white continueBtn d-flex'>
                    {activeStep === 0 && <div className='pe-3'>
                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                    </div>}
                    <div>

                        {activeStep === 0 && <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleNext} disabled={(brandOwnerDetailsFormError === "" ? true : false) || (brandOwnerDetailsFormError && (brandOwnerDetailsFormError?.person_name !== null || brandOwnerDetailsFormError?.mobile !== null || brandOwnerDetailsFormError?.email !== null))} title={`${activeStep !== 6 ? 'Confirm & Next' : 'Upload Document'}`} />}
                        {activeStep === 1 && <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleNext} disabled={
                            (brandDetailsFormError === "" ? true : false) ||
                            (brandDetailsFormError &&
                                (brandDetailsFormError?.brand_name !== null ||
                                    brandDetailsFormError?.email_id !== null ||
                                    brandDetailsFormError?.brand_mobile !== null ||
                                    brandDetailsFormError?.pan !== null ||
                                    brandDetailsFormError?.gstin !== null ||
                                    brandDetailsFormError?.account_name !== null ||
                                    brandDetailsFormError?.bank_name !== null ||
                                    brandDetailsFormError?.account_number !== null ||
                                    brandDetailsFormError?.ifsc_code !== null ||
                                    brandDetailsFormError?.branch !== null ||
                                    brandDetailsFormError?.branch_address !== null
                                    // brandDetailsFormError?.branch_pincode !== null ||
                                    // brandDetails?.branch_city === '' ||
                                    // brandDetails?.branch_state === ''  
                                )) ||
                            brandDetails?.product_type?.length === 0 ||
                            brandDetails?.status === 'false'
                        }
                            title={`${activeStep !== 6 ? 'Confirm & Next' : 'Upload Document'}`} />}

                        {activeStep === 2 && <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleNext} disabled={isDisabled()} title={`${activeStep !== 6 ? 'Confirm & Next' : 'Upload Document'}`} />}
                        {/* {activeStep === 2 && <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleNext} disabled={isFileDetailsEmpty(contactDetails[0]) ? (contactDetailsFormError === "" ? true : false) || (contactDetailsFormError && (contactDetailsFormError?.person_dept !== null || contactDetailsFormError?.person_name !== null || contactDetailsFormError?.person_mobile !== null || contactDetailsFormError?.email !== null )): false} title={`${activeStep !== 6 ? 'Confirm & Next' : 'Upload Document'}`} />} */}
                        {activeStep === 3 && <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleNext} disabled={false} title={`${activeStep !== 6 ? 'Confirm & Next' : 'Upload Document'}`} />}
                        {activeStep === 4 && <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleNext} disabled={
                            (addressFormError === "" ? true : false) ||
                            (addressFormError && (
                                addressFormError?.registered_office_address?.address !== null ||
                                addressFormError?.registered_office_address?.pincode !== null ||
                                addressFormError?.registered_office_address?.area !== null ||
                                addressFormError?.registered_office_address?.email !== null ||

                                addressFormError?.warehouse_address?.address !== null ||
                                addressFormError?.warehouse_address?.pincode !== null ||
                                addressFormError?.warehouse_address?.area !== null ||
                                addressFormError?.warehouse_address?.email !== null ||
                                addressFormError?.warehouse_address?.mobile !== null ||
                                addressFormError?.warehouse_address?.person_mobile !== null ||

                                addressFormError?.return_warehouse_address?.address !== null ||
                                addressFormError?.return_warehouse_address?.pincode !== null ||
                                addressFormError?.return_warehouse_address?.area !== null ||

                                addressFormError?.return_warehouse_address?.email !== null ||
                                addressFormError?.return_warehouse_address?.mobile !== null ||
                                addressFormError?.return_warehouse_address?.person_mobile !== null ||

                                addressFormError?.billing_address?.address !== null ||
                                addressFormError?.billing_address?.pincode !== null ||
                                addressFormError?.billing_address?.area !== null ||
                                addressFormError?.billing_address?.email !== null ||
                                addressFormError?.billing_address?.mobile !== null ||
                                addressFormError?.billing_address?.person_mobile !== null ||

                                address?.registered_office_address?.state === '' ||
                                address?.registered_office_address?.city === '' ||

                                address?.warehouse_address?.state === '' ||
                                address?.warehouse_address?.city === '' ||
                                address?.warehouse_address?.region === '' ||

                                address?.return_warehouse_address?.state === '' ||
                                address?.return_warehouse_address?.city === '' ||

                                address?.billing_address?.region === '' ||
                                address?.billing_address?.state === ''
                            ))} title={`${activeStep !== 6 ? 'Confirm & Next' : 'Upload Document'}`} />}
                        {activeStep === 5 && <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleNext} disabled={
                            (companyContactDetailsFormError === "" ? true : false) ||
                            (companyContactDetailsFormError && (
                                companyContactDetailsFormError?.contacts_name !== null ||
                                companyContactDetailsFormError?.contacts_mobile !== null ||
                                companyContactDetailsFormError?.contacts_email_id !== null ||
                                companyContactDetailsFormError?.contacts_person_dept !== null ||
                                companyContactDetailsFormError?.account_name !== null ||
                                companyContactDetailsFormError?.bank_name !== null ||
                                companyContactDetailsFormError?.account_number !== null ||
                                companyContactDetailsFormError?.ifsc_code !== null ||
                                companyContactDetailsFormError?.branch !== null ||
                                companyContactDetailsFormError?.branch_address !== null
                                // companyContactDetailsFormError?.branch_pincode !== null ||
                                // companyContactDetails?.branch_city === '' ||
                                // companyContactDetails?.branch_state === '' 
                            ))} title={`${activeStep !== 6 ? 'Confirm & Next' : 'Upload Document'}`} />}
                        {activeStep === 6 && <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleFinalNext} disabled={(isFileDetailsEmpty(panDocumentFile) || isFileDetailsEmpty(gstDocumentFile)) || (documentUrls?.length === 0 ? true : false) || (documentUrlsFormError && (documentUrlsFormError !== null))} title={`${activeStep !== 6 ? 'Confirm & Next' : 'Upload Document'}`} />}
                    </div>
                </div>
            </div>

            <SuccessPopup open={openSuccessPopup} closable={false} onClose={() => { if (timeoutRef.current) { clearTimeout(timeoutRef.current); } setOpenSuccessPopup(false); history.push('/app/brandManagement'); }} content={<div>
                <div className='paddingTop32 text-center'>
                    <div className='textColorBlack fontSize24 montserratFont fontMedium'>Created Successfully!</div>
                    <div className='fontSize16 textColor333'>You have successfully created your brand</div>
                </div>
            </div>} />
            {loading && <Loader />}
        </div>
    )
}

export default CreateBrand