import React from 'react';
import RightArrowIcon from '../../../assets/svg/rightArrow.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomTable from './CustomTable';

const TargetVsAchievementViewDetails = () => {

    const data = useSelector(state => state.target.viewDetailsData);
    const allDataById = useSelector(state => state.target.viewDetailsAllDataByID);

    const valueCheck = (value) => {
        if ((value && value !== 'null')) {
            return value
        } else {
            return '-'
        }
    }

    const convertDates = (date) => {
        const startDate = new Date(date);
        const yyyy = startDate.getFullYear();
        const mm = String(startDate.getMonth() + 1).padStart(2, "0");
        const dd = String(startDate.getDate()).padStart(2, "0");
        return `${dd}/${mm}/${yyyy}`
    }

    const calculatePercentage = (firstValue, secondValue) => {
        let percentage = (firstValue / secondValue) * 100;
        return Math.round(percentage) + '%';
    }

    const randomBackgroundColor = () => {
        const colors = ['bg1', 'bg2', 'bg3', 'bg4', 'bg5', 'bg6', 'bg7', 'bg8', 'bg9'];
        const randomColor = colors[Math.floor(Math.random() * colors.length)];
        console.log("randomColor====>", randomColor);
        return randomColor;
    }

    return (
        <div>
            <div>
                <div className='breadCrumbs'>
                    <span className='fontLight'>
                        <Link className="breadCrumbs" to={'/app/targetVsAchievementListView'}>Home</Link>
                    </span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>
                        <span className="breadCrumbs" >View Details</span>
                    </span>
                </div>
            </div>
            <div className='view-detail-container'>
                {allDataById && <div className='view-detail-content customScroll'>
                    <div className='cont-1'>
                        <div id='width-of-div'>
                            <div className='storeLabel'>Month</div>
                            <div className='storeValue'>{valueCheck(allDataById?.targetAchievementsDataArray[0]?.month)}</div>
                        </div>
                        <div id='width-of-div'>
                            <div className='storeLabel'>Brand Name</div>
                            <div className='storeValue'>{valueCheck(allDataById?.targetAchievementsDataArray[0]?.store?.brands?.brand_name)}</div>
                        </div>
                        <div id='width-of-div'>
                            <div className='storeLabel'>Store Code</div>
                            <div className='storeValue'>{valueCheck(allDataById?.targetAchievementsDataArray[0]?.store_code)}</div>
                        </div>
                        <div id='width-of-div'>
                            <div className='storeLabel'>Store Name</div>
                            <div className='storeValue'>{valueCheck(allDataById?.targetAchievementsDataArray[0]?.store_name)}</div>
                        </div>
                        <div id='width-of-div'>
                            <div className='storeLabel'>Region</div>
                            <div className='storeValue'>{valueCheck(allDataById?.targetAchievementsDataArray[0]?.store?.store_address?.region)}</div>
                        </div>
                        <div id='width-of-div'>
                            <div className='storeLabel'>State</div>
                            <div className='storeValue'>{valueCheck(allDataById?.targetAchievementsDataArray[0]?.store?.store_address?.state)}</div>
                        </div>
                    </div>
                    <div className='cont-1 paddingTop32'>
                        <div id='width-of-div'>
                            <div className='storeLabel'>RSM Name</div>
                            <div className='storeValue'>{valueCheck(allDataById?.targetAchievementsDataArray[0]?.store?.regional_manager)}</div>
                        </div>
                        <div id='width-of-div'>
                            <div className='storeLabel'>FL Status</div>
                            <div className='storeValue'>{allDataById?.targetAchievementsDataArray[0]?.store?.brands?.is_brand_staff ? 'Yes' : 'No'}</div>
                        </div>
                        <div id='width-of-div'>
                            <div className='storeLabel'>FL Name</div>
                            <div className='storeValue'>{valueCheck(allDataById?.targetAchievementsDataArray[0]?.store?.brands?.brand_staff_name)}</div>
                        </div>
                        <div id='width-of-div'>
                            <div className='storeLabel'>Total Target Value</div>
                            <div className='storeValue'>{valueCheck(allDataById?.targetAchievementsDataArray[0]?.total_value)}</div>
                        </div>
                        <div id='width-of-div'>
                            <div className='storeLabel'>Total Target Qty</div>
                            <div className='storeValue'>{valueCheck(allDataById?.targetAchievementsDataArray[0]?.total_quantity)}</div>
                        </div>
                        <div id='width-of-div'>
                            <div className='storeLabel'>Sell Thru</div>
                            <div className='storeValue'>{allDataById?.targetAchievementsDataArray[0]?.sell_through ? allDataById?.targetAchievementsDataArray[0]?.sell_through : 0}%</div>
                        </div>
                    </div>
                    <div className='cont-1 paddingTop32'>
                        <div id='width-of-div'>
                            <div className='storeLabel'>Achievement Value</div>
                            <div className='storeValue'>{valueCheck(allDataById?.targetAchievementsDataArray[0]?.achievementValue)}</div>
                        </div>
                        <div id='width-of-div'>
                            <div className='storeLabel'>Achievement Quantity</div>
                            <div className='storeValue'>{valueCheck(allDataById?.targetAchievementsDataArray[0]?.achievement_quantity)}</div>
                        </div>
                        <div id='width-of-div'>
                            <div className='storeLabel'>Achievement Value %</div>
                            <div className='storeValue'>{calculatePercentage((allDataById?.targetAchievementsDataArray[0]?.achievementValue === undefined ? 0 : allDataById?.targetAchievementsDataArray[0]?.achievementValue), (allDataById?.targetAchievementsDataArray[0]?.total_value === undefined ? 0 : allDataById?.targetAchievementsDataArray[0]?.total_value))}</div>
                        </div>
                        <div id='width-of-div'>
                            <div className='storeLabel'>Achievement Quantity %</div>
                            <div className='storeValue'>{calculatePercentage((allDataById?.targetAchievementsDataArray[0]?.achievement_quantity === undefined ? 0 : allDataById?.targetAchievementsDataArray[0]?.achievement_quantity), (allDataById?.targetAchievementsDataArray[0]?.total_quantity === undefined ? 0 : allDataById?.targetAchievementsDataArray[0]?.total_quantity))}</div>
                        </div>
                    </div>
                    <div className='border-dashed mt-4'></div>
                    <div className='paddingTop24'>
                        <div className='montSerratFont fontSize18 fontMedium textColorBlack'>Weekly status</div>
                        <div className='paddingTop18 d-flex gap-3 overflow-auto customScroll pb-2'>
                            {
                                allDataById?.weeklyCalculations && allDataById?.weeklyCalculations?.map(item =>
                                    // console.log("item====>", item)
                                    <CustomTable data={item} backgroundColor={'bg7'} />
                                )
                            }
                            {/* <CustomTable data={allDataById?.weeklyCalculations[0]} backgroundColor={randomBackgroundColor()} />
                            <CustomTable data={allDataById?.weeklyCalculations[0]} backgroundColor={randomBackgroundColor()} />
                            <CustomTable data={allDataById?.weeklyCalculations[0]} backgroundColor={randomBackgroundColor()} />
                            <CustomTable data={allDataById?.weeklyCalculations[0]} backgroundColor={randomBackgroundColor()} />
                            <CustomTable data={allDataById?.weeklyCalculations[0]} backgroundColor={randomBackgroundColor()} />
                            <CustomTable data={allDataById?.weeklyCalculations[0]} backgroundColor={randomBackgroundColor()} /> */}
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className='montSerratFont fontSize18 fontMedium textColorBlack'>Daily status</div>
                        <div className='paddingTop18 daily-data'>
                            {
                                allDataById?.targetAchievementsDataArray[0]?.target_values && allDataById?.targetAchievementsDataArray[0]?.target_values?.map(item =>
                                    <CustomTable data={item} backgroundColor={'bg5'} />
                                )
                            }
                            {/* <CustomTable backgroundColor={'bg-f5f5f5'} />
                            <CustomTable backgroundColor={'bg-f5f5f5'} />
                            <CustomTable backgroundColor={'bg-f5f5f5'} />
                            <CustomTable backgroundColor={'bg-f5f5f5'} />
                            <CustomTable backgroundColor={'bg-f5f5f5'} />
                            <CustomTable backgroundColor={'bg-f5f5f5'} /> */}
                        </div>
                    </div>
                    {/* <div className='paddingTop32 monthly-detail-card'>
                        {data[0]?.target_values?.map(item => (<div className='detail-cont-div'>
                            <div className='detail-card'>
                                <div className='storeLabel me-4'>Date</div>
                                <div className='storeValue pt-0'>{convertDates(item?.date)}</div>
                                <div className='storeLabel paddingTop14 me-4'>Value</div>
                                <div className='storeValue paddingTop14'>{item?.value}</div>
                                <div className='storeLabel paddingTop14 me-4'>Quantity</div>
                                <div className='storeValue paddingTop14'>{item?.quantity}</div>
                            </div>
                        </div>))}
                    </div> */}
                </div>}
            </div>
        </div>
    )
}

export default TargetVsAchievementViewDetails