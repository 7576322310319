import { common } from "@mui/material/colors";

export const CustomErrorMsg = {
    common: 'This field is required.',
    name: 'Please Enter Valid Name',
    mobile: 'Please Enter Valid Phone Number',
    email: 'Please Enter Valid Email Id',
    product: 'Please Enter Valid Product',
    pan: 'Please Enter Valid PAN No.',
    gstin: 'Please Enter Valid GST No.',
    securityComment: 'Please Enter Valid Security Comment',
    accountName: 'Please Enter Valid Account Name',
    bankName: 'Please Enter Valid Bank Name',
    accountNumber: 'Please Enter Valid Account Number',
    ifscCode: 'Please Enter Valid IFSC Code',
    branch: 'Please Enter Valid Branch',
    address: 'Please Enter Valid Address',
    pincode: 'Please Enter Valid Pincode',
    city: 'Please Enter Valid City',
    state: 'Please Enter Valid State',
    latitude: 'Please Enter Valid Latitude',
    longitude: 'Please Enter Valid Longitude',
    date: 'Please Enter Valid Date',
    details: 'Please Enter Valid Details',
    regionalManager: 'Please Enter Valid Regional Manager',
    margin: 'Please Enter Valid Margin',
    eossmargin: 'Please Enter Valid Eoss Margin',
    eossthresholdvalue: 'Please Enter Valid Threshold',
    basemargin: 'Please Enter Valid Base Margin',
    percent: 'Please Enter Valid Percent',
    percentType: 'Please Enter Valid Percent Type',
    payableDuration: 'Please Enter Valid Payable Duration',
    comment: 'Please Enter Valid Comment',
    creditLimit: 'Please Enter Valid Credit Limit',
    size: 'Please Enter Valid Size',
    custNum: 'Please Enter Valid Cust_No / Store Code',
    department: 'Please Enter Valid Department',
}