import React, { useEffect, useState } from 'react';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton'
import { Link, useHistory } from 'react-router-dom';
import RightArrowIcon from '../../../assets/svg/rightArrow.svg';
import '../Feedback.css';
import CustomAutoComplete from '../../../ReUsableComponent/CustomAutocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { updateFeedbackDetails } from '../Feedback.service';
import Loader from '../../../ReUsableComponent/Loader';
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import SuccessPopup from '../../../ReUsableComponent/SuccessPopupMsg';
import CustomModal from '../../../ReUsableComponent/CustomModal';
import CloseIcon from '../../../assets/svg/closeIconofmodal.svg';
import FileRenderer from '../../../ReUsableComponent/FileRenderer';
import PdfIcon from '../../../assets/svg/pdfIcon.svg';
import moment from 'moment';
import { userAuthActions } from '../../../store/reducers/UserAuthReducers';

const FEEDBACK_STATUS = [{ label: 'Action Taken', value: 'Action Taken' }, { label: 'No Action Required', value: 'No Action Required' }];
const uploadedFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};
const CrossIcon = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="9.564" height="9.564" viewBox="0 0 9.564 9.564">
        <path id="Path_17915" data-name="Path 17915" d="M14.759,5.627a.722.722,0,0,0-1.023,0l-3.547,3.54L6.642,5.62A.723.723,0,0,0,5.62,6.642l3.547,3.547L5.62,13.736a.723.723,0,1,0,1.023,1.023l3.547-3.547,3.547,3.547a.723.723,0,1,0,1.023-1.023l-3.547-3.547,3.547-3.547A.727.727,0,0,0,14.759,5.627Z" transform="translate(-5.407 -5.407)" fill="#B9B5B5" />
    </svg>)
}

const ViewFeedbackDetails = () => {

    const history = useHistory();

    const [viewFile, setViewFile] = useState([]);
    const [loading, setLoading] = useState(false);
    const [adminComment, setAdminComment] = useState('');
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const [openFileViewerModal, setOpenFileViewerModal] = useState(false);
    const [feedbackStatus, setFeedbackStatus] = useState({ label: '', value: '' });
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const dispatch = useDispatch();

    const feedbackDetails = useSelector((state) => state.feedback.viewFeedbackDetails);

    const onInputComment = (event) => {
        setAdminComment(event.target.value)
    }

    const onChangeFeedbackStatus = (event, value) => {
        setFeedbackStatus(value);
    }

    useEffect(() => {
        dispatch(userAuthActions.setActivePage({ type: '/feedback' }));
        dispatch(userAuthActions.setHeaderName({ type: 'Feedback' }));
        if (feedbackDetails?.feedbackAction?.toUpperCase() !== 'No Action Taken'.toUpperCase()) {
            setFeedbackStatus({ label: feedbackDetails?.feedbackAction, value: feedbackDetails?.feedbackAction });
            setAdminComment(feedbackDetails?.feedbackResponse);
        } else {
            setFeedbackStatus({ label: '', value: '' });
            setAdminComment('');
        }
    }, [feedbackDetails])


    const onClickCtaBtn = (btnType) => {
        if (btnType === 'cancel') {
            history.push('/app/feedback')
        } else {
            submitFeedbackResponse();
        }
    }

    const valueCheck = (value) => {
        if (value) {
            return value
        } else {
            return '-'
        }
    }

    const submitFeedbackResponse = () => {
        setLoading(true);
        let feedbackReq = {
            'remark': adminComment,
            'action': feedbackStatus.value
        };
        let param = `?id=${feedbackDetails?._id}`;
        updateFeedbackDetails(feedbackReq, param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 201) {
                setOpenSuccessPopup(true);
                setTimeout(() => {
                    closeSuccessPopup();
                }, 3000);
            }
        }).catch(error => {
            ToastErrorService(error.response.data.message);
            if (error.response.status === 401) {
                history.push('/')
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const closeSuccessPopup = () => {
        setOpenSuccessPopup(false);
        history.push('/app/feedback');
    }

    const openFile = (item) => {
        setViewFile(item);
        setOpenFileViewerModal(true);
    }

    const convertDate = (date_string) => {
        // let fullDateTimeString = '2024-03-26T13:30:00';
        let date = new Date(date_string);
        // Extract the date part
        let year = date.getFullYear();
        let month = date.getMonth() + 1; // Month is zero-based, so we add 1
        let day = date.getDate();

        // Create a new date string in the format YYYY-MM-DD
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

        return moment(dateString).format("DD MMM YYYY");
    }

    return (
        <div className='notoSansFont'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='breadCrumbs'>
                    <span className='fontLight'>
                        <Link className="breadCrumbs" to={'/app/feedback'}>Feedback</Link>
                    </span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>View Feedback Details</span>
                </div>
            </div>
            <div className='pt-3'>
                <div className='bg-white createStoreFormCard viewFeedbackCard'>
                    <div className='montSerratFont fontSize18 fontMedium textColorBlack pb-2'>Feedback</div>
                    {feedbackDetails && <div className={`position-relative customScroll ${feedbackDetails?.feedbackAction?.toLowerCase() === 'No Action Taken'.toLowerCase() ? '' : 'height_for_view_feedback'}`}>
                        <div className={`paddingTop16 notoSansFont ${feedbackDetails?.feedbackAction?.toLowerCase() === 'No Action Taken'.toLowerCase() ? 'view-feedback-cont' : 'height_for_view_feedback'} customScroll`}>
                            <div className='viewFeedackDetails'>
                                <div>
                                    <div className='storeLabel'>Employee ID</div>
                                    <div className='storeValue'>{valueCheck(feedbackDetails?.userDetail?.emp_id)}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Employee Name</div>
                                    <div className='storeValue'>{valueCheck(feedbackDetails?.userDetail?.userName)}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Employee Mobile Number</div>
                                    <div className='storeValue'>{feedbackDetails?.userDetail?.user_mobile ? '+91 ' : ''}{valueCheck(feedbackDetails?.userDetail?.user_mobile)}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Brand Name</div>
                                    <div className='storeValue'>{valueCheck(feedbackDetails?.userDetail?.brandName)}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Store Name</div>
                                    <div className='storeValue'>{valueCheck(feedbackDetails?.storeDetail?.store_name)}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Area</div>
                                    <div className='storeValue'>{valueCheck(feedbackDetails?.storeDetail?.store_area)}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>City</div>
                                    <div className='storeValue'>{valueCheck(feedbackDetails?.storeDetail?.store_city)}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Reporting Manager</div>
                                    <div className='storeValue'>{valueCheck(feedbackDetails?.userDetail?.reportingToName)}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Reporting Manager Mobile Number</div>
                                    <div className='storeValue'>{feedbackDetails?.userDetail?.reportingToMobile ? '+91 ' : ''}{valueCheck(feedbackDetails?.userDetail?.reportingToMobile)}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Created Date</div>
                                    <div className='storeValue'>{convertDate(feedbackDetails?.created_at)}</div>
                                </div>
                            </div>
                            <div className='paddingTop40 viewFeedackDetails'>
                                <div>
                                    <div className='storeLabel'>Title</div>
                                    <div className='storeValue'>{valueCheck(feedbackDetails?.title)}</div>
                                </div>
                                {!feedbackDetails?.audioFile ? (<div id='feedback-comment'>
                                    <div className='storeLabel'>Comment</div>
                                    <div className='storeValue' >{valueCheck(feedbackDetails?.feedbackRequest)}</div>
                                </div>) :
                                    (<div className='audio-player' >
                                        <audio controls preload>
                                            <source src={feedbackDetails?.audioFile?.fileUrl} type={`audio/${feedbackDetails?.audioFile?.fileType}`}></source>
                                        </audio>
                                    </div>)}
                            </div>
                            <div className='paddingTop40 tabheader'>
                                <div>
                                    <div className='storeLabel pb-3'>Attached Images</div>
                                    {feedbackDetails?.imageFiles && <div id='attached-images'>
                                        {feedbackDetails?.imageFiles.map(item => (<button title={`View ${item.type === 'pdf' ? 'File' : 'Image'}`} key={item.fileUrl} className='imgPreview' onClick={() => openFile(item)} >
                                            <img src={item.fileType === 'pdf' ? PdfIcon : item.fileUrl} width={100} height={100} alt="" />
                                        </button>))}
                                    </div>}
                                </div>
                            </div>
                            <div className='paddingTop24'>
                                <div className='storeLabel'>Write remark and select action</div>
                                <div className='paddingTop24 feedback-action'>
                                    <div className='feedback-remark'>
                                        <textarea placeholder='Remarks' rows={5} value={adminComment} maxlength="200"
                                            onChange={onInputComment} disabled={feedbackDetails?.feedbackAction?.toUpperCase() !== 'No Action Taken'.toUpperCase() ? true : false} />
                                        <div id="the-count">
                                            <span id="current">{adminComment?.length}</span>
                                            <span id="maximum">/200</span>
                                        </div>
                                    </div>
                                    <div>
                                        <CustomAutoComplete disabled={feedbackDetails?.feedbackAction?.toUpperCase() !== 'No Action Taken'.toUpperCase() ? true : false} onChange={onChangeFeedbackStatus} required options={FEEDBACK_STATUS} value={feedbackStatus.value} label='Action*' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>

            {feedbackDetails?.feedbackAction === 'No Action Taken' && <div className='pt-3'>
                <div className='bg-white btnContainer d-flex'>
                    <div className=''>
                        <SecondaryButton customClsName='feedbackCancelBtn' title={'Cancel'} onClick={() => onClickCtaBtn('cancel')} />
                    </div>
                    <div>
                        <CustomBlueButton customCreatePropsBtnClass={'feedbackCancelBtn text-white'} onClick={() => onClickCtaBtn('submit')} disabled={feedbackStatus?.value ? false : true} title={`Submit`} />
                    </div>
                </div>
            </div>}

            {/* Success Popup content */}
            <SuccessPopup open={openSuccessPopup} closable={false} onClose={closeSuccessPopup} content={<div>
                <div className='paddingTop32 text-center'>
                    <div className='textColorBlack fontSize24 montserratFont fontMedium'>Updated Successfully!</div>
                    <div className='fontSize16 textColor333'>Feedback response submitted successfully</div>
                </div>
            </div>} />

            {/* File Viewer Modal*/}
            <CustomModal classes="customeProgressModal" open={openFileViewerModal} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>{viewFile.fileName}</div>
                            <button className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenFileViewerModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </button>
                        </div>
                    </div>
                    <div className='paddingTop24' style={{ height: '80vh' }}>
                        <FileRenderer file={viewFile.fileUrl} fileType={viewFile.fileType} />
                    </div>
                </div>} />

            {loading && <Loader />}
        </div>
    )
}

export default ViewFeedbackDetails