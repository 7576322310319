import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import RightArrowIcon from '../../../assets/svg/rightArrow.svg';
import ImportIcon from '../../../assets/svg/uploadIcon.svg';
import InputTextField from '../../../ReUsableComponent/InputTextField';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import CustomAutoComplete from '../../../ReUsableComponent/CustomAutocomplete';
import { GetBrandNameList, GetStoreCustomerNameList } from '../../RuleManagement/RuleManagement.service';
import '../Feedback.css';
import CustomDropzone from '../../../ReUsableComponent/CustomDropzone';
import { useDispatch, useSelector } from 'react-redux';
import { createFeedback } from '../Feedback.service';
import Loader from '../../../ReUsableComponent/Loader';
import { ToastErrorService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import SuccessPopup from '../../../ReUsableComponent/SuccessPopupMsg';
import CustomModal from '../../../ReUsableComponent/CustomModal';
import ProgressBarForFileUpload from '../../../ReUsableComponent/ProgressBarForFileUpload';
import CloseIcon from '../../../assets/svg/closeIconofmodal.svg';
import PdfIcon from '../../../assets/svg/pdfIcon.svg';
import FileRenderer from '../../../ReUsableComponent/FileRenderer';
import { userAuthActions } from '../../../store/reducers/UserAuthReducers';

const uploadedFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 835,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const ShareFeedback = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const USER_ID = useSelector((state) => state.userAuthentication.userId);

    const [title, setTitle] = useState('');
    const [comment, setComment] = useState('');
    const [progress, setProgress] = useState(30);
    const [viewFile, setViewFile] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startTime, setStartTime] = useState(null);
    const [uploadSpeed, setUploadSpeed] = useState(null);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [storeNameList, setStoreNameList] = useState([]);
    const [brandNameList, setBrandNameList] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const [openFileViewerModal, setOpenFileViewerModal] = useState(false);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [openFileSelectedModal, setOpenFileSelectedModal] = useState(false);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [shareFeedbackBtnDisabled, setShareFeedbackBtnDisabled] = useState(false);
    const [selectedBrandName, setSelectedBrandName] = useState({ label: '', value: '' });
    const [selectedStoreName, setSelectedStoreName] = useState({ label: '', value: '' });

    useEffect(() => {
        dispatch(userAuthActions.setActivePage({ type: '/feedback' }));
        dispatch(userAuthActions.setHeaderName({ type: 'Feedback' }));
        getStoreList();
        getBrandList();
    }, [])

    useEffect(() => {
        if (comment?.length < 5 || title?.length < 5 || selectedBrandName?.value === '' || selectedBrandName?.value === '') {
            setShareFeedbackBtnDisabled(true)
        } else {
            setShareFeedbackBtnDisabled(false)
        }

    }, [shareFeedbackBtnDisabled, comment, title, selectedBrandName, selectedBrandName, uploadedImages]);

    const CrossIcon = () => {
        return (<svg xmlns="http://www.w3.org/2000/svg" width="9.564" height="9.564" viewBox="0 0 9.564 9.564">
            <path id="Path_17915" data-name="Path 17915" d="M14.759,5.627a.722.722,0,0,0-1.023,0l-3.547,3.54L6.642,5.62A.723.723,0,0,0,5.62,6.642l3.547,3.547L5.62,13.736a.723.723,0,1,0,1.023,1.023l3.547-3.547,3.547,3.547a.723.723,0,1,0,1.023-1.023l-3.547-3.547,3.547-3.547A.727.727,0,0,0,14.759,5.627Z" transform="translate(-5.407 -5.407)" fill="#B9B5B5" />
        </svg>)
    }

    const getBrandList = () => {
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item?.brand_name,
                    value: item?._id,
                }
                dropdownData.push(row)
            })
            setBrandNameList(dropdownData);
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        });
    }

    const getStoreList = (brand_id) => {
        let queryParams = `?brand_id=${brand_id}`;
        GetStoreCustomerNameList(queryParams, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item?.customer_name,
                    value: item?._id,
                }
                dropdownData.push(row);
            })
            setStoreNameList(dropdownData);
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        });
    }

    const onSelectBrandName = (e, value) => {
        if (value) {
            setSelectedBrandName(value);
        } else {
            setSelectedBrandName({ label: '', value: '' });
        }
        getStoreList(value.value)
    }
    const onSelectStoreName = (e, value) => {
        if (value) {
            setSelectedStoreName(value);
        } else {
            setSelectedStoreName({ label: '', value: '' });
        }
    }

    const onInput = (event, inputFor) => {
        if (inputFor.toLowerCase() === 'comment'.toLowerCase()) {
            setComment(event.target.value);
        } else {
            setTitle(event.target.value);
        }
    }

    const onClickCtaBtn = (btnType) => {
        if (btnType === 'cancel') {
            history.push('/app/feedback')
        } else {
            shareFeedback()
        }
    }

    const shareFeedback = () => {
        setLoading(true);

        let formdata = new FormData();
        formdata.append('brand_name', selectedBrandName.label);
        formdata.append('brand_id', selectedBrandName.value);
        formdata.append('store_id', selectedStoreName.value);
        // formdata.append('audioFile', selectedBrandName.value);
        selectedImages.forEach(image => formdata.append('imageFiles', image))
        formdata.append('feedbackRequest', comment);
        formdata.append('title', title);
        formdata.append('userId', USER_ID);

        createFeedback(formdata, USER_TOKEN).then(response => {
            if (response.data.statusCode === 201) {
                setOpenSuccessPopup(true);
                setTimeout(() => {
                    closeSuccessPopup();
                }, 5000);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleFileDrop = (files) => {
        if (selectedImages?.length < 10) {
            const fileUploaded = files[0];
            if (fileUploaded) {
                const index = selectedImages?.findIndex(x => x.name === fileUploaded.name);
                if (index === -1) {
                    setUploadedFile(fileUploaded);
                    setProgress(0);
                    simulateUpload();
                    setOpenFileSelectedModal(true);
                } else {
                    ToastErrorService('Please select a different file')
                }
            }
        } else {
            ToastErrorService('You can select only 10 files');
        }

    };

    const onClickUploadImage = () => {
        setSelectedImages(prevState => [...prevState, uploadedFile]);
        let newFile = URL.createObjectURL(uploadedFile);
        let fileType = '';
        let fileName = uploadedFile.name;
        if (uploadedFile.type === 'application/pdf') {
            fileType = 'pdf'
        } else {
            fileType = 'img'
        }
        setUploadedImages(prevFiles => [...prevFiles, { type: fileType, newFile, name: fileName }]);
        setOpenFileSelectedModal(false);
    }

    const simulateUpload = () => {
        setUploadButtonDisabled(true);
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                const nextProgress = prevProgress + 20;
                if (nextProgress >= 100) {
                    clearInterval(interval);
                    setUploadSpeed(null);
                    setUploadButtonDisabled(false);
                    return 100;
                }
                if (!startTime) {
                    setStartTime(Date.now());
                }
                const elapsedTime = (Date.now() - startTime) / 1000; // in seconds
                const speed = (nextProgress / elapsedTime).toFixed(2); // in % per second
                setUploadSpeed(speed);
                return nextProgress;
            });
        }, 500);
    };

    const closeSuccessPopup = () => {
        setOpenSuccessPopup(false);
        history.push('/app/feedback');
    };

    const removeFile = (fileFrom, fileName) => {
        if (fileFrom === 'upload') {
            setUploadedFile([]);
        } else {
            const index = selectedImages?.findIndex(x => x.name === fileName);
            const indexOfUploaded = uploadedImages?.findIndex(x => x.name === fileName);
            if (index > -1) {
                setSelectedImages((prevValue) => {
                    let selectedCopy = [...prevValue]
                    let sliced = selectedCopy.splice(index, 1)
                    return [...selectedCopy]
                });
            }
            if (indexOfUploaded > -1) {
                setUploadedImages((prevValue) => {
                    let selectedCopy = [...prevValue]
                    let sliced = selectedCopy.splice(index, 1)
                    return [...selectedCopy]
                });
            }
        }
    }

    const openFile = (item) => {
        setViewFile(item);
        setOpenFileViewerModal(true);
    }

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='breadCrumbs'>
                    <span className='fontLight'>
                        <Link className="breadCrumbs" to={'/app/feedback'}>Feedback</Link>
                    </span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>Share Feedback</span>
                </div>
            </div>

            <div className='pt-3'>
                <div className='bg-white createStoreFormCard viewFeedbackCard'>
                    <div className='montSerratFont fontSize18 fontMedium textColorBlack'>Write Your Feedback</div>
                    <div className='feedback-form pt-3 customScroll'>
                        <div className='d-flex gap-3 pb-3'>
                            <div id='form-field'>
                                <InputTextField type='text' maxLength={80} minLength={5} disabled={false} value={title} onChange={(e) => { onInput(e, 'Title') }} required label='Title' />
                            </div>
                            <div id='form-field'>
                                <CustomAutoComplete onChange={onSelectBrandName} value={selectedBrandName.label} required options={brandNameList} label='Brand Name*' />
                            </div>
                        </div>
                        <div className='d-flex gap-3 pb-3'>
                            <div id='form-field'>
                                <CustomAutoComplete onChange={onSelectStoreName} value={selectedStoreName.label} required options={storeNameList} label='Sore Name*' />
                            </div>
                            <div id='form-field'>
                                <InputTextField type='text' maxLength={200} minLength={5} disabled={false} value={comment} onChange={(e) => { onInput(e, 'Comment') }} required label='Type Comment' />
                            </div>
                        </div>
                        <div>
                            <div className={`${selectedImages?.length > 0 ? 'd-flex' : 'feedback'}`}>
                                <CustomDropzone filesAccepted={['.jpeg', '.png', '.pdf', '.jpg']} onFileDrop={handleFileDrop} displayContent={<div className={`dropZoneContentFeedback ${selectedImages?.length > 0 ? 'uploadedFileDropZoneContent cardBorder' : ''}`}>
                                    <div className={`${selectedImages?.length > 0 ? '' : 'dropZoneInnerContainer'} d-flex justify-content-center`}>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload PNG, JPG, JPEG and PDF File Only</div>
                                        <div className='textColorBlack fontSize14 paddingTop12'>OR</div>
                                    </div>
                                    <div className='paddingTop12 d-flex justify-content-center align-items-center'>
                                        <SecondaryButton customClsName='feedbackCancelBtn feedback-upload-btn' title={'Choose File'} onClick={null} />
                                    </div>
                                </div>} />
                                {uploadedImages && <div className='ps-4 img-preview-cont'>
                                    {uploadedImages.map(item => (<div className='position-relative' key={item}>
                                        <button title={`View ${item.type === 'pdf' ? 'File' : 'Image'}`} className='imgPreview' onClick={() => openFile(item)} >
                                            <img src={item.type === 'pdf' ? PdfIcon : item.newFile} width={90} height={90} alt="" />
                                        </button>
                                        <button className='remove-img' onClick={() => removeFile('selected', item.name)}>
                                            <CrossIcon />
                                        </button>
                                    </div>
                                    ))}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='pt-3'>
                <div className='bg-white btnContainer d-flex'>
                    <div className=''>
                        <SecondaryButton customClsName='feedbackCancelBtn' title={'Cancel'} onClick={() => onClickCtaBtn('cancel')} />
                    </div>
                    <div>
                        <CustomBlueButton customCreatePropsBtnClass={'feedbackCancelBtn text-white'} onClick={() => onClickCtaBtn('submit')} disabled={shareFeedbackBtnDisabled} title={`Share Feedback`} />
                    </div>
                </div>
            </div>

            {/* Success Popup content */}
            <SuccessPopup open={openSuccessPopup} closable={false} onClose={closeSuccessPopup} content={<div>
                <div className='paddingTop32 text-center'>
                    <div className='textColorBlack fontSize24 montserratFont fontMedium'>Created Successfully!</div>
                    <div className='fontSize16 textColor333'>Feedback submitted successfully.</div>
                </div>
            </div>} />

            {/* Upload file progress Modal */}
            <CustomModal classes="customeProgressModal" open={openFileSelectedModal} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Upload File</div>
                            <button className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenFileSelectedModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </button>
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className='dropZoneContentContainer'>
                            <div className='channelDisplayData'>
                                <CustomDropzone filesAccepted={['.jpeg', '.png', '.pdf', '.jpg']} onFileDrop={handleFileDrop} displayContent={<div className='dropZoneContent uploadedFileDropZoneContent h100'>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload PNG, JPG, JPEG and PDF File Only</div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <SecondaryButton onClick={handleFileDrop} title={<span className='fontSemiBold textColorBlue'>Choose File</span>} />
                                    </div>
                                </div>} />
                            </div>
                            <div className='dropZoneContentRight'>
                                {uploadedFile?.path && <div>
                                    <p className='uploadingP'>Uploading</p>
                                    <div className='paddingTop24'>
                                        <ProgressBarForFileUpload onClickImageCloseIcon={() => removeFile('upload', '')} progress={progress} File={uploadedFile} uploadSpeed={uploadSpeed} />
                                    </div>
                                    <div className='pt-3 CustomBlueButtonConatiner' >
                                        <CustomBlueButton customCreatePropsBtnClass="customCreatePropsBtnClass" disabled={uploadButtonDisabled} onClick={onClickUploadImage} title={'Upload'} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>} />

            {/* File Viewer Modal*/}
            <CustomModal classes="customeProgressModal" open={openFileViewerModal} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>{viewFile.name}</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenFileViewerModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <FileRenderer file={viewFile.newFile} fileType={viewFile.type} />
                    </div>
                </div>} />

            {loading && <Loader />}
        </div>
    )
}

export default ShareFeedback