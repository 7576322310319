import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FilledInput from "@mui/material/FilledInput";
import './MultiSelectCheckBox.css';
import { ListItemIcon } from "@mui/material";

const CustomIcon = () => {
    return <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="10.828" height="6.414" viewBox="0 0 10.828 6.414">
            <path id="Path_17966" data-name="Path 17966" d="M12,16l4-4L12,8" transform="translate(17.414 -10.586) rotate(90)" fill="none" stroke="#4169e1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </svg>
    </div>
}

export default function MultiSelectCheckBox(props) {
    const { options, selectedValue, handleChange, MenuProps, label, showSelectAll } = props;

    const renderSelectedValue = (selected) => {
        if (Array.isArray(selected)) {
            return selected.join(", ");
        }
        if (typeof selected === 'object') {
            return Object.values(selected).join(", ");
        }
        return String(selected);
    };

    return (
        <div className="customMultiSelect">
            <FormControl>
                <InputLabel variant="filled">{label}</InputLabel>
                <Select
                    multiple
                    value={Array.isArray(selectedValue) ? selectedValue : []}
                    onChange={handleChange}
                    input={<FilledInput label={label} />}
                    renderValue={renderSelectedValue}
                    MenuProps={MenuProps}
                    IconComponent={CustomIcon}
                    disabled={props?.disabled}
                    className={`${props?.disabled ? 'customSelect' : ''}`}
                >
                    {(showSelectAll && options?.length > 0) && <MenuItem value="all" className="customSelect">
                        <Checkbox
                            checked={options?.length > 0 && selectedValue?.length === options.length}
                            indeterminate={selectedValue?.length > 0 && selectedValue?.length < options.length}
                        />
                        <ListItemText primary="Select All" />
                    </MenuItem>}
                    {options?.map((option, index) => (
                        <MenuItem key={index} value={option.value} className="customSelect" >
                            <Checkbox checked={selectedValue?.indexOf(option.value) > -1} />
                            <ListItemText primary={option.label} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
