import { combineReducers } from '@reduxjs/toolkit';
import userAuthReducerSlice from './UserAuthReducers';
import storeManagementReducerSlice from './StoreManagementReducer';
import brandManagementSlice from './BrandManagementReducer';
import userManagementSlice from './UserManagementReducer';
import teamManagementSlice from './TeamManagementReducer';
import feedbackSlice from './FeedbackReducer';
import salesSlice from './SalesOverviewReducer';
import sidebarSlice from './SidebarReducer';
import stockSlice from './StockOverviewReducer';
import targetVsAchievementSlice from './TargetVsAchievement.reducer';
import promotionSlice from './PromotionReducer'
import incentiveSlice from './IncentiveReducer';
import employeeStatusSlice from './EmployeeStatusReducer';
import notificationSlice from './NotificationsReducer';

const appReducer = combineReducers({
    userAuthentication: userAuthReducerSlice.reducer,
    storeManagement: storeManagementReducerSlice.reducer,
    brandManagement: brandManagementSlice.reducer,
    userManagement: userManagementSlice.reducer,
    teamManagement: teamManagementSlice.reducer,
    feedback: feedbackSlice.reducer,
    sales: salesSlice.reducer,
    stock: stockSlice.reducer,
    sidebar: sidebarSlice.reducer,
    target: targetVsAchievementSlice.reducer,
    promotion: promotionSlice.reducer,
    incentive: incentiveSlice.reducer,
    employeeStatus: employeeStatusSlice.reducer,
    notification: notificationSlice.reducer,
});

export default appReducer;
