import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { differenceInDays, differenceInYears } from 'date-fns';
import moment from "moment";
import './PromotionForm.css';
import InputTextField from '../../../ReUsableComponent/InputTextField';
import CustomAutoComplete from '../../../ReUsableComponent/CustomAutocomplete';
import CustomDateRangePicker from '../../../ReUsableComponent/CustomDaterangePicker';
import MultiSelectCheckBox from '../../../ReUsableComponent/MultiSelectCheckBox';
import { ToastErrorService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import { GetBrandNameList, GetBrandStateList, GetCategoryByBrand, GetRegions, GetSeasons, GetStoreCustomerNameList, GetStyleCode } from './../promotion.service';
import { validateTextInputAnyMin, validateNumber1To2Input, validateNumber1To4Input, validateNumberInput, validNumericInRange } from '../../../ReUsableComponent/CustomFormValidation';
import { promotionActions } from '../../../store/reducers/PromotionReducer';
import { GetDesignationList } from '../../TeamManagement/TeamManagement.service';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center"
    },
    variant: "menu"
};

const REGION_LIST = [{ label: 'North', value: 'North', name: 'region' }, { label: 'East', value: 'East', name: 'region' }, { label: 'West', value: 'West', name: 'region' }, { label: 'South', value: 'South', name: 'region' }];
const CONDITIONLIST = [{ label: 'Greater Than', value: "Greater Than", name: 'conditionlist' }, { label: 'Less Than', value: 'Less Than', name: 'conditionlist' }, { label: 'Equal', value: 'Equal', name: 'conditionlist' }];
const PROMOTIONTYPELIST = [
    {
        label: 'Flat X % Off',
        value: 'Flat X % Off',
        name: 'PROMOTIONTYPELIST'
    },
    {
        label: 'Buy X units Get Rs Y Off',
        value: 'Buy X units Get Rs Y Off',
        name: 'PROMOTIONTYPELIST'
    },
    {
        label: 'Buy X units Get Y % Off',
        value: 'Buy X units Get Y % Off',
        name: 'PROMOTIONTYPELIST'
    },
    {
        label: 'Buy X units Get Y units Free',
        value: 'Buy X units Get Y units Free',
        name: 'PROMOTIONTYPELIST'
    },
    {
        label: 'Buy Rs X Get Rs Y Off',
        value: 'Buy Rs X Get Rs Y Off',
        name: 'PROMOTIONTYPELIST'
    },
    {
        label: 'Buy Rs X Get Freebies',
        value: 'Buy Rs X Get Freebies',
        name: 'PROMOTIONTYPELIST'
    }
];


const PromotionForm = ({ formData, setFormData, formDataError, setFormDataError, isXValueDisabled, setIsXValueDisabled, isYValueDisabled, setIsYValueDisabled }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const minDate = new Date();
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [loading, setLoading] = useState(false);
    const [disabledFields, setDisabledFields] = useState({
        region: false,
        state: false,
        store_detail: false,
    });
    const editFormVisible = useSelector((state) => state.promotion.isEditFormVisible);

    const [brandTypeList, setBrandTypeList] = useState([]);
    const [stateTypeList, setStateTypeList] = useState([]);
    const [storeNameTypeList, setStoreNameTypeList] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(formData?.brand_data?.brand_id);
    const [selectedBrandName, setSelectedBrandName] = useState('');
    const [selectedRegion, setSelectedRegion] = useState(formData?.region);
    const [selectedState, setSelectedState] = useState(formData?.state);
    const [seasonList, setSeasonList] = useState([]);

    // date range
    const today = new Date();
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [startDate, setStartDate] = useState(formData?.from_date ? new Date(formData?.from_date) : null);
    const [endDate, setEndDate] = useState(formData?.to_date ? new Date(formData?.to_date) : null);
    const [selectedRange, setSelectedRange] = useState([
        formData?.from_date ? new Date(formData?.from_date) : today,
        formData?.to_date ? new Date(formData?.to_date) : null,
    ]);
    const USER_ID = useSelector((state) => state.userAuthentication.userId);
    const [seasonTypeList, setSeasonTypeList] = useState([]);
    const [categoryTypeList, setCategoryTypeList] = useState([]);
    const [styleCodeTypeList, setStyleCodeTypeList] = useState([]);
    const [REGIONLIST, setREGIONLIST] = useState([]);



    useEffect(() => {
        getAllBrandList();
        // getBrandStateList();
        // getRegionList('');
        // getCategoryGroupList();
    }, []);
    useEffect(() => {
        getSeasonList(selectedBrandName)
    }, [selectedBrandName]);

    useEffect(() => {
        getStoreList();
        if (selectedBrand !== '') {
            getStyleStore('', selectedBrandName);
        }
    }, [selectedBrand, selectedRegion, selectedState]);

    // Get All Brand List
    const getAllBrandList = () => {
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then((response) => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    brand_id: item?._id,
                    brand_name: item?.brand_name,
                    label: item?.brand_name,
                    value: item?.brand_name,
                    email: item?.email_id,
                }
                dropdownData.push(row)
            });
            setBrandTypeList(dropdownData);
        }).catch(error => {
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const getRegionList = (brand) => {
        let param = `?brand=${brand}`
        GetRegions(param, USER_TOKEN).then((response) => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    // brand_id: item,
                    brand_name: item,
                    label: item,
                    value: item,
                    // email: item?.email_id,
                }
                dropdownData.push(row)
            });
            setREGIONLIST(dropdownData);
            // setREGIONLIST(response?.data?.data);
        }).catch(error => {
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    // Get Seaons
    const getSeasonList = (brand) => {
        let param = `?brand=${brand}`
        GetSeasons(param).then(response => {
            let seasonItem = [];
            response?.data?.data.forEach(item => {
                let row = {
                    label: item?.season,
                    value: item?.season
                }
                seasonItem.push(row)
            })
            setSeasonTypeList(seasonItem)
        }).catch(error => {
            console.log(error)
        }).finally(() => {

        })
    }

    // Get All Brand State
    const getBrandStateList = (region, brand) => {
        let param = `?region=${region}&brand=${brand}`
        GetBrandStateList(param, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item,
                    value: item,
                }
                dropdownData.push(row)
            });
            setStateTypeList(dropdownData);
        });
    }

    // Get All Store Based On Brand
    const getStoreList = () => {
        let queryParams = `?brand_id=${selectedBrand}&region=${selectedRegion?.join(',')}&state=${selectedState?.join(',')}`;
        GetStoreCustomerNameList(queryParams, USER_TOKEN).then(response => {
            let dropdownData = [];
            let customerNames = new Set();
            response?.data?.data?.forEach(item => {
                if (!customerNames.has(item?.customer_name)) {
                    let row = {
                        store_id: item?._id,
                        store_code: item?.store_code,
                        store_name: item?.customer_name,
                        label: item?.customer_name,
                        value: item?.customer_name,
                    };
                    dropdownData.push(row);
                    customerNames.add(item?.customer_name);
                }
            });
            setStoreNameTypeList(dropdownData)
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        });
    }

    const getStyleStore = (category, brand) => {
        let queryParams = `?style=${''}&brand=${brand}` + category;
        GetStyleCode(queryParams, USER_TOKEN).then(response => {
            let dropdownData = [];
            let styleCodeNames = new Set();
            response?.data?.data?.forEach(item => {
                if (!styleCodeNames.has(item?.style)) {
                    let row = {
                        label: item?.style,
                        value: item?.style,
                    }
                    dropdownData.push(row)
                    styleCodeNames.add(item?.style);
                }
            });
            setStyleCodeTypeList(dropdownData)
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        });
    }

    const getCategoryGroupList = (brand_id) => {
        let param = `?brand_id=${brand_id}`
        GetCategoryByBrand(param, USER_TOKEN).then(response => {
            let dropdownItem = [];
            response?.data?.data.forEach(item => {
                let row = {
                    label: item,
                    value: item
                }
                dropdownItem.push(row)
            })
            setCategoryTypeList(dropdownItem)
        }).catch(error => {
            console.log(error)
        }).finally(() => {

        })
    }

    // date range
    const openCalendar = () => {
        setCalendarOpen(prev => !prev)
    }

    const convertDate = (openingDate) => {
        let date = new Date(openingDate);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        return moment(dateString).format("DD MMM YYYY");
    }

    const handleDateRangeChange = (key, value, optionsList, index) => {

        const ONE_DAY = 1;
        const ONE_YEAR_IN_DAYS = 365; // 1 year as 365 days

        const dayDifference = startDate ? differenceInDays(value, startDate) : null;

        if (!startDate || (startDate && endDate)) {
            setStartDate(value);
            setEndDate(null);
            setSelectedRange([value, null]);
        } else if (startDate && !endDate && value >= startDate) {
            if (dayDifference >= ONE_DAY && dayDifference <= ONE_YEAR_IN_DAYS) {
                setEndDate(value);
                setSelectedRange([startDate, value]);
                setFormData(prevState => ({
                    ...prevState,
                    from_date: moment(startDate).format("yyyy-MM-DD"),
                    to_date: moment(value).format("yyyy-MM-DD")
                }));
            } else {
                alert(`Please select a date between 1 day and 1 year from the start date.`);
            }
        } else if (startDate && !endDate && value < startDate) {
            setStartDate(value);
            setSelectedRange([value, null]);
        }
    };


    // Helper function to format promotion type value
    const getPromotionTypeValue = (promotionType, xValue, yValue) => {
        if (promotionType === 'Buy X units Get Y % Off') {
            yValue = yValue ? yValue.slice(0, 2) : '';
        }

        switch (promotionType) {
            case 'Flat X % Off':
                return xValue ? `Flat ${xValue} % Off` : '';
            case 'Buy Rs X Get Freebies':
                return xValue ? `Buy Rs ${xValue} Get Freebies` : '';
            case 'Buy X units Get Rs Y Off':
                return (xValue && yValue) ? `Buy ${xValue} units Get Rs ${yValue} Off` : '';
            case 'Buy X units Get Y % Off':
                return (xValue && yValue) ? `Buy ${xValue} units Get ${yValue} % Off` : '';
            case 'Buy X units Get Y units Free':
                return (xValue && yValue) ? `Buy ${xValue} units Get ${yValue} units Free` : '';
            case 'Buy Rs X Get Rs Y Off':
                return (xValue && yValue) ? `Buy Rs ${xValue} Get Rs ${yValue} Off` : '';
            default:
                return '';
        }
    };

    useEffect(() => {
        if (!editFormVisible) {
            setSelectedBrand(formData?.brand_data?.brand_id);
            setSelectedBrandName(formData?.brand_data?.brand_name);
            getCategoryGroupList(formData?.brand_data?.brand_id);
            getRegionList(formData?.brand_data?.brand_id);
            getBrandStateList(formData?.region.toString(), formData?.brand_data?.brand_id);
            let param = `&category=${formData?.category.toString()}`;
            getStyleStore(param, formData?.brand_data?.brand_name)
        }
    }, [editFormVisible])



    const handleFormDataChange = (field, value, currentArrayOfObj, validateFun, setError, errorMsg) => {
        let updatedFormData = { ...formData, [field]: value };
        // Handle brand_name transformation
        if (field === 'brand_data') {
            updatedFormData.brand_data = {
                brand_id: value?.brand_id,
                brand_name: value?.brand_name
            };
            setSelectedBrand(value?.brand_id);
            setSelectedBrandName(value?.brand_name);
            getCategoryGroupList(value?.brand_id);
            getRegionList(value?.brand_id);
            getBrandStateList('', value?.brand_id);
            getStyleStore('', value?.brand_name)
            setSelectedRegion([])
            updatedFormData.category = [];
            updatedFormData.style_code = [];
            updatedFormData.store_detail = [];
            updatedFormData.region = [];
            updatedFormData.state = [];
        }
        if (field === 'category') {
            if (value[value.length - 1] === "all") {
                let category_list = [];
                categoryTypeList.forEach(category => {
                    category_list = [...category_list, category?.value]
                })
                setFormData(prevState => ({
                    ...prevState,
                    category: formData?.category?.length === categoryTypeList.length ? [] : category_list, style: []
                }));
                let param = `&category=${category_list.toString()}`;
                getStyleStore(param, updatedFormData?.brand_data?.brand_name);
                return;
            }
            let param = `&category=${value.toString()}`;
            getStyleStore(param, updatedFormData?.brand_data?.brand_name);
            updatedFormData.style_code = [];
        }

        // Handle season
        if (field === 'season') {
            if (value[value.length - 1] === "all") {
                let season_list = [];
                seasonTypeList.forEach(season => {
                    season_list = [...season_list, season?.value]
                })
                setFormData(prevState => ({
                    ...prevState,
                    season: formData?.season?.length === seasonTypeList.length ? [] : season_list
                }));
                return;
            }
        }

        // Handle Style code
        if (field === 'style_code') {
            if (value[value.length - 1] === "all") {
                let styel_code_list = [];
                styleCodeTypeList.forEach(style => {
                    styel_code_list = [...styel_code_list, style?.value]
                })
                setFormData(prevState => ({
                    ...prevState,
                    style_code: formData?.style_code?.length === styleCodeTypeList.length ? [] : styel_code_list
                }));
                return;
            }
        }

        // Handle Reagion 
        if (field === 'region') {
            updatedFormData.state = [];
            if (value[value.length - 1] === "all") {
                let region_list = [];
                REGIONLIST.forEach(region => {
                    region_list = [...region_list, region?.value]
                })
                setSelectedRegion(selectedRegion.length === REGIONLIST.length ? [] : region_list);
                setFormData(prevState => ({
                    ...prevState,
                    region: selectedRegion.length === REGIONLIST.length ? [] : region_list
                }));
                getBrandStateList(region_list.toString(), selectedBrand)
                return;
            }
            getBrandStateList(value.toString(), selectedBrand)
            setSelectedRegion(value);
        }

        // Handle State 
        if (field === 'state') {
            if (value[value.length - 1] === "all") {
                let state_list = [];
                stateTypeList.forEach(state => {
                    state_list = [...state_list, state?.value]
                })
                setSelectedState(selectedState.length === stateTypeList.length ? [] : state_list);
                setFormData(prevState => ({
                    ...prevState,
                    state: selectedState.length === stateTypeList.length ? [] : state_list
                }));
                return;
            }
            setSelectedState(value);
        }

        // Handle store_detail transformation
        if (field === 'store_detail') {
            if (value[value.length - 1] === "all") {
                if (formData.store_detail?.length === storeNameTypeList?.length) {
                    setFormData(prevState => ({
                        ...prevState,
                        store_detail: []
                    }));
                    return;
                } else {
                    let storeDetails = []
                    storeNameTypeList.map(store => {
                        let selectedStore = {
                            store_id: store.store_id,
                            store_code: store.store_code,
                            store_name: store.store_name,
                        };
                        storeDetails.push(selectedStore);
                    })
                    setFormData(prevState => ({
                        ...prevState,
                        store_detail: storeDetails
                    }));
                    return;
                }
            }
            updatedFormData.store_detail = value.map(storeName => {
                const selectedStore = storeNameTypeList?.find(store => store.store_name === storeName);
                return selectedStore ? {
                    store_id: selectedStore.store_id,
                    store_code: selectedStore.store_code,
                    store_name: selectedStore.store_name,
                } : null;
            }).filter(store => store);
        }

        if (field === 'x_value') {
            updatedFormData.x_value = value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1')
        }
        if (field === 'y_value') {
            if (formData.promotion_type === 'Buy X units Get Y % Off') {
                const cleanedValue = value.replace(/[^0-9]/g, '').slice(0, 2);
                updatedFormData.y_value = cleanedValue;
            } else {
                updatedFormData.y_value = value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
            }
        }

        // Handle promotion_type logic
        if (field === 'promotion_type') {
            updatedFormData.promotion_type = value?.value;
            updatedFormData.x_value = "";
            updatedFormData.y_value = "";
            updatedFormData.promotion_type_value = "";

            switch (value?.value) {
                case 'Flat X % Off':
                case 'Buy Rs X Get Freebies':
                    setIsXValueDisabled(false);
                    setIsYValueDisabled(true);
                    setError((prevState) => ({
                        ...prevState,
                        x_value: '',
                        y_value: '',
                    }));
                    break;
                case 'Buy X units Get Rs Y Off':
                case 'Buy X units Get Y % Off':
                case 'Buy X units Get Y units Free':
                case 'Buy Rs X Get Rs Y Off':
                    setIsXValueDisabled(false);
                    setIsYValueDisabled(false);
                    setError((prevState) => ({
                        ...prevState,
                        x_value: '',
                        y_value: '',
                    }));
                    break;
                default:
                    setIsXValueDisabled(false);
                    setIsYValueDisabled(false);
                    setError((prevState) => ({
                        ...prevState,
                        x_value: '',
                        y_value: '',
                    }));
                    break;
            }
        }

        // Update promotion_type_value dynamically based on x_value, y_value, and promotion_type
        if (['promotion_type', 'x_value', 'y_value'].includes(field)) {
            let promotionType = field === 'promotion_type' ? value?.value : formData?.promotion_type;
            let xValue = field === 'promotion_type' ? "" : (field === 'x_value' ? value : formData?.x_value);
            let yValue = field === 'promotion_type' ? "" : (field === 'y_value' ? value : formData?.y_value);
            updatedFormData.promotion_type_value = getPromotionTypeValue(promotionType, xValue, yValue);
        }

        setFormData(updatedFormData);
        dispatch(promotionActions.setPromotionDataListById({ type: updatedFormData }));

        if (!isYValueDisabled) {
            if (field === 'description') {
                if (!validateFun(value)) {
                    setError((prevState) => ({
                        ...prevState,
                        [field]: errorMsg,
                    }));
                } else {
                    setError((prevState) => ({
                        ...prevState,
                        [field]: null,
                    }));
                }
            }
        } else {
            if (field === 'description') {
                if (!validateFun(value)) {
                    setError((prevState) => ({
                        ...prevState,
                        [field]: errorMsg,
                    }));
                } else {
                    setError((prevState) => ({
                        ...prevState,
                        [field]: null,
                    }));
                }
            }
        }
    };

    return (
        <>
            <div className='pt-3 createPromotionMainFormCont customScroll'>
                <div className='customeRow'>
                    <div className='inputBottomSpace'>
                        <InputTextField
                            onChange={(event, newVal) => handleFormDataChange('description', event?.target?.value, '', validateTextInputAnyMin, setFormDataError, 'Please Enter Valid Amount')}
                            value={formData?.description}
                            type='text'
                            disabled={false}
                            required
                            minLength={3}
                            maxLength={80}
                            label='Promotion Description'
                        />
                        {formDataError?.description && <div className="error">{formDataError?.description}</div>}
                    </div>
                    <div className='inputBottomSpace'>
                        <CustomAutoComplete
                            onChange={(event, newVal) => handleFormDataChange('brand_data', newVal, brandTypeList, '', setFormDataError, 'Please Select Any Value')}
                            value={formData?.brand_data?.brand_name}
                            selectedValue={formData?.brand_data?.brand_name}
                            options={brandTypeList}
                            required
                            label='Brand Name*'
                        />
                        {formDataError?.brand_data && <div className="error">{formDataError?.brand_data}</div>}
                    </div>
                    <div className='inputDateRangeBottomSpace'>
                        <div onClick={openCalendar} className='open_calendar_open'>
                            <InputTextField
                                type='text'
                                disabled={false}
                                name='dateRange'
                                value={
                                    startDate || endDate
                                        ? `${startDate ? convertDate(startDate) : ''} ${(startDate && endDate) ? '-' : ''} ${endDate ? convertDate(endDate) : ''}`
                                        : ''
                                }
                                onChange={() => { }}
                                required
                                minLength={10}
                                maxLength={10}
                                label='Date'
                                calendarIcon={true}
                            />
                        </div>
                        <div className=''>
                            <CustomDateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                calendarOpen={calendarOpen}
                                selectedRange={selectedRange}
                                handleSelect={(date, newVal) => handleDateRangeChange('dateRange', date, '', '', setFormDataError, 'Please Select Any Value')}
                                closeOnOutSide={() => setCalendarOpen(false)}
                            // minDate={new Date()}
                            />
                        </div>
                        {formDataError?.date && <div className="error">{formDataError?.date}</div>}
                    </div>
                </div>

                <div className='customeRow'>
                    <div className='inputBottomSpace'>
                        <MultiSelectCheckBox showSelectAll={true}
                            handleChange={(event, newVal) => handleFormDataChange('region', event?.target?.value, REGIONLIST, validNumericInRange, '', '')}
                            selectedValue={formData?.region}
                            options={REGIONLIST}
                            MenuProps={MenuProps}
                            disabled={disabledFields.region}
                            label='Region'
                        />
                    </div>
                    <div className='inputBottomSpace'>
                        <MultiSelectCheckBox showSelectAll={true}
                            handleChange={(event, newVal) => handleFormDataChange('state', event?.target?.value, stateTypeList, validNumericInRange, '', '')}
                            selectedValue={formData?.state}
                            options={stateTypeList}
                            MenuProps={MenuProps}
                            disabled={disabledFields.state}
                            label='State'
                        />
                    </div>
                    <div className='inputBottomSpace'>
                        <MultiSelectCheckBox showSelectAll={true}
                            handleChange={(event, newVal) => handleFormDataChange('store_detail', event?.target?.value, storeNameTypeList, validNumericInRange, '', '')}
                            selectedValue={formData?.store_detail?.map(item => item?.store_name)}
                            options={storeNameTypeList}
                            MenuProps={MenuProps}
                            disabled={disabledFields.store_detail}
                            label='Store Name'
                        />
                    </div>
                </div>

                <div className='customeRow'>
                    <div className='inputBottomSpace'>
                        <CustomAutoComplete
                            onChange={(event, newVal) => handleFormDataChange('promotion_type', newVal, PROMOTIONTYPELIST, '', setFormDataError, 'Please Select Any Value')}
                            value={formData?.promotion_type}
                            selectedValue={formData?.promotion_type}
                            options={PROMOTIONTYPELIST}
                            required
                            label='Promotion Type*'
                        />
                        {formDataError?.promotion_type && <div className="error">{formDataError?.promotion_type}</div>}
                    </div>
                    <div className='inputBottomSpace'>
                        <InputTextField
                            onChange={(event, newVal) => handleFormDataChange('x_value', event?.target?.value, '', validateNumber1To4Input, setFormDataError, 'Please Enter Valid Amount')}
                            value={formData?.x_value}
                            type='text'
                            disabled={isXValueDisabled}
                            required
                            label='X Value'
                        />
                        {formDataError?.x_value && <div className="error">{formDataError?.x_value}</div>}
                    </div>
                    <div className='inputBottomSpace'>
                        <InputTextField
                            onChange={(event, newVal) => handleFormDataChange('y_value', event?.target?.value, '', validateNumber1To4Input, setFormDataError, 'Please Enter Valid Amount')}
                            value={formData?.y_value}
                            type='text'
                            disabled={isYValueDisabled}
                            required
                            label='Y Value'
                        />
                        {formDataError?.y_value && <div className="error">{formDataError?.y_value}</div>}
                    </div>
                </div>

                {formData?.promotion_type_value && formData?.x_value &&
                    <>
                        {!isNaN(Number(formData?.x_value)) && !isNaN(Number(formData?.y_value)) &&
                            <div className='promotionTypeValueCustomeRow me-2'>
                                <div className='promotionTypeValue'>{formData?.promotion_type_value}</div>
                            </div>
                        }
                    </>
                }

                <div className='customeRow'>
                    <div className='inputBottomSpace'>
                        <MultiSelectCheckBox showSelectAll={true}
                            handleChange={(event, newVal) => handleFormDataChange('season', event?.target?.value, seasonTypeList, validNumericInRange, '', '')}
                            selectedValue={formData?.season}
                            options={seasonTypeList}
                            MenuProps={MenuProps}
                            label='Season'
                        />
                    </div>
                    <div className='inputBottomSpace'>
                        <MultiSelectCheckBox showSelectAll={true}
                            handleChange={(event, newVal) => handleFormDataChange('category', event?.target?.value, categoryTypeList, validNumericInRange, '', '')}
                            selectedValue={formData?.category}
                            options={categoryTypeList}
                            MenuProps={MenuProps}
                            label='Category'
                        />
                    </div>
                    <div className='inputBottomSpace'>
                        <MultiSelectCheckBox showSelectAll={true}
                            handleChange={(event, newVal) => handleFormDataChange('style_code', event?.target?.value, styleCodeTypeList, validNumericInRange, '', '')}
                            selectedValue={formData?.style_code}
                            options={styleCodeTypeList}
                            disabled={selectedBrand === ''}
                            MenuProps={MenuProps}
                            label='Style Code'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PromotionForm
