import React, { useEffect, useRef, useState } from 'react';
import Loader from '../../ReUsableComponent/Loader';
import dropDownIcon from '../../assets/svg/dropDownIcon.svg';
import CustomFilterDropDown from '../../ReUsableComponent/CustomFilterDropdown';
import CustomFilterSearchDropdown from '../../ReUsableComponent/CustomFilterSearchDropdown';
import CustomBlueButton from '../../ReUsableComponent/CustomBlueButton';
import './ruleManagement.css';
import CustomTableWithFilter from './../../ReUsableComponent/CustomTableWithFilter';
import { rulesMangmentViewTableHeader } from './../../Pages/TableHeaders';
import { GetReportHeaderList, GetBrandNameList, GetStoreCustomerNameList, GetHeaderValueList, CreateRuleMangment, UpdateRuleMangment } from './RuleManagement.service';
import CustomPagination from '../../ReUsableComponent/Pagination';
import { ToastErrorService, ToastSuccessService } from '../../ReUsableComponent/ToastNotification/ToastService';
import { ButtonDropdown } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SearchBox from './../../ReUsableComponent/CustomSearch/indedx';

const RuleManagement = () => {
    const gridRef = useRef(null);

    const history = useHistory();
    const [activeTab, setActiveTab] = useState(1);
    const [allTableListData, setAllTableListData] = useState([]);
    const [tableListData, setTableListData] = useState([]);
    const [tableListFilteredData, setTableListFilteredData] = useState([]);
    const [tableListFilteredDataStoreType, setTableListFilteredDataStoreType] = useState([]);
    const [tableListSearchDataStoreType, setTableListSearchDataStoreType] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [excelHeaderValue, setExcelHeaderValue] = useState('');
    const [excelHeaderData, setExcelHeaderData] = useState([]);

    const [excelHeaderMappingValue, setExcelHeaderMappingValue] = useState('');
    const [excelHeaderMappingData, setExcelHeaderMappingData] = useState([]);
    const [discountPercentageMappingValue, setDiscountPercentageMappingValue] = useState('');
    const [discountPercentageMappingData, setDiscountPercentageMappingData] = useState([]);

    const [correctHeaderMappingValue, setCorrectHeaderMappingValue] = useState('');
    const [correctHeaderMappingData, setCorrectHeaderMappingData] = useState([]);

    const [correctPercentageMappingValue, setcorrectPercentageMappingValue] = useState('');
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [updatedRuleMangmentID, setUpdatedRuleMangmentID] = useState('');
    const [updetedTableListData, setUpdatedTableListData] = useState('');
    const [openUpdetedTableListData, setOpenUpdatedTableListData] = useState(true);

    // reportType
    const [selectedReportType, setSelectedReportType] = useState('');
    const [initialSelectedReportType, setInitialSelectedReportType] = useState({ label: 'Header', value: 'Header' });
    const [anchorElForReportType, setAnchorElForReportType] = useState(null);
    const openReportTypeFilter = Boolean(anchorElForReportType);
    const [reportTypeList, setReportTypeList] = useState([{ label: 'Sales', value: 'Sales' }, { label: 'Stock', value: 'Stock' }]);
    // BrandType
    const [selectedBrandType, setSelectedBrandType] = useState('');
    const [initialSelectedBrandType, setInitialSelectedBrandType] = useState({ label: 'Brand', value: 'Brand' });
    const [anchorElForBrandType, setAnchorElForBrandType] = useState(null);
    const openBrandTypeFilter = Boolean(anchorElForBrandType);
    const [brandTypeList, setBrandTypeList] = useState([]);
    // StoreType
    const [selectedStoreType, setselectedStoreType] = useState('');
    const [initialSelectedStoreType, setInitialSelectedStoreType] = useState({ label: 'Store', value: 'Store' });
    const [anchorElForStoreType, setAnchorElForStoreType] = useState(null);
    const openStoreTypeFilter = Boolean(anchorElForStoreType);
    const [storeTypeList, setStoreTypeList] = useState([]);

    // Table Filter StoreType
    // const [selectedStoreTableFilterType, setselectedStoreTableFilterType] = useState('');
    const [initialSelectedBrandTableFilterType, setInitialSelectedBrandTableFilterType] = useState({ label: 'Brand', value: 'Brand' });
    const [anchorElForBrandTableFilterType, setAnchorElForBrandTableFilterType] = useState(null);
    const openBrandTableFilterTypeFilter = Boolean(anchorElForBrandTableFilterType);
    const [BrandTableFilterTypeList, setBrandTableFilterTypeList] = useState([]);

    // Table Filter reportType TableFilter
    const [selectedReportTableFilterType, setSelectedReportTableFilterType] = useState('');
    const [initialSelectedReportTableFilterType, setInitialSelectedReportTableFilterType] = useState({ label: 'Header', value: 'Header' });
    const [anchorElForReportTableFilterType, setAnchorElForReportTableFilterType] = useState(null);
    const openReportTableFilterTypeFilter = Boolean(anchorElForReportTableFilterType);
    const [reportTableFilterTypeList, setReportTableFilterTypeList] = useState([{ label: 'Sales', value: 'Sales' }, { label: 'Stock', value: 'Stock' }]);

    const [loading, setLoading] = useState(false);
    const [viewRowReportTypeData, setViewRowReportTypeData] = useState("");
    const [selectedPage, setSelectedPage] = useState(1);
    const [totalReportHeaderCount, setTotalReportHeaderCount] = useState(0);
    const [isbtnActive, setIsBtnActive] = useState(false);
    const [dropSearchValue, setdropSearchValue] = React.useState('');

    const [slectedFilterBrandData, setSlectedFilterBrandData] = useState('');
    const [slectedFilterBrandDataForStore, setSlectedFilterBrandDataForStore] = useState('');

    const USER_ID = useSelector((state) => state.userAuthentication.userId);

    useEffect(() => {
        getAllHeaderList();
        getAllBrandList();
    }, []);

    useEffect(() => {
        getAllHeaderList();
    }, [selectedPage, slectedFilterBrandData]);

    useEffect(() => {
        getAllStoreCustomerNameList();
    }, [slectedFilterBrandDataForStore]);

    const getAllHeaderList = () => {
        // let queryParams = `?page=${selectedPage}&limit=${20}`;
        let queryParams = `?brand=${slectedFilterBrandData && slectedFilterBrandData?.id}&page=${''}&limit=${''}`;
        GetReportHeaderList(queryParams, USER_TOKEN).then((response) => {
            setAllTableListData(response?.data?.data?.data);
            setViewRowReportTypeData(response?.data?.data?.data[0]);
            let tableData = [];
            response?.data?.data?.data?.map((item, index) => {
                let row = {
                    id: item?._id,
                    index: index,
                    reportTypeName: item?.report_type ? item?.report_type : "-",
                    reportTypeBrandName: item?.brand_details?.brand_name ? item?.brand_details?.brand_name : "-",
                    reportTypeStoreName: item?.store_details?.store_name ? item?.store_details?.store_name : "-",
                    reportTypeStoreID: item?.store ? item?.store : "-",
                }
                tableData.push(row)
            })
            setTableListData(tableData);
            setTableListFilteredData(tableData);
            setTableListSearchDataStoreType(tableData);
            setTotalReportHeaderCount(response?.data?.data?.totalCount);
        }).catch(error => {
            if (error?.response?.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const getAllBrandList = () => {
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then((response) => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item?.brand_name,
                    value: item?.brand_name,
                    email: item?.email_id,
                    id: item?._id,
                }
                dropdownData.push(row)
            });
            // console.log("response====>", response?.data?.data, dropdownData);
            // setBrandTableFilterTypeList(dropdownData);
            setBrandTypeList(dropdownData);

        }).catch(error => {
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
        if (tabNumber === 1) {
            setInitialSelectedBrandType({ label: 'Brand', value: 'Brand' });
            setInitialSelectedReportType({ label: 'Header', value: 'Header' });
            setInitialSelectedStoreType({ label: 'Store', value: 'Store' });
            setdropSearchValue('');
            setTableListFilteredData(tableListData);
            setTableListSearchDataStoreType(tableListData);
        } else if (tabNumber === 2) {
            setTableListFilteredData(tableListData);
            setTableListSearchDataStoreType(tableListData);
            setInitialSelectedBrandTableFilterType({ label: 'Brand', value: 'Brand' });
            setInitialSelectedReportTableFilterType({ label: 'Header', value: 'Header' });
            setdropSearchValue('');
            setSlectedFilterBrandData('');
            setSlectedFilterBrandDataForStore('');
        }
    };

    const handleDropDownClick = (event, TypeOfData) => {
        if (TypeOfData === 'ReportType') {
            setAnchorElForReportType(event.currentTarget);
            setdropSearchValue('');
        } else if (TypeOfData === 'BrandType') {
            setAnchorElForBrandType(event.currentTarget);
            setdropSearchValue('');
        } else if (TypeOfData === 'StoreType') {
            setAnchorElForStoreType(event.currentTarget);
            setdropSearchValue('');
        } else if (TypeOfData === 'tableFilterBrandType') {
            setAnchorElForBrandTableFilterType(event.currentTarget);
            setdropSearchValue('');
        } else if (TypeOfData === 'tableFilterReportType') {
            setAnchorElForReportTableFilterType(event.currentTarget);
            setdropSearchValue('');
        }

    };
    const handleTypeClose = () => {
        setAnchorElForReportType(null);
        setAnchorElForBrandType(null);
        setAnchorElForStoreType(null);
        setAnchorElForBrandTableFilterType(null);
        setAnchorElForReportTableFilterType(null);
    }

    const handleSearchChange = (event) => {
        setdropSearchValue(event.target.value);
    };

    const handleInputChange = (event, TypeOfParm) => {
        if (TypeOfParm === "ExcelHeader") {

            const inputValue = event.target.value;
            setExcelHeaderValue(inputValue);
            const words = inputValue.split(',');
            setExcelHeaderData(words);

        } else if (TypeOfParm === "ExcelHeaderMapping") {

            const inputValue = event.target.value;
            setExcelHeaderMappingValue(inputValue);
            const words = inputValue.split(',');
            setExcelHeaderMappingData(words);

        } else if (TypeOfParm === "DiscountPercentage") {
            const inputValue = event.target.value;
            setDiscountPercentageMappingValue(inputValue);
            const words = inputValue.split(',');
            setDiscountPercentageMappingData(words);
        }
    }
    const handleKeyDown = (event, TypeOfParm) => {
        if (TypeOfParm === "ExcelHeader") {

            if (event.key === ',') {
                event.preventDefault();
                const cursorPosition = event.target.selectionStart;
                const newValue = excelHeaderValue.substring(0, cursorPosition) + ',\n' + excelHeaderValue.substring(cursorPosition);
                setExcelHeaderValue(newValue);
                setExcelHeaderData(newValue.split(','));
            }
        } else if (TypeOfParm === "ExcelHeaderMapping") {
            if (event.key === ',') {
                event.preventDefault();
                const cursorPosition = event.target.selectionStart;
                const newValue = excelHeaderMappingValue.substring(0, cursorPosition) + ',\n' + excelHeaderMappingValue.substring(cursorPosition);
                setExcelHeaderMappingValue(newValue);
                setExcelHeaderMappingData(newValue.split(','));
            }
        } else if (TypeOfParm === "DiscountPercentage") {
            if (event.key === ',') {
                event.preventDefault();
                const cursorPosition = event.target.selectionStart;
                const newValue = discountPercentageMappingValue.substring(0, cursorPosition) + ',\n' + discountPercentageMappingValue.substring(cursorPosition);
                setDiscountPercentageMappingValue(newValue);
                setDiscountPercentageMappingData(newValue.split(','));
            }
        }
    };

    const filterData = (data, searchValue, searchType) => {
        if (!searchValue) {
            return data;
        }

        if (searchType === 'tableFilterBrandType') {
            return data?.filter(item => item?.reportTypeBrandName?.toLowerCase()?.includes(searchValue?.toLowerCase()))
        } else if (searchType === 'tableFilterReportType') {
            return data?.filter(item => item?.reportTypeName?.toLowerCase()?.includes(searchValue?.toLowerCase()))
        }
    }

    const onSelectChangeType = (event, TypeOfData) => {
        if (TypeOfData === 'ReportType') {
            reportTypeList.forEach(TypeValue => {
                if (event.target.value === TypeValue?.value) {
                    setInitialSelectedReportType({ label: TypeValue?.label, value: event.target.value });
                    setSelectedReportType(TypeValue);
                    setAnchorElForReportType(null);
                    getAllHeaderValueOnlyList(event.target.value);
                }
            })
        } else if (TypeOfData === 'BrandType') {
            brandTypeList.forEach(TypeValue => {
                if (event.target.value === TypeValue?.value) {
                    setInitialSelectedBrandType({ label: TypeValue?.label, value: event.target.value });
                    setSelectedBrandType(TypeValue);
                    setAnchorElForBrandType(null);
                    setSlectedFilterBrandDataForStore(TypeValue);
                    console.log("TypeValue---->", TypeValue);
                }
            })
        } else if (TypeOfData === 'StoreType') {
            storeTypeList.forEach(TypeValue => {
                if (event.target.value === TypeValue?.value) {
                    setInitialSelectedStoreType({ label: TypeValue?.label, value: event.target.value });
                    setselectedStoreType(TypeValue);
                    setAnchorElForStoreType(null);
                }
            })
        } else if (TypeOfData === 'tableFilterBrandType') {
            brandTypeList?.forEach(TableFilterTypeValue => {
                if (event.target.value === TableFilterTypeValue?.value) {
                    console.log("tableFilterBrandType====>", TableFilterTypeValue);
                    setSlectedFilterBrandData(TableFilterTypeValue)

                    setInitialSelectedBrandTableFilterType({ label: TableFilterTypeValue?.label, value: event.target.value });
                    // setselectedStoreTableFilterType(TableFilterTypeValue);
                    setAnchorElForBrandTableFilterType(null);
                    // setTableListFilteredDataStoreType(filterData(tableListData, TableFilterTypeValue?.value, 'tableFilterBrandType'));
                    // setTableListSearchDataStoreType(filterData(tableListData, TableFilterTypeValue?.value, 'tableFilterBrandType'));
                    // setTableListFilteredData(filterData(tableListData, TableFilterTypeValue?.value, 'tableFilterBrandType'));
                    // setInitialSelectedReportTableFilterType({ label: 'Header', value: 'Header' });
                }
            });
        } else if (TypeOfData === 'tableFilterReportType') {
            reportTableFilterTypeList.forEach(TableFilterTypeValue => {
                if (event.target.value === TableFilterTypeValue?.value) {
                    setInitialSelectedReportTableFilterType({ label: TableFilterTypeValue?.label, value: event.target.value });
                    setSelectedReportTableFilterType(TableFilterTypeValue);
                    setAnchorElForReportTableFilterType(null);
                    if (tableListFilteredDataStoreType?.length !== 0) {
                        setTableListFilteredData(filterData(tableListFilteredDataStoreType, TableFilterTypeValue?.value, 'tableFilterReportType'));
                        setTableListSearchDataStoreType(filterData(tableListFilteredDataStoreType, TableFilterTypeValue?.value, 'tableFilterReportType'));
                    } else {
                        setTableListFilteredData(filterData(tableListData, TableFilterTypeValue?.value, 'tableFilterReportType'));
                        setTableListSearchDataStoreType(filterData(tableListData, TableFilterTypeValue?.value, 'tableFilterReportType'));
                    }
                }
            });
        }

    }

    const onRowClicked = (event) => {
        setLoading(true);
        if (event?.data?.reportTypeName !== '' && event?.data?.reportTypeStoreID !== '') {
            let queryParams = `?report_type=${event?.data?.reportTypeName}&store=${event?.data?.reportTypeStoreID}`;
            GetReportHeaderList(queryParams, USER_TOKEN).then(response => {
                setViewRowReportTypeData(response?.data?.data?.data[0]);
            }).catch(error => {
                if (error?.response?.status === 401) {
                    history.push('/')
                }
                ToastErrorService(error.response.data.message);
            }).finally(() => {
                setLoading(false);
            })
        }
    };

    const handlePageChange = (pageValue) => {
        setSelectedPage(pageValue);
    }

    const getAllStoreCustomerNameList = () => {
        let queryParams = `?brand_id=${slectedFilterBrandDataForStore && slectedFilterBrandDataForStore?.id}`;
        GetStoreCustomerNameList(queryParams, USER_TOKEN).then(response => {
            console.log("responseStore====>", response);
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item?.customer_name,
                    value: item?.customer_name,
                    email: item?.store_email_id,
                    id: item?._id,
                }
                dropdownData.push(row)
            })
            setStoreTypeList(dropdownData);
            // setBrandTableFilterTypeList(dropdownData);
        }).catch(error => {
            ToastErrorService(error?.response?.data?.message)
        });
    }

    const getAllHeaderValueOnlyList = (reportType) => {
        let queryParams = `?report_type=${reportType}`;
        GetHeaderValueList(queryParams, USER_TOKEN).then(response => {
            let arrayData = [];
            response?.data?.header_values?.map(item => {
                arrayData.push(item)
            });
            setCorrectHeaderMappingValue(arrayData?.join(',\n'))
        });
    }

    const onClickSubmitRuleMangment = () => {
        setLoading(true);
        let requestBody = {
            brand: selectedBrandType?.id,
            brand_details: {
                brand_name: selectedBrandType?.value,
                brand_id: selectedBrandType?.id,
                email: selectedBrandType?.email
            },
            report_type: selectedReportType?.value,
            store_details: {
                store_name: selectedStoreType?.value,
                email: selectedStoreType?.email,
            },
            store: selectedStoreType?.id,
            excel_headers: excelHeaderData?.length !== 0 && excelHeaderData,
            headers: excelHeaderMappingData?.length !== 0 && excelHeaderMappingData,
            is_percentage: discountPercentageMappingData
        }

        if (!requestBody?.excel_headers || !requestBody?.headers) {
            ToastErrorService('Please fill all the fields');
            setLoading(false);
        } else if (!requestBody?.brand || !requestBody?.store || !requestBody?.report_type) {
            ToastErrorService('Please select Brand, Header and Store.');
            setLoading(false);
        } else {
            CreateRuleMangment(requestBody, USER_TOKEN).then(response => {
                ToastSuccessService("Headers created successfully!")
                // setTimeout(() => {
                getAllHeaderList();
                setActiveTab(1);
                setInitialSelectedReportType({ label: 'Header', value: 'Header' });
                setSelectedReportType('');
                setInitialSelectedBrandType({ label: 'Brand', value: 'Brand' });
                setSelectedBrandType('');
                setInitialSelectedStoreType({ label: 'Store', value: 'Store' });
                setselectedStoreType('');
                setExcelHeaderValue('');
                setExcelHeaderData([]);
                setExcelHeaderMappingValue('');
                setExcelHeaderMappingData([]);
                setOpenUpdatedTableListData(true);
                setCorrectHeaderMappingData([]);
                // }, 500);
            }).catch(error => {
                ToastErrorService('Something went wrong, Please try again after sometime.');
                if (error?.response?.status === 401) {
                    history.push('/')
                }
            }).finally(() => {
                setLoading(false);
            });
        }

    }

    const updateRuleMangmentRow = (id, index) => {
        // console.log("updateRuleMangmentRow====>", index, allTableListData[index])
        setIsBtnActive(true);
        setOpenUpdatedTableListData(false);
        setUpdatedRuleMangmentID(allTableListData[index]?._id);

        setUpdatedTableListData(allTableListData[index]);
        setInitialSelectedReportType({ label: allTableListData[index]?.report_type, value: allTableListData[index]?.report_type });
        setSelectedReportType({ label: allTableListData[index]?.report_type, value: allTableListData[index]?.report_type });
        setInitialSelectedStoreType({ label: allTableListData[index]?.store_details?.store_name, value: allTableListData[index]?.store_details?.store_name });
        setselectedStoreType(
            {
                label: allTableListData[index]?.store_details?.store_name,
                value: allTableListData[index]?.store_details?.store_name,
                email: allTableListData[index]?.store_details?.email,
                id: allTableListData[index]?.store,
            }
        );
        setInitialSelectedBrandType(
            {
                label: allTableListData[index]?.brand_details?.brand_name,
                value: allTableListData[index]?.brand_details?.brand_name,
                email: allTableListData[index]?.brand_details?.email,
                id: allTableListData[index]?.brand_details?.brand_id,
            }
        );
        setSelectedBrandType(
            {
                label: allTableListData[index]?.brand_details?.brand_name,
                value: allTableListData[index]?.brand_details?.brand_name,
                email: allTableListData[index]?.brand_details?.email,
                id: allTableListData[index]?.brand_details?.brand_id,
            }
        );
        setExcelHeaderValue(allTableListData[index]?.excel_headers?.join(',\n'));
        setExcelHeaderData(allTableListData[index]?.excel_headers);
        setExcelHeaderMappingValue((allTableListData[index]?.processed_headers.length > 0 ? allTableListData[index]?.processed_headers : allTableListData[index]?.process_excel_headers)?.join(',\n'));
        setExcelHeaderMappingData(allTableListData[index]?.processed_headers);
        if (allTableListData[index].header_values.length > 0) {
            setCorrectHeaderMappingValue(allTableListData[index]?.header_values?.join(',\n'));
        } else {
            let values = Object.values(allTableListData[index].processed_header_mapping)
            setCorrectHeaderMappingValue(values.join(',\n'));
        }
        if (allTableListData[index].is_percentage) {
            setcorrectPercentageMappingValue(allTableListData[index]?.is_percentage?.join(',\n'))
        }
    }

    const onGridReady = (params) => {
        gridRef.current = params.api;
        gridRef.current.sizeColumnsToFit();
    };

    const onclickHandleUpdateRuleMangment = () => {
        setLoading(true);
        let requestBody = {
            // id: updatedRuleMangmentID,
            brand: selectedBrandType?.id,
            brand_details: {
                brand_name: selectedBrandType?.value,
                brand_id: selectedBrandType?.id,
                email: selectedBrandType?.email
            },
            report_type: selectedReportType?.value,
            store_details: {
                store_name: selectedStoreType?.value,
                email: selectedStoreType?.email,
            },
            store: selectedStoreType?.id,
            excel_headers: excelHeaderData,
            headers: excelHeaderMappingData,
            is_percentage: discountPercentageMappingData
        }

        if ((requestBody?.excel_headers && requestBody?.excel_headers[0] === '') || (requestBody?.headers && requestBody?.headers[0] === '')) {
            ToastErrorService('Please fill all the fields');
            setLoading(false);
        } else {
            CreateRuleMangment(requestBody, USER_TOKEN).then(response => {
                ToastSuccessService("Headers Updated successfully!")
                getAllHeaderList();
                setActiveTab(1);
                setSelectedPage(1);
                setInitialSelectedReportType({ label: 'Header', value: 'Header' });
                setSelectedReportType('');
                setInitialSelectedStoreType({ label: 'Store', value: 'Store' });
                setselectedStoreType('');
                setExcelHeaderValue('');
                setExcelHeaderData([]);
                setExcelHeaderMappingValue('');
                setExcelHeaderMappingData([]);
                setOpenUpdatedTableListData(true);
                setCorrectHeaderMappingData([]);
                setCorrectHeaderMappingValue('');
            }).catch(error => {
                ToastErrorService('Something went wrong, Please try again after sometime.');
                if (error?.response?.status === 401) {
                    history.push('/')
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const onClickBackButton = () => {
        setIsBtnActive(false);
        setActiveTab(1);
        setInitialSelectedReportType({ label: 'Header', value: 'Header' });
        setSelectedReportType('');
        setInitialSelectedBrandType({ label: 'Brand', value: 'Brand' });
        setSelectedBrandType('');
        setInitialSelectedStoreType({ label: 'Store', value: 'Store' });
        setselectedStoreType('');
        setExcelHeaderValue('');
        setExcelHeaderData([]);
        setExcelHeaderMappingValue('');
        setExcelHeaderMappingData([]);
        setDiscountPercentageMappingValue('');
        setDiscountPercentageMappingData([]);
        setOpenUpdatedTableListData(true);
        setCorrectHeaderMappingData([]);
        setCorrectHeaderMappingValue('');
        // setInitialSelectedBrandType({ label: 'Brand', value: 'Brand' });
    }

    const renderBackButton = (btnActive) => {
        if (btnActive) {
            return <button className='backButton cursorPointer' onClick={onClickBackButton}>
                <svg xmlns="http://www.w3.org/2000/svg" width="8.402" height="14.805" viewBox="0 0 8.402 14.805">
                    <path id="Path_63354" data-name="Path 63354" d="M0,0,5.988,5.988,0,11.977" transform="translate(6.988 13.391) rotate(180)" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                </svg>
            </button>
        }
    }

    useEffect(() => {
        if (searchTerm === '') {
            setTableListFilteredData(tableListFilteredData);
        }
    }, [searchTerm, tableListFilteredData]);

    const handleSearchTableData = (event) => {
        const value = event?.target?.value?.toLowerCase();
        setSearchTerm(value);

        if (value) {
            const filtered = tableListSearchDataStoreType?.filter(report =>
                report?.reportTypeStoreName?.toLowerCase()?.includes(value)
            );
            setTableListFilteredData(filtered);
        } else {
            setTableListFilteredData(tableListFilteredData);
        }
    };



    return (
        <>
            {openUpdetedTableListData ?

                <>
                    {/* <CustomeChip /> */}
                    <div className='d-flex ruleMangTabHeader'>
                        <div className='d-flex mb-3'>
                            <div className='storeAndRevenue'>
                                <button value={1} className={`tab ${activeTab === 1 ? 'tabBackground fontBold textColorBlack' : 'bg-white textColorGary'}`} onClick={() => handleTabClick(1)}>
                                    View Rule
                                </button>
                                <button value={2} className={`tab ${activeTab === 2 ? 'tabBackground fontBold textColorBlack' : 'bg-white textColorGary'}`} onClick={() => handleTabClick(2)}>
                                    Create Rule
                                </button>
                            </div>
                        </div>
                        {
                            activeTab === 1 &&
                            <div className='d-flex mx-4 rulemangFilterCont'>
                                <div className='position-relative'>
                                    <button className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                        onClick={(e) => handleDropDownClick(e, 'tableFilterBrandType')}>
                                        <p className='text-truncate' title={initialSelectedBrandTableFilterType.label}>{initialSelectedBrandTableFilterType?.label}</p>
                                        <img className={`${openBrandTableFilterTypeFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                    </button>
                                    <CustomFilterSearchDropdown data={brandTypeList} handleClose={handleTypeClose} open={openBrandTableFilterTypeFilter} anchorEl={anchorElForBrandTableFilterType}
                                        onChange={(e) => onSelectChangeType(e, 'tableFilterBrandType')} selectedValue={initialSelectedBrandTableFilterType?.value} category={'tableFilterBrandType'} dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                                </div>
                                <div className='position-relative'>
                                    <button className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                        onClick={(e) => handleDropDownClick(e, 'tableFilterReportType')}>
                                        <p className='text-truncate' title={initialSelectedReportTableFilterType.label}>{initialSelectedReportTableFilterType?.label}</p>
                                        <img className={`${openReportTableFilterTypeFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                    </button>
                                    <CustomFilterDropDown data={reportTableFilterTypeList} handleClose={handleTypeClose} open={openReportTableFilterTypeFilter} anchorEl={anchorElForReportTableFilterType}
                                        onChange={(e) => onSelectChangeType(e, 'tableFilterReportType')} selectedValue={initialSelectedReportTableFilterType?.value} category={'tableFilterReportType'} />
                                </div>
                                {/* <div className='position-relative'>
                                    <SearchBox extraClass="rulemangSearch" />
                                </div> */}
                            </div>
                        }
                    </div>
                    {activeTab === 1 &&
                        <div className='d-flex'>
                            <div className={`rulemangementSearch customSearch search-wrapper active`}>
                                <div className="input-holder">
                                    <input type="text" className="search-input" placeholder="Type to search" onChange={handleSearchTableData} />
                                    <button
                                        className={`search-icon`} >
                                        <span />
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className=''>
                        {activeTab === 1
                            ?
                            <>
                                <div className='ruleMangmentViewCont d-flex'>
                                    <div className='ruleMangmentViewTableCont reports'>
                                        <CustomTableWithFilter isContentLeftRightFixed={false} onGridReady={onGridReady} column={rulesMangmentViewTableHeader} tableRowData={tableListFilteredData} onRowClicked={onRowClicked} filter={null} handleActiveToggle={null} onClickEdit={updateRuleMangmentRow} onFilterChanged={null} filterModel={null} customeHeightClassName={'tableCustomeHeightClass'} />
                                    </div>
                                    {viewRowReportTypeData &&
                                        <div className='ruleMangmentViewListCont'>
                                            <div id='headers' className='d-flex CH50'>
                                                <div className='w-50'>
                                                    <div className='w-100'>
                                                        <label className='m-2' htmlFor={'storeTyped'}>
                                                            Input excel Headers:
                                                        </label>
                                                    </div>
                                                    <div className='excelHeaderCont calcH50'>
                                                        <div className='overflowAuto'>
                                                            {viewRowReportTypeData?.excel_headers?.map((item, index) => (
                                                                <div key={item}>
                                                                    <span key={item}>{item},</span><br />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='w-50'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <label className='m-2' htmlFor={'brandTyped'}>
                                                            Mapped excel Headers:
                                                        </label>
                                                    </div>
                                                    <div className='excelHeaderCont calcH50'>
                                                        <div className='overflowAuto'>
                                                            {(viewRowReportTypeData?.processed_headers.length > 0 ? viewRowReportTypeData?.processed_headers : viewRowReportTypeData?.process_excel_headers)?.map((item, index) => (
                                                                <>
                                                                    <span key={item}>{item},</span><br />
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='excelCorrectHeaderMapingCont d-flex CH50'>
                                                <div className='w-50'>
                                                    <div className='w-100'>
                                                        <label className='m-2' htmlFor={'storeTyped'}>
                                                            Predefined Header List:
                                                        </label>
                                                    </div>
                                                    <div className='excelHeaderCont calcH50'>
                                                        <div className='overflowAuto'>
                                                            {(viewRowReportTypeData?.header_values.length > 0 ? viewRowReportTypeData?.header_values : Object.values(viewRowReportTypeData?.processed_header_mapping))?.map((item, index) => (
                                                                <>
                                                                    <span key={item}>{item},</span><br />
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='w-50'>
                                                    <div className='w-100'>
                                                        <label className='m-2' htmlFor={'storeTyped'}>
                                                            Discount in Percentage:
                                                        </label>
                                                    </div>
                                                    <div className='excelHeaderCont calcH50'>
                                                        <div className='overflowAuto'>
                                                            {viewRowReportTypeData?.is_percentage?.map((item, index) => (
                                                                <div key={item}>
                                                                    <span key={item}>{item},</span><br />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {/* {(totalReportHeaderCount !== 0 || totalReportHeaderCount?.length !== 0) &&
                                    <div className='paddingTop16 d-flex justify-content-end customPagination'>
                                        <CustomPagination onClick={handlePageChange} activePage={selectedPage} pageSize={20} currentPage={selectedPage} totalItems={totalReportHeaderCount} />
                                    </div>
                                } */}
                            </>
                            :
                            <div >
                                <div className='mb-2'>
                                    <div className='d-flex align-items-end rulemangCreateUpdate'>
                                        <div className='position-relative'>
                                            <button className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                                onClick={(e) => handleDropDownClick(e, 'BrandType')}>
                                                <p className='text-truncate' title={initialSelectedBrandType.label}>{initialSelectedBrandType?.label}</p>
                                                <img className={`${openBrandTypeFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                            </button>
                                            <CustomFilterSearchDropdown data={brandTypeList} handleClose={handleTypeClose} open={openBrandTypeFilter} anchorEl={anchorElForBrandType}
                                                onChange={(e) => onSelectChangeType(e, 'BrandType')} selectedValue={initialSelectedBrandType?.value} category={'BrandType'} dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                                        </div>
                                        <div className='position-relative'>
                                            <button className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                                onClick={(e) => handleDropDownClick(e, 'ReportType')}>
                                                <p className='text-truncate' title={initialSelectedReportType.label}>{initialSelectedReportType?.label}</p>
                                                <img className={`${openReportTypeFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                            </button>
                                            <CustomFilterDropDown data={reportTypeList} handleClose={handleTypeClose} open={openReportTypeFilter} anchorEl={anchorElForReportType}
                                                onChange={(e) => onSelectChangeType(e, 'ReportType')} selectedValue={initialSelectedReportType?.value} category={'ReportType'} />
                                        </div>
                                        <div className='position-relative'>
                                            <button className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                                onClick={(e) => handleDropDownClick(e, 'StoreType')}>
                                                <p className='text-truncate' title={initialSelectedStoreType.label}>{initialSelectedStoreType?.label}</p>
                                                <img className={`${openStoreTypeFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                            </button>
                                            <CustomFilterSearchDropdown data={storeTypeList} handleClose={handleTypeClose} open={openStoreTypeFilter} anchorEl={anchorElForStoreType}
                                                onChange={(e) => onSelectChangeType(e, 'StoreType')} selectedValue={initialSelectedStoreType?.value} category={'StoreType'} dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex ruleMangmentForm createForm'>
                                    <div className='d-flex flex-column leftTypeBox'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <label className='m-2' htmlFor={'reportTypeId'}>
                                                Input excel Headers:
                                            </label>
                                            <div className="info-icon-container">
                                                <span className="info-icon">i</span>
                                                <div className="tooltip">Please enter the column names. Each column name should be end of comma(",") separated</div>
                                            </div>
                                        </div>
                                        <div className='textOverlowAuto height100'>
                                            <textarea className='textarea height100' id={'reportTypeId'} name="postContent" value={excelHeaderValue} onChange={(e) => handleInputChange(e, 'ExcelHeader')} onKeyDown={(e) => handleKeyDown(e, 'ExcelHeader')} />
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column rightTypeBox height100 '>
                                        <div className='d-flex h-75 h75_dflex'>
                                            <div className='w-50 pe-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <label className='m-2' htmlFor={'brandTyped'}>
                                                        Mapped excel Headers:
                                                    </label>
                                                    <div className="info-icon-container">
                                                        <span className="info-icon">i</span>
                                                        <div className="tooltip">Please enter the column names. Each column name should be end of comma(",") separated</div>
                                                    </div>
                                                </div>
                                                <div className='textOverlowAuto calcH50'>
                                                    <textarea className='textarea height100' id={'brandTyped'} name="postContent" value={excelHeaderMappingValue} onChange={(e) => handleInputChange(e, 'ExcelHeaderMapping')} onKeyDown={(e) => handleKeyDown(e, 'ExcelHeaderMapping')} />
                                                </div>
                                            </div>
                                            <div className='w-50'>
                                                <label className='m-2' htmlFor={'storeTyped'}>
                                                    Predefined Header List:
                                                </label>
                                                <div className='textOverlowAuto calcH50'>
                                                    {/* <CustomeChip disabled={true} value={correctHeaderMappingData} /> */}
                                                    <textarea className='textarea height100' id={'storeTyped'} name="postContent" value={correctHeaderMappingValue} disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex width-100'>
                                            <div className='pe-3 w-50'>
                                                <div className='d-flex justify-content-between align-items-baseline'>
                                                    <label className='m-2' htmlFor={'discount'}>
                                                        Discount in Percentage:
                                                    </label>
                                                    <div className="info-icon-container">
                                                        <span className="info-icon">i</span>
                                                        <div className="tooltip">Please enter the discount column names. Values should be comma(,) separated</div>
                                                    </div>
                                                </div>
                                                <div className='textOverlowAuto calcH36'>
                                                    {/* <CustomeChip disabled={true} value={correctHeaderMappingData} /> */}
                                                    <textarea className='textarea height100' id={'discount'} name="postContent" value={discountPercentageMappingValue} onChange={(e) => handleInputChange(e, 'DiscountPercentage')} onKeyDown={(e) => handleKeyDown(e, 'DiscountPercentage')} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex mt-3'>
                                    <CustomBlueButton customCreatePropsBtnClassName={(selectedReportType !== '' && selectedBrandType !== '' && selectedStoreType !== '' && excelHeaderData?.length !== 0 && excelHeaderMappingData?.length !== 0) ? '' : 'disabled'} title={<div className='d-flex align-items-center'>
                                        <div>Submit</div>
                                    </div>} onClick={onClickSubmitRuleMangment} />
                                </div>
                            </div>
                        }
                        {loading && <Loader />}
                    </div>

                </>
                :
                <div >
                    <div className='mb-4'>
                        <h5 className='d-flex align-items-center'>{renderBackButton(isbtnActive)} Update Rule Management</h5>
                    </div>
                    <div className='mb-2'>
                        <div className='d-flex align-items-end rulemangCreateUpdate'>
                            <div className='position-relative'>
                                <button className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                    onClick={(e) => handleDropDownClick(e, 'BrandType')}>
                                    <p className='text-truncate' title={initialSelectedBrandType.label}>{initialSelectedBrandType?.label}</p>
                                    <img className={`${openBrandTypeFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                </button>
                                {/* <CustomFilterDropDown data={brandTypeList} handleClose={handleTypeClose} open={openBrandTypeFilter} anchorEl={anchorElForBrandType}
                                    onChange={(e) => onSelectChangeType(e, 'BrandType')} selectedValue={initialSelectedBrandType?.value} category={'BrandType'} /> */}
                                <CustomFilterSearchDropdown data={brandTypeList} handleClose={handleTypeClose} open={openBrandTypeFilter} anchorEl={anchorElForBrandType}
                                    onChange={(e) => onSelectChangeType(e, 'BrandType')} selectedValue={initialSelectedBrandType?.value} category={'BrandType'} dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                            </div>
                            <div className='position-relative'>
                                <button className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                    onClick={(e) => handleDropDownClick(e, 'ReportType')}>
                                    <p className='text-truncate' title={initialSelectedReportType.label}>{initialSelectedReportType?.label}</p>
                                    <img className={`${openReportTypeFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                </button>
                                <CustomFilterDropDown data={reportTypeList} handleClose={handleTypeClose} open={openReportTypeFilter} anchorEl={anchorElForReportType}
                                    onChange={(e) => onSelectChangeType(e, 'ReportType')} selectedValue={initialSelectedReportType?.value} category={'ReportType'} />
                            </div>

                            <div className='position-relative'>
                                <ButtonDropdown className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                    onClick={(e) => handleDropDownClick(e, 'StoreType')}>
                                    <p className='text-truncate' title={initialSelectedStoreType.label}>{initialSelectedStoreType?.label}</p>
                                    <img className={`${openStoreTypeFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                </ButtonDropdown>
                                <CustomFilterDropDown data={storeTypeList} handleClose={handleTypeClose} open={openStoreTypeFilter} anchorEl={anchorElForStoreType}
                                    onChange={(e) => onSelectChangeType(e, 'StoreType')} selectedValue={initialSelectedStoreType?.value} category={'StoreType'} />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex ruleMangmentForm updateCont'>
                        <div className='d-flex flex-column leftTypeBox'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <label className='m-2' htmlFor={'reportTypeId'}>
                                    Input excel Headers:
                                </label>
                                <div className="info-icon-container">
                                    <span className="info-icon">i</span>
                                    <div className="tooltip">Please enter the column names. Each column name should be end of comma(",") separated</div>
                                </div>
                            </div>
                            <div className='textOverlowAuto height100'>
                                <textarea className='textarea height100' id={'reportTypeId'} name="postContent" value={excelHeaderValue} onChange={(e) => handleInputChange(e, 'ExcelHeader')} onKeyDown={(e) => handleKeyDown(e, 'ExcelHeader')} />
                            </div>
                        </div>
                        <div className='d-flex flex-column rightTypeBox height100'>
                            <div className='d-flex h-75 h75_dflex'>
                                <div className='w-50 pe-3'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <label className='m-2' htmlFor={'brandTyped'}>
                                            Mapped excel Headers:
                                        </label>
                                        <div className="info-icon-container">
                                            <span className="info-icon">i</span>
                                            <div className="tooltip">Please enter the column names. Each column name should be end of comma(",") separated</div>
                                        </div>
                                    </div>
                                    <div className='textOverlowAuto calcH50'>
                                        <textarea className='textarea height100' id={'brandTyped'} name="postContent" value={excelHeaderMappingValue} onChange={(e) => handleInputChange(e, 'ExcelHeaderMapping')} onKeyDown={(e) => handleKeyDown(e, 'ExcelHeaderMapping')} />
                                    </div>
                                </div>
                                <div className='w-50'>
                                    <label className='m-2' htmlFor={'storeTyped'}>
                                        Predefined Header List:
                                    </label>
                                    <div className='textOverlowAuto h-75'>
                                        {/* <CustomeChip disabled={true} value={correctHeaderMappingData} /> */}
                                        <textarea className='textarea height100' id={'storeTyped'} name="postContent" value={correctHeaderMappingValue} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex width-100'>
                                <div className='pe-3 w-50'>
                                    <div className='d-flex justify-content-between align-items-baseline'>
                                        <label className='m-2' htmlFor={'discount'}>
                                            Discount in Percentage:
                                        </label>
                                        <div className="info-icon-container">
                                            <span className="info-icon">i</span>
                                            <div className="tooltip">Please enter the discount column names. Values should be comma(,) separated</div>
                                        </div>
                                    </div>
                                    <div className='textOverlowAuto calcH36'>
                                        {/* <CustomeChip disabled={true} value={correctHeaderMappingData} /> */}
                                        <textarea className='textarea height100' id={'discount'} name="postContent" value={discountPercentageMappingValue} onChange={(e) => handleInputChange(e, 'DiscountPercentage')} onKeyDown={(e) => handleKeyDown(e, 'DiscountPercentage')} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='d-flex mt-4 mb-4'>
                        <CustomBlueButton customCreatePropsBtnClassName={(selectedReportType !== '' && selectedBrandType !== '' && selectedStoreType !== '' && (excelHeaderData?.length !== 0 || excelHeaderValue !== '') && (excelHeaderMappingData?.length !== 0 || excelHeaderMappingValue !== '')) ? '' : 'disabled'} title={<div className='d-flex align-items-center'>
                            <div>Submit</div>
                        </div>} onClick={onclickHandleUpdateRuleMangment} />
                    </div>
                </div>
            }
            {loading && <Loader />}
        </>
    )
};
export default RuleManagement;