import React, { useEffect, useRef, useState } from 'react';
import CustomFilterDropDown from '../../ReUsableComponent/CustomFilterDropdown';
import CustomDateRangePicker from '../../ReUsableComponent/CustomDaterangePicker';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userAuthActions } from '../../store/reducers/UserAuthReducers';
import dropDownIcon from '../../assets/svg/dropDownIcon.svg';
import CalendarIcon from '../../assets/svg/calendar_2_fill.svg';
import moment from 'moment';
import { GetBrandNameList } from '../RuleManagement/RuleManagement.service';
import { DownloadSalesDashboardReport, GetSalesDashboardData, GetSalesYear } from './SalesOverview.service';
import { salesActions } from '../../store/reducers/SalesOverviewReducer';
import Loader from '../../ReUsableComponent/Loader';
import GrossSalesIcon from '../../assets/svg/gross-sales.svg';
import NetUnitSalesIcon from '../../assets/svg/net-unit-sold.svg';
import NetSalesIcon from '../../assets/svg/net-sales.svg';
import DiscountValueIcon from '../../assets/svg/discount-value.svg';
import { ToLacs } from '../../utils/AmountCalculations';
import { BrandWiseSalesHeader, ProductSKUHeader, TopPerformingCategoryHeader } from '../TableHeaders';
import CustomTableWithFilter from '../../ReUsableComponent/CustomTableWithFilter';
import { AreaCharts } from '../../ReUsableComponent/CustomAreaChart';
import CategoryBarChart from './CategoryBarChart';
import { ToastErrorService, ToastSuccessService } from '../../ReUsableComponent/ToastNotification/ToastService';
import DownloadIcon from '../../assets/svg/blueDownload.svg';
import { saveAs } from 'file-saver';
import CustomFilterSearchDropdown from '../../ReUsableComponent/CustomFilterSearchDropdown';
import dayjs from 'dayjs';

const SalesOverview = () => {

    const today = new Date();
    today.setDate(1)
    today.setHours(-1)
    const firstDayOfMonth = new Date(today.getFullYear(), (today.getMonth()));
    const gridRef = useRef(null);

    const history = useHistory();
    const dispatch = useDispatch();
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [brandList, setBrandList] = useState([]);
    const [salesYearList, setSalesYearList] = useState([]);
    const [overAllSales, setOverAllSales] = useState([]);
    const [anchorElForBrand, setAnchorElForBrand] = useState(null);
    const openBrandFilter = Boolean(anchorElForBrand);
    const [anchorElForSales, setAnchorElForSales] = useState(null);
    const openSalesYearFilter = Boolean(anchorElForSales);
    const [selectedBrand, setSelectedBrand] = useState({ label: '', value: '' });
    const [selectedSalesYear, setSelectedSalesYear] = useState({ label: '', value: '' });
    const [startDateDisplay, setStartDateDisplay] = useState(null);
    const [endDateDisplay, setEndDateDisplay] = useState(null);
    const [selectedRange, setSelectedRange] = useState([firstDayOfMonth,]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const defaultYear = useSelector(state => state.sales.defaultYear);
    const defaultBrand = useSelector(state => state.sales.defaultBrand);
    const [activeTabBtn, setActiveTabBtn] = useState(1);
    const [performingBtn, setPerformingBtn] = useState(1);
    const [topPerformingCategory, setTopPerformingCategory] = useState([]);
    const [bottomPerformingCategory, setBottomPerformingCategory] = useState([]);
    const [topPerformingProduct, setTopPerformingProduct] = useState([]);
    const [bottomPerformingProduct, setBottomPerformingProduct] = useState([]);
    const [totalTopValue, setTotalTopValue] = useState({});
    const [totalBotValues, setTotalBotValues] = useState({});
    const [totalTopProductValue, setTotalTopProductValue] = useState({});
    const [totalBotProductValues, setTotalBotProductValues] = useState({});
    const [totalBrandWiseValues, setTotalBrandWiseValues] = useState({});
    const [grossSalesChartData, setGrossSalesChartData] = useState([]);
    const [brandWiseSaleData, setBrandWiseSaleData] = useState([]);
    const [barGraphLabels, setBarGraphLabels] = useState([]);
    const [barGraphValues, setBarGraphValues] = useState([]);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [disabledDateRange, setDisabledDateRange] = useState(true);
    const [dropSearchValue, setdropSearchValue] = React.useState('');
    const USER_ID = useSelector((state) => state.userAuthentication.userId);
    const handleSearchChange = (event) => {
        setdropSearchValue(event.target.value);
    };

    useEffect(() => {
        dispatch(userAuthActions.setHeaderName({ type: 'Sales Overview' }));
        dispatch(userAuthActions.setIsBackBtnActive({ type: false }));
        getAllBrand();
        getSalesYearList();
    }, [])

    useEffect(() => {
        if (defaultBrand && defaultYear) {
            getDashboardDetails(defaultYear, defaultBrand);
        }

    }, [defaultYear, defaultBrand])

    const openCalendar = () => {
        setCalendarOpen(prev => !prev)
    }

    const onClickGotoReports = () => {
        history.push('/app/salesOverviewList');
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
    }

    const handleBrandClick = (event) => {
        setAnchorElForBrand(event.currentTarget);
    };
    const handleBrandClose = () => {
        setAnchorElForBrand(null);
    };

    const handleSalesClick = (event) => {
        setAnchorElForSales(event.currentTarget);
    };
    const handleSalesClose = () => {
        setAnchorElForSales(null);
    };

    const onSelectBrand = (event) => {
        brandList.forEach(brand => {
            if (event.target.value === brand?.value) {
                setSelectedBrand({ label: brand?.label, value: event.target.value })
                setAnchorElForBrand(null);
            }
        });
        dispatch(salesActions.setDefaultBrand({ type: event.target.value }));
    }

    const onSelectSalesYear = (event) => {
        if (event.target.value !== '') {
            dispatch(salesActions.setDefaultYear({ type: event.target.value }));
            setStartDate(null);
            setEndDate(null);
            setStartDateDisplay(null);
            setEndDateDisplay(null);
            setDisabledDateRange(true);
        } else {
            setDisabledDateRange(false);
        }
        if (event.target.value === '') {
            setSelectedSalesYear({ label: 'Custom', value: event.target.value });
        } else {
            setSelectedSalesYear({ label: event.target.value, value: event.target.value });
        }
        setAnchorElForSales(null);
    }

    const getAllBrand = () => {
        setLoading(true);
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                let brands = []
                response.data.data.forEach(element => {
                    let brandObject = {
                        label: element.brand_name,
                        value: element._id
                    }
                    brands.push(brandObject)
                });
                dispatch(salesActions.setDefaultBrand({ type: brands[0]?.value }));
                setBrandList(brands);
                setSelectedBrand({ label: brands[0]?.label, value: brands[0]?.value });
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickDownload = () => {
        if (startDate && endDate) {
            downloadSalesReport('', selectedBrand?.value, startDate, convertDateStringToObj(dayjs(endDate).endOf('day')?.toISOString()));
        } else {
            downloadSalesReport(selectedSalesYear?.value, selectedBrand?.value)
        }
    }

    const convertDate = (openingDate) => {
        let date = new Date(openingDate);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

        return moment(dateString).format("DD MMM YYYY");
    }

    const convertDateStringToObj = (dateString) => {
        const dateInIST = moment.tz(dateString, 'Asia/Kolkata');
        const finalFormattedDate = new Date(dateInIST.format());
        return finalFormattedDate
    }

    const handleDateRangeSelect = (date) => {
        if (!startDate || (startDate && endDate)) {
            setStartDate(date);
            setEndDate(null);
            setStartDateDisplay(date);
            setSelectedRange([]);
        } else if (startDate && !endDate && date >= startDate) {
            let endDay = dayjs(date).endOf('day');
            setEndDate(convertDateStringToObj(endDay?.toISOString()));
            setEndDateDisplay(convertDateStringToObj(endDay?.toISOString()));
            setSelectedRange([startDate, convertDateStringToObj(endDay?.toISOString())]);
            setCalendarOpen(false)
            getDashboardDetails('', selectedBrand.value, startDate, convertDateStringToObj(endDay?.toISOString()));
        } else if (startDate && !endDate && date < startDate) {
            setStartDate(date);
            setStartDateDisplay(date);
        }
    };

    const getSalesYearList = () => {
        GetSalesYear(USER_TOKEN).then(response => {
            let salesYear = []
            response.data.data.forEach(element => {
                let salesObj = {
                    label: element,
                    value: element
                }
                salesYear.push(salesObj)
            });
            setStartDate(null);
            setEndDate(null);
            setStartDateDisplay(null);
            setEndDateDisplay(null);
            setDisabledDateRange(true);
            salesYear.push({ label: 'Custom', value: '' });
            setSalesYearList(salesYear);
            dispatch(salesActions.setDefaultYear({ type: salesYear.at(-2)?.value }));
            setSelectedSalesYear({ label: salesYear.at(-2)?.label, value: salesYear.at(-2)?.value });
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {

        })
    }

    const downloadSalesReport = (range, brand, startdate, enddate) => {
        setLoading(true);
        let param = `?brand_id=${brand}`;
        if (startdate && enddate) {
            param = param + `&from_date=${startdate}&to_date=${enddate}`;
        } else {
            param = param + `&view_range=${range}`;
        }
        DownloadSalesDashboardReport(param, USER_TOKEN).then(response => {
            const responseInUnit = new Uint8Array(response.data.buffer.data);
            const blob = new Blob([responseInUnit], { type: 'application/octet-stream' });
            saveAs(blob, response.data.filename);
            if (response?.data?.buffer?.statusCode === 400) {
                ToastErrorService(response?.data?.buffer?.message);
            } else {
                ToastSuccessService('Report downloaded successfully.');
            }
        }).catch(error => {
            ToastErrorService(error?.response?.data?.message)
        }).finally(() => {
            setLoading(false);
        })
    }

    const getDashboardDetails = (range, brand, startdate, enddate) => {
        setLoading(true);
        let param = '';
        if (startdate && enddate) {
            param = `?brand_id=${brand}&from_date=${startdate}&to_date=${enddate}`;
        } else {
            param = `?view_range=${range}&brand_id=${brand}`;
        }
        GetSalesDashboardData(param, USER_TOKEN).then(response => {
            setOverAllSales(response?.data?.data?.overAllSales[0]);
            let dataForTopCategory = [];
            let dataForBotCategory = [];
            let totalGrossSales = 0;
            let totalNetSales = 0;
            let totalNetUnitSold = 0;
            let totalDiscount = 0;

            if (response.data.stausCode === 200) {

                setGrossSalesChartData(response?.data?.data?.trendGrossSale)

                response.data.data.topPerformingCategory.forEach(item => {
                    let row = {
                        category: item.category_group,
                        grossSales: `₹${Number(item.totalGrossSale).toLocaleString('en-IN')}`,
                        netSales: `₹${Number(item.totalNetSale).toLocaleString('en-IN')}`,
                        netUnitSold: item.totalQuantity,
                        discount: `${item.discount_percentage.toFixed(2)}%`,
                    }
                    totalGrossSales = totalGrossSales + Number(item.totalGrossSale);
                    totalNetSales = totalNetSales + Number(item.totalNetSale);
                    totalNetUnitSold = totalNetUnitSold + Number(item.totalQuantity);
                    totalDiscount = totalDiscount + Number(item.discount_percentage);
                    dataForTopCategory.push(row);
                })
                let averageDiscountTopCategory = (Number(totalDiscount) / Number(response.data.data.topPerformingCategory?.length));
                setTopPerformingCategory(dataForTopCategory);
                setTotalTopValue(prev => ({ ...prev, totalGrossSales, totalNetSales, totalNetUnitSold, averageDiscountTopCategory }));

                let totalBotGrossSales = 0;
                let totalBotNetSales = 0;
                let totalBotNetUnitSold = 0;
                let totalBotDiscount = 0;

                response.data.data.bottomPerformingCategory.forEach(item => {
                    let row = {
                        category: item.category_group,
                        grossSales: `₹${Number(item.totalGrossSale).toLocaleString('en-IN')}`,
                        netSales: `₹${Number(item.totalNetSale).toLocaleString('en-IN')}`,
                        netUnitSold: item.totalQuantity,
                        discount: `${item.discount_percentage.toFixed(2)}%`,
                    }

                    totalBotGrossSales = totalBotGrossSales + Number(item.totalGrossSale);
                    totalBotNetSales = totalBotNetSales + Number(item.totalNetSale);
                    totalBotNetUnitSold = totalBotNetUnitSold + Number(item.totalQuantity);
                    totalBotDiscount = totalBotDiscount + Number(item.discount_percentage);
                    dataForBotCategory.push(row);
                })
                let averageDiscountBotCategory = (Number(totalBotDiscount) / Number(response.data.data.bottomPerformingCategory?.length));
                setBottomPerformingCategory(dataForBotCategory);
                setTotalBotValues(prev => ({ ...prev, totalBotGrossSales, totalBotNetSales, totalBotNetUnitSold, averageDiscountBotCategory }));


                let dataForTopProduct = [];
                let dataForBotProduct = [];
                let totalProdGrossSales = 0;
                let totalProdNetSales = 0;
                let totalProdNetUnitSold = 0;
                let totalProdDiscount = 0;

                response.data.data.topPerformingProduct.forEach(item => {
                    let row = {
                        nameBrand: item.brand_name,
                        product: item.category_group,
                        sku: item.style_code,
                        grossSales: `₹${Number(item.totalGrossSale).toLocaleString('en-IN')}`,
                        netSales: `₹${Number(item.totalNetSale).toLocaleString('en-IN')}`,
                        netUnitSold: item.totalQuantity,
                        discount: `${item.discount_percentage.toFixed(2)}%`,
                    }

                    totalProdGrossSales = totalProdGrossSales + Number(item.totalGrossSale);
                    totalProdNetSales = totalProdNetSales + Number(item.totalNetSale);
                    totalProdNetUnitSold = totalProdNetUnitSold + Number(item.totalQuantity);
                    totalProdDiscount = totalProdDiscount + Number(item.discount_percentage);
                    dataForTopProduct.push(row);
                })
                let averageDiscountTopProduct = (Number(totalProdDiscount) / Number(response.data.data.topPerformingProduct?.length));
                setTopPerformingProduct(dataForTopProduct);
                setTotalTopProductValue(prev => ({ ...prev, totalProdGrossSales, totalProdNetSales, totalProdNetUnitSold, averageDiscountTopProduct }));

                let totalBotProdGrossSales = 0;
                let totalBotProdNetSales = 0;
                let totalBotProdNetUnitSold = 0;
                let totalBotProdDiscount = 0;

                response.data.data.bottomPerformingProduct.forEach(item => {
                    let row = {
                        nameBrand: item.brand_name,
                        product: item.category_group,
                        sku: item.style_code,
                        grossSales: `₹${Number(item.totalGrossSale).toLocaleString('en-IN')}`,
                        netSales: `₹${Number(item.totalNetSale).toLocaleString('en-IN')}`,
                        netUnitSold: item.totalQuantity,
                        discount: `${item.discount_percentage.toFixed(2)}%`,
                    }
                    totalBotProdGrossSales = totalBotProdGrossSales + Number(item.totalGrossSale);
                    totalBotProdNetSales = totalBotProdNetSales + Number(item.totalNetSale);
                    totalBotProdNetUnitSold = totalBotProdNetUnitSold + Number(item.totalQuantity);
                    totalBotProdDiscount = totalBotProdDiscount + Number(item.discount_percentage);
                    dataForBotProduct.push(row);
                })
                let averageDiscountBotProduct = (Number(totalBotProdDiscount) / Number(response.data.data.bottomPerformingProduct?.length)).toFixed(2);
                setBottomPerformingProduct(dataForBotProduct);
                setTotalBotProductValues(prev => ({ ...prev, totalBotProdGrossSales, totalBotProdNetSales, totalBotProdNetUnitSold, averageDiscountBotProduct }));

                //Brand Wise Sales
                let dataForBrandWiseSale = [];
                let totalBrandWiseGrossSales = 0;
                let totalBrandWiseNetSales = 0;
                let totalBrandWiseNetUnitSold = 0;
                let totalBrandWiseDiscount = 0;
                let totalBrandWiseReturnUnit = 0;
                let totalBrandWiseReturnValue = 0;
                let totalBrandWiseSaleBeforeTax = 0;
                let totalBrandWiseASP = 0;
                let totalBrandWiseATV = 0;
                let totalBrandWiseBs = 0;
                let totalBrandWiseSellThrough = 0;
                let totalBrandWiseMrpValue = 0;
                let totalBrandWiseTargetValue = 0;
                let totalRecords = 0;

                response.data.data.storeSales.splice(0, 20).forEach((item, index) => {
                    let row = {
                        nameStore: valueCheck(item.store_name),
                        // saleBeforeTax: `₹${Number(0).toLocaleString('en-IN')}`,
                        returnUnit: `${item.negative_quantity}`.replace(/-/g, ""),
                        returnValue: `₹${Number(`${item.negative_gross_sale}`.replace(/-/g, "")).toLocaleString('en-IN')}`,
                        asp: `₹${Number(item.average_selling_price).toLocaleString('en-IN')}`,
                        asv: `₹${Number(item.average_selling_value).toLocaleString('en-IN')}`,
                        bs: item.basket_size,
                        sellThrough: item?.sell_through + '%',
                        grossSale: `₹${Number(item.total_gross_sale).toLocaleString('en-IN')}`,
                        netSale: `₹${Number(item.total_net_sale).toLocaleString('en-IN')}`,
                        netUnitSold: item.total_quantity,
                        totalTgtValue: valueCheck(item?.total_value),
                        achievementValue: `₹${Number(item?.total_mrp_value).toLocaleString('en-IN')}`,
                        achievementPercentage: `${item?.achievementPercentage ? item?.achievementPercentage : 0}%`,
                        discount: `${item.total_discount.toFixed(2)}%`,
                        mrpValue: `₹${valueCheck(item.total_mrp_value).toLocaleString('en-IN')}`,
                        markdown: `${valueCheck(item.markdown)}`,
                    }

                    totalBrandWiseGrossSales = totalBrandWiseGrossSales + Number(item.total_gross_sale);
                    totalBrandWiseNetSales = totalBrandWiseNetSales + Number(item.total_net_sale);
                    totalBrandWiseNetUnitSold = totalBrandWiseNetUnitSold + Number(item.total_quantity);
                    totalBrandWiseDiscount = totalBrandWiseDiscount + Number(item.total_discount);
                    totalBrandWiseReturnValue = totalBrandWiseReturnValue + Number(`${item.negative_gross_sale}`.replace(/-/g, ""));
                    totalBrandWiseReturnUnit = totalBrandWiseReturnUnit + Number(`${item.negative_quantity}`.replace(/-/g, ""));
                    totalBrandWiseSaleBeforeTax = totalBrandWiseSaleBeforeTax + Number(0);
                    totalBrandWiseASP = totalBrandWiseASP + Number(item.average_selling_price);
                    totalBrandWiseATV = totalBrandWiseATV + Number(item.average_selling_value);
                    totalBrandWiseBs = totalBrandWiseBs + Number(item.basket_size);
                    totalBrandWiseSellThrough = totalBrandWiseSellThrough + Number(item.sell_through);
                    totalBrandWiseMrpValue = totalBrandWiseMrpValue + Number(item.total_mrp_value);
                    totalBrandWiseTargetValue = totalBrandWiseTargetValue + Number(item.total_value);
                    dataForBrandWiseSale.push(row);
                    totalRecords = index + 1;
                })

                let averageSellThrough = totalBrandWiseSellThrough > 0 ? (Number(totalBrandWiseSellThrough) / Number(totalRecords)).toFixed(2) : 0;
                let averageASP = totalBrandWiseASP > 0 ? (Number(totalBrandWiseASP) / Number(totalRecords)).toFixed(2) : 0;
                let averageATV = totalBrandWiseATV > 0 ? (Number(totalBrandWiseATV) / Number(totalRecords)).toFixed(2) : 0;
                let averageStoreDiscount = totalBrandWiseDiscount > 0 ? (Number(totalBrandWiseDiscount) / Number(totalRecords)).toFixed(2) : 0;
                const totalRow = createBrandWiseTotalRow(totalBrandWiseGrossSales,
                    totalBrandWiseNetSales,
                    totalBrandWiseNetUnitSold,
                    averageStoreDiscount,
                    totalBrandWiseReturnValue,
                    totalBrandWiseReturnUnit,
                    totalBrandWiseSaleBeforeTax,
                    averageASP,
                    averageATV,
                    totalBrandWiseBs,
                    averageSellThrough,
                    totalBrandWiseMrpValue,
                    totalBrandWiseTargetValue);
                setBrandWiseSaleData([...dataForBrandWiseSale, totalRow]);

                // Bar Chart Data
                let graphLabel = [];
                let graphValue = [];
                response.data.data.categoryGrossSales.forEach(item => {
                    graphLabel.push(item.category_group);
                    graphValue.push(item.totalGrossSale);
                })
                setBarGraphLabels(graphLabel);
                setBarGraphValues(graphValue);
            }
        }).catch(error => {
            if (error?.response?.status === 401) {
                history.push('/')
            }
            ToastErrorService(error?.response?.data?.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const valueCheck = (value) => {
        if ((value && value !== 'null')) {
            return value;
        } else {
            return '-';
        }
    }

    const createBrandWiseTotalRow = (totalBrandWiseGrossSales,
        totalBrandWiseNetSales,
        totalBrandWiseNetUnitSold,
        totalBrandWiseDiscount,
        totalBrandWiseReturnValue,
        totalBrandWiseReturnUnit,
        totalBrandWiseSaleBeforeTax,
        totalBrandWiseASP,
        totalBrandWiseASV,
        totalBrandWiseBs,
        totalBrandWiseSellThrough,
        totalBrandWiseMrpValue,
        achievementPercentage,
        totalBrandWiseTargetValue,) => {
        return {
            nameStore: 'Total',
            grossSale: `₹${Number(Math.round(totalBrandWiseGrossSales)).toLocaleString('en-IN')}`,
            netSale: `₹${Number(Math.round(totalBrandWiseNetSales)).toLocaleString('en-IN')}`,
            mrpValue: `₹${Number(Math.round(totalBrandWiseMrpValue)).toLocaleString('en-IN')}`,
            netUnitSold: totalBrandWiseNetUnitSold,
            returnValue: `₹${Number(Math.round(totalBrandWiseReturnValue)).toLocaleString('en-IN')}`,
            returnUnit: totalBrandWiseReturnUnit,
            discount: `${totalBrandWiseDiscount}%`,
            saleBeforeTax: `₹${Number(totalBrandWiseSaleBeforeTax).toLocaleString('en-IN')}`,
            asp: `₹${Number(Math.round(totalBrandWiseASP)).toLocaleString('en-IN')}`,
            asv: `₹${Number(Math.round(totalBrandWiseASV)).toLocaleString('en-IN')}`,
            bs: totalBrandWiseBs,
            sellThrough: Math.round(totalBrandWiseSellThrough) + '%',
            achievementValue: `₹${valueCheck(Math.round(totalBrandWiseMrpValue)).toLocaleString('en-IN')}`,
            totalTgtValue: valueCheck(totalBrandWiseTargetValue),
            achievementPercentage: `${achievementPercentage ? achievementPercentage : 0}%`,
            totalRow: true,
        };
    }

    const getRowStyle = (params) => {
        if (params.data.totalRow) {
            return { fontWeight: 'bold', backgroundColor: '#f0f0f0' };
        }
        return null;
    };

    const barChartData = {
        labels: barGraphLabels,
        datasets: [
            {
                backgroundColor: ({ chart: { ctx } }) => {
                    var canvas = document.getElementById("barrr");
                    const bg = ctx.createLinearGradient(canvas.width / 2, 0, canvas.width / 2, canvas.height);
                    bg.addColorStop(0, '#7D96FF');
                    bg.addColorStop(0.5, '#7263D6');
                    bg.addColorStop(1, '#BBC5EE');
                    return bg;
                },
                borderColor: ({ chart: { ctx } }) => {
                    var canvas = document.getElementById("barrr");
                    const bg = ctx.createLinearGradient(canvas.width / 2, 0, canvas.width / 2, canvas.height);
                    bg.addColorStop(0, '#7D96FF');
                    bg.addColorStop(0.5, '#7263D6');
                    bg.addColorStop(1, '#BBC5EE');
                    return bg;
                },
                borderWidth: 1,
                hoverBackgroundColor: ({ chart: { ctx } }) => {
                    var canvas = document.getElementById("barrr");
                    const bg = ctx.createLinearGradient(canvas.width / 2, 0, canvas.width / 2, canvas.height);
                    bg.addColorStop(0, '#7D96FF');
                    bg.addColorStop(0.5, '#7263D6');
                    bg.addColorStop(1, '#BBC5EE');
                    return bg;
                },
                hoverBorderColor: ({ chart: { ctx } }) => {
                    var canvas = document.getElementById("barrr");
                    const bg = ctx.createLinearGradient(canvas.width / 2, 0, canvas.width / 2, canvas.height);
                    bg.addColorStop(0, '#7D96FF');
                    bg.addColorStop(0.5, '#7263D6');
                    bg.addColorStop(1, '#BBC5EE');
                    return bg;
                },
                borderCapStyle: "round",
                data: barGraphValues,
                barThickness: 40,
            },
        ],
    }

    const onClickTabBtn = (value) => {
        setActiveTabBtn(value);
        setPerformingBtn(1);
    }
    const onClickPerforming = (value) => {
        setPerformingBtn(value)
    }

    const onGridReady = (params) => {
        gridRef.current = params.api;
        gridRef.current.sizeColumnsToFit();
    };

    return (
        <div className='main-container'>
            <div className='d-flex justify-content-between filter-cont'>
                <div className='d-flex align-items-center first-filter'>
                    <div className='goToMasterBtn me-3' onClick={onClickGotoReports}>Go to Reports</div>
                    <div className='position-relative min-w-150'>
                        <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={handleBrandClick}>
                            <p className='text-truncate' title={selectedBrand.label}>{selectedBrand?.label}</p>
                            <img className={`${openBrandFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </div>
                        {/* <CustomFilterDropDown data={brandList} handleClose={handleBrandClose} open={openBrandFilter} anchorEl={anchorElForBrand}
                            onChange={onSelectBrand} selectedValue={selectedBrand?.value} category={'Brand'} /> */}
                        <CustomFilterSearchDropdown data={brandList} handleClose={handleBrandClose} open={openBrandFilter} anchorEl={anchorElForBrand}
                            onChange={onSelectBrand} selectedValue={selectedBrand?.value} category={'Brand'}
                            dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    <div className='position-relative min-w-150'>
                        <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={handleSalesClick}>
                            <p className='text-truncate' title={selectedSalesYear.label}>{selectedSalesYear?.label}</p>
                            <img className={`${openSalesYearFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </div>
                        <CustomFilterDropDown data={salesYearList} handleClose={handleSalesClose} open={openSalesYearFilter} anchorEl={anchorElForSales}
                            onChange={onSelectSalesYear} selectedValue={selectedSalesYear?.value} category={'Category'} />
                    </div>
                    {/* <div className='me-3'>Or</div> */}
                    <div className='position-relative'>
                        <button className={`d-flex dateRangeFilter cursorPointer align-items-center ${!disabledDateRange ? 'justify-content-between' : ''}`} disabled={disabledDateRange}
                            onClick={openCalendar} >
                            <img src={CalendarIcon} alt="" />
                            {(startDateDisplay && endDateDisplay) ? <div>{`${convertDate(startDateDisplay)} - ${convertDate(endDateDisplay)}`}</div> : <div className='text-center w-100'>Select Date</div>}
                        </button>
                        <div className=''>
                            <CustomDateRangePicker restrictFutureDates={true} startDate={startDate} endDate={endDate} calendarOpen={calendarOpen} selectedRange={selectedRange} handleSelect={handleDateRangeSelect}
                                closeOnOutSide={() => setCalendarOpen(false)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='sales-dash-container pt-3'>
                <div className='sales-card'>
                    <div className='img-class'>
                        <img src={GrossSalesIcon} alt="" />
                    </div>
                    <div>
                        <div className='heading'>Gross Sale</div>
                        <div className='sale-value text-white'>₹{overAllSales?.totalGrossSale ? ToLacs(overAllSales?.totalGrossSale) : '0'}
                            <span className='fontSize14 ps-2'>Lac</span>
                        </div>
                    </div>
                </div>
                <div className='sales-card'>
                    <div className='img-class'>
                        <img src={NetSalesIcon} alt="" />
                    </div>
                    <div>
                        <div className='heading'>Net Sale</div>
                        <div className='sale-value'>₹{overAllSales?.totalNetSale ? ToLacs(overAllSales?.totalNetSale) : '0'}
                            <span className='fontSize14 ps-2'>Lac</span>
                        </div>
                    </div>
                </div>
                <div className='sales-card'>
                    <div className='img-class'>
                        <img src={NetUnitSalesIcon} alt="" />
                    </div>
                    <div>
                        <div className='heading'>Net Unit Sold</div>
                        <div className='sale-value'>{overAllSales?.totalQuantity ? overAllSales?.totalQuantity : '0'}</div>
                    </div>
                </div>
                <div className='sales-card'>
                    <div className='img-class'>
                        <img src={DiscountValueIcon} alt="" />
                    </div>
                    <div>
                        <div className='heading'>Discount Percentage</div>
                        <div className='sale-value'>{overAllSales?.discountPercentage ? overAllSales?.discountPercentage.toFixed(2) : '0'}%
                        </div>
                    </div>
                </div>
            </div>
            <div className='sales-category'>
                <div className='gross-sales-category'>
                    <div className='montserratFont fontSize18 textColorBlack'>Gross Sales - Category Wise</div>
                    <div style={{ width: '100%', overflowX: 'auto' }}>
                        <CategoryBarChart width={370} height={249} chartData={barChartData} />
                    </div>
                </div>
                <div className='gross-sales-category table-view'>
                    <div className='tab-btn-container'>
                        <button onClick={() => onClickTabBtn(1)} className={`tab-btn notoSansFont fontSemibold fontSize12 ${activeTabBtn === 1 && 'active-btn'}`}>Category</button>
                        <button onClick={() => onClickTabBtn(2)} className={`tab-btn notoSansFont fontSemibold fontSize12 ${activeTabBtn === 2 && 'active-btn'}`}>Products (SKU)</button>
                    </div>
                    <div className='dashed-border'></div>
                    <div className='pt-3 btn-cont'>
                        <button onClick={() => onClickPerforming(1)} className={`notoSansFont fontSemibold fontSize12 ${performingBtn === 1 ? 'active-btn-2' : 'tab-btn-2'} me-3`}>Top Performing - 5</button>
                        <button onClick={() => onClickPerforming(2)} className={`notoSansFont fontSemibold fontSize12 ${performingBtn === 2 ? 'active-btn-2' : 'tab-btn-2'}`}>Non Selling - 5</button>
                    </div>
                    {activeTabBtn === 1 && <div className='paddingTop16 sales-dashboard'>
                        <CustomTableWithFilter isContentLeftRightFixed={false} height={450} column={TopPerformingCategoryHeader} tableRowData={performingBtn === 1 ? topPerformingCategory : bottomPerformingCategory} onGridReady={onGridReady} customeHeightClass={'category-table'} />
                        <table className='total-value'>
                            {performingBtn === 1 && <tr>
                                <td>Total</td>
                                <td>₹{Number(totalTopValue?.totalGrossSales).toLocaleString('en-IN')}</td>
                                <td>₹{Number(totalTopValue?.totalNetSales).toLocaleString('en-IN')}</td>
                                <td>{totalTopValue?.totalNetUnitSold}</td>
                                <td>{totalTopValue?.averageDiscountTopCategory ? totalTopValue?.averageDiscountTopCategory.toFixed(2) : 0}%</td>
                            </tr>}
                            {performingBtn === 2 && <tr>
                                <td>Total</td>
                                <td>₹{Number(totalBotValues?.totalBotGrossSales).toLocaleString('en-IN')}</td>
                                <td>₹{Number(totalBotValues?.totalBotNetSales).toLocaleString('en-IN')}</td>
                                <td>{totalBotValues?.totalBotNetUnitSold}</td>
                                <td>{totalBotValues?.averageDiscountBotCategory ? totalBotValues?.averageDiscountBotCategory.toFixed(2) : 0}%</td>
                            </tr>}
                        </table>
                    </div>}
                    {activeTabBtn === 2 && <div className='paddingTop16 sales-dashboard'>
                        <CustomTableWithFilter isContentLeftRightFixed={false} height={450} column={ProductSKUHeader} tableRowData={performingBtn === 1 ? topPerformingProduct : bottomPerformingProduct} onGridReady={onGridReady} customeHeightClass={'category-table'} />
                        <table className='total-value-prod'>
                            {performingBtn === 1 && <tr>
                                <td></td>
                                <td>Total</td>
                                <td>₹{Number(totalTopProductValue?.totalProdGrossSales).toLocaleString('en-IN')}</td>
                                <td>₹{Number(totalTopProductValue?.totalProdNetSales).toLocaleString('en-IN')}</td>
                                <td>{totalTopProductValue?.totalProdNetUnitSold}</td>
                                <td>{totalTopProductValue?.averageDiscountTopProduct ? totalTopProductValue?.averageDiscountTopProduct.toFixed(2) : 0}%</td>
                            </tr>}
                            {performingBtn === 2 && <tr>
                                <td></td>
                                <td>Total</td>
                                <td>₹{Number(totalBotProductValues?.totalBotProdGrossSales).toLocaleString('en-IN')}</td>
                                <td>₹{Number(totalBotProductValues?.totalBotProdNetSales).toLocaleString('en-IN')}</td>
                                <td>{totalBotProductValues?.totalBotProdNetUnitSold}</td>
                                <td>{totalBotProductValues?.averageDiscountBotProduct ? totalBotProductValues?.averageDiscountBotProduct : 0}%</td>
                            </tr>}
                        </table>
                    </div>}
                </div>
            </div>
            <div className='trend-gross-sales'>
                <div className='montserratFont fontSize18'>
                    Trend Gross Sales
                </div>
                <div className='paddingTop32'>
                    <AreaCharts chartData={grossSalesChartData} dataKeyArea={'totalGrossSale'} dataKeyXaxis={'monthName'} />
                </div>
            </div>

            <div className='brand-wise-sale'>
                <div className='d-flex justify-content-between'>
                    <div className='montserratFont fontSize18'>
                        {selectedBrand?.label} Sale
                    </div>
                    <button className='download-btn' onClick={onClickDownload}>
                        <img src={DownloadIcon} width={24} height={24} alt="" />
                    </button>
                </div>
                <div className='paddingTop16'>
                    <CustomTableWithFilter isContentLeftRightFixed={true} getRowStyle={getRowStyle} column={BrandWiseSalesHeader} totalValues={totalBrandWiseValues} tableRowData={brandWiseSaleData} onGridReady={null} />
                </div>
            </div>
            {loading && <Loader />}
        </div>
    )
}

export default SalesOverview
